import React, { useEffect, useState } from "react";
import "./signAssignModal.css";
import { useMutation } from "@apollo/client";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import UpdateSignAssignmentCheck from "../../../../utils/formValidators/signAssignmentFormValidate";
import {
  UPDATE_SIGN_ASSIGN,
  CREATE_SIGN_ASSIGN,
} from "../../../../utils/GraphQL/mutations";

const UpdateSignAssignModal = ({ close, selectedSignObj, open, createNew }) => {
  const [updateSign] = useMutation(UPDATE_SIGN_ASSIGN);
  const [createSign] = useMutation(CREATE_SIGN_ASSIGN);
  const [formErrors, setFormErrors] = useState([]);
  const [updateSignObj, setUpdateSignObj] = useState({
    signNum: "",
    signName: "",
  });

  useEffect(() => {
    const body = document.querySelector("body");

    if (open === true) {
      body.style.overflow = "hidden"
    }
    if (open === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectedSignObj && selectedSignObj !== undefined) {
      setUpdateSignObj({
        ...selectedSignObj,
      });
    }
  }, [selectedSignObj]);

  // form validation class
  const updateTest = new UpdateSignAssignmentCheck(updateSignObj);

  const updateSignAssign = async () => {
    const valid = await updateTest.updateValid();

    if (valid !== true) {
      setFormErrors(valid);
      return;
    }

    if (valid === true) {
      const { data } = await updateSign({
        variables: { ...updateSignObj },
      });

      if (data?.updateSignAssignment?.signName === updateSignObj.signName) {
        clearItemObj();
        close(true);
        return;
      }
    }
  };

  const createSignAssign = async () => {
    const valid = await updateTest.updateValid();

    if (valid !== true) {
      setFormErrors(valid);
      return;
    }

    if (valid === true) {
      const { data } = await createSign({
        variables: { ...updateSignObj },
      });

      if (data?.createSignAssignment?.signName === updateSignObj.signName) {
        clearItemObj();
        close("new");
        return;
      }
    }
  };

  const clearItemObj = () => {
    setUpdateSignObj(() => ({
      signNum: "",
      signName: "",
    }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "signNum") {
      value = value.replace(/[^0-9]/gi, "");
    }
    if (name === "signName") {
      value = capitalizeFirstLetters(value);
    }

    setUpdateSignObj((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className={open ? "sign-modal-show" : "sign-modal-hidden"}>
      <div
        onClick={(event) => {
          event.stopPropagation();
          clearItemObj();
          close();
        }}
        className="sign-update-item-modal-bg"
      ></div>
      <div className="sign-update-item-modal-box">
        <div className="sign-update-item-modal-hdr">
          {createNew ? (
            <h2>Create New Sign Tracking</h2>
          ) : (
            <h2>Update Sign Tracking</h2>
          )}
          <button
            onClick={(event) => {
              event.stopPropagation();
              clearItemObj();
              close();
            }}
            className="close-modal-x-btn"
          >
            X
          </button>
        </div>
        {createNew ? (
          <CssTextFieldStandard
            sx={{ width: "95%", my: 1 }}
            type="text"
            label={`Sign Number`}
            name="signNum"
            inputMode="numeric"
            inputProps={{ inputMode: "numeric" }}
            value={updateSignObj.signNum}
            onChange={handleChange}
            variant="standard"
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                  createNew ? createSignAssign() : updateSignAssign();
                }
              }}
          />
        ) : (
          <h3 className="sign-update-hdr">Sign {updateSignObj.signNum}: </h3>
        )}
        <CssTextFieldStandard
          sx={{ width: "95%", my: 1 }}
          type="text"
          label={`Current Assignee: ${selectedSignObj?.signName}`}
          name="signName"
          inputMode="text"
          value={updateSignObj.signName}
          onChange={handleChange}
          onFocus={() => {
            setFormErrors([]);
          }}
          variant="standard"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              createNew ? createSignAssign() : updateSignAssign();
            }
          }}
        />
        {formErrors && (
          <ul>
            {formErrors?.map((err) => {
              return <li key={err}>{err}</li>;
            })}
          </ul>
        )}
        <button className="sign-update-modal-item-btn" onClick={() => {
            createNew ? createSignAssign() : updateSignAssign();
        }}>
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateSignAssignModal;
