class SendEmailValidator {
    constructor(formState) {
      this.to = formState.to;
      this.from = formState.from;
      this.subject = formState.subject;
      this.text = formState.text;
      this.type = formState.type;
      this.attachArr = formState.attachArr;
      this.quoteLink = formState.quoteLink;

      this.errors = [];
    }
  
    async testToEmail() {
      const emailRegex = /.+@.+\..+/;
      if (emailRegex.test(this.to)) {
        return true;
      }
  
      this.errors.push('Please Enter Valid To: Email');
      return false;
    }

    async testFromEmail() {
      const emailRegex = /.+@.+\..+/;
      if (emailRegex.test(this.from)) {
        return true;
      }
  
      this.errors.push('Please Enter Valid From: Email');
      return false;
    }

    async testSubject() {
      if (this.subject?.length > 0) {
        return true;
      }
  
      this.errors.push('Please Enter Email Subject');
      return false;
    }
    
    async testEmailText() {
      if (this.type === "quoteTemplate") {
        return true;
      }

      if (this.text?.length > 0) {
        return true;
      }
  
      this.errors.push('Please Enter Email Message Body');
      return false;
    }
    
    async testEmailType() {
      if (this.type?.length > 0) {
        return true;
      }
  
      this.errors.push('Please Enter Email Template Type');
      return false;
    }
    
    async testQuoteLink() {
      if (this.type !== "quoteTemplate") {
        return true;
      }

      if (this.quoteLink?.length > 0 && this.type === "quoteTemplate") {
        return true;
      }
  
      this.errors.push('Please Enter Valid Quote Link');
      return false;
    }
    
    async testAttachArr() {
      if (typeof this.attachArr === "object") {
        return true;
      }
  
      this.errors.push('Error with attachments. Please reload page and try again.');
      return false;
    }
  
    async emailMesValid() {
      this.errors = [];
  
      //run tests for errors first
      await this.testToEmail();
      await this.testFromEmail();
      await this.testSubject();
      await this.testEmailText();
      await this.testEmailType();
      await this.testAttachArr();

  
      if (this.errors?.length === 0) {
        return true;
      }
  
      return this.errors;
    }
  }
  
  export default SendEmailValidator;
  