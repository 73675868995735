import React, { useEffect, useState, useRef } from "react";
import "./clients.css";
import { Snackbar } from "@mui/material";
import AdminBDRSpinner from "../../adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import CreateClient from "../../adminComponents/CreateClient/CreateClient";
import { GET_RECENT_CLIENTS } from "../../../../utils/GraphQL/queries";
import { useLazyQuery } from "@apollo/client";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClientSearchInput from "../../adminComponents/ClientSearchInput/ClientSearchInput";
import { useNavigate } from "react-router";

const Clients = () => {
  const navigate = useNavigate()
  const [totalMinHeight, setTotalMinHeight] = useState(2000);
  const [recentClients, setRecentClients] = useState([]);
  const [clientSaving, setClientSaving] = useState({ bool: false, mes: "" });
  const [pageHeightTrigger, setPageHeightTrigger] = useState(0);
  const ClientsPage = useRef();
  const [queryRecentClients] = useLazyQuery(GET_RECENT_CLIENTS, {
    fetchPolicy: "network-only",
  });
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePageHeight();
    recentClientsCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageHeightTrigger > 0) {
      handlePageHeight();
    }
  }, [pageHeightTrigger]);

  const heightTrigger = () => {
    setPageHeightTrigger((prev) => prev + 1);
  };

  const changeSaving = (bool, mes) => {
    setClientSaving({
      bool: bool,
      mes: mes,
    });
  };

  const recentClientsCall = async () => {
    const { data } = await queryRecentClients();

    if (data?.getRecentClients?.clientIds) {
      const reversed = [...data?.getRecentClients?.clientIds].reverse();
      setRecentClients(reversed);
    }

    changeSaving(false, "");
  };

  const handlePageHeight = () => {
    setTimeout(() => {
      const navHeight = document.querySelector(
        "#admin-nav-height-marker"
      )?.offsetHeight;
      const createClientHeight = document.querySelector(
        "#create-client-height-marker"
      )?.offsetHeight;
      const headerHeight = document.querySelector(
        "#clients-page-hdr-marker"
      )?.offsetHeight;

      const totalHeight =
        navHeight + createClientHeight + headerHeight + window.innerHeight;
      setTotalMinHeight(totalHeight);
    }, 1000);
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  return (
    <div
      className="clients-page"
      ref={ClientsPage}
      style={{ minHeight: `${totalMinHeight}px` }}
    >
      {clientSaving?.bool && <AdminBDRSpinner message={clientSaving?.mes} />}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <CreateClient
        heightTrigger={heightTrigger}
        changeSaving={changeSaving}
        recentClientsCall={recentClientsCall}
        handleSnack={handleSnack}
        dropdown={true}
      />
      <div className="clients-page-container">
        {recentClients?.length > 0 ? (
          <div className="clients-page-recent-box">
            <div className="clients-page-recent-header">
              <h2>Recently Created/Viewed Clients</h2>
              <button
                className="clients-page-recent-refresh-button"
                onClick={() => {
                  changeSaving(true, "Loading...");
                  recentClientsCall();
                }}
              >
                <RefreshIcon />
              </button>
            </div>
            <div className="clients-search-field-box">
              <ClientSearchInput />
            </div>
            {recentClients?.map((client, index) => {
              return (
                <div
                  key={index + client?._id}
                  className="clients-page-recent-item"
                >
                  <h5>
                    <div
                      className={
                        client?.clientStatus === "Lead"
                          ? "clients-page-small-circle lead"
                          : client?.clientStatus === "Client"
                          ? "clients-page-small-circle client"
                          : "clients-page-small-circle other"
                      }
                    ></div>{" "}
                    {client?.firstName} {client?.lastName}
                  </h5>
                  <div className="clients-page-recent-item-status">
                    <span>
                      Status: {client?.clientStatus}{" "}
                      <button
                        className="clients-page-client-anchor"
                        onClick={() => {
                          navigate(`/bdr-office/clients/view-client/${client?._id}`)
                        }}
                      >
                        View
                      </button>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h2>No Recent Clients</h2>
        )}
      </div>
    </div>
  );
};

export default Clients;
