import React, { useEffect } from "react";
import {
  REQ_WEB_AUTHN_CHALLENGE,
  DELETE_UNUSED_WEB_AUTHN_CHALLENGE,
} from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import { Buffer } from "buffer";
import "./webAuthnComp.css";
import { useNavigate } from "react-router-dom";
import Auth from "../../../../utils/auth";

const WebAuthnAuthenticate = ({ changeLoggedIn }) => {
  const navigate = useNavigate();
  const [reqChallenge] = useMutation(REQ_WEB_AUTHN_CHALLENGE);
  const [deleteChallenge] = useMutation(DELETE_UNUSED_WEB_AUTHN_CHALLENGE);

  useEffect(() => {
    if (navigator.credentials && navigator.credentials.get) {
      getAndAuthenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChallenge = async () => {
    const { data } = await reqChallenge();

    if (data?.reqWebAuthnChallenge) {
      return data?.reqWebAuthnChallenge;
    }
  };

  const base64ToUint8Array = (base64String) => {
    const binaryString = atob(base64String);
    const uint8Array = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return uint8Array;
  };

  const hashClientData = async (clientData) => {
    const hashBuffer = await crypto.subtle.digest("SHA-256", clientData);

    return hashBuffer;
  };

  const loginBypassRecent = async () => {
    localStorage.removeItem("BDRLoggedOut");
    getAndAuthenticate();

    return;
  };

  const getAndAuthenticate = async () => {
    // if recently logged out in last 5 mins, don't run function.
    const recentLogoutMills = localStorage.getItem("BDRLoggedOut");
    if (recentLogoutMills) {
      const fiveMin = 1000 * 60 * 5;
      const dateMinus5 = Date.now() - fiveMin;
      if (parseInt(recentLogoutMills) > dateMinus5) {
        return;
      }
    }

    const authAbortController = new AbortController();
    const authAbortSignal = authAbortController.signal;
    const challengeCall = await getChallenge();
    const serverString = challengeCall?.challenge;

    // Retrieve from the cookie and decode
    const localIdStored = localStorage.getItem("BDRWebAuthnCredId");

    if (!localIdStored) {
      deleteChallenge({
        variables: { challenge: serverString },
      });
      return;
    }

    const credentialUint8Array = base64ToUint8Array(localIdStored);

    const publicKeyCredentialRequestOptions = {
      challenge: Uint8Array.from(serverString, (c) => c.charCodeAt(0)),
      allowCredentials: [
        {
          id: credentialUint8Array?.buffer,
          type: "public-key",
        },
      ],
      timeout: 30000,
    };

    try {
      const assertion = await navigator.credentials.get({
        publicKey: publicKeyCredentialRequestOptions,
        signal: authAbortSignal,
      });

      // Decoding clientData for challenge retrieval for verification
      const utf8Decoder = new TextDecoder("utf-8");
      const decodedClientDataGet = utf8Decoder.decode(
        assertion.response.clientDataJSON
      );
      const clientAssertionDataObj = JSON.parse(decodedClientDataGet);
      const clientChallenge = clientAssertionDataObj.challenge;

      const userId = utf8Decoder.decode(assertion?.response.userHandle);

      const signature = Buffer.from(
        new Uint8Array(assertion?.response?.signature),
        "utf-8"
      ).toString("base64");

      // Hashed for authData concatenation for signature
      // verification on server with public key
      const authData = assertion.response.authenticatorData;
      const hashedClient = await hashClientData(
        assertion.response.clientDataJSON
      );

      //authData and hashedClientData concatenation
      const array1 = new Uint8Array(authData);
      const array2 = new Uint8Array(hashedClient);
      const combinedArray = new Uint8Array(array1.length + array2.length);
      combinedArray.set(array1, 0);
      combinedArray.set(array2, array1.length);

      fetch("/bdr-office/web-authn-authentication", {
        method: "POST",
        body: JSON.stringify({
          credentialId: localIdStored,
          userId,
          challenge: clientChallenge,
          signature: signature,
          hashedClientAuthData: Buffer.from(combinedArray, "utf8").toString(
            "base64"
          ),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(async (body) => {
          if (body.token) {
            const loginSuccess = await Auth.login(body.token);
            if (loginSuccess) {
              await changeLoggedIn(true);

              navigate("/bdr-office/dashboard");
            }
          }
        });
    } catch (error) {
      deleteChallenge({
        variables: { challenge: serverString },
      });
    }
  };

  return (
    <button className="web-authn-btn" onClick={loginBypassRecent}>
      Login
    </button>
  );
};

export default WebAuthnAuthenticate;
