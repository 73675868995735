import React, { useEffect, useState } from "react";
import "./clientModalInvoiceQuotePage.css";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";

const logo = require("../../../../assets/images/BDR-logo.png");

const ClientModalInvoiceQuotePage = ({
  invoiceObj,
  quoteObj,
  stripePreview,
}) => {
  const [isInvoice, setIsInvoice] = useState(false);
  const [clientQuote, setClientQuote] = useState({});
  const [clientInvoice, setClientInvoice] = useState({});
  const [quoteDate, setQuoteDate] = useState("");
  const [discountTotal, setDiscountTotal] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");

  useEffect(() => {
    if (quoteObj) {
      setIsInvoice(false);
      setClientQuote({ ...quoteObj });
      setDiscountPercent(quoteObj?.discountPercent);
    }

    if (invoiceObj) {
      setIsInvoice(true);
      setClientInvoice({ ...invoiceObj });
      setDiscountPercent(invoiceObj?.discountPercent);
      console.log(invoiceObj);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteObj, invoiceObj]);

  useEffect(() => {
    if (clientQuote?.dateCreated || clientInvoice?.jobScheduledFor) {
      const useDate = isInvoice
        ? clientInvoice?.jobScheduledFor
        : clientQuote?.dateCreated;

      const date = BAGroupTimeFormat(useDate, false, true)
        ?.split(" ")
        ?.slice(1);

      setQuoteDate(`${date[0]} ${date[1]}, ${date[2]}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientQuote?.dateCreated, clientInvoice?.jobScheduledFor]);

  useEffect(() => {
    if (clientQuote?.quoteItems || clientInvoice?.invoiceItems) {
      const arr = clientInvoice?.invoiceItems?.length
        ? [...clientInvoice?.invoiceItems]
        : [...clientQuote?.quoteItems];

      const totalArr = arr?.map((item) => {
        return parseInt(
          makeTotal(
            parseInt(item?.price),
            parseInt(item?.quantity),
            item?.discountApplicable
          )
        );
      });

      const subtotalArr = arr?.map((item) => {
        return parseInt(
          makeTotal(parseInt(item?.price), parseInt(item?.quantity), false)
        );
      });

      const quoteTotal = totalArr?.reduce((price, price2) => price + price2, 0);
      const subtotal = subtotalArr?.reduce(
        (price, price2) => price + price2,
        0
      );

      const discountOff = subtotal - quoteTotal;
      console.log(subtotal);
      console.log(quoteTotal);
      console.log(discountOff);
      setDiscountTotal(`${discountOff}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientQuote?.quoteItems, clientInvoice?.invoiceItems]);

  const makeTotal = (price, quantity, discount) => {
    const total = discount
      ? ((((100 - parseInt(discountPercent)) / 100) * price * quantity) / 100)
          ?.toFixed(2)
          .replace(".", "")
      : ((price * quantity) / 100)?.toFixed(2).replace(".", "");

    return total;
  };

  return (
    <div className="client-invoice-quote-modal-page-container">
      <div
        className="client-invoice-quote-modal-page"
        id="client-invoice-quote-modal-invoice-display"
      >
        <div
          className={
            isInvoice && clientInvoice?.invoicePaid
              ? "client-invoice-quote-modal-paid-invoice-box"
              : "client-invoice-quote-modal-paid-invoice-box-none"
          }
        >
          <div className="client-invoice-quote-modal-paid-invoice">PAID</div>
        </div>
        <div className="client-invoice-quote-modal-info-accept-box">
          <div className="client-invoice-quote-modal-company-info">
            <div className="client-invoice-quote-modal-company-info-inner">
              <div className="client-invoice-quote-modal-img-box">
                <img
                  className="client-invoice-quote-modal-logo-img"
                  src={logo}
                  alt="BDR Logo"
                />
              </div>
              <ul className="client-invoice-quote-modal-ul">
                <li>
                  <strong>Biggin's Door Refinishing</strong>
                </li>
                <li>18975 Marbach Ln</li>
                <li>Suite 22-1209</li>
                <li>San Antonio, Tx 78266</li>
              </ul>
            </div>
          </div>
          <div className="client-invoice-quote-modal-accept-box">
            <ul className="client-invoice-quote-modal-ul">
              <li className="client-invoice-quote-modal-text-right">
                <strong>{isInvoice ? "Invoice" : "Quote"} #</strong>
              </li>
              <li className="client-invoice-quote-modal-text-right">
                {isInvoice ? clientInvoice?.invoiceNum : clientQuote?.quoteNum}
              </li>
              {!stripePreview && (
                <>
                  <li className="client-invoice-quote-modal-text-right">
                    <strong>
                      Internal {isInvoice ? "Invoice" : "Quote"} Id #
                    </strong>
                  </li>
                  <li className="client-invoice-quote-modal-text-right">
                    {isInvoice ? clientInvoice?.id : clientQuote?.id}
                  </li>
                </>
              )}

              {!isInvoice && (
                <li className="client-invoice-quote-modal-text-right">
                  <span>
                    <strong>Status:</strong> {clientQuote?.quoteStatus}
                  </span>
                </li>
              )}
            </ul>
            {!isInvoice && (
              <div>
                {/active/gi.test(clientQuote?.quoteStatus) &&
                  !clientQuote?.quoteAccepted && (
                    <button
                      className="client-invoice-quote-modal-accept-button"
                      onClick={() => {
                        window?.alert(
                          "This is for customers to click! Da hell?!"
                        );
                      }}
                    >
                      Accept Quote
                    </button>
                  )}
                {/active/gi.test(clientQuote?.quoteStatus) &&
                  clientQuote?.quoteAccepted && (
                    <div className="client-invoice-quote-modal-accepted-box">
                      <h5>
                        Great! We will contact you shortly to schedule your
                        appointment.
                      </h5>
                    </div>
                  )}
                {/expired/gi.test(clientQuote?.quoteStatus) && (
                  <div className="client-invoice-quote-modal-invoice-warning-box">
                    <span>
                      We're sorry, this quote has expired. Please contact us to
                      request an updated quote!
                    </span>
                  </div>
                )}
                {/invoiced/gi.test(clientQuote?.quoteStatus) && (
                  <div className="client-invoice-quote-modal-invoice-warning-box">
                    <h3 className="client-invoice-quote-modal-invoice-warning">
                      Invoiced
                    </h3>
                    <span>Please contact us to request a new quote.</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="client-invoice-quote-modal-to-box">
          <div className="client-invoice-quote-modal-to-section">
            <span>
              <strong>To</strong>
            </span>
            <h5 className="client-invoice-quote-modal-to-bubble">
              {isInvoice
                ? capitalizeFirstLetters(clientInvoice?.customerName)
                : capitalizeFirstLetters(clientQuote?.customerName)}
            </h5>
          </div>
          <div className="client-invoice-quote-modal-to-section">
            <span>
              <strong>{isInvoice ? "Scheduled" : "Quote"} Date</strong>
            </span>
            <h5 className="client-invoice-quote-modal-to-bubble">
              {quoteDate}
            </h5>
          </div>
          <div className="client-invoice-quote-modal-to-section">
            <span>
              <strong>Total Amount</strong>
            </span>
            <h5 className="client-invoice-quote-modal-to-bubble">
              {isInvoice
                ? priceStrFormatter(clientInvoice?.invoiceTotal)
                : priceStrFormatter(clientQuote?.quoteTotal)}
            </h5>
          </div>
        </div>
        {/* Display for Invoice Items */}
        {clientInvoice?.invoiceItems?.length > 0 && (
          <div className="client-invoice-quote-modal-items-container">
            <div className="client-invoice-quote-modal-items-item">
              <span>Item</span>
              <span>Price</span>
              <span>Quantity</span>
              <span>Discount</span>
              <span>Total</span>
            </div>
            <div className="client-invoice-quote-modal-hr"></div>
            {clientInvoice?.invoiceItems?.map((item, index) => {
              const total = makeTotal(
                parseInt(item?.price),
                parseInt(item?.quantity),
                item?.discountApplicable
              );

              return (
                <div
                  key={`client-invoice-quote-modal-item-${index}-${item?.itemName}`}
                  className="client-invoice-quote-modal-items-item"
                >
                  <span>{item?.itemName}</span>
                  <span>{priceStrFormatter(item?.price)}</span>
                  <span>{item?.quantity}</span>
                  <span>
                    {item?.discountApplicable ? `${discountPercent}%` : "N/A"}
                  </span>
                  <span>{priceStrFormatter(total)}</span>
                </div>
              );
            })}
            <div className="client-invoice-quote-modal-hr"></div>
            <div className="client-invoice-quote-modal-totals-box">
              <div>
                <span>
                  Subtotal: {priceStrFormatter(clientInvoice?.subtotal)}
                </span>
              </div>
              {discountTotal?.length > 0 && (
                <div>
                  <span>- Discount: {priceStrFormatter(discountTotal)}</span>
                </div>
              )}
              <div>
                <strong>
                  <span>
                    Invoice Total:{" "}
                    {priceStrFormatter(clientInvoice?.invoiceTotal)}
                  </span>
                </strong>
                {clientInvoice?.paidBy && (
                  <div className="client-invoice-quote-modal-page-paid-by">
                    <span>(Paid by: {clientInvoice?.paidBy})</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Display for Quote Items */}
        {clientQuote?.quoteItems?.length > 0 && (
          <div className="client-invoice-quote-modal-items-container">
            <div className="client-invoice-quote-modal-items-item">
              <span>Item</span>
              <span>Price</span>
              <span>Quantity</span>
              <span>Discount</span>
              <span>Total</span>
            </div>
            <div className="client-invoice-quote-modal-hr"></div>
            {clientQuote?.quoteItems?.map((item, index) => {
              const total = makeTotal(
                parseInt(item?.price),
                parseInt(item?.quantity),
                item?.discountApplicable
              );

              return (
                <div
                  key={`client-invoice-quote-modal-item-${index}-${item?.itemName}`}
                  className="client-invoice-quote-modal-items-item"
                >
                  <span>{item?.itemName}</span>
                  <span>{priceStrFormatter(item?.price)}</span>
                  <span>{item?.quantity}</span>
                  <span>
                    {item?.discountApplicable ? `${discountPercent}%` : "N/A"}
                  </span>
                  <span>{priceStrFormatter(total)}</span>
                </div>
              );
            })}
            <div className="client-invoice-quote-modal-hr"></div>
            <div className="client-invoice-quote-modal-totals-box">
              <div>
                <span>
                  Subtotal: {priceStrFormatter(clientQuote?.subtotal)}
                </span>
              </div>
              {discountTotal?.length > 0 && (
                <div>
                  <span>- Discount: {priceStrFormatter(discountTotal)}</span>
                </div>
              )}
              <div>
                <strong>
                  <span>
                    Quote Total: {priceStrFormatter(clientQuote?.quoteTotal)}
                  </span>
                </strong>
              </div>
            </div>
          </div>
        )}
        {isInvoice ? (
          clientInvoice?.invoiceNote?.length > 0 && (
            <p className="client-invoice-quote-modal-page-note">
              {clientInvoice?.invoiceNote}
            </p>
          )
        ) : (
          <>
            <p className="client-invoice-quote-modal-page-note">
              Quote will remain valid for 90 days after it was issued. Valid
              until{" "}
              <strong>{BAGroupTimeFormat(clientQuote?.expirationDate)}</strong>.
            </p>
            {clientQuote?.quoteNote?.length > 0 && (
              <p className="client-invoice-quote-modal-page-note">
                {clientQuote?.quoteNote}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClientModalInvoiceQuotePage;
