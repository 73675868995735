import React, { useEffect, useState } from "react";
import { GET_SERVICE_ITEMS } from "../../../../utils/GraphQL/queries";
import "./createQuoteModal.css";
import { useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssTextFieldStandardBlack } from "../../../../components/CssTextFields/CssTextFieldStandard";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  priceStrFormatter,
  removePriceStrFormatter,
} from "../../../../utils/formatters/priceStrFormatter";

const clearedNewItem = {
  itemName: "",
  price: "0",
  quantity: "",
  discountApplicable: false,
  jobType: "",
};

const NewQuoteItemBox = ({ discountPercent, addQuoteItem, handleSnack }) => {
  const under600 = useMediaQuery("(max-width: 600px)");
  const { data: servicesData } = useQuery(GET_SERVICE_ITEMS);
  const [customPrice, setCustomPrice] = useState(false);
  const [totalDisplay, setTotalDisplay] = useState("0");
  const [newItem, setNewItem] = useState({ ...clearedNewItem });

  useEffect(() => {
    if (newItem?.itemName !== "") {
      totalHandler(
        newItem?.price,
        newItem?.quantity,
        newItem?.discountApplicable
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountPercent]);

  const totalHandler = (price, quantity, discount) => {
    const total = discount
      ? ((((100 - discountPercent) / 100) * price * quantity) / 100)
          ?.toFixed(2)
          .replace(".", "")
      : ((price * quantity) / 100)?.toFixed(2).replace(".", "");

    setTotalDisplay(total);
  };

  const handleNewItem = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "itemName") {
      setCustomPrice(false);
      const selected = servicesData?.getServicesItems?.filter(
        (item) => item?.itemName === value
      )[0];

      setNewItem((prev) => {
        const singlePrice = parseInt(selected?.price) || 0;
        const howMany = parseInt(prev?.quantity) || 1;

        totalHandler(
          singlePrice,
          howMany,
          selected?.discountApplicable || false
        );

        return {
          ...prev,
          itemName: selected?.itemName,
          price: singlePrice?.toString(),
          quantity: howMany?.toString(),
          discountApplicable: selected?.discountApplicable || false,
          jobType: selected?.jobType,
        };
      });

      return;
    }

    if (name === "quantity") {
      // setCustomPrice(false);
      setNewItem((prev) => {
        const selected = servicesData?.getServicesItems?.filter(
          (item) => item?.itemName === prev?.itemName
        )[0];

        const singlePrice = customPrice
          ? parseInt(prev?.price)
          : parseInt(selected?.price) || 0;
        const howMany = parseInt(value) || 1;

        if (!customPrice) {
          totalHandler(
            singlePrice,
            howMany,
            selected?.discountApplicable || false
          );
        }

        if (customPrice) {
          totalHandler(singlePrice, 1, false);
        }

        return {
          ...prev,
          price: singlePrice?.toString(),
          quantity: howMany?.toString(),
        };
      });

      return;
    }

    if (name === "price") {
      setCustomPrice(true);
      setNewItem((prev) => {
        value = removePriceStrFormatter(value);

        totalHandler(parseInt(value), 1, false);

        return {
          ...prev,
          price: value,
        };
      });

      return;
    }

    if (name === "discountApplicable") {
      // setCustomPrice(false);
      setNewItem((prev) => {
        const selected = servicesData?.getServicesItems?.filter(
          (item) => item?.itemName === prev?.itemName
        )[0];
        const singlePrice = customPrice
          ? parseInt(prev?.price)
          : parseInt(selected?.price) || 0;
        const howMany = parseInt(prev?.quantity) || 1;

        if (!customPrice) {
          totalHandler(singlePrice, howMany, value || false);
        }

        if (customPrice) {
          totalHandler(singlePrice, 1, false);
        }

        return {
          ...prev,
          price: singlePrice?.toString(),
          discountApplicable: value,
        };
      });

      return;
    }
  };

  const resetPrice = () => {
    setCustomPrice(false);
    setNewItem((prev) => {
      const selected = servicesData?.getServicesItems?.filter(
        (item) => item?.itemName === prev?.itemName
      )[0];

      const singlePrice = parseInt(selected?.price) || 0;
      const howMany = parseInt(prev?.quantity) || 1;

      totalHandler(singlePrice, howMany, prev?.discountApplicable || false);

      return {
        ...prev,
        price: singlePrice?.toString(),
        quantity: howMany?.toString(),
      };
    });

    return;
  };

  return (
    <div className="create-quote-new-item-box">
      <FormControl
        variant="standard"
        sx={{
          marginBottom: "3px",
          minWidth: under600 ? "65%" : "34%",
          background: "#e4e4e4",
          borderRadius: "3px",
          border: "1px solid #000",
          padding: "0 5px",
        }}
      >
        <InputLabel
          id="item-select"
          sx={{
            color: "#000",
            "&.Mui-focused": { color: "#000" },
          }}
        >
          Item
        </InputLabel>
        <Select
          labelId="item-select"
          id="item-select-input"
          name="itemName"
          value={newItem?.itemName}
          onChange={(event) => {
            handleNewItem(event);
          }}
          disableUnderline={true}
          sx={{
            color: "#000",
            WebkitTextFillColor: "#000",
            "& .Mui-disabled": {
              color: "#000 !important",
              WebkitTextFillColor: "#000",
            },
          }}
          style={{ borderBottom: "none" }}
        >
          {servicesData?.getServicesItems?.length > 0 &&
            servicesData?.getServicesItems?.map((item) => {
              return (
                <MenuItem key={item?.id} value={item?.itemName}>
                  {item?.itemName}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        sx={{
          marginBottom: "3px",
          minWidth: under600 ? "30%" : "20%",
          background: "#e4e4e4",
          borderRadius: "3px",
          border: "1px solid #000",
          padding: "0 5px",
        }}
      >
        <InputLabel
          id="quantity-select"
          sx={{
            color: "#000",
            "&.Mui-focused": { color: "#000" },
          }}
        >
          Quantity
        </InputLabel>
        <Select
          labelId="quantity-select"
          id="quantity-select-input"
          name="quantity"
          value={newItem?.quantity}
          onChange={(event) => {
            handleNewItem(event);
          }}
          disableUnderline={true}
          sx={{
            color: "#000",
            WebkitTextFillColor: "#000",
            "& .Mui-disabled": {
              color: "#000 !important",
              WebkitTextFillColor: "#000",
            },
          }}
          style={{ borderBottom: "none" }}
        >
          {[...Array(30)]?.map((item, index) => {
            const num = (index + 1)?.toString();

            return (
              <MenuItem key={`quantity-select-menu-${index}`} value={num}>
                {num}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <CssTextFieldStandardBlack
        sx={{
          width: under600 ? "45%" : "20%",
          marginBottom: "3px",
          background: "#e4e4e4",
          borderRadius: "3px",
          border: "1px solid #000",
          padding: "0 5px",
        }}
        type="tel"
        label={
          customPrice ? (
            "Custom Price - Per"
          ) : (
            "Price"
          )
        }
        inputMode="tel"
        name="price"
        value={priceStrFormatter(totalDisplay)}
        onChange={handleNewItem}
        variant="standard"
      />
      <FormControl
        variant="standard"
        sx={{
          marginBottom: "3px",
          minWidth: under600 ? "50%" : "20%",
          background: "#e4e4e4",
          borderRadius: "3px",
          border: "1px solid #000",
          padding: "0 5px",
        }}
      >
        <InputLabel
          id="apply-discount-select"
          sx={{
            color: "#000",
            "&.Mui-focused": { color: "#000" },
          }}
        >
          Apply Discount?
        </InputLabel>
        <Select
          labelId="apply-discount-select"
          id="apply-discount-select-input"
          name="discountApplicable"
          value={newItem?.discountApplicable}
          onChange={(event) => {
            handleNewItem(event);
          }}
          disableUnderline={true}
          sx={{
            color: "#000",
            WebkitTextFillColor: "#000",
            "& .Mui-disabled": {
              color: "#000 !important",
              WebkitTextFillColor: "#000",
            },
          }}
          style={{ borderBottom: "none" }}
        >
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      </FormControl>
      <div className="create-quote-new-item-save">
        {customPrice && (
          <button className="create-quote-save-button" onClick={resetPrice}>
            Custom Price - Reset
          </button>
        )}
        <button
          className="create-quote-save-button"
          onClick={() => {
            if (newItem?.itemName !== "") {
              addQuoteItem(newItem);
              setNewItem({ ...clearedNewItem });
              setCustomPrice(false);
              setTotalDisplay("0");
              handleSnack("Item Added", 2000);
              return;
            }

            handleSnack("Please select an item", 2500);
            return;
          }}
        >
          Add Item
        </button>
      </div>
    </div>
  );
};

export default NewQuoteItemBox;
