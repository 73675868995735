class UserTaskFormValidator {
  constructor(formState) {
    this.assigneeName = formState.assigneeName;
    this.assigneeId = formState.assigneeId;
    this.taskType = formState.taskType;
    this.note = formState.note;
    this.dueDate = formState.dueDate;

    this.errors = [];
  }

  async testAssigneeName() {
    const test = this.assigneeName === "" ? false : true;

    if (test === false) {
      this.errors.push("Please assign task.");
    }

    return test;
  }

  async testAssigneeId() {
    return this.assigneeId === "" ? false : true;
  }

  async testTaskType() {
    const test = this.taskType === "" ? false : true;

    if (test === false) {
      this.errors.push("Please select task type.");
    }

    return test;
  }

  async testNote() {
    const test = this.note === "" ? false : true;

    if (test === false) {
      this.errors.push("Please leave task note.");
    }

    return test;
  }

  async testDueDate() {
    const test = this.dueDate === "" ? false : true;

    if (test === false) {
      this.errors.push("Please select due date.");
    }

    return test;
  }

  async formValid() {
    this.errors = [];

    await this.testAssigneeName();
    await this.testAssigneeId();
    await this.testTaskType();
    await this.testNote();
    await this.testDueDate();

    return this.errors?.length === 0 ? true : this.errors;
  }
}

export default UserTaskFormValidator;
