import React, { useState, useRef, useEffect } from "react";
import {
  SEND_NEW_INVITE,
  SEND_TEMP_PASS,
} from "../../../../utils/GraphQL/mutations";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { Button, Snackbar } from "@mui/material";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import "./inviteUser.css";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import { useMutation } from "@apollo/client";

const InviteNewUser = ({ open }) => {
  const inviteUserScrollRef = useRef();
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [resetSentSuccess, setResetSentSuccess] = useState(false);
  const [userInviteMes, setUserInviteMes] = useState("");
  const [sendInviteEmail] = useMutation(SEND_NEW_INVITE);
  const [sendTempPassReset] = useMutation(SEND_TEMP_PASS);
  const [inviteForm, setInviteForm] = useState({
    emailToInvite: "",
    firstName: "",
    permission: "",
  });

  //   useEffect(() => {
  //     console.log(inviteForm);
  //     // eslint-disable-next-line
  //   }, [inviteForm]);

  useEffect(() => {
    if (open !== undefined) {
      setBarCollapsed(!open)
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSnack = () => {
    setOpenSnack(true);
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  const handleInviteForm = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "firstName") {
      value = capitalizeFirstLetters(value);
    }

    if (name === "emailToInvite") {
      value = value.toLowerCase();
    }

    setInviteForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const autoEmailAdd = (event) => {
    const atDomain = event.target.innerText;
    const email = inviteForm?.emailToInvite.split("@")[0];

    setInviteForm((prev) => {
      return { ...prev, emailToInvite: `${email}${atDomain}` };
    });
  };

  const changePerm = (event) => {
    const value = event.target.name;
    setInviteForm((prev) => {
      return { ...prev, permission: value };
    });
  };

  const sendInvite = async () => {
    const emailRegex = /.+@.+\..+/;

    const Email = emailRegex.test(inviteForm?.emailToInvite);
    const Perm = inviteForm.permission?.length > 0;
    const First = inviteForm.firstName?.length > 0;

    if (Email && Perm && First) {
      const { data } = await sendInviteEmail({
        variables: { ...inviteForm },
      });

      if (data?.sendInviteEmail === null) {
        setUserExists(true);
        setUserInviteMes("User Already Exists - Send Password Reset?");
        return;
      }

      setInviteForm({
        emailToInvite: "",
        firstName: "",
        permission: "",
      });

      handleSnack();
    }
  };

  const sendTempPass = async () => {
    const { data: tempData } = await sendTempPassReset({
      variables: { emailTo: inviteForm.emailToInvite },
    });
    console.log(tempData?.sendForgotPasswordEmail);

    if (tempData?.sendForgotPasswordEmail === true) {
      setUserInviteMes("Password Reset Email Sent");
      setResetSentSuccess(true);
      return;
    }

    setUserInviteMes("Error - Email Not Sent");
    setResetSentSuccess(false);
    return;
  };

  return (
    <div
      ref={inviteUserScrollRef}
      id="invite-user-height-marker"
      className="invite-user-container"
    >
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message="Invite Sent"
      />
      <button
        onClick={() => {
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = inviteUserScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              inviteUserScrollRef.current.scrollIntoView({ block: "start" });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Invite New User</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="invite-user-email-input">
          <div className="invite-user-sec">
            <CssTextFieldStandard
              sx={{ width: "45%", my: 1 }}
              type="text"
              label={"First Name"}
              name="firstName"
              onChange={handleInviteForm}
              value={inviteForm.firstName}
              variant="standard"
            />
            <div>
              <h3>Permission:</h3>
              <button
                name="admin"
                onClick={changePerm}
                className={
                  inviteForm.permission === "admin"
                    ? "invite-user-autofill-email selected"
                    : "invite-user-autofill-email"
                }
              >
                Admin
              </button>
              <button
                name="office"
                onClick={changePerm}
                className={
                  inviteForm.permission === "office"
                    ? "invite-user-autofill-email selected"
                    : "invite-user-autofill-email"
                }
              >
                Office
              </button>
              <button
                name="refinisher"
                onClick={changePerm}
                className={
                  inviteForm.permission === "refinisher"
                    ? "invite-user-autofill-email selected"
                    : "invite-user-autofill-email"
                }
              >
                Refinisher
              </button>
            </div>
          </div>

          <CssTextFieldStandard
            sx={{ width: "95%", my: 1 }}
            type="text"
            label={"Enter New User Email"}
            name="emailToInvite"
            onChange={handleInviteForm}
            value={inviteForm.emailToInvite}
            variant="standard"
          />
          <div className="invite-user-sec emails">
            <button
              onClick={autoEmailAdd}
              className="invite-user-autofill-email"
            >
              @gmail.com
            </button>
            <button
              onClick={autoEmailAdd}
              className="invite-user-autofill-email"
            >
              @yahoo.com
            </button>
            <button
              onClick={autoEmailAdd}
              className="invite-user-autofill-email"
            >
              @icloud.com
            </button>
          </div>
          {userExists && (
            <Button onClick={sendTempPass}>
              <span
                className={
                  resetSentSuccess ? "invite-exists success" : "invite-exists"
                }
              >
                {userInviteMes}
              </span>
            </Button>
          )}
          <Button onClick={sendInvite} id="invite-user-button">
            Send Invite
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InviteNewUser;
