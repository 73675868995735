class PictureFormTest {
    constructor(formState) {
      this.beforeId = formState.beforeId;
      this.afterId = formState.afterId;
      this.dateEntered = formState.dateEntered;
      this.dateEnteredText = formState.dateEnteredText;
      this.refinishers = formState.refinishers;
      this.stainColor = formState.stainColor;
      this.stainColorChoice = formState.stainColorChoice;
    }
  
    async testBeforeId() {
      return this.beforeId === "" ? false : true;
    }
  
    async testAfterId() {
      return this.afterId === "" ? false : true;
    }
    
    async testDateEntered() {
      return this.dateEntered === "" ? false : true;
    }
    
    async testDateEnteredText() {
      return this.dateEnteredText === "" || this.dateEnteredText.length < 8 ? false : true;
    }
    
    async testRefinishers() {
      return this.refinishers.length === 0 ? false : true;
    }
  
    async testOther() {
      if (this.stainColorChoice === "Other") {
          return this.stainColor === "Other" || this.stainColor === ""
          ? false
          : true;
      }
      
      return true;
    }
  
    async uploadValid() {
      if (
        (await this.testBeforeId()) &&
        (await this.testAfterId()) &&
        (await this.testDateEntered()) &&
        (await this.testRefinishers()) &&
        (await this.testOther())
      ) {
        return true;
      }
  
      return false;
    }
  }
  
  export default PictureFormTest;
  