//switch case function to append lettered suffix
const dateSuffix = (val) => {
  let dateNum = Number(val);
  let numFormat = "";

  switch (dateNum) {
    case 1:
      numFormat = "1st";
      break;
    case 21:
      numFormat = "21st";
      break;
    case 31:
      numFormat = "31st";
      break;
    case 2:
      numFormat = "2nd";
      break;
    case 22:
      numFormat = "22nd";
      break;
    case 3:
      numFormat = "3rd";
      break;
    case 23:
      numFormat = "23rd";
      break;
    default:
      numFormat = `${dateNum}th`;
  }

  return numFormat;
};

export default dateSuffix;