import React, { useEffect, useState, useRef } from "react";
import "./singlePictureLookup.css";
import { useLazyQuery } from "@apollo/client";
import { QUERY_CLIENT_PHOTOS } from "../../../../utils/GraphQL/queries";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";

const SinglePictureLookup = ({ heightTrigger }) => {
  const [imgLoading, setImgLoading] = useState(false);
  const [searchedId, setSearchedId] = useState("");
  const [barCollapsed, setBarCollapsed] = useState(true);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [loadPhoto, { data: queryData }] = useLazyQuery(QUERY_CLIENT_PHOTOS);
  const [imgData, setImgData] = useState({});
  const picLookupScrollRef = useRef();
  const picLookupInput = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (queryData?.getImageById[0]) {
      setImgData(queryData?.getImageById[0]);
    }
  }, [queryData]);

  const handleIdChange = async (event) => {
    const value = event.target.value;
    setSearchedId(value);
    if (value.length === 24) {
      setImgLoading((prev) => !prev);
      await loadPhoto({ variables: { pictureIdArr: [value] } });

      setImgLoading((prev) => !prev);
    }

    return;
  };

  return (
    <div
      id="admin-pic-lookup-height-marker"
      className="pic-lookup-container"
      ref={picLookupScrollRef}
    >
      {imgLoading && <AdminBDRSpinner message={"Loading..."} />}
      <button
        onClick={() => {
          if (heightTrigger) {
            heightTrigger();
          }
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = picLookupScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
              setTimeout(() => {
                picLookupInput.current?.focus();
              }, 900);
            }

            if (smaller900 === false && barCollapsed === true) {
              picLookupScrollRef.current.scrollIntoView({ block: "start" });
              setTimeout(() => {
                picLookupInput.current?.focus();
              }, 900);
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);

          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Photo Lookup</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="pic-lookup-form">
          <CssTextFieldStandard
            sx={{ width: "95%", my: 1 }}
            type="text"
            label="Enter Photo Id"
            name="dateEnteredText"
            inputProps={{ maxLength: "24", ref: picLookupInput }}
            onChange={handleIdChange}
            onBlur={handleIdChange}
            value={searchedId}
            variant="standard"
          />
        </div>
        {imgData?.imageData && (
          <div className="img-by-id-box">
            <h4>Id: {imgData?._id}</h4>
            <img
              className="img-by-id-img"
              src={imgData?.imageData}
              alt="Single Door"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePictureLookup;
