import React, { useEffect, useState } from "react";
import "./customerQuotePage.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_OUTWARD_CLIENT_QUOTE } from "../../utils/GraphQL/queries";
import { ACCEPT_CLIENT_QUOTE } from "../../utils/GraphQL/mutations";
import AdminBDRSpinner from "../AdminDashboard/adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import { priceStrFormatter } from "../../utils/formatters/priceStrFormatter";
import capitalizeFirstLetters from "../../utils/formatters/capitalizeFirstLetters";
import BAGroupTimeFormat from "../../utils/formatters/BAGroupTimeFormat";
import Contact from "../Contact/Contact";

const logo = require("../../assets/images/BDR-logo.png");

const CustomerQuotePage = ({ displayNav, changeStickyTab, changeFooter }) => {
  const { quoteId } = useParams();
  const [getQuote] = useLazyQuery(GET_OUTWARD_CLIENT_QUOTE);
  const [acceptClientQuoteGQL] = useMutation(ACCEPT_CLIENT_QUOTE);
  const [loading, setLoading] = useState(true);
  const [infoFound, setInfoFound] = useState(true);
  const [clientQuote, setClientQuote] = useState({});
  const [quoteDate, setQuoteDate] = useState("");
  const [discountTotal, setDiscountTotal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    displayNav(false);
    changeStickyTab(false);
    changeFooter(false);
    setLoading(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quoteId) {
      queryQuote();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  useEffect(() => {
    if (clientQuote?.dateCreated) {
      const useDate = clientQuote?.dateCreated;

      const date = BAGroupTimeFormat(useDate, false, true)
        ?.split(" ")
        ?.slice(1);

      setQuoteDate(`${date[0]} ${date[1]}, ${date[2]}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientQuote?.dateCreated]);

  useEffect(() => {
    const totalArr = clientQuote?.quoteItems?.map((item) => {
      return parseInt(
        makeTotal(
          parseInt(item?.price),
          parseInt(item?.quantity),
          item?.discountApplicable
        )
      );
    });

    const subtotalArr = clientQuote?.quoteItems?.map((item) => {
      return parseInt(
        makeTotal(parseInt(item?.price), parseInt(item?.quantity), false)
      );
    });

    const quoteTotal = totalArr?.reduce((price, price2) => price + price2, 0);
    const subtotal = subtotalArr?.reduce((price, price2) => price + price2, 0);

    const discountOff = subtotal - quoteTotal;
    setDiscountTotal(`${discountOff}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientQuote?.quoteItems]);

  const makeTotal = (price, quantity, discount) => {
    const total = discount
      ? (
          (((100 - parseInt(clientQuote?.discountPercent)) / 100) *
            price *
            quantity) /
          100
        )
          ?.toFixed(2)
          .replace(".", "")
      : ((price * quantity) / 100)?.toFixed(2).replace(".", "");

    return total;
  };

  const queryQuote = async (id) => {
    const bdr = localStorage.getItem("bdr-non-visitor");

    const { data } = await getQuote({
      variables: {
        quoteId: quoteId,
        localEpoch: Date.now()?.toString(),
        isUser: /true/gi?.test(bdr),
      },
    });

    if (data?.getCustomerOutwardQuote) {
      setInfoFound(true);
      setClientQuote({ ...data?.getCustomerOutwardQuote });

      return;
    }

    setInfoFound(false);
    return;
  };

  const acceptQuote = async () => {
    if (quoteId) {
      const { data } = await acceptClientQuoteGQL({
        variables: {
          quoteId: quoteId,
          localEpoch: Date.now().toString(),
        },
      });

      if (data?.acceptClientQuote) {
        queryQuote();
      }
    }
  };

  return (
    <div className="cust-quote-page-container">
      {loading && <AdminBDRSpinner message={"Loading"} />}
      {infoFound ? (
        <>
          {quoteId && (
            <div className="cust-quote-page-print-btn-box">
              <button
                className="cust-quote-accept-button"
                onClick={() => {
                  window.print();
                }}
              >
                Print Quote
              </button>
            </div>
          )}
          <div className="cust-quote-page" id="cust-quote-invoice-display">
            <div className="cust-quote-info-accept-box">
              <div className="cust-quote-company-info">
                <div className="cust-quote-company-info-inner">
                  <div className="cust-quote-img-box">
                    <img
                      className="cust-quote-logo-img"
                      src={logo}
                      alt="BDR Logo"
                    />
                  </div>
                  <ul className="cust-quote-ul">
                    <li>
                      <strong>Biggin's Door Refinishing</strong>
                    </li>
                    <li>18975 Marbach Ln</li>
                    <li>Suite 22-1209</li>
                    <li>San Antonio, Tx 78266</li>
                  </ul>
                </div>
              </div>
              <div className="cust-quote-accept-box">
                <ul className="cust-quote-ul">
                  <li className="cust-quote-text-right">
                    <strong>Quote #</strong>
                  </li>
                  <li className="cust-quote-text-right">
                    {clientQuote?.quoteNum}
                  </li>
                </ul>
                {clientQuote?.quoteStatus && (
                  <div>
                    {/active/gi.test(clientQuote?.quoteStatus) &&
                      !clientQuote?.quoteAccepted && (
                        <button
                          className="cust-quote-accept-button"
                          onClick={acceptQuote}
                        >
                          Accept Quote
                        </button>
                      )}
                    {/active/gi.test(clientQuote?.quoteStatus) &&
                      clientQuote?.quoteAccepted && (
                        <div className="cust-quote-accepted-box">
                          <h5>
                            Great! We will contact you shortly to schedule your
                            appointment.
                          </h5>
                        </div>
                      )}
                    {/expired/gi.test(clientQuote?.quoteStatus) && (
                      <div className="cust-quote-invoice-warning-box">
                        <span>
                          We're sorry, this quote has expired. Please contact us
                          to request an updated quote!
                        </span>
                      </div>
                    )}
                    {/invoiced/gi.test(clientQuote?.quoteStatus) && (
                      <div className="cust-quote-invoice-warning-box">
                        <h3 className="cust-quote-invoice-warning">Invoiced</h3>
                        <span>Please contact us to request a new quote.</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="cust-quote-to-box">
              <div className="cust-quote-to-section">
                <span>
                  <strong>To</strong>
                </span>
                <h5 className="cust-quote-to-bubble">
                  {capitalizeFirstLetters(clientQuote?.customerName)}
                </h5>
              </div>
              <div className="cust-quote-to-section">
                <span>
                  <strong>Quote Date</strong>
                </span>
                <h5 className="cust-quote-to-bubble">{quoteDate}</h5>
              </div>
              <div className="cust-quote-to-section">
                <span>
                  <strong>Total Amount</strong>
                </span>
                <h5 className="cust-quote-to-bubble">
                  {priceStrFormatter(clientQuote?.quoteTotal)}
                </h5>
              </div>
            </div>
            {clientQuote?.quoteItems?.length > 0 && (
              <div className="cust-quote-items-container">
                <div className="cust-quote-items-item">
                  <span>Item</span>
                  <span>Price</span>
                  <span>Quantity</span>
                  <span>Discount</span>
                  <span>Total</span>
                </div>
                <div className="cust-quote-hr"></div>
                {clientQuote?.quoteItems?.map((item, index) => {
                  const total = makeTotal(
                    parseInt(item?.price),
                    parseInt(item?.quantity),
                    item?.discountApplicable
                  );

                  return (
                    <div
                      key={`cust-quote-item-${index}-${item?.itemName}`}
                      className="cust-quote-items-item"
                    >
                      <span>{item?.itemName}</span>
                      <span>{priceStrFormatter(item?.price)}</span>
                      <span>{item?.quantity}</span>
                      <span>{item?.discountApplicable ? `${clientQuote?.discountPercent}%` : "N/A"}</span>
                      <span>{priceStrFormatter(total)}</span>
                    </div>
                  );
                })}
                <div className="cust-quote-hr"></div>
                <div className="cust-quote-totals-box">
                  <div>
                    <span>
                      Subtotal: {priceStrFormatter(clientQuote?.subtotal)}
                    </span>
                  </div>
                  {discountTotal?.length > 0 && (
                    <div>
                      <span>
                        - Discount: {priceStrFormatter(discountTotal)}
                      </span>
                    </div>
                  )}
                  <div>
                    <strong>
                      <span>
                        Quote Total:{" "}
                        {priceStrFormatter(clientQuote?.quoteTotal)}
                      </span>
                    </strong>
                  </div>
                </div>
              </div>
            )}
            <p className="cust-quote-page-note">
              Quote will remain valid for 90 days after it was issued. Valid
              until{" "}
              <strong>{BAGroupTimeFormat(clientQuote?.expirationDate)}</strong>.
            </p>
            {clientQuote?.quoteNote?.length > 0 && (
              <p className="cust-quote-page-note">{clientQuote?.quoteNote}</p>
            )}
          </div>
        </>
      ) : (
        <div className="cust-quote-none-div">
          <div className="cust-quote-none-header">
            <h1>No Quote Found</h1>
            <p>
              To receive information about your quote, or to request a quote,
              please contact <strong>Biggin's Door Refinishing</strong>!
            </p>
          </div>
          <div className="cust-quote-contact-info-box">
            <Contact displayHeader={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerQuotePage;
