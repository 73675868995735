import React, { useEffect } from "react";
import { Box } from "@mui/material";
import "./home.css"
import HomeImageCarousel from "../../components/Carousels/HomeImageCarousel/HomeImageCarousel";
import ReviewCarousel from "../../components/Carousels/ReviewsCarousel/ReviewCarousel";
import Tracking from "../../components/TrackingComp";
import OnVisible, { setDefaultProps } from 'react-on-visible';
// import SpecialModal from "../../components/SpecialModal/SpecialModal";


const logo = require("../../assets/images/BDR-logo.png");

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  setDefaultProps({
    bounce: true,
    visibleClassName: 'tag-line-box',
    percent: 10
});
  
  return (
    <Box>
      {/* <SpecialModal /> */}
      <Box className="home-box">
      <div className="home-image-box">
        <HomeImageCarousel/>
        <Box sx={{display: {xs: "none", sm: "block", md: "none"}}}>
          <ul className="small-list">
            <li><h1>We Provide:</h1></li>
            <li><h2>Excellent Customer Relations</h2></li>
            <li><h2>Work done in Any weather</h2></li>
            <li><h2>Same Day On-site</h2></li>
            <li><h2>Weather Strip/ Door Sweep replacement</h2></li>
          </ul>
        </Box>
      </div>
      
      <section className="home-info">
        <div>
          <img className="logo-img" src={logo} alt="BDR Logo" />
          <h2 className="center-text text-shadow">Welcome to</h2>
          <h1 className="text-shadow home-info-hdr">Biggin's Door Refinishing</h1>
          <h3 className="info-section">We are your local experts specializing in 
          refinishing residential wood doors
          </h3>
          <p>At Biggin's Door Refinishing, we are focused on providing you with a quality refinished door 
            while maintaining the highest level of customer satisfaction. 
            We will do everything we can to meet your expectations.
          </p>
          <p>
          Just as you care about the appearance of your home, we care about the 
          service we provide to you. We take pride in our finished product and we're 
          positive you'll be happy working with us.
          </p>
          <h2 className="text-shadow">Let OUR family serve YOUR family!</h2>
        </div>
      </section>
      </Box>
        <OnVisible>
          <div className="home-absolute-box">
            <div id="make-visible" className="tag-line-box-invis">
              <h1 className="tag-line-header">"You may find somebody cheaper, but you can't find anybody BETTER!"</h1>
            </div>
          </div>
        </OnVisible>
      <Box className="home-box">
        <ReviewCarousel />
      </Box>
      <Tracking  page={"home"} />
    </Box>
  );
};

export default Home;
