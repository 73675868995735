class CreateQuoteValidator {
  constructor(formState) {
    this.quoteStatus = formState.quoteStatus;
    this.dateCreated = formState.dateCreated;
    this.createdBy = formState.createdBy;
    this.quoteItems = formState.quoteItems;
    this.quoteTotal = formState.quoteTotal;
    this.subtotal = formState.subtotal;
    this.customerName = formState.customerName;
    this.quoteNum = formState.quoteNum;
    this.expirationDate = formState.expirationDate;
    this.discountPercent = formState.discountPercent;
    this.customerId = formState.customerId;
    this.items = formState.items;

    this.errors = [];
  }

  async testStatus() {
    if (/active|expired|invoiced/gi.test(this.quoteStatus)) {
      return true;
    }

    this.errors.push("Please select status");
    return false;
  }

  async testDateAndCreator() {
    if (this.dateCreated?.length > 0 && this.createdBy?.length > 0) {
      return true;
    }

    this.errors.push(
      `Something went wrong. Please close the modal and try again.`
    );
    return false;
  }

  async testItems() {
    //   itemName: "",
    //   price: "0",
    //   quantity: "",
    //   discountApplicable: false,

    if (this.items?.length > 0) {
      this.items?.forEach((item, index) => {
        if (item?.itemName === "") {
          this.errors.push(`Please remove and re-select item #${index + 1}`);
          return false;
        }

        if (item?.quantity === "" || parseInt(item?.quantity) <= 0) {
          this.errors.push(
            `Please enter valid quantity for item #${index + 1}`
          );
          return false;
        }

        const nameIsDiscount = /discount/gi.test(item?.itemName)
        if (!nameIsDiscount && parseInt(item?.price) <= 0) {
          this.errors.push(`Please enter valid price for item #${index + 1}`);
          return false;
        }
      });
    } else {
      this.errors.push("Please select item(s)");
      return false;
    }

    return true;
  }

  async testQuoteItems() {
    //   itemName: "",
    //   price: "0",
    //   quantity: "",
    //   discountApplicable: false,

    if (this.quoteItems?.length > 0) {
      this.quoteItems?.forEach((item, index) => {
        if (item?.itemName === "") {
          this.errors.push(`Please remove and re-select item #${index + 1}`);
          return false;
        }

        if (item?.quantity === "" || parseInt(item?.quantity) <= 0) {
          this.errors.push(
            `Please enter valid quantity for item #${index + 1}`
          );
          return false;
        }

        if (parseInt(item?.price) <= 0) {
          this.errors.push(`Please enter valid price for item #${index + 1}`);
          return false;
        }
      });
    } else {
      this.errors.push("Please select item(s)");
      return false;
    }

    return true;
  }

  async testCustomerName() {
    if (this.customerName === "") {
      this.errors.push("Please enter customer name");
      return false;
    }

    return true;
  }

  async testUpdateTotals() {
    if (parseInt(this.quoteTotal) <= 0) {
      this.errors.push("Invalid total");
      return false;
    }

    if (parseInt(this.subtotal) <= 0) {
      this.errors.push("Invalid subtotal");
      return false;
    }

    return true;
  }

  async testTotals() {
    if (parseInt(this.subtotal) <= 0) {
      this.errors.push("Invalid subtotal");
      return false;
    }

    if (parseInt(this.quoteTotal) <= 0) {
      this.errors.push("Invalid quote total");
      return false;
    }

    return true;
  }

  async testExpiration() {
    const expire = parseInt(this.expirationDate);
    const created = parseInt(this.dateCreated);

    const days30 = 1000 * 60 * 60 * 24 * 90 + created;

    if (expire !== days30) {
      this.errors.push("Invalid expiration date");
      return false;
    }

    return true;
  }

  async testDiscount() {
    if (parseInt(this.discountPercent) < 0) {
      this.errors.push("Invalid discount percentage");
      return false;
    }

    return true;
  }

  async testCustomerId() {
    if (this.customerId?.length > 0) {
      return true;
    }

    this.errors.push("Missing Customer Id. Please Contact Support");
    return false;
  }

  async updateValid() {
    this.errors = [];

    //run tests for errors first
    await this.testItems();
    await this.testUpdateTotals();
    await this.testDiscount();

    if (this.errors?.length === 0) {
      return true;
    }

    return this.errors;
  }

  async createClientQuoteValid() {
    this.errors = [];

    //run tests for errors first
    await this.testStatus();
    await this.testQuoteItems();
    await this.testCustomerName();
    await this.testDateAndCreator();
    await this.testTotals();
    await this.testExpiration();
    await this.testDiscount();
    await this.testCustomerId();

    if (this.errors?.length === 0) {
      return true;
    }

    return this.errors;
  }
}

export default CreateQuoteValidator;
