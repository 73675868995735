import React, { useEffect, useRef, useState } from "react";
import { UPDATE_CLIENT_NOTE } from "../../../../utils/GraphQL/mutations";
import "./updateNoteModal.css";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { useMutation } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";

const clearedNote = {
  note: "",
  dateCreated: "",
  dateUpdated: "",
  updatedBy: "",
  createdBy: "",
};

const UpdateNoteModal = ({ updateCB, incNote, open, close, userName }) => {
  const mediaOver900 = useMediaQuery("(min-width: 900px)");
  const [updateNoteCall] = useMutation(UPDATE_CLIENT_NOTE);
  const [noteObj, setNoteObj] = useState({ ...clearedNote });
  const [error, setError] = useState("");
  const inputRef = useRef();
  const [selectionRange, setSelectionRange] = useState(null);

  useEffect(() => {
    setNoteObj({ ...incNote });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incNote]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (open) {
      body.style.overflow = "hidden";
    }
    
    if (!open) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectionRange) {
      inputRef?.current?.setSelectionRange(selectionRange, selectionRange);
    }
  }, [selectionRange]);

  const handleChange = (event) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;
    let selectionRangeVal = null;

    //regex to look behind and only find double asterisk that
    //isn't preceded by another asterisk. only finding the closing asterisks.
    const breakOutOfAsterisk = (prevNote, forwardBool) => {
      const regex = forwardBool
        ? /(?<=\*\*)([ \t]?\w{1}[\w\W\s]*?)(?=\*{2})/gm
        : /\*{4}/gim;
      const cursorStart = forwardBool
        ? target.selectionStart - 1
        : target.selectionStart;
      const matched = prevNote?.match(regex);
      const indexOfObjArr = matched?.map((str) => {
        const start = prevNote?.indexOf(str);
        const end = start + str?.length;
        return { start, end };
      });
      // console.log("matched", matched);
      // console.log(indexOfObjArr);
      // console.log(cursorStart);

      let returnedItem = { cursorPos: null, prevNote: null };

      indexOfObjArr?.forEach((obj) => {
        let splicedNote = "";

        if (forwardBool) {
          if (obj?.start <= cursorStart && obj?.end >= cursorStart) {
            const afterAsterisk = obj?.end + 2;
            splicedNote =
              prevNote?.slice(0, afterAsterisk) +
              "\n" +
              prevNote?.slice(afterAsterisk);
            returnedItem = {
              cursorPos: afterAsterisk + 1,
              prevNote: splicedNote,
            };
          }
        }

        if (!forwardBool) {
          if (obj?.start <= cursorStart && obj?.end > cursorStart) {
            splicedNote =
              prevNote?.slice(0, obj?.start) + prevNote?.slice(obj?.end);
            returnedItem = {
              cursorPos: obj?.start,
              prevNote: splicedNote,
            };
          }
        }
      });

      return returnedItem;
    };

    // Regex test for triple asterisk shortcut to
    // set double asterisk start and end and cursor middle
    const shortcutRegex = mediaOver900
      ? /(?<!\*)\*{3}(?!\*+)/gi
      : /(?<![c])[c]{3}(?![c]+)/gi;

    if (shortcutRegex.test(value)) {
      const replacement = value?.length === 3 ? "****" : "\n****";
      selectionRangeVal =
        value?.length === 3 ? target.selectionStart - 1 : target.selectionStart;
      value = target.value?.replace(shortcutRegex, replacement);
    }

    setNoteObj((prev) => {
      const type = event?.nativeEvent?.inputType;
      if (type === "insertLineBreak" || type === "deleteContentBackward") {
        const forwardBool = type === "insertLineBreak" ? true : false;
        const { cursorPos, prevNote } = breakOutOfAsterisk(
          prev?.note,
          forwardBool
        );
        selectionRangeVal = cursorPos;
        return {
          ...prev,
          [name]: prevNote || value,
        };
      }

      return {
        ...prev,
        [name]: value,
      };
    });

    setSelectionRange(selectionRangeVal);
  };

  const clearError = () => {
    setError("");
  };

  const closeModal = () => {
    setNoteObj({ ...clearedNote });
    setSelectionRange(null);
    clearError();
    close();
  };

  const saveNote = async () => {
    if (noteObj?.note?.length <= 0) {
      setError("Please Enter Note");
      return;
    }

    const dateNow = Date.now().toString();

    const { data } = await updateNoteCall({
      variables: {
        noteId: noteObj?._id,
        noteText: noteObj?.note?.trim() || noteObj?.note,
        date: dateNow,
        userUpdated: userName,
      },
    });

    updateCB(data?.updateClientNote);
  };

  return (
    <>
      {open && (
        <div
          className="update-note-modal-container"
          onClick={() => {
            closeModal();
          }}
        >
          <div
            className="update-note-modal-content"
            style={{
              width: !mediaOver900 ? "95%" : "65%",
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <button className="update-note-modal-close" onClick={closeModal}>
              <div className="update-note-modal-close-x">X</div>
            </button>
            <div className="update-note-modal-shortcut">
              Triple {mediaOver900 ? "'asterisk'" : "'c'"} to start calendar
              note shortcut. e.g. **Hello there**
            </div>
            {error && (
              <ul>
                <li>{error}</li>
              </ul>
            )}
            <CssTextFieldStandard
              inputRef={inputRef}
              sx={{ width: "90%", my: 1 }}
              label="Update Note:"
              name="note"
              onFocus={clearError}
              value={noteObj?.note}
              onChange={handleChange}
              multiline
              minRows={5}
            />
            <div className="update-note-modal-func-box">
              <button className="update-note-modal-btn" onClick={closeModal}>
                Cancel
              </button>
              <button className="update-note-modal-btn" onClick={saveNote}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateNoteModal;
