import React, { useState } from "react";
import { Typography, Menu, MenuItem } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { ASSIGN_NUM_TO_CLIENT } from "../../../../utils/GraphQL/mutations";
import "./msgPageContactOptions.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClientSearchInput from "../../adminComponents/ClientSearchInput/ClientSearchInput";
import { useMutation } from "@apollo/client";

const MsgPageContactOptions = ({
  anchorEl,
  handleClose,
  handleSnack,
  selectedId,
  handleUpdate,
  handleNewClientDisplay
}) => {
  //   const navigate = useNavigate();
  const matchesMQ = useMediaQuery("(min-width: 900px)");
  const [searchOpen, setSearchOpen] = useState(false);
  const [assignNumCall] = useMutation(ASSIGN_NUM_TO_CLIENT);

  const resultsToAssignNum = async (client) => {
    if (!selectedId || selectedId?.length <= 0 || !client) {
      handleSnack("No Number Selected. Not assigned to client.", 2000);
      return;
    }

    const match = client?.phoneNumberIds?.filter((id) => id === selectedId);
    const name = `${client?.firstName} ${client?.lastName}`;

    const { data } = await assignNumCall({
      variables: {
        clientFullName: name?.trim(),
        clientId: client?._id,
        phoneNumId: selectedId,
        localTime: Date.now().toString(),
        clientAlreadyHasId: match?.length > 0,
      },
    });

    if (data?.assignNumToClient) {
      handleSnack(`Number assigned to ${name}`, 2000);
      handleUpdate({...data?.assignNumToClient})
      handleClose(true)
    }
  };

  return searchOpen ? (
    <ClientSearchInput
      menuReturn={{
        isMenu: true,
        anchor: anchorEl,
        disableScrollLock: matchesMQ,
        menuClose: () => {
          setSearchOpen(false);
          handleClose(true);
        },
        searchClose: () => {
          setSearchOpen(false);
        },
      }}
      handleSnack={handleSnack}
      returnResults={resultsToAssignNum}
    />
  ) : (
    <Menu
      id="manage-menu-popover"
      anchorEl={anchorEl}
      disableScrollLock={matchesMQ ? true : false}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(true)}
    >
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          setSearchOpen(true);
        }}
      >
        <Typography textAlign="center">Assign To Client</Typography>
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          handleNewClientDisplay(true)
          handleClose(true)
        }}
      >
        <Typography textAlign="center">Create New Client</Typography>
      </MenuItem>
    </Menu>
  );
};

export default MsgPageContactOptions;
