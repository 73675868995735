import React, { useEffect, useState } from "react";
import "./quoteStickyTab.css";

const QuoteStickyTab = () => {
  const [notQuotePage, setNotQuotePage] = useState(false);

  useEffect(() => {
    const page = window.location.pathname;
    
    if (page === "/quote" || page === "/login-portal") {
      setNotQuotePage(false);
    }

    if (page !== "/quote" && page !== "/login-portal") {
      setNotQuotePage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <div className="quote-tab-container">
      <div className={notQuotePage ? "slide-in" : "slide-out"}>
        <a className="quote-page-button" role="navigation" href="/quote">
          Get a Quote!
          {/* End of Summer Special!  */}
        </a>
      </div>
    </div>
  );
};

export default QuoteStickyTab;
