const copyStringToClipboard = async (string, cb) => {
    await navigator.clipboard.writeText(string);

    if (cb) {
        cb()
    }

    return 
}
  
  export default copyStringToClipboard;