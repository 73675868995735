import React, { useEffect, useState } from "react";
import { CREATE_USER_TASK } from "../../../../utils/GraphQL/mutations";
import { GET_USER_INFO_FOR_TASKS } from "../../../../utils/GraphQL/queries";
import "./userTaskCreate.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import ClientSearchInput from "../ClientSearchInput/ClientSearchInput";
import CalendarTimeSelect from "../CalendarTimeSelect/CalendarTimeSelect";
import UserTaskFormValidator from "../../../../utils/formValidators/userTaskFormValidator";

const clearedForm = {
  assigneeName: "All",
  assigneeId: "22",
  taskType: "",
  note: "",
  associatedClientId: "",
  associatedClientName: "",
  active: true,
  dateCreated: "",
  dueDate: "",
};

const UserTaskCreate = ({ clientObj, buttonCSS, handleSnack, queryTasks }) => {
  const [openModal, setOpenModal] = useState(false);
  const [taskForm, setTaskForm] = useState({ ...clearedForm });
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [afterLastUserIndex, setAfterLastUserIndex] = useState(0);
  const [formErrors, setFormErrors] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [getUsersInfoCall] = useLazyQuery(GET_USER_INFO_FOR_TASKS);
  const smaller600 = useMediaQuery("(max-width:600px)");
  const [inputsWidth, setInputsWidth] = useState("45%");
  const [createNewTaskCall] = useMutation(CREATE_USER_TASK);

  // Form Validator
  const taskValidator = new UserTaskFormValidator(taskForm);

  useEffect(() => {
    const body = document.querySelector("body");

    if (openModal === true) {
      body.style.overflow = "hidden";
      setInputsWidth(smaller600 ? "95%" : "45%");
    }
    if (openModal === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (clientObj?._id && openModal === true) {
      setTaskForm((prev) => ({
        ...prev,
        associatedClientId: clientObj?._id,
        associatedClientName: `${clientObj?.firstName} ${clientObj?.lastName}`,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientObj, openModal]);

  useEffect(() => {
    if (usersInfo?.length <= 0 && openModal) {
      makeGetInfoCall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const makeGetInfoCall = async () => {
    const { data } = await getUsersInfoCall()

    if ( data?.getUsersNamesForTasks ) {
      const index = data?.getUsersNamesForTasks?.length + 1;
      setSelectedUserIndex(index);
      setAfterLastUserIndex(index);
      setUsersInfo([...data?.getUsersNamesForTasks])
    }
  };

  const handleChangeAssignee = (index) => {
    setSelectedUserIndex(index);

    if (index !== afterLastUserIndex) {
      const selected = usersInfo?.[index];

      setTaskForm((prev) => ({
        ...prev,
        assigneeId: selected?._id || "",
        assigneeName: `${selected?.firstName} ${selected?.lastName}`,
      }));

      return;
    }

    setTaskForm((prev) => ({
      ...prev,
      assigneeId: "22",
      assigneeName: "All",
    }));
  };

  const handleChange = ({ name, value }) => {
    let dueDateTime = null;
    if (name === "dueDate") {
      const dayMill = 1000 * 60 * 60 * 24;
      dueDateTime = (dayMill * value + Date.now()).toString();
    }

    setTaskForm((prev) => ({
      ...prev,
      [name]: value,
      dueDate: dueDateTime || prev?.dueDate,
    }));
  };

  const searchResultSet = (result) => {
    setTaskForm((prev) => ({
      ...prev,
      associatedClientId: result?._id,
      associatedClientName: `${result?.firstName} ${result?.lastName}`,
    }));
  };

  const clearSearchResult = () => {
    setTaskForm((prev) => ({
      ...prev,
      associatedClientId: "",
      associatedClientName: "",
    }));
  };

  const timeSelected = (timeStr) => {
    setTaskForm((prev) => ({
      ...prev,
      dueDate: timeStr,
    }));
  };

  const closeAndReset = () => {
    setTaskForm((prev) => ({
      ...clearedForm,
    }));
    setOpenModal(false);
  };

  const createNewTask = async () => {
    const formOk = await taskValidator.formValid();

    if (formOk !== true) {
      setFormErrors([...formOk]);
      return;
    }

    if (formOk === true) {
      const { data } = await createNewTaskCall({
        variables: {
          ...taskForm,
          dateCreated: Date.now().toString(),
        },
      });

      if (data?.createUserTask) {
        if (handleSnack) {
          handleSnack("Task Created", 2500);
        }

        if (queryTasks) {
          queryTasks();
        }

        closeAndReset();
        return;
      }

      if (handleSnack) {
        handleSnack("Something went wrong...", 3000);
      }

      return;
    }
  };

  return (
    <>
      <button
        className={buttonCSS ? buttonCSS : "user-task-start-button"}
        onClick={(event) => {
          event.stopPropagation();
          setOpenModal(true);
        }}
      >
        Create Task
      </button>
      {openModal && (
        <div id="cal-id" className="user-task-modal-container">
          <div
            className="user-task-modal-content"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="user-task-modal-close-container">
              <h1 className="user-task-modal-header">Create Task</h1>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  closeAndReset();
                }}
                className="close-modal-x-btn"
              >
                X
              </button>
            </div>
            <div className="user-task-inputs-container">
              {formErrors?.length > 0 && (
                <ul className="user-task-error-ul">
                  {formErrors?.map((err, index) => {
                    return <li key={err + index}>{err}</li>;
                  })}
                </ul>
              )}
              <div className="user-task-inputs-box">
                <FormControl
                  variant="standard"
                  sx={{ my: 1, width: inputsWidth }}
                >
                  <InputLabel
                    id="user-task-select"
                    sx={{
                      color: "#05F842",
                      "&.Mui-focused": { color: "#05F842" },
                    }}
                  >
                    Assign To:
                  </InputLabel>
                  <Select
                    labelId="user-task-select"
                    id="user-task-select-input"
                    name="user-task-select"
                    value={selectedUserIndex}
                    onChange={(event) => {
                      handleChangeAssignee(event.target.value);
                    }}
                    disableUnderline={true}
                    sx={{
                      color: "#fff",
                      WebkitTextFillColor: "#fff",
                      "& .Mui-disabled": {
                        color: "#fff !important",
                        WebkitTextFillColor: "#fff",
                      },
                    }}
                    style={{ borderBottom: "1px solid #fff" }}
                  >
                    {usersInfo?.length > 0 &&
                      usersInfo?.map(
                        (userInfo, index) => {
                          const username = `${userInfo?.firstName} ${userInfo?.lastName}`;

                          return (
                            <MenuItem key={userInfo?._id} value={index}>
                              {username?.trim()}
                            </MenuItem>
                          );
                        }
                      )}
                    <MenuItem value={afterLastUserIndex}>All</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  sx={{ my: 1, width: inputsWidth }}
                >
                  <InputLabel
                    id="user-task-select"
                    sx={{
                      color: "#05F842",
                      "&.Mui-focused": { color: "#05F842" },
                    }}
                  >
                    Task Type:
                  </InputLabel>
                  <Select
                    labelId="user-task-select"
                    id="user-task-select-input"
                    name="taskType"
                    value={taskForm?.taskType}
                    onChange={(event) => {
                      handleChange(event.target);
                    }}
                    disableUnderline={true}
                    sx={{
                      color: "#fff",
                      WebkitTextFillColor: "#fff",
                      "& .Mui-disabled": {
                        color: "#fff !important",
                        WebkitTextFillColor: "#fff",
                      },
                    }}
                    style={{ borderBottom: "1px solid #fff" }}
                  >
                    <MenuItem value="F/U">F/U</MenuItem>
                    <MenuItem value="F/U 2">F/U 2</MenuItem>
                    <MenuItem value="Schedule">Schedule</MenuItem>
                    <MenuItem value="Send Payment Receipt">
                      Send Payment Receipt
                    </MenuItem>
                    <MenuItem value="Appt / Invoice">Appt / Invoice</MenuItem>
                    <MenuItem value="Add To Ledger">Add To Ledger</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="user-task-inputs-box">
                {!taskForm?.associatedClientId ? (
                  <div className="user-task-associated-client-box">
                    <span className="green-color-text user-task-text-nowrap">
                      Select Client:
                    </span>
                    <ClientSearchInput
                      setWidth={"auto"}
                      returnResults={searchResultSet}
                    />
                  </div>
                ) : (
                  <div className="user-task-associated-client-box">
                    <span className="green-color-text user-task-text-nowrap">
                      Associated Client:
                    </span>
                    <span>{taskForm?.associatedClientName}</span>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        clearSearchResult();
                      }}
                      className="user-task-small-remove-btn"
                    >
                      - Remove
                    </button>
                  </div>
                )}
                <div className="user-task-due-date-box">
                  <CalendarTimeSelect
                    leadingText={
                      <span className="green-color-text">Due Date:</span>
                    }
                    returnMill={timeSelected}
                    hrSelect={10}
                  />
                </div>
              </div>
              <CssTextFieldStandard
                sx={{ width: "95%", my: 1 }}
                label="Note:"
                name="note"
                value={taskForm?.note}
                onChange={(event) => handleChange(event.target)}
                multiline
                minRows={3}
              />
            </div>
            <div className="user-task-save-box">
              <button
                className="user-task-start-button"
                onClick={(event) => {
                  event.stopPropagation();
                  setFormErrors([]);
                  createNewTask();
                }}
              >
                Save Task
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserTaskCreate;
