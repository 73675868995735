import React, { useState, useRef } from "react";
import "./officeShortcuts.css";
import { Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GET_KEY_SHORTCUTS } from "../../../../utils/GraphQL/queries";
import copyStringToClipboard from "../../../../utils/formatters/copyStringToClipboard";

const OfficeShortcuts = ({ handleSnack, passedBtnCss }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const shortcutsBtnRef = useRef();
  const matchesMQ = useMediaQuery("(min-width: 900px)");
  const [shortcuts, setShortcuts] = useState([]);
  const [getShortcuts] = useLazyQuery(GET_KEY_SHORTCUTS);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const queryShortcuts = async () => {
    const { data } = await getShortcuts({
        fetchPolicy: "network-only"
    });

    if (data?.getKeyShortcuts) {
      setShortcuts([...data?.getKeyShortcuts]);
    }
  };

  return (
    <div className="key-shortcuts-box">
      <button
        ref={shortcutsBtnRef}
        className={passedBtnCss ? passedBtnCss : "key-shortcuts-btn"}
        onClick={() => {
          if (shortcuts?.length === 0) {
            queryShortcuts();
          }
          setAnchorEl(shortcutsBtnRef?.current);
        }}
      >
        Shortcuts
      </button>

      <Menu
        id="email-menu-popover"
        anchorEl={anchorEl}
        disableScrollLock={matchesMQ ? true : false}
        anchorOrigin={{
          vertical: matchesMQ ? "bottom" : "top",
          horizontal: matchesMQ ? "center" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: matchesMQ ? "center" : "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {shortcuts &&
          shortcuts?.map((item, index) => {
            return (
              <MenuItem
                key={`shorcut-item-${index}`}
                onClick={() => {
                  copyStringToClipboard(item?.text, () => {
                    if (handleSnack) {
                      handleSnack("Shorcut Copied", 2000);
                    }
                    handleCloseMenu();
                  });
                }}
              >
                <Typography textAlign="center">{item?.title}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default OfficeShortcuts;
