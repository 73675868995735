import React, { useEffect, useState } from "react";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import "./calendarTimeSelect.css";
import localeEn from "air-datepicker/locale/en";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const CalendarTimeSelect = ({
  returnMill,
  hrSelect,
  leadingText,
  justifyContent,
  passedSelectedTime,
  hideInput,
  passedColor,
}) => {
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    if (passedSelectedTime) {
      setSelectedTime(passedSelectedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedSelectedTime]);

  const calInitAndSelect = () => {
    const button = {
      content: "Today",
      className: "cal-time-sel-start-button",
      onClick: () => {
        const now = Date.now().toString();
        returnMill(now);
        setSelectedTime(now);
        cal.hide();

        return setTimeout(() => {
          cal.destroy();
        }, 500);
      },
    };

    const cal = new AirDatepicker("#cal-el-btn", {
      isMobile: true,
      autoClose: true,
      locale: localeEn,
      range: false,
      buttons: [button],
      onSelect: ({ date }) => {
        // Returns string milliseconds at midnight of that day.
        // Add hrs variable for time selection
        const selectedMill = parseInt(cal.formatDate(date, "T"));
        const hrSelection = hrSelect ? 1000 * 60 * 60 * hrSelect : 0;
        const totalMillStr = (selectedMill + hrSelection).toString();

        returnMill(totalMillStr);
        setSelectedTime(totalMillStr);

        return setTimeout(() => {
          cal.destroy();
        }, 500);
      },
    });

    const focusedDate = passedSelectedTime
      ? new Date(parseInt(passedSelectedTime))
      : new Date();

    cal.setFocusDate(focusedDate, { viewDateTransition: true });
    cal.show();
  };

  return (
    <div
      className="cal-time-select-container"
      style={{
        justifyContent: justifyContent ? justifyContent : "flex-start",
        color: passedColor ? passedColor : "#fff",
      }}
    >
      {leadingText && leadingText}
      <input
        type={hideInput ? "hidden" : "text"}
        className="cal-el-input"
        value={selectedTime ? BAGroupTimeFormat(selectedTime) : ""}
        placeholder="Select Date"
        readOnly={true}
        id="cal-el-btn"
        onClick={calInitAndSelect}
        style={{ margin: leadingText ? "1px 0px 1px 12px" : "1px 0px" }}
      />

      <button className="cal-time-sel-img-button" onClick={calInitAndSelect}>
        <CalendarMonthIcon
          sx={{ color: passedColor ? passedColor : "#fff" }}
          titleAccess="Select Date"
        />
      </button>
    </div>
  );
};

export default CalendarTimeSelect;
