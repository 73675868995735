class CreateClientValidator {
  constructor(formState) {
    this.firstName = formState.firstName;
    this.phoneNumberIds = formState.phoneNumberIds;
    this.primaryEmail = formState.primaryEmail;
    this.secondaryEmail = formState.secondaryEmail;
    this.existingPhn = formState.existingPhn;

    this.errors = [];
  }

  async testFirst() {
    if (this.firstName?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter First Name");
    return false;
  }

  async testPhoneNumbers() {
    if (this.existingPhn?.length === 24) {
      return true;
    }
    
    const checks = this.phoneNumberIds?.map((numObj, index) => {
      if (numObj?.phoneNumber?.length === 12) {
        if (numObj?.numberType?.length > 0) {
          return true;
        }
        this.errors.push(
          `Please Enter Valid Type For Phone Number ${index + 1}`
        );
        return false;
      }

      console.log(numObj?.phoneNumber)
      if (numObj?.phoneNumber === "") {
        return true
      }

      this.errors.push(
        `Please Enter Valid Number For Phone Number ${index + 1}`
      );
      return false;
    });

    const answer = checks?.includes(false) ? false : true;

    return answer;
  }

  async testPrimaryEmail() {
    const emailRegex = /.+@.+\..+/;
    if (emailRegex.test(this.primaryEmail)) {
      return true;
    }

    if (this.primaryEmail === "") {
      return true
    }

    this.errors.push("Please Enter Valid Primary Email");
    return false;
  }

  async testSecondaryEmail() {
    const emailRegex = /.+@.+\..+/;
    if (
      emailRegex.test(this.secondaryEmail) ||
      this.secondaryEmail?.length === 0
    ) {
      return true;
    }

    this.errors.push("Please Remove or Enter Valid Secondary Email");
    return false;
  }

  async createClientIsValid() {
    this.errors = [];

    //run tests for errors first
    await this.testFirst();
    await this.testPrimaryEmail();
    await this.testSecondaryEmail();
    await this.testPhoneNumbers();

    if (this.errors?.length === 0) {
      return true;
    }

    return this.errors;
  }
}

export default CreateClientValidator;
