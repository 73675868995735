import React, { useEffect, useState } from "react";
import "./customerInvoicePage.css";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_OUTWARD_CLIENT_INVOICE } from "../../utils/GraphQL/queries";
import AdminBDRSpinner from "../AdminDashboard/adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import { priceStrFormatter } from "../../utils/formatters/priceStrFormatter";
import capitalizeFirstLetters from "../../utils/formatters/capitalizeFirstLetters";
import BAGroupTimeFormat from "../../utils/formatters/BAGroupTimeFormat";
import Contact from "../Contact/Contact";

const logo = require("../../assets/images/BDR-logo.png");

const CustomerInvoicePage = ({ displayNav, changeStickyTab, changeFooter }) => {
  const { invoiceId } = useParams();
  const [getInvoice] = useLazyQuery(GET_OUTWARD_CLIENT_INVOICE);
  const [loading, setLoading] = useState(true);
  const [infoFound, setInfoFound] = useState(true);
  const [clientInvoice, setClientInvoice] = useState({});
  const [invoiceDate, setInvoiceDate] = useState("");
  const [discountTotal, setDiscountTotal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (invoiceId) {
      displayNav(false);
      changeStickyTab(false);
      changeFooter(false);
    }
    setLoading(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoiceId) {
      queryInvoice();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  useEffect(() => {
    if (clientInvoice?.jobScheduledFor) {
      const useDate = clientInvoice?.jobScheduledFor;

      const date = BAGroupTimeFormat(useDate, false, true)
        ?.split(" ")
        ?.slice(1);

      setInvoiceDate(`${date[0]} ${date[1]}, ${date[2]}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInvoice?.jobScheduledFor]);

  useEffect(() => {
    const totalArr = clientInvoice?.invoiceItems?.map((item) => {
      return parseInt(
        makeTotal(
          parseInt(item?.price),
          parseInt(item?.quantity),
          item?.discountApplicable
        )
      );
    });

    const subtotalArr = clientInvoice?.invoiceItems?.map((item) => {
      return parseInt(
        makeTotal(parseInt(item?.price), parseInt(item?.quantity), false)
      );
    });

    const quoteTotal = totalArr?.reduce((price, price2) => price + price2, 0);
    const subtotal = subtotalArr?.reduce((price, price2) => price + price2, 0);

    const discountOff = subtotal - quoteTotal;
    setDiscountTotal(`${discountOff}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInvoice?.invoiceItems]);

  const makeTotal = (price, quantity, discount) => {
    const total = discount
      ? (
          (((100 - parseInt(clientInvoice?.discountPercent)) / 100) *
            price *
            quantity) /
          100
        )
          ?.toFixed(2)
          .replace(".", "")
      : ((price * quantity) / 100)?.toFixed(2).replace(".", "");

    return total;
  };

  const queryInvoice = async () => {
    const { data } = await getInvoice({
      variables: {
        invoiceId: invoiceId,
      },
    });

    if (data?.getClientOutwardInvoice) {
      console.log(data?.getClientOutwardInvoice);
      setClientInvoice({ ...data?.getClientOutwardInvoice });
      return;
    }

    setInfoFound(false);
    return;
  };

  return (
    <div className="cust-invoice-page-container">
      {loading && <AdminBDRSpinner message={"Loading"} />}
      {infoFound ? (
        <>
          <div className="cust-invoice-page-print-btn-box">
            <button
              className="cust-invoice-accept-button"
              onClick={() => {
                window.print();
              }}
            >
              Print Invoice
            </button>
          </div>
          <div className="cust-invoice-page" id="cust-invoice-display">
            <div
              className={
                clientInvoice?.invoicePaid
                  ? "cust-invoice-paid-invoice-box"
                  : "cust-invoice-paid-invoice-box-none"
              }
            >
              <div className="cust-invoice-paid-invoice">PAID</div>
            </div>

            <div className="cust-invoice-info-accept-box">
              <div className="cust-invoice-company-info">
                <div className="cust-invoice-company-info-inner">
                  <div className="cust-invoice-img-box">
                    <img
                      className="cust-invoice-logo-img"
                      src={logo}
                      alt="BDR Logo"
                    />
                  </div>
                  <ul className="cust-invoice-ul">
                    <li>
                      <strong>Biggin's Door Refinishing</strong>
                    </li>
                    <li>18975 Marbach Ln</li>
                    <li>Suite 22-1209</li>
                    <li>San Antonio, Tx 78266</li>
                  </ul>
                </div>
              </div>
              <div className="cust-invoice-accept-box">
                <ul className="cust-invoice-ul">
                  <li className="cust-invoice-text-right">
                    <strong>Invoice #</strong>
                  </li>
                  <li className="cust-invoice-text-right">
                    {clientInvoice?.invoiceNum}
                  </li>
                </ul>
              </div>
            </div>
            <div className="cust-invoice-to-box">
              <div className="cust-invoice-to-section">
                <span>
                  <strong>To</strong>
                </span>
                <h5 className="cust-invoice-to-bubble">
                  {capitalizeFirstLetters(clientInvoice?.customerName)}
                </h5>
              </div>
              <div className="cust-invoice-to-section">
                <span>
                  <strong>Scheduled Date</strong>
                </span>
                <h5 className="cust-invoice-to-bubble">{invoiceDate}</h5>
              </div>
              <div className="cust-invoice-to-section">
                <span>
                  <strong>Total Amount</strong>
                </span>
                <h5 className="cust-invoice-to-bubble">
                  {priceStrFormatter(clientInvoice?.invoiceTotal)}
                </h5>
              </div>
            </div>
            {clientInvoice?.invoiceItems?.length > 0 && (
              <div className="cust-invoice-items-container">
                <div className="cust-invoice-items-item">
                  <span>Item</span>
                  <span>Price</span>
                  <span>Quantity</span>
                  <span>Discount</span>
                  <span>Total</span>
                </div>
                <div className="cust-invoice-hr"></div>
                {clientInvoice?.invoiceItems?.map((item, index) => {
                  const total = makeTotal(
                    parseInt(item?.price),
                    parseInt(item?.quantity),
                    item?.discountApplicable
                  );

                  return (
                    <div
                      key={`cust-invoice-item-${index}-${item?.itemName}`}
                      className="cust-invoice-items-item"
                    >
                      <span>{item?.itemName}</span>
                      <span>{priceStrFormatter(item?.price)}</span>
                      <span>{item?.quantity}</span>
                      <span>{item?.discountApplicable ? `${clientInvoice?.discountPercent}%` : "N/A"}</span>
                      <span>{priceStrFormatter(total)}</span>
                    </div>
                  );
                })}
                <div className="cust-invoice-hr"></div>
                <div className="cust-invoice-totals-box">
                  <div>
                    <span>
                      Subtotal: {priceStrFormatter(clientInvoice?.subtotal)}
                    </span>
                  </div>
                  {discountTotal?.length > 0 && (
                    <div>
                      <span>
                        - Discount: {priceStrFormatter(discountTotal)}
                      </span>
                    </div>
                  )}
                  <div>
                    <strong>
                      <span>
                        Invoice Total:{" "}
                        {priceStrFormatter(clientInvoice?.invoiceTotal)}
                      </span>
                    </strong>
                  </div>
                </div>
              </div>
            )}
            {clientInvoice?.invoiceNote?.length > 0 && (
              <p className="cust-invoice-page-note">
                {clientInvoice?.invoiceNote}
              </p>
            )}
          </div>
        </>
      ) : (
        <div className="cust-invoice-none-div">
          <div className="cust-invoice-none-header">
            <h1>No Invoice Found</h1>
            <p>
              To receive information about your invoice, please contact <br />{" "}
              <strong>Biggin's Door Refinishing</strong>!
            </p>
            <div className="cust-invoice-contact-info-box">
              <Contact displayHeader={false} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerInvoicePage;
