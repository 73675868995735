import capitalizeFirstLetters from "./capitalizeFirstLetters";

const cities = [
  "Enter City",
  "San Antonio",
  "San Marcos",
  "New Braunfels",
  "Kyle",
  "Universal City",
  "Canyon Lake",
  "Schertz",
  "Seguin",
  "Kerrville",
  "Lockhart",
  "Converse",
  "Live Oak",
  "Pleasanton",
  "Leon Valley",
  "Kirby",
  "Hondo",
  "Boerne",
  "Bulverde",
  "Saint Hedwig",
  "Castroville",
  "Selma",
  "Helotes",
  "Marion",
  "Adkins",
  "McQueeney",
  "La Vernia",
  "Fair Oaks Ranch",
  "Comfort",
  "Spring Branch",
  "Fischer",
  "Wimberley",
  "Blanco",
  "Fredericksburg",
  "Johnson City",
  "Junction",
  "Devine",
  "Moore",
  "Poteet",
  "Victoria",
  "Stockdale",
  "Gonzales",
  "Austin",
  "Buda",
  "Dripping Springs",
  "Cedar Creek",
  "Bastrop",
  "Round Rock",
  "Pflugerville",
  "Cedar Park",
  "Georgetown",
  "Bandera",
];

const pasteKeapAddress = async (clipboard, handleSnack) => {
  const text = await clipboard.readText();
  if (!text || text?.trim()?.length === 0) {
    if (handleSnack) {
      handleSnack("Nothing to paste...", 2000);
    }
    return "";
  }
  
  // expected string "123 Apple St New Braunfels, Texas, 78148 United States"
  // expected return is array. [street, city, state, zip]

  const noCountry = text?.replace(/united states/gi, "");
  const pieces = noCountry?.split(",");
  let cityIndex = 0;
  let streetAddress = "Enter Street";
  const state = capitalizeFirstLetters(pieces[1]?.trim());
  const zipCode = capitalizeFirstLetters(pieces[2]?.trim());

  for (let index = 0; index < cities.length; index++) {
    const city = cities[index];
    const regex = new RegExp(city, "ig");

    if (regex?.test(pieces[0])) {
      cityIndex = index;
      streetAddress = capitalizeFirstLetters(
        pieces[0]?.replace(regex, "")?.trim()
      );

      console.log("streetAddress: ", streetAddress);
      console.log("city: ", city);
      break;
    }
  }

  return {
    city: capitalizeFirstLetters(cities[cityIndex]),
    state,
    streetAddress,
    zipCode,
  };
};

export default pasteKeapAddress;
