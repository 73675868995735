import decode from 'jwt-decode';

class AuthService {

  getProfile() {
    return this.getToken() ?  decode(this.getToken()) : false
  }
  
  getPermissions() {
    const profile = this.getProfile()
    if (profile) {
      return profile?.data.permission
    }
    return profile
  }

  loggedIn() {
    const token = this.getToken();
    if (!token) {
      return false
    }
    // If there is a token and it's not expired, return `true`
    return token && !this.isTokenExpired(token) ? true : false;
  }

  isTokenExpired(token) {
    // Decode the token to get its expiration time that was set by the server
    const decoded = decode(token);
    // If the expiration time is less than the current time (in seconds), the token is expired and we return `true`
    if (decoded.exp < Date.now() / 1000) {
      localStorage.removeItem('id_token');
      return true;
    }
    // If token hasn't passed its expiration time, return `false`
    return false;
  }

  getToken() {
    return localStorage.getItem('id_token');
  }

  resetToken(idToken) {
    if (!idToken) {
      return false
    }

    if (decode(idToken)?.data?.permission === undefined) {
      return false
    }

    localStorage.setItem('id_token', idToken)
    window.location.reload();
    return true
  }

  async login(idToken) {
    if (!idToken) {
      return false
    }

    const userData = decode(idToken)

    if (userData?.data?.permission === undefined) {
      return false
    }

    localStorage.removeItem('BDRLoggedOut');
    localStorage.setItem('id_token', idToken);
    return true
  }

  async logout() {
    localStorage.setItem('BDRLoggedOut', Date.now().toString());
    localStorage.removeItem('id_token');
    return true
  }
}
const Auth = new AuthService()
export default Auth;