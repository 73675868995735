import React, { useEffect, useState, useRef } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { DELETE_BA_GROUP } from "../../../../utils/GraphQL/mutations";
import { GET_ONE_BAG } from "../../../../utils/GraphQL/queries";
import BdrSpinner from "../../../../components/BdrSpinner/BdrSpinner";
import {
  CssTextFieldStandard,
  MyFormHelperText,
} from "../../../../components/CssTextFields/CssTextFieldStandard";
import { Button, Snackbar } from "@mui/material";
import nameListFormat from "../../../../utils/formatters/refinisherFormat";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import "./deleteBAGroup.css";

const DeleteBAGroup = ({heightTrigger}) => {
  const deleteBAGScrollRef = useRef();
  const deleteBagInputRef = useRef();
  const [selectedId, setSelectedId] = useState("");
  const [errorState, setErrorState] = useState(false);
  const smaller900 = useMediaQuery("(max-width:900px)")
  const [bagState, setBAGState] = useState({});
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [deleteBAG, { loading: BAGDeleteLoading }] =
    useMutation(DELETE_BA_GROUP);
  const [queryOneBAG, { loading: BAGQueryLoading }] = useLazyQuery(GET_ONE_BAG);

  useEffect(() => {
    if (selectedId.length === 0) {
      clearError();
      return;
    }

    checkIfQuery();

    return;
    // eslint-disable-next-line
  }, [selectedId]);

  const handleGroupIdChange = (event) => {
    setSelectedId(event.target.value);
  };

  const checkIfQuery = async () => {
    const validId = /^[0-9a-fA-F]{24}$/.test(selectedId);

    if (validId) {
      const { data } = await queryOneBAG({
        variables: { selectedId: selectedId },
      });

      if (data?.getBAGById !== null) {
        setBAGState(data?.getBAGById);
      }

      if (data?.getBAGById === null) {
        setBAGState({});
      }

      return;
    }

    if (validId === false) {
      setBAGState({});
      return;
    }
    return;
  };

  const checkError = async () => {
    //state is for display. return is for "is valid"
    if (selectedId.match(/^[0-9a-fA-F]{24}$/) || selectedId?.length === 0) {
      setErrorState(false);
      return true;
    }
    setErrorState(true);
    return false;
  };

  const clearError = () => {
    setErrorState(false);
  };

  const handleSnack = () => {
    setOpenSnack(true);
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  return (
    <div ref={deleteBAGScrollRef}
      id="delete-ba-group-height-marker"
      className="delete-group-container"
    >
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message="Group Deleted"
      />
      <div
        className={
          BAGDeleteLoading || BAGQueryLoading
            ? "delete-over-loader"
            : "delete-none"
        }
      >
        <BdrSpinner message={"Loading..."} />
      </div>
      <button
        onClick={() => {
          heightTrigger()
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = deleteBAGScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;

              window.scrollTo(0, totalMobileScroll);
              setTimeout(() => {
                deleteBagInputRef.current?.focus()
              }, 800);
            }
            
            if (smaller900 === false && barCollapsed === true) {
              deleteBAGScrollRef.current.scrollIntoView({block: "start"}); 
              setTimeout(() => {
                deleteBagInputRef.current?.focus()
              }, 800);
            }

            if ( barCollapsed === false ) {
              window.scrollTo({top: 0}); 
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Delete BA Group</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        {bagState?.afterId && (
          <div className="delete-info-box">
            <div className="delete-img-box">
              <ul className="delete-info-ul">
                <li>Date Completed: {bagState?.dateEntered}</li>
                <li>Refinishers: {nameListFormat(bagState?.refinishers)}</li>
                <li>Stain Color: {bagState?.stainColor}</li>
                <li>Week Display: {bagState?.weekGalleryDisplay}</li>
              </ul>
              <img
                className="delete-img"
                src={bagState?.beforeId.imageData}
                alt="before pic"
              />
              <img
                className="delete-img"
                src={bagState?.afterId.imageData}
                alt="after pic"
              />
            </div>
          </div>
        )}
        <div className="delete-id-input">
          <CssTextFieldStandard
            sx={{ width: "95%", my: 1 }}
            type="text"
            label={"Group Id to Delete"}
            name="GroupId"
            inputProps={{ref: deleteBagInputRef}}
            onChange={handleGroupIdChange}
            onFocus={clearError}
            onBlur={checkError}
            value={selectedId}
            variant="standard"
          />
          <Button
            onClick={async () => {
              const isValid = await checkError();
              if (bagState?.dateEntered && isValid) {
                const deleted = await deleteBAG({
                  variables: {
                    baGroupId: selectedId,
                  },
                });

                if (deleted?.data.deleteBAGroup === true) {
                  setBAGState({});
                  setSelectedId("");
                  handleSnack();
                }

                return;
              }

              setErrorState(true);
            }}
            id="delete-bag-button"
          >
            Delete Group
          </Button>
          <MyFormHelperText
            errorText={"Please enter valid Id"}
            formError={errorState}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteBAGroup;
