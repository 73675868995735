const priceStrFormatter = (price) => {
  if (price) {
    const filteredNumber = price?.toString()?.replace(/[^0-9]/gi, "");
    let number = parseInt(filteredNumber) / 100;
    if(isNaN(number)) {return "0"}

    let neg = /-/gi.test(price);

    if (number === 0) {
      neg = false;
    }

    number = number.toFixed(2);

    if (number?.length > 6) {
      const decimal = number?.split(".")[1]
      const whole = number?.split(".")[0]
      const thousands = whole?.slice(0, -3)
      const hundreds = whole?.slice(-3)
      
      number = `${thousands},${hundreds}.${decimal}`
    }


    return `${neg ? "-" : ""}$${number}`;
  }
  return "0";
};

const removePriceStrFormatter = (price) => {
  if (price) {
    const filteredNumber = price.replace(/[^0-9]/gi, "");
    const number = parseInt(filteredNumber);
    if(isNaN(number)) {return "0"}

    let neg = /-/gi.test(price);

    if (number === 0) {
      neg = false;
    }

    return `${neg ? "-" : ""}${number}`;
  }

  return '0';
};

const quantityFormatter = (quantity) => {
  if (quantity.length) {
    const filteredNum = quantity.replace(/[^0-9]/gi, "");

    if (filteredNum) {
      let number = filteredNum;
      number = parseInt(filteredNum);

      let neg = /-/gi.test(quantity);

      if (number === 0) {
        neg = false;
      }

      return `${neg ? "-" : ""}${number}`;
    }

    return "0";
  }

  return "0";
};

export { priceStrFormatter, removePriceStrFormatter, quantityFormatter };
