import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  background: "linear-gradient(to left top,#34113F, #2A8FBD)",
  border: "3px solid #fff",
  color: "#fff",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const TransitionsModal = ({
  modalOpen,
  handleClose,
  modalText
}) => {
  const [redirectStop, SetRedirectStop] = useState(false);
  const [counter, setCounter] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (modalOpen === true) {
      setCounter(5);

      setTimeout(() => {
        handleClose();
      }, modalText.time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    if (modalText.home && modalOpen === true) {
      redirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const stopLoopRedirect = () => {
    SetRedirectStop(true);
  };

  const redirect = async () => {
    const timePromise = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    if (modalOpen === true) {
      if (counter <= 0 && redirectStop === false) {
        navigate("/");
        return;
      }

      if (redirectStop === true) {
        return;
      }

      await timePromise(1000);
      setCounter((prev) => prev - 1);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby={modalText.title}
        aria-describedby={modalText.description}
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography
              sx={{
                color: "#05F842",
                fontFamily: "Noto, serif",
                textShadow: "-3px 3px 5px #000",
                margin: "20px",
                textAlign: "center",
              }}
              id="transition-modal-title"
              variant="h4"
              component="h2"
            >
              {modalText.title}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {modalText.description}
            </Typography>
            {modalText.home && !redirectStop && (
              <div>
                <div>Redirect to Home in {counter}...</div>
                <Button onClick={stopLoopRedirect}>Stop redirect</Button>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
