import React, { useEffect, useState } from "react";
import { UPDATE_REFIN_INV } from "../../../../utils/GraphQL/mutations";
import {
  GET_SHOP_INVENTORY,
  GET_CURRENT_USER,
} from "../../../../utils/GraphQL/queries";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client/";
import { useMediaQuery } from "@mui/material";
import AdminBDRSpinner from "../../adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import "./refinUpdateList.css";

const RefinUpdateList = () => {
  const { data: InvItems } = useQuery(GET_SHOP_INVENTORY);
  const [itemsArr, setItemsArr] = useState([]);
  const [beenChanged, setBeenChanged] = useState(false);
  const [getUser] = useLazyQuery(GET_CURRENT_USER);
  const [updateRefinInvReq] = useMutation(UPDATE_REFIN_INV);
  const [updateItemMarked, setUpdateItemMarked] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [navHeight, setNavHeight] = useState();
  const smaller900 = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (InvItems?.getInventory.length) {
      const itemQuantityObj = InvItems?.getInventory.map((item) => {
        const { itemName, id } = item;
        return { itemName, id, requestAmount: "0" };
      });

      retrieveUserSetQuantities(itemQuantityObj);
    }

    const navHeightMark = document.querySelector(
      "#admin-nav-height-marker"
    )?.offsetHeight;
    setNavHeight(navHeightMark);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InvItems]);

  const retrieveUserSetQuantities = async (items) => {
    const { data: user } = await getUser();

    items.forEach((element) => {
      const result = user?.getCurrentUser.refinReqInv.map((obj) => {
        if (obj.reqItemId === element.id) {
          setItemsArr((prev) => [
            ...prev,
            { ...element, requestAmount: obj.reqItemCount },
          ]);
          return true;
        }
        return false;
      });

      if (result.includes(true)) {
        return;
      }

      setItemsArr((prev) => [...prev, element]);
      return;
    });
    setLoading(false);
  };

  const changeHandler = (event) => {
    setBeenChanged(true);
    const name = event.target.name;
    const value = event.target.value;

    const adjustedArr = itemsArr.map((item) => {
      if (item.id === name) {
        return { ...item, requestAmount: value };
      }
      return item;
    });

    setItemsArr(adjustedArr);
    return;
  };

  const updateListCall = async () => {
    let updatedArr = [];
    itemsArr.map((item) => {
      const reqCount = item.requestAmount.toString();
      updatedArr.push({ reqItemId: item.id, reqItemCount: reqCount });
      return true;
    });
    const { data: updateResult } = await updateRefinInvReq({
      variables: {
        itemsIdAndCountArr: updatedArr,
      },
    });

    if (updateResult) {
      setBeenChanged(false);
      setUpdateItemMarked({});
    }
    setLoading(false);
  };

  const handleItemChanged = (index) => {
    setUpdateItemMarked((state) => ({
      ...state, // <-- copy previous state
      [index]: true, // <-- update value by index key
    }));
  };

  return (
    <div className="admin-container">
      <h1 className="refin-update-page-header">Update List</h1>
      {isLoading && <AdminBDRSpinner message={"Loading..."} />}
      {beenChanged && (
        <div style={{ top: `${navHeight}px` }} className="refin-absolute">
          <button
            style={{ top: smaller900 ? `${navHeight + 10}px`: '10px' }}
            disabled={beenChanged ? false : true}
            id="refin-update-list-page-header"
            className={
              beenChanged
                ? "refin-inv-update-btn updated"
                : "refin-inv-update-btn"
            }
            onClick={() => {
              setLoading(true);
              updateListCall();
            }}
          >
            Update
          </button>
        </div>
      )}
      <div className="refin-req-inv-container">
        {itemsArr &&
          itemsArr.map((item, index) => {
            const reqAmount = parseInt(item.requestAmount);

            return (
              <div
                key={item.id}
                className={
                  updateItemMarked[index]
                    ? "refin-inv-item-req updated"
                    : "refin-inv-item-req"
                }
              >
                <h3>{item.itemName}</h3>
                <div className="refin-inv-item-label">
                  <label htmlFor="req-amount-input">Request Amount</label>
                  <input
                    id="req-amount-input"
                    name={item.id}
                    onChange={(event) => {
                      handleItemChanged(index);
                      changeHandler(event);
                    }}
                    min="0"
                    type="number"
                    defaultValue={reqAmount}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RefinUpdateList;
