class UpdateSignAssignmentCheck {
  constructor(formState) {
    this.signNum = formState.signNum;
    this.signName = formState.signName;
    this.errors = [];
  }

  async testSignNum() {
    if (this.signNum?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter Valid Sign Number");
    return false;
  }

  async testSignName() {
    if (this.signName?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter Valid Assignee");
    return false;
  }

  async updateValid() {
    this.errors = [];

    //run tests for errors first
    await this.testSignNum();
    await this.testSignName();

    if (this.errors?.length === 0) {
      return true;
    }

    return this.errors;
  }
}

export default UpdateSignAssignmentCheck;
