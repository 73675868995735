import TextField from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& label": {
    color: "#fff"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
    backgroundColor: "#6d6d6d",
    color: "#fff",
    fontSize: "1.3em",
  },
});

export default CssTextField;
