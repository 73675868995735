import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Snackbar } from "@mui/material";
import { GET_KEY_SHORTCUTS } from "../../../../utils/GraphQL/queries";
import {
  DELETE_KEY_SHORTCUT,
  CREATE_KEY_SHORTCUT,
} from "../../../../utils/GraphQL/mutations";
import "./shortcutsPage.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";

const blankShortcut = {
  title: "",
  text: "",
};

const ShortcutsPage = () => {
  const [shortcuts, setShortcuts] = useState([]);
  const [createItem, setCreateItem] = useState(false);
  const [newShortcut, setNewShortcut] = useState({ ...blankShortcut });
  const [shortcutsFetch] = useLazyQuery(GET_KEY_SHORTCUTS);
  const [deleteCall] = useMutation(DELETE_KEY_SHORTCUT);
  const [createCall] = useMutation(CREATE_KEY_SHORTCUT);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    shortcutsCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortcutsCall = async () => {
    const { data } = await shortcutsFetch({
      fetchPolicy: "network-only",
    });

    if (data?.getKeyShortcuts) {
      setShortcuts([...data?.getKeyShortcuts]);
    }
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const addItem = async (item) => {
    const { data } = await createCall({
      variables: {
        ...newShortcut,
      },
    });

    if (data?.createKeyShortcut) {
      handleSnack("Shortcut Created", 2000);
      setShortcuts((prev) => [...prev, data?.createKeyShortcut]);
      setCreateItem(false);
      setNewShortcut({ ...blankShortcut });

      return;
    }

    handleSnack("Something went wrong...Please try again", 2000);
  };

  const handleChange = async (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    setNewShortcut((prev) => ({ ...prev, [name]: value }));
  };

  const deleteItem = async (id) => {
    const { data } = await deleteCall({
      variables: {
        id,
      },
    });

    if (data?.deleteKeyShortcut === true) {
      handleSnack("Shortcut Deleted", 2000);
      setShortcuts((prev) => {
        const filtered = prev?.filter((item) => item.id !== id);

        return [...filtered];
      });
    }
  };

  return (
    <div className="shortcuts-page-page">
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="shortcuts-page-container">
        <div className="shortcuts-page-item-create-box">
          <button
            className="shortcuts-page-action-img-button"
            onClick={() => {
              setCreateItem((prev) => !prev);
            }}
          >
            {createItem ? (
              <RemoveIcon titleAccess="Cancel Create" />
            ) : (
              <AddIcon titleAccess="Create New Shortcut" />
            )}
          </button>
        </div>
        {createItem && (
          <div className="shortcuts-page-new-box">
            <h5>New Shortcut</h5>
            <CssTextFieldStandard
              sx={{ width: "95%", my: 0.5 }}
              type="text"
              label={"Title:"}
              name="title"
              onChange={handleChange}
              value={newShortcut?.title}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "95%", my: 0.5 }}
              type="text"
              label={"Text:"}
              name="text"
              onChange={handleChange}
              value={newShortcut?.text}
              variant="standard"
              multiline
            />
            <button className="shortcuts-page-btn" onClick={addItem}>
              Save Shortcut
            </button>
          </div>
        )}
        <table className="shortcuts-page-table-box">
          <tbody>
            <tr>
              <th>Title</th>
              <th>Text</th>
              <th className="shortcuts-page-item-actions">Actions</th>
            </tr>

            {shortcuts?.length > 0 &&
              shortcuts?.map((item, index) => {
                return (
                  <tr key={item?.id}>
                    <td>{item?.title}</td>
                    <td>{item?.text} </td>
                    <td className="shortcuts-page-item-actions">
                      {/* <button
                        className="shortcuts-page-action-img-button"
                        onClick={() => {
                          setCreateItem(false);
                        }}
                      >
                        <EditIcon titleAccess="Edit" />
                      </button> */}
                      <button
                        className="shortcuts-page-action-img-button"
                        onClick={() => {
                          deleteItem(item?.id);
                        }}
                      >
                        <DeleteIcon titleAccess="Delete" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShortcutsPage;
