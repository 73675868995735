class UpdateUserInfoTest {
  constructor(formState) {
    this.firstName = formState.firstName;
    this.lastName = formState.lastName;
    this.email = formState.email;
    this.phoneNumbers = formState.phoneNumberIds;
    this.streetAddress = formState.streetAddress;
    this.city = formState.city;
    this.state = formState.state;
    this.zipCode = formState.zipCode;
    this.newPass = formState.newPass;
    this.initLogin = formState.initLogin;
    this.errors = [];
  }

  async testName() {
    if (this.firstName?.length > 0 && this.lastName?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter First AND Last Name");
    return false;
  }

  async testEmail() {
    const emailRegex = /.+@.+\..+/;
    if (emailRegex.test(this.email)) {
      return true;
    }

    this.errors.push("Please Enter Valid Email");
    return false;
  }

  async testPhone() {
    const phones = this.phoneNumbers.map((phoneNum) => {
      if (phoneNum.phoneNumber?.length > 0 && phoneNum.phoneNumber?.length < 12) {
        this.errors.push("Please Enter Valid Phone Number");
        return "partial number";
      }

      const phoneRegex = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return phoneRegex.test(phoneNum.phoneNumber);
    });

    if (phones.includes("partial number")) {
      return false;
    }

    if (phones.includes(true)) {
      return true;
    }
    this.errors.push("Please Enter Valid Phone Number");
    return false;
  }

  async testStreetAddress() {
    if (this.streetAddress?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter Valid Street Address");
    return false;
  }

  async testCity() {
    if (this.city?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter Valid City");
    return false;
  }

  async testState() {
    if (this.state?.length > 0) {
      return true;
    }

    this.errors.push("Please Enter Valid State");
    return false;
  }

  async testZipCode() {
    if (this.zipCode?.length === 5) {
      return true;
    }

    this.errors.push("Please Enter Valid Zip Code");
    return false;
  }
  
  async testNewPass() {
    const upperLowerDigit = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

    if (this.initLogin === false && this.newPass?.length === 0){
        return true
    }
    
    if (upperLowerDigit.test(this.newPass)) {
      return true;
    }

    this.errors.push("Please Enter Valid Password");
    return false;
  }


  async updateValid() {
    this.errors = [];

    //run tests for errors first
    await this.testName();
    await this.testEmail();
    await this.testPhone();
    await this.testStreetAddress();
    await this.testCity();
    await this.testState();
    await this.testZipCode();
    await this.testNewPass();

    if (this.errors?.length === 0) {
      return true;
    }

    return this.errors;
  }
}

export default UpdateUserInfoTest;
