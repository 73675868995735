import React, { useEffect } from "react";

const Testimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
      <h1>Our Customer Reviews</h1>
    </div>
  );
};

export default Testimonials;
