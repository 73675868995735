import React, { useEffect, useState, useRef } from "react";
import "./viewClientInvoiceModal.css";
import { useMutation } from "@apollo/client";
import { MARK_INVOICE_PAID } from "../../../../utils/GraphQL/mutations";
import QuoteRadioForm from "../../../../components/QuoteRadioForm/QuoteRadioForm";
import ClientModalInvoiceQuotePage from "../ClientModalInvoiceQuotePage/ClientModalInvoiceQuotePage";

const ViewClientInvoiceModal = ({
  selectedInvoice,
  closeInvoiceModal,
  handleSnack,
  queryClient,
  emailObjHandler,
  changeParentForEmailModal,
}) => {
  const [markInvoicePaidCall] = useMutation(MARK_INVOICE_PAID);
  const [invoicePaidOpt, setInvoicePaidOpt] = useState(false);
  const [staticPrint, setStaticPrint] = useState(false);
  const [paidSelection, setPaidSelection] = useState("");
  const timeoutIdRef = useRef();

  useEffect(() => {
    const body = document.querySelector("body");

    if (selectedInvoice?.id?.length === 24) {
      body.style.overflow = "hidden";
    }
    if (selectedInvoice?.id?.length !== 24) {
      body.style.overflow = "auto";
    }

    if (selectedInvoice?.id) {
      const prevOrDefault = selectedInvoice?.paidBy || "Check";
      setPaidSelection(prevOrDefault);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoice?.id]);

  useEffect(() => {
    const timeoutId = timeoutIdRef.current;
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (staticPrint === true) {
      sendInvoiceEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticPrint]);

  const closeModal = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    closeInvoiceModal("");
  };

  const openOptions = (bool) => {
    setInvoicePaidOpt(bool);
  };

  const paidByHandler = (event) => {
    setPaidSelection(event.target.value);
  };

  const sendInvoiceEmail = async () => {
    if (selectedInvoice?.invoicePaid === true) {
      emailObjHandler({
        type: "receiptTemplate",
        invoiceLink: `https://bigginsdoorrefinishing.com/client/view-invoice/${selectedInvoice?.id}`,
        attachArr: [],
      });
    }

    if (selectedInvoice?.invoicePaid !== true) {
      emailObjHandler({
        type: "invoiceTemplate",
        invoiceLink: `https://bigginsdoorrefinishing.com/client/view-invoice/${selectedInvoice?.id}`,
        attachArr: [],
      });
    }

    const id = setTimeout(() => {
      changeParentForEmailModal(true);
    }, 700);

    timeoutIdRef.current = id;
    closeModal();
    setStaticPrint(false);
    return;
  };

  //   const returnMills = (mills) => {
  //     if (mills) {
  //       setDateErr(false);
  //     }
  //     setInvoice((prev) => ({ ...prev, jobDate: mills }));
  //   };

  const markPaid = async (paid) => {
    const paidClearOrValue = paid ? paidSelection : null;
    const { data } = await markInvoicePaidCall({
      variables: {
        invoiceId: selectedInvoice?.id,
        invoicePaid: paid,
        paidBy: paidClearOrValue,
      },
    });

    if (data?.markInvoicePaid) {
      if (handleSnack) {
        handleSnack(`Invoice Marked ${paid ? "Paid" : "Unpaid"}`, 2500);
      }

      if (queryClient) {
        queryClient();
      }

      openOptions(false);
      return;
    }
  };

  return (
    <div
      className="view-invoice-modal-container"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className="view-invoice-modal-content"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <button
          className="view-invoice-modal-btn"
          onClick={() => {
            if (!selectedInvoice?.invoicePaid) {
              openOptions(!invoicePaidOpt);
              return;
            }
            markPaid(false);
          }}
        >
          {selectedInvoice?.invoicePaid
            ? "Mark Invoice Unpaid"
            : "Mark Invoice Paid"}
        </button>
        <button
          className="view-invoice-modal-btn"
          onClick={() => {
            setStaticPrint(true);
          }}
        >
          Send Invoice
        </button>
        <button
          className="view-invoice-modal-close"
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>
        {invoicePaidOpt && (
          <div className="view-invoice-modal-invoice-paid-opt-box">
            <QuoteRadioForm
              BoxCSS={"view-quote-modal-invoice-paid"}
              choiceStateFunction={paidByHandler}
              passValue={paidSelection}
              name="paidBy"
              question={"Paid By?"}
              choices={["Check", "Card", "Cash"]}
              forceRow={true}
            />
            <div className="view-quote-modal-invoice-paid-save">
              <button
                className="view-invoice-modal-btn"
                onClick={() => {
                  markPaid(true);
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
        <ClientModalInvoiceQuotePage invoiceObj={selectedInvoice} />
      </div>
    </div>
  );
};

export default ViewClientInvoiceModal;
