import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN_USER, SEND_TEMP_PASS } from "../../utils/GraphQL/mutations";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../utils/auth";
import { useParams } from "react-router-dom";

import "./login.css";
import Tracking from "../../components/TrackingComp";
import WebAuthnAuthenticate from "../AdminDashboard/adminComponents/WebAuthn/WebAuthnAuthenticate";

const Login = ({ changeLoggedIn }) => {
  const { paymentID } = useParams();
  const [formState, setFormState] = useState({ email: "", password: "" });
  const [login, { error }] = useMutation(LOGIN_USER);
  const [sendTempPassReset] = useMutation(SEND_TEMP_PASS);
  const [errorRes, setErrorRes] = useState("");
  const [errorTemp, setErrorTemp] = useState("");
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [helperMes, setHelperMes] = useState("");
  const [registeredCred, setRegisteredCred] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (typeof PublicKeyCredential !== "undefined") {
      const localIdStored = localStorage.getItem("BDRWebAuthnCredId");
      if (localIdStored) {
        setRegisteredCred(true);
      }
    }
  }, []);
  
  useEffect(() => {
    if (paymentID) {
      console.log("Payment Id: ", paymentID)
      sessionStorage.setItem("bdr-redirect-payment-id", paymentID)
    }
  }, [paymentID]);

  useEffect(() => {
    if (error) {
      setErrorRes(error.message);
      setFailedAttempts((prev) => prev + 1);
    }
  }, [error]);

  useEffect(() => {
    const alreadyLoggedIn = Auth.loggedIn();

    if (alreadyLoggedIn) {
      navigate("/bdr-office/dashboard");
    }
  });

  // update state based on form input changes
  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "email") {
      value = value.toLowerCase();
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // submit form
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await login({
        variables: {
          email: formState.email.trim(),
          password: formState.password.trim(),
        },
      });

      const loginSuccess = Auth.login(data.loginUser.token);

      if (loginSuccess === false) {
        return (
          <p>
            <h1>Sorry, something went wrong</h1>
            <Link to="/">back to the homepage.</Link>
          </p>
        );
      }

      localStorage.setItem("bdr-non-visitor", true);

      await changeLoggedIn(true);

      navigate("/bdr-office/dashboard");
    } catch (err) {
      console.error(err.message);
    }

    // clear form values
    setFormState((prev) => ({
      email: prev.email,
      password: "",
    }));
  };

  const clearError = () => {
    setErrorRes("");
    setErrorTemp("");
  };

  const sendTempPass = async () => {
    clearError();
    const { data: tempData } = await sendTempPassReset({
      variables: { emailTo: formState.email },
    });

    if (tempData?.sendForgotPasswordEmail === null) {
      setErrorTemp("User Not Found");
      return;
    }

    if (tempData?.sendForgotPasswordEmail === true) {
      setHelperMes("Password Reset Email Sent");
      return;
    }

    setHelperMes("Error - Email Not Sent");
    return;
  };

  return (
    <div className="login-box">
      {registeredCred ? (
        <div className="login-card registered-login">
          <div className="login-btn-box">
            <WebAuthnAuthenticate changeLoggedIn={changeLoggedIn}/>
            <button className="form-btn" onClick={() => {setRegisteredCred(false)}}>
              Login with Credentials
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="login-card">
            <form
              autoComplete="on"
              action=""
              method=""
              className="center-form"
              onSubmit={handleFormSubmit}
            >
              <input
                className="form-input"
                placeholder="Your email"
                name="email"
                type="email"
                value={formState.email}
                onChange={handleChange}
                onFocus={clearError}
                autoComplete="email"
              />
              <input
                className="form-input"
                placeholder="******"
                name="password"
                type="password"
                value={formState.password}
                onChange={handleChange}
                onFocus={clearError}
                autoComplete="current-password"
              />
              <div className="login-btn-box">
                <button className="form-btn" type="submit">
                  Login
                </button>
              </div>
            </form>
            {failedAttempts > 1 && (
              <button onClick={sendTempPass} className="form-btn">
                Reset password?
              </button>
            )}
            {helperMes && <h3 className="login-reset-pass">{helperMes}</h3>}
          </div>
          {errorTemp && <h3 className="error-login">{errorTemp}</h3>}
          {!errorTemp && errorRes && (
            <h3 className="error-login">{errorRes}</h3>
          )}
        </>
      )}
      <Tracking removeVisit={true} />
    </div>
  );
};

export default Login;
