import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./recentProjectsCarousel.css";
import { useLazyQuery } from "@apollo/client";
import { QUERY_RECENT_BA_GROUPS } from "../../../utils/GraphQL/queries";
import nameListFormat from "../../../utils/formatters/refinisherFormat.js";
import BAGroupTimeFormat from "../../../utils/formatters/BAGroupTimeFormat";
import BdrSpinner from "../../BdrSpinner/BdrSpinner";
import ImgGroupModal from "../../Modal/imgGroupModal";
import { Button } from "@mui/material";

const RecentProjectsCarousel = ({ paginationCB, paginationState }) => {
  const [index, setIndex] = useState(0);
  const [imgModalOpenRecent, setImgModalOpenRecent] = useState(false);
  const [BAGroupsLoading, setBAGroupsLoading] = useState(false);
  const [BAGroups, setBAGroups] = useState(null);
  const [queryTrigger, setQueryTrigger] = useState(0);
  const [modalGroupArrIdRecent, setModalGroupArrIdRecent] = useState(Number);
  const [pageObj, setPageObj] = useState({
    useRecents: null,
    count: null,
    page: "1",
  });

  const [queryBAGroups] = useLazyQuery(QUERY_RECENT_BA_GROUPS);

  useEffect(() => {
    if (paginationState) {
      setBAGroupsLoading(true);
      queryCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState]);

  useEffect(() => {
    if (queryTrigger > 0) {
      queryMoreRecents(pageObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTrigger]);

  const queryCall = async () => {
    const local = Date.now().toString();
    const { data } = await queryBAGroups({
      variables: {
        localTimeEpoch: local,
        ...pageObj,
      },
    });

    if (data?.getRecentBAGs) {
      setPageObj({
        useRecents: data?.getRecentBAGs?.useRecents,
        count: data?.getRecentBAGs?.count,
        page: (parseInt(data?.getRecentBAGs?.pageNum) + 1).toString(),
      });
      setBAGroups([...data?.getRecentBAGs?.BAGData]);
      setBAGroupsLoading(false);
      paginationCB();

      if (data?.getRecentBAGs?.nextPage === true) {
        setQueryTrigger((prev) => prev + 1);
      }
    }
    return;
  };

  const queryMoreRecents = async (pageObjScoped) => {
    const local = Date.now().toString();
    const { data } = await queryBAGroups({
      variables: {
        localTimeEpoch: local,
        ...pageObjScoped,
      },
      fetchPolicy: "network-only",
    });

    if (data?.getRecentBAGs) {
      setPageObj(() => {
        return {
          useRecents: data?.getRecentBAGs?.useRecents,
          count: data?.getRecentBAGs?.count,
          page: (parseInt(data?.getRecentBAGs?.pageNum) + 1).toString(),
        };
      });
      setBAGroups((prev) => {
        if (data?.getRecentBAGs?.nextPage === true) {
          return [...prev, ...data?.getRecentBAGs?.BAGData];
        }

        if (data?.getRecentBAGs?.nextPage === false) {
          let filtered = [...prev, ...data?.getRecentBAGs?.BAGData];
          return [...new Set(filtered)];
        }
      });
      setBAGroupsLoading(false);

      if (data?.getRecentBAGs?.nextPage === true) {
        setQueryTrigger((prev) => prev + 1);
      }
    }
    return;
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // modal controls
  const handleImgOpen = () => setImgModalOpenRecent(true);
  const handleImgClose = () => setImgModalOpenRecent(false);

  const imgModalRecentEvent = (event) => {
    handleImgOpen();
    setModalGroupArrIdRecent(index);
  };

  return (
    <div className="our-doors-carousel-container ">
      {imgModalOpenRecent ? (
        <ImgGroupModal
          BAGroup={BAGroups[modalGroupArrIdRecent]}
          handleImgClose={handleImgClose}
          imgModalOpen={imgModalOpenRecent}
        />
      ) : null}
      {BAGroupsLoading ? (
        <BdrSpinner message={"Loading..."} />
      ) : (
        <Carousel
          pause={false}
          activeIndex={index}
          indicators={false}
          onSelect={handleSelect}
        >
          {BAGroups?.length &&
            BAGroups?.map((image, index) => {
              const nameList = nameListFormat(
                image?.refinishers || ["BDR Team"]
              );

              return (
                <Carousel.Item
                  onClick={imgModalRecentEvent}
                  key={image?._id + `${index}`}
                >
                  <div className="our-doors-week-card">
                    <div className="our-doors-flex-container ">
                      <Button className="our-doors-img-box">
                        <span className="our-doors-before-after">
                          Before...
                        </span>
                        <img
                          className="our-doors-carousel-img"
                          src={image?.beforeId?.imageData}
                          alt=""
                        />
                      </Button>
                      <Button className="our-doors-img-box">
                        <img
                          className="our-doors-carousel-img"
                          src={image?.afterId?.imageData}
                          alt=""
                        />
                        <span className="our-doors-before-after">After!</span>
                      </Button>
                    </div>
                    <ul className="our-doors-ul">
                      <li>
                        Refinished By: <h3>{nameList}</h3>
                      </li>
                      <li>
                        Completed: {BAGroupTimeFormat(image?.dateEntered)}
                      </li>
                      <li>Stain Color : {image?.stainColor}</li>
                    </ul>
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      )}
    </div>
  );
};

export default RecentProjectsCarousel;
