import React, { useEffect, useState } from "react";
import "./stripeTransactions.css";
import { useLazyQuery } from "@apollo/client";
import { GET_STRIPE_TRANSACTIONS } from "../../../../utils/GraphQL/queries";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";

const StripeTransactions = () => {
  const [trans, setTrans] = useState([]);
  const [totalHeight, setTotalHeight] = useState(800);
  const [getStripeTransactionsCall] = useLazyQuery(GET_STRIPE_TRANSACTIONS);

  useEffect(() => {
    queryTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = setTimeout(() => {
      handlePageHeight();
    }, 80);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageHeight = () => {
    const navHeight = document.querySelector(
      "#admin-nav-height-marker"
    )?.offsetHeight;

    const totalHeight = window.innerHeight - navHeight;
    setTotalHeight(totalHeight);
  };

  const queryTransactions = async () => {
    const { data } = await getStripeTransactionsCall();

    console.log(data?.getStripeTransactions);
    if (data?.getStripeTransactions) {
      setTrans([...data?.getStripeTransactions]);
    }
  };

  const transactionTime = (mill) => {
    const timeStr = BAGroupTimeFormat(mill, true, true);

    const today = /today/gi.test(timeStr);
    const oneYear = 1000 * 60 * 60 * 24 * 365;
    const olderThenAYear = Date.now() - oneYear > parseInt(mill);

    const time = today
      ? `${timeStr?.split("@")[1]}`
      : olderThenAYear
      ? `${timeStr?.split(",")[1]?.replace(/@/gi, "-")}`
      : `${BAGroupTimeFormat(mill, true, false)?.replace(/@/gi, "-")}`;

    return time;
  };

  return (
    <div className="trans-page" style={{ height: `${totalHeight}px` }}>
      <div className="trans-page-container">
        <div className="trans-page-sticky-header"><h2>Recent Transactions</h2></div>
        {trans?.length > 0 &&
          trans?.map((item, index) => {
            const date = transactionTime(item?.dateCreated);
            const tip = parseInt(item?.tip) || 0;

            return (
              <div key={`transaction-${item?.id}`} className="trans-item">
                <div className="trans-item-amount">
                  Total: {priceStrFormatter(item?.amount)} -{" "}
                  <span className="trans-item-tip">{date}</span>
                </div>
                {tip !== 0 && (
                  <div className="trans-item-tip">
                    + {priceStrFormatter(tip?.toString())} Tip
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default StripeTransactions;
