import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import BAGroupTimeFormat from "../../utils/formatters/BAGroupTimeFormat";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "@mui/material";
import nameListFormat from "../../utils/formatters/refinisherFormat";
import "./imgGroupModal.css";

const ImgGroupModal = ({ BAGroup, imgModalOpen, handleImgClose }) => {
  //returns true is below 600px
  const mediaQuery = useMediaQuery("(max-width: 600px)");

  return (
    <div>
      <Modal
        aria-labelledby={BAGroup?._id}
        aria-describedby={BAGroup?._id}
        open={imgModalOpen}
        onClose={handleImgClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={imgModalOpen}>
          <Box className="img-modal-container">
            {mediaQuery ? (
              <Carousel pause={false} indicators={false}>
                <Carousel.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      className="img-modal-img"
                      src={BAGroup?.beforeId?.imageData}
                      alt="Before"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      className="img-modal-img"
                      src={BAGroup?.afterId?.imageData}
                      alt="After"
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            ) : (
              <div className="img-modal-full-box">
                <img
                  className="img-modal-img"
                  src={BAGroup?.beforeId?.imageData}
                  alt="Before"
                />
                <img
                  className="img-modal-img"
                  src={BAGroup?.afterId?.imageData}
                  alt="After"
                />
              </div>
            )}
            <>
              <ul className="img-modal-finishers-ul">
                <li>
                  Refinished By: <h2>{nameListFormat(BAGroup?.refinishers)}</h2>
                </li>
                <li>Completed: {BAGroupTimeFormat(BAGroup?.dateEntered)}</li>
                <li>Stain Color : {BAGroup?.stainColor}</li>
              </ul>
              <Button onClick={handleImgClose} sx={{ color: "#fff" }}>
                close
              </Button>
            </>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ImgGroupModal;
