import React, { useEffect, useRef, useState } from "react";
import { CREATE_CLIENT_QUOTE } from "../../../../utils/GraphQL/mutations";
import {
  GET_QUOTE_COUNT,
  GET_USER_INFO_FOR_TASKS,
} from "../../../../utils/GraphQL/queries";
import "./createQuoteModal.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
// import AdminRadioForm from "../../adminComponents/AdminRadioForm/AdminRadioForm";
// import Auth from "../../../../utils/auth";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";
import NewQuoteItemBox from "./NewQuoteItemBox";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import Auth from "../../../../utils/auth";
import { CssTextFieldStandardBlack } from "../../../../components/CssTextFields/CssTextFieldStandard";
import CreateQuoteValidator from "../../../../utils/formValidators/createQuoteValidator";
import SendEmailModal from "../SendEmail/SendEmailModal";

const logo = require("../../../../assets/images/BDR-logo.png");

const clearedForm = {
  quoteStatus: "Active",
  // quoteViewed: [
  //   {
  //     type: String,
  //   },
  // ],
  dateCreated: "",
  createdBy: "",
  quoteItems: [
    // {
    //   itemName: "Double Doors, Int",
    //   price: "92356",
    //   quantity: "1",
    //   discountApplicable: true,
    // },
  ],
  quoteTotal: "0",
  subtotal: "",
  customerName: "",
  quoteNum: "",
  expirationDate: "",
  discountPercent: "10",
  quoteNote: "",
  quoteType: "",
  customerId: "",
  assigneeId: "22",
  assigneeName: "All",
};

const QTEBlank = {
  type: "quoteTemplate",
  // bigginsdoorrefinishing.com/client/view-quote/*quoteId
  quoteLink: [],
  toFirstName: "",
};

const CreateQuoteModal = ({
  clientObj,
  buttonCSS,
  handleSnack,
  fetchClientInfo,
  quotesDisplayTrigger,
}) => {
  //   const mediaOver900 = useMediaQuery("(min-width: 900px)");
  //   const under600 = useMediaQuery("(max-width: 600px)");
  const [createQuoteGQLCall] = useMutation(CREATE_CLIENT_QUOTE);
  const { data: quoteNum, refetch: refetchQuoteCount } =
    useQuery(GET_QUOTE_COUNT);
  const [openModal, setOpenModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [quoteForm, setQuoteForm] = useState({ ...clearedForm });
  const [quoteToEmailObj, setQuoteToEmailObj] = useState({ ...QTEBlank });
  const [quoteDate, setQuoteDate] = useState("");
  const [formErrors, setFormErrors] = useState([]);
  const [discountTotal, setDiscountTotal] = useState("0");
  const [quoteCount, setQuoteCount] = useState("0");
  const messageInput = useRef();
  const timeoutIdRef = useRef();
  const [usersInfo, setUsersInfo] = useState([]);
  const [getUsersInfoCall] = useLazyQuery(GET_USER_INFO_FOR_TASKS);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [afterLastUserIndex, setAfterLastUserIndex] = useState(0);

  const quoteValidator = new CreateQuoteValidator(quoteForm);

  useEffect(() => {
    const body = document.querySelector("body");

    // used to getting the most recent quote num.
    // In case of back to back quote creations
    refetchQuoteCount();

    if (openModal === true) {
      body.style.overflow = "hidden";
    }
    if (openModal === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (clientObj?._id) {
      setQuoteForm((prev) => ({
        ...prev,
        customerName: `${clientObj?.firstName} ${clientObj?.lastName}`,
        customerId: clientObj?._id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientObj, openModal]);

  useEffect(() => {
    const timeoutId = timeoutIdRef.current;
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (quoteNum?.getQuoteCount) {
      setQuoteCount((parseInt(quoteNum?.getQuoteCount) + 2)?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteNum]);

  useEffect(() => {
    const totalArr = quoteForm?.quoteItems?.map((item) => {
      return parseInt(
        makeTotal(
          parseInt(item?.price),
          parseInt(item?.quantity),
          item?.discountApplicable
        )
      );
    });

    const subtotalArr = quoteForm?.quoteItems?.map((item) => {
      return parseInt(
        makeTotal(parseInt(item?.price), parseInt(item?.quantity), false)
      );
    });

    const quoteTotal = totalArr?.reduce((price, price2) => price + price2, 0);
    const subtotal = subtotalArr?.reduce((price, price2) => price + price2, 0);

    const discountOff = subtotal - quoteTotal;
    setDiscountTotal(`${discountOff}`);
    setQuoteForm((prev) => ({
      ...prev,
      quoteTotal: `${quoteTotal}`,
      subtotal: `${subtotal}`,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteForm?.quoteItems, quoteForm?.discountPercent]);

  useEffect(() => {
    if (usersInfo?.length <= 0 && openModal) {
      makeGetInfoCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const makeGetInfoCall = async () => {
    const { data } = await getUsersInfoCall();

    if (data?.getUsersNamesForTasks) {
      const index = data?.getUsersNamesForTasks?.length + 1;
      setSelectedUserIndex(index);
      setAfterLastUserIndex(index);
      setUsersInfo([...data?.getUsersNamesForTasks]);
    }
  };

  useEffect(() => {
    const { firstName, lastName } = Auth.getProfile()?.data;
    const now = Date.now();
    const date = BAGroupTimeFormat(now, false, true)?.split(" ")?.slice(1);

    const days90 = 1000 * 60 * 60 * 24 * 90 + now;

    setQuoteForm((prev) => ({
      ...prev,
      dateCreated: `${now}`,
      expirationDate: `${days90}`,
      createdBy: `${firstName} ${lastName}`,
    }));

    setQuoteDate(`${date[0]} ${date[1]}, ${date[2]}`);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteNum, openModal]);

  const addQuoteItem = (newItem) => {
    if (formErrors?.length > 0) {
      clearFormErrors();
    }
    setQuoteForm((prev) => ({
      ...prev,
      quoteItems: [...prev?.quoteItems, newItem] || [newItem],
    }));
  };

  const makeTotal = (price, quantity, discount) => {
    const total = discount
      ? (
          (((100 - parseInt(quoteForm?.discountPercent)) / 100) *
            price *
            quantity) /
          100
        )
          ?.toFixed(2)
          .replace(".", "")
      : ((price * quantity) / 100)?.toFixed(2).replace(".", "");

    return total;
  };

  const removeQuoteItem = (index) => {
    const arr = Array.from(quoteForm?.quoteItems);
    arr?.splice(index, 1);

    handleSnack("Item Removed", 2000);
    setQuoteForm((prev) => ({ ...prev, quoteItems: arr }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "text") {
      messageInput.current?.scrollIntoView();
    }

    if (name === "discountPercent") {
      value = value.replace(/[^0-9]/gi, "");
    }

    setQuoteForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeAssignee = (index) => {
    setSelectedUserIndex(index);

    if (index !== afterLastUserIndex) {
      const selected = usersInfo?.[index];

      setQuoteForm((prev) => ({
        ...prev,
        assigneeId: selected?._id || "",
        assigneeName: `${selected?.firstName} ${selected?.lastName}`,
      }));

      return;
    }

    setQuoteForm((prev) => ({
      ...prev,
      assigneeId: "22",
      assigneeName: "All",
    }));
  };

  const clearFormErrors = () => {
    setFormErrors([]);
  };

  const createQuote = async (event) => {
    const valid = await quoteValidator.createClientQuoteValid();

    if (valid !== true) {
      setFormErrors([...valid]);
      return;
    }

    const jobTypesArr = quoteForm?.quoteItems?.map((item) => {
      if (item?.jobType === "Refinish") {
        return true;
      }
      return false;
    });

    const quoteTypeAnswer = jobTypesArr?.includes(true)
      ? "Refinish"
      : "Maintenance";

    if (event.target.name === "saveOnly") {
      const { data } = await createQuoteGQLCall({
        variables: {
          ...quoteForm,
          quoteType: quoteTypeAnswer,
        },
      });

      if (data?.createClientQuote) {
        setQuoteForm({ ...clearedForm });
        fetchClientInfo();
        handleSnack("Quote & F/U Task Created", 3000);
        setOpenModal(false);
        const id = setTimeout(() => {
          quotesDisplayTrigger();
        }, 500);
        timeoutIdRef.current = id;
        return;
      }
    }

    if (event.target.name === "createAndEmail") {
      const { data } = await createQuoteGQLCall({
        variables: { ...quoteForm },
      });

      if (data?.createClientQuote) {
        setQuoteForm({ ...clearedForm });
        fetchClientInfo();
        handleSnack("Quote Created", 3000);
        setOpenModal(false);

        setQuoteToEmailObj({
          type: "quoteTemplate",
          // https://bigginsdoorrefinishing.com/client/view-quote/*quoteId
          quoteLink: [
            `https://bigginsdoorrefinishing.com/client/view-quote/${data?.createClientQuote?.id}`,
          ],
          toFirstName: clientObj?.firstName || "",
        });

        const id = setTimeout(() => {
          changeParentForEmailModal(true);
        }, 700);

        timeoutIdRef.current = id;
        return;
      }
    }

    handleSnack("Something went wrong...Please Try Again", 3000);
  };

  const changeParentForEmailModal = (bool) => {
    setOpenEmailModal(bool);
  };

  return (
    <>
      <button
        className={buttonCSS ? buttonCSS : "create-quote-start-button"}
        onClick={(event) => {
          event.stopPropagation();
          setOpenModal(true);
        }}
      >
        Create Quote
      </button>
      {openEmailModal && (
        <SendEmailModal
          sendTo={clientObj?.primaryEmail}
          templateObj={quoteToEmailObj}
          changeParent={changeParentForEmailModal}
          handleSnack={handleSnack}
        />
      )}
      {openModal && (
        <div
          className="create-quote-modal-container"
          // bg click closes modal
          // onClick={() => {
          // }}
        >
          <div
            className="create-quote-modal-content"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="create-quote-modal-close-container">
              <h1 className="create-quote-modal-header">Create Quote</h1>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  setQuoteForm((prev) => ({
                    ...clearedForm,
                    customerName: prev?.customerName,
                    expirationDate: prev?.expirationDate,
                  }));
                  setOpenModal(false);
                }}
                className="close-modal-x-btn"
              >
                X
              </button>
            </div>
            <div className="cust-quote-page-assignee-box">
              <FormControl variant="standard" sx={{ my: 1, width: "80%" }}>
                <InputLabel
                  id="user-task-select"
                  sx={{
                    color: "#05F842",
                    "&.Mui-focused": { color: "#05F842" },
                  }}
                >
                  Assign F/U To:
                </InputLabel>
                <Select
                  labelId="user-task-select"
                  id="user-task-select-input"
                  name="user-task-select"
                  value={selectedUserIndex}
                  onChange={(event) => {
                    handleChangeAssignee(event.target.value);
                  }}
                  disableUnderline={true}
                  sx={{
                    color: "#fff",
                    WebkitTextFillColor: "#fff",
                    "& .Mui-disabled": {
                      color: "#fff !important",
                      WebkitTextFillColor: "#fff",
                    },
                  }}
                  style={{ borderBottom: "1px solid #fff" }}
                >
                  {usersInfo?.length > 0 &&
                    usersInfo?.map((userInfo, index) => {
                      const username = `${userInfo?.firstName} ${userInfo?.lastName}`;

                      return (
                        <MenuItem key={userInfo?._id} value={index}>
                          {username?.trim()}
                        </MenuItem>
                      );
                    })}
                  <MenuItem value={afterLastUserIndex}>All</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="create-quote-page">
              <div className="cust-quote-info-accept-box">
                <div className="cust-quote-company-info">
                  <div className="cust-quote-company-info-inner">
                    <div className="cust-quote-img-box">
                      <img
                        className="cust-quote-logo-img"
                        src={logo}
                        alt="BDR Logo"
                      />
                    </div>
                    <ul className="cust-quote-ul">
                      <li>
                        <strong>Biggin's Door Refinishing</strong>
                      </li>
                      <li>18975 Marbach Ln</li>
                      <li>Suite 22-1209</li>
                      <li>San Antonio, Tx 78266</li>
                    </ul>
                  </div>
                </div>
                <div className="cust-quote-accept-box">
                  <ul className="cust-quote-ul">
                    <li>
                      <strong>Quote #</strong>
                    </li>
                    <li className="cust-quote-text-right">{quoteCount}</li>
                  </ul>
                  <div>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: "95%",
                        my: 1,
                        background: "#e4e4e4",
                        borderRadius: "3px",
                        border: "1px solid #000",
                        padding: "0 5px",
                      }}
                    >
                      <InputLabel
                        id="quote-discount"
                        sx={{
                          color: "#000",
                          "&.Mui-focused": { color: "#000" },
                        }}
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="quote-discount"
                        id="quote-discount-input"
                        name="quoteStatus"
                        value={quoteForm?.quoteStatus}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        disableUnderline={true}
                        sx={{
                          color: "#000",
                          WebkitTextFillColor: "#000",
                          "& .Mui-disabled": {
                            color: "#000 !important",
                            WebkitTextFillColor: "#000",
                          },
                        }}
                        style={{ borderBottom: "none" }}
                      >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Expired"}>Expired</MenuItem>
                        <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: "95%",
                        my: 1,
                        background: "#e4e4e4",
                        borderRadius: "3px",
                        border: "1px solid #000",
                        padding: "0 5px",
                      }}
                    >
                      <InputLabel
                        id="quote-discount"
                        sx={{
                          color: "#000",
                          "&.Mui-focused": { color: "#000" },
                        }}
                      >
                        Discount Percent
                      </InputLabel>
                      <Select
                        labelId="quote-discount"
                        id="quote-discount-input"
                        name="discountPercent"
                        value={quoteForm?.discountPercent}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        disableUnderline={true}
                        sx={{
                          color: "#000",
                          WebkitTextFillColor: "#000",
                          "& .Mui-disabled": {
                            color: "#000 !important",
                            WebkitTextFillColor: "#000",
                          },
                        }}
                        style={{ borderBottom: "none" }}
                      >
                        <MenuItem value={"0"}>0</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"20"}>20</MenuItem>
                        <MenuItem value={"25"}>25</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="cust-quote-to-box">
                <div className="cust-quote-to-section">
                  <span>
                    <strong>To</strong>
                  </span>
                  <h5 className="cust-quote-to-bubble">
                    {capitalizeFirstLetters(quoteForm?.customerName)}
                  </h5>
                </div>
                <div className="cust-quote-to-section">
                  <span>
                    <strong>Quote Date</strong>
                  </span>
                  <h5 className="cust-quote-to-bubble">{quoteDate}</h5>
                </div>
                <div className="cust-quote-to-section">
                  <span>
                    <strong>Total Amount</strong>
                  </span>
                  <h5 className="cust-quote-to-bubble">
                    {priceStrFormatter(quoteForm?.quoteTotal)}
                  </h5>
                </div>
              </div>
              {formErrors?.length > 0 && (
                <div className="create-quote-err-box">
                  <ul>
                    {formErrors?.map((err, index) => {
                      return <li key={err + index}>{err}</li>;
                    })}
                  </ul>
                </div>
              )}
              {quoteForm?.quoteItems?.length > 0 && (
                <div className="cust-quote-items-container">
                  <div className="cust-quote-items-item">
                    <span>Item</span>
                    <span>Price</span>
                    <span>Quantity</span>
                    <span>Discount</span>
                    <span>Total</span>
                  </div>
                  <div className="cust-quote-hr"></div>
                  {quoteForm?.quoteItems?.map((item, index) => {
                    const total = makeTotal(
                      parseInt(item?.price),
                      parseInt(item?.quantity),
                      item?.discountApplicable
                    );

                    return (
                      <div
                        key={`cust-quote-item-${index}-${item?.itemName}`}
                        className="cust-quote-items-item"
                      >
                        <span>
                          <span className="create-quote-item-rmv-btn-wrapper">
                            <button
                              className="create-quote-item-rmv-btn"
                              onClick={() => {
                                removeQuoteItem(index);
                              }}
                            >
                              X
                            </button>
                          </span>
                          <span className="create-quote-item-name">
                            {item?.itemName}
                          </span>
                        </span>
                        <span>{priceStrFormatter(item?.price)}</span>
                        <span>{item?.quantity}</span>
                        <span>
                          {item?.discountApplicable
                            ? quoteForm?.discountPercent === "0"
                              ? "N/A"
                              : `${quoteForm?.discountPercent}%`
                            : "N/A"}
                        </span>
                        <span>{priceStrFormatter(total)}</span>
                      </div>
                    );
                  })}
                </div>
              )}
              <NewQuoteItemBox
                discountPercent={parseInt(quoteForm?.discountPercent)}
                addQuoteItem={addQuoteItem}
                handleSnack={handleSnack}
              />
              <div className="cust-quote-hr"></div>
              <div className="cust-quote-totals-box">
                <div>
                  <span>
                    Subtotal: {priceStrFormatter(quoteForm?.subtotal)}
                  </span>
                </div>
                {discountTotal > 0 && (
                  <div>
                    <span>- Discount: {priceStrFormatter(discountTotal)}</span>
                  </div>
                )}
                <div>
                  <strong>
                    <span>
                      Quote Total: {priceStrFormatter(quoteForm?.quoteTotal)}
                    </span>
                  </strong>
                </div>
              </div>
              <p>
                Quote will remain valid for 30 days after it was issued. Valid
                until{" "}
                <strong>
                  {BAGroupTimeFormat(quoteForm?.expirationDate, false, true)}
                </strong>
                .
              </p>
              <CssTextFieldStandardBlack
                sx={{
                  width: "90%",
                  my: 1,
                  mx: "auto",
                  marginBottom: "3px",
                  background: "#e4e4e4",
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": { border: "1px solid #000" },
                    "& fieldset": { border: "1px solid #000" },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #000",
                    },
                  },
                }}
                ref={messageInput}
                label="Quote Note"
                name="quoteNote"
                value={quoteForm?.quoteNote}
                onChange={handleChange}
                multiline
                minRows={4}
              />
              <div className="create-quote-save-btns-box">
                <button
                  name="saveOnly"
                  className={
                    formErrors?.length > 0
                      ? "create-quote-save-button create-quote-btn-err"
                      : "create-quote-save-button"
                  }
                  onClick={(event) => {
                    clearFormErrors();
                    createQuote(event);
                  }}
                >
                  Create Quote
                </button>
                <button
                  name="createAndEmail"
                  className={
                    formErrors?.length > 0
                      ? "create-quote-save-button create-quote-btn-err"
                      : "create-quote-save-button"
                  }
                  onClick={(event) => {
                    clearFormErrors();
                    createQuote(event);
                  }}
                >
                  Create & Send Email
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateQuoteModal;
