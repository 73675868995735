const pasteKeapNote = async (clipboard, handleSnack) => {
  const text = await clipboard.readText();
  if (!text || text?.trim()?.length === 0) {
    if (handleSnack) {
      handleSnack("Nothing to paste...", 2000)
    }
    return ""
  }
  const newLined = text + "\n"

  const regex = /(?<=\*\*)([.\w\W\D\d]*?)(?=[\n])/gmi
  const found = newLined?.match(regex)

  if (found?.length > 0) {
    let changedText = newLined;
    found?.forEach((str) => {
      const newString = `${str?.trim()}**`
      changedText = changedText?.replace(str, newString)
    })

    return changedText?.trim()
  }

  return newLined?.trim()
};

export default pasteKeapNote;
