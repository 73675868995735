import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./pictureUpload.css";
import { useMutation } from "@apollo/client";
import { NEW_CLIENT_PHOTO } from "../../utils/GraphQL/mutations";
import BdrSpinner from "../BdrSpinner/BdrSpinner";
import resizeImage from "../../utils/formatters/resizeImage";

const PictureUpload = ({
  changeSticky,
  changeLoading,
  changePhotoIds,
}) => {
  const [files, setFiles] = useState();
  const [uploadedImageTempUrl, setUploadedImageTempUrl] = useState([]);
  const [savePhoto, { loading: imgSaving }] = useMutation(NEW_CLIENT_PHOTO);

  const clearTempUrls = () => {
    setUploadedImageTempUrl([]);
  };

  const handleUploadSubmit = async () => {
    if (files?.length === 0) {
      return;
    }

    // changeSticky(false);
    await changeLoading(true);

    for await (const file of files) {
      const date = Date.now()
      const resizedImg = await resizeImage(file);

      if ((await resizedImg.length) > 0) {
        const photoData = await savePhoto({
          variables: {
            imageData: resizedImg,
            beforeOrAfter: "before",
            dateEntered: date.toString(),
          },
        });

        if (photoData.data) {
          changePhotoIds(photoData.data.storeImage._id);
        }
      }
    }

    // For saving to google drive instead of Mongodb

    // fetch("/image/uploading", {
    //   method: "POST",
    //   body: data,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.photoId) {
    //       changePhotoIds(data.photoId);
    //       changeLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    changeLoading(false);
    return;
  };

  const handleFileChange = (event) => {
    clearTempUrls();
    if (event.target.files.length === 0) {
      // changeSticky(true)
    }

    if (event.target.files) {
      setFiles([...event.target.files]);

      return;
    }
  };

  const createTemps = async () => {
    const b64toBlob = (
      b64Data,
      contentType = "image/jpeg",
      sliceSize = 512
    ) => {
      const splitData = b64Data.split(",")[1];
      const byteCharacters = atob(splitData);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    if (files) {
      for (const file of files) {
        const newSize = await resizeImage(file);
        const blob = b64toBlob(newSize);
        const url = URL.createObjectURL(blob);
        const name = file.name;

        setUploadedImageTempUrl((uploadedImageTempUrl) => [
          ...uploadedImageTempUrl,
          { url, name },
        ]);
      }

      handleUploadSubmit();

      return;
    }
  };

  useEffect(() => {
    createTemps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <div className="uploaded-container">
      <label className="custom-file-upload">
        <input
          multiple
          onChange={handleFileChange}
          type="file"
          accept="image/*"
          name="clientImageUpload"
        />
        <FileUploadIcon /> Select File(s)
      </label>
      <div className="uploaded-box relative">
        <div className={imgSaving ? "over-loader" : "none"}>
          <BdrSpinner message={"Saving..."} />
        </div>
        {uploadedImageTempUrl &&
          uploadedImageTempUrl.map((file) => {
            return (
              <img
                className="uploaded-pic"
                key={file.name + Date.now()}
                src={file.url}
                alt="User upload"
              />
            );
          })}
      </div>
    </div>
  );
};
export default PictureUpload;
