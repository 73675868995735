import React, { useEffect, useRef, useState } from "react";
import "./viewClientQuoteModal.css";
import { useMutation } from "@apollo/client";
import { CREATE_CLIENT_INVOICE } from "../../../../utils/GraphQL/mutations";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import QuoteRadioForm from "../../../../components/QuoteRadioForm/QuoteRadioForm";
import CalendarTimeSelect from "../CalendarTimeSelect/CalendarTimeSelect";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import ClientModalInvoiceQuotePage from "../ClientModalInvoiceQuotePage/ClientModalInvoiceQuotePage";

const invoiceObj = {
  jobDate: "",
  invoicePaid: false,
  invoiceNote: "",
  paidBy: null,
};

const ViewClientQuoteModal = ({
  selectedQuote,
  closeQuoteModal,
  handleSnack,
  queryClient,
  emailObjHandler,
  changeParentForEmailModal,
}) => {
  const [viewHistory, setViewHistory] = useState([]);
  const [invoice, setInvoice] = useState({ ...invoiceObj });
  const [openInvoiceOptions, setOpenInvoiceOptions] = useState(false);
  const [allowInvoice, setAllowInvoice] = useState(false);
  const [noteLabel, setNoteLabel] = useState(`Invoice Note:  (Optional)`);
  const [dateErr, setDateErr] = useState(false);
  const [createInvoiceCall] = useMutation(CREATE_CLIENT_INVOICE);
  const timeoutIdRef = useRef();

  useEffect(() => {
    const body = document.querySelector("body");

    if (selectedQuote?.id?.length === 24) {
      body.style.overflow = "hidden";

      if (/invoice/gi?.test(selectedQuote?.quoteStatus) === false) {
        setAllowInvoice(true);
      }

      if (selectedQuote?.quoteViewed?.length > 0) {
        setViewHistory([...selectedQuote?.quoteViewed]);
      }
    }

    if (selectedQuote?.id?.length !== 24) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote]);

  useEffect(() => {
    const timeoutId = timeoutIdRef.current;
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const closeModal = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    closeQuoteModal("");
  };

  const createInvoice = async (setEmailObj) => {
    if (invoice?.jobDate?.length === 0) {
      if (handleSnack) {
        handleSnack("Please select job date", 3500);
      }
      setDateErr(true);
      return;
    }

    setDateErr(false);

    const { data } = await createInvoiceCall({
      variables: {
        quoteId: selectedQuote?.id,
        localEpoch: Date.now().toString(),
        jobScheduledFor: invoice?.jobDate,
        invoicePaid: invoice?.invoicePaid,
        invoiceNote: invoice?.invoiceNote,
        paidBy: invoice?.paidBy,
      },
    });

    if (data?.createClientInvoice) {
      if (handleSnack) {
        handleSnack("Invoice Created", 3500);
      }
      if (queryClient) {
        queryClient();
      }
      if (setEmailObj) {
        emailObjHandler({
          type: "invoiceTemplate",
          invoiceLink: `https://bigginsdoorrefinishing.com/client/view-invoice/${data?.createClientInvoice}`,
        });

        const id = setTimeout(() => {
          changeParentForEmailModal(true);
        }, 700);

        timeoutIdRef.current = id;
      }

      closeModal();
    }

    return;
  };

  const sendQuoteEmail = () => {
    emailObjHandler({
      type: "quoteTemplate",
      invoiceLink: null,
      quoteLink: [
        `https://bigginsdoorrefinishing.com/client/view-quote/${selectedQuote?.id}`,
      ],
    });

    const id = setTimeout(() => {
      changeParentForEmailModal(true);
    }, 700);

    timeoutIdRef.current = id;
    closeModal();
  };

  const invoiceChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "invoicePaid") {
      value = event.target.value === "Yes" ? true : false;

      setInvoice((prev) => ({
        ...prev,
        paidBy: value ? "Check" : null,
        [name]: value,
      }));

      return;
    }

    setInvoice((prev) => ({ ...prev, [name]: value }));
  };

  const returnMills = (mills) => {
    if (mills) {
      setDateErr(false);
    }
    setInvoice((prev) => ({ ...prev, jobDate: mills }));
  };

  return (
    <div
      className="view-quote-modal-container"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className="view-quote-modal-content"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <button
          className="view-quote-modal-close"
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>
        <div className="view-quote-modal-invoice-box">
          <div>
            {allowInvoice && (
              <button
                className="view-quote-modal-btn"
                onClick={() => {
                  setOpenInvoiceOptions((prev) => {
                    if (prev) {
                      setInvoice({ ...invoiceObj });
                    }
                    return !prev;
                  });
                }}
              >
                {openInvoiceOptions ? "Cancel" : "Invoice"}
              </button>
            )}
            <button className="view-quote-modal-btn" onClick={sendQuoteEmail}>
              Send Quote
            </button>
          </div>
          {openInvoiceOptions && (
            <div className="view-quote-modal-invoice-date">
              {dateErr && (
                <ul>
                  <li>Please select job date!</li>
                </ul>
              )}
              <div className="view-quote-modal-invoice-date-select">
                <QuoteRadioForm
                  BoxCSS={"view-quote-modal-invoice-paid-box"}
                  choiceStateFunction={invoiceChangeHandler}
                  name="invoicePaid"
                  question={"Has invoice already been paid?"}
                  choiceDefault={"No"}
                  choices={["Yes", "No"]}
                  forceRow={true}
                  endComponent={
                    <>
                      {invoice?.invoicePaid && (
                        <QuoteRadioForm
                          BoxCSS={"view-quote-modal-invoice-paid-end-box"}
                          choiceStateFunction={invoiceChangeHandler}
                          name="paidBy"
                          question={"Paid By?"}
                          choiceDefault={"Check"}
                          choices={["Check", "Card", "Cash"]}
                          forceRow={true}
                        />
                      )}
                      <CalendarTimeSelect
                        justifyContent={"center"}
                        returnMill={returnMills}
                        hrSelect={8}
                        leadingText={"Job Scheduled For: "}
                      />
                    </>
                  }
                />
              </div>
              <CssTextFieldStandard
                sx={{ width: "95%", my: 0.5, mx: "auto" }}
                label={noteLabel}
                name="invoiceNote"
                value={invoice?.invoiceNote}
                onChange={invoiceChangeHandler}
                onFocus={() => {
                  setNoteLabel("Invoice Note:");
                }}
                onBlur={() => {
                  if (invoice?.invoiceNote?.length <= 0) {
                    setNoteLabel("Invoice Note for Client: (Optional)");
                  }
                }}
                multiline
                minRows={3}
              />
              <div className="view-quote-modal-invoice-create-box">
                <button
                  className="view-quote-modal-btn"
                  onClick={() => {
                    createInvoice(false);
                  }}
                >
                  Create Invoice
                </button>
                <button
                  className="view-quote-modal-btn"
                  onClick={() => {
                    createInvoice(true);
                  }}
                >
                  Create Invoice & Send Email
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="view-quote-modal-resized">
          <ClientModalInvoiceQuotePage quoteObj={selectedQuote} />
          {viewHistory?.length > 0 && (
            <ul className="view-quote-history-ul">
              {viewHistory?.map((item, index) => {
                return (
                  <li key={`quote-history-view-item-${index}`}>{`View ${
                    viewHistory?.length - index
                  } - ${BAGroupTimeFormat(
                    item?.timestamp,
                    true,
                    true,
                    false
                  )}`}</li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewClientQuoteModal;
