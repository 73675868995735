import React from "react";
import TextField from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";
import Collapse from "@mui/material/Collapse";

import FormHelperText from "@mui/material/FormHelperText";
import "./cssTextField.css";

function MyFormHelperText({ formError, errorText, noBG }) {
  return (
    <Collapse timeout={500} in={formError}>
      <FormHelperText
        component={"div"}
        className={noBG ? "helper-text noBG" : "helper-text"}
      >
        {errorText}
      </FormHelperText>
    </Collapse>
  );
}

const CssTextFieldStandard = styled(TextField)({
  "& label.Mui-focused": {
    color: "#05F842",
  },
  "& label": {
    color: "#05F842",
  },
  "& .MuiFormHelperText-root": {
    color: "#fff",
    fontSize: ".85em",
    backgroundColor: "#b17532",
    width: "fit-content",
    border: "1px solid #fff",
    borderRadius: "12px",
    padding: "2px 7px",
    margin: "4px 0px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": { border: "1px solid #05F842" },
    "& fieldset": { border: "1px solid #fff" },
    "&.Mui-focused fieldset": {
      border: "1px solid #fff",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff",
  },
  "& .MuiInput-root:hover:before": {
    borderBottom: "2px solid #05F842",
  },
  "& .MuiInput-underline": {
    borderBottomColor: "#fff",
  },
  "& .MuiInputBase-input": {
    borderBottomColor: "#fff",
    "& fieldset": {
      borderBottomColor: "#fff",
    },
    "&:hover fieldset": {
      borderBottomColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: "#fff",
    },
    color: "#fff",
  },
});

const CssTextFieldStandardBlack = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& label": {
    color: "#000",
  },
  "& MuiTextField-root.MuiInputBase-input": {
    border: "none",
  },
  "& .MuiFormHelperText-root": {
    color: "#000",
    fontSize: "inherit",
    backgroundColor: "#b17532",
    width: "fit-content",
    border: "none",
    borderRadius: "12px",
    padding: "2px 7px",
    margin: "4px 0px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": { border: "none" },
    "& fieldset": { border: "none" },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    border: "none",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#000",
    border: "none",
  },
  "& .MuiInput-root:hover:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline": {
    borderBottomColor: "transparent",
    border: "none",
  },
  "& .MuiInputBase-input": {
    borderBottomColor: "transparent",
    border: "none",
    "& fieldset": {
      borderBottomColor: "transparent",
      border: "none",
    },
    "&:hover fieldset": {
      borderBottomColor: "transparent",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: "transparent",
      border: "none",
    },
    color: "#000",
  },
});

const CssTextFieldStandardBlackWithBorder = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& label": {
    color: "#000",
  },
  "& MuiTextField-root.MuiInputBase-input": {
    border: "",
  },
  "& .MuiFormHelperText-root": {
    color: "#000",
    fontSize: "inherit",
    backgroundColor: "#b17532",
    width: "fit-content",
    border: "none",
    borderRadius: "12px",
    padding: "2px 7px",
    margin: "4px 0px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": { border: "none" },
    "& fieldset": { border: "none" },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    border: "none",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#000",
    border: "none",
    borderBottom: "1px solid #000000"
  },
  "& .MuiInput-root:hover:before": {
    borderBottom: "1px solid #000000",
  },
  "& .MuiInput-underline": {
    borderBottomColor: "transparent",
    border: "none",
  },
  "& .MuiInputBase-input": {
    borderBottomColor: "transparent",
    border: "none",
    "& fieldset": {
      borderBottomColor: "transparent",
      border: "none",
    },
    "&:hover fieldset": {
      borderBottomColor: "transparent",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: "transparent",
      border: "none",
    },
    color: "#000",
  },
});

export {
  CssTextFieldStandard,
  CssTextFieldStandardBlack,
  CssTextFieldStandardBlackWithBorder,
  MyFormHelperText,
};
