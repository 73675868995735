import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { ADD_SHOP_ITEM } from "../../../../utils/GraphQL/mutations";
import { GET_SHOP_INVENTORY } from "../../../../utils/GraphQL/queries";
import { Snackbar } from "@mui/material";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import "./adminAddInvItem.css";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import {
  removePriceStrFormatter,
  priceStrFormatter,
  quantityFormatter,
} from "../../../../utils/formatters/priceStrFormatter";

const AddInvItem = ({ heightTrigger }) => {
  const AddInvItemRef = useRef();
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [newItemLoading, setNewItemLoading] = useState(false);
  const [updateSuccess, setUpdateSuccessObj] = useState(false);
  const [createInvItem] = useMutation(ADD_SHOP_ITEM);
  const [newItemObj, setNewItemObj] = useState({
    itemName: "",
    currentQuantity: "",
    maxQuantity: "",
    price: "",
    priceStr: "",
  });
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "price") {
      const toNumStr = removePriceStrFormatter(value);
      const newPriceStr = priceStrFormatter(toNumStr);
      setNewItemObj((prev) => ({
        ...prev,
        price: toNumStr,
        priceStr: newPriceStr,
      }));

      return;
    }

    if (name === "currentQuantity" || name === "maxQuantity") {
      //sets only number strings for positive and negative
      let finalQuantity = quantityFormatter(value);

      setNewItemObj((prev) => ({
        ...prev,
        [name]: finalQuantity,
      }));
      return;
    }

    setNewItemObj((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearItemObj = () => {
    setNewItemObj((prev) => ({
      itemName: "",
      currentQuantity: "",
      maxQuantity: "",
      price: "",
      priceStr: "",
    }));
  };

  const createCheck = async () => {
    const { itemName, currentQuantity, maxQuantity, price } = newItemObj;
    const check = {
      name: false,
      curQ: false,
      maxQ: false,
      price: false,
    };

    if (itemName.length !== 0 && typeof itemName === "string") {
      check.name = true;
    }
    if (currentQuantity.length !== 0 && typeof currentQuantity === "string") {
      check.curQ = true;
    }
    if (maxQuantity.length !== 0 && typeof maxQuantity === "string") {
      check.maxQ = true;
    }
    if (price.length !== 0 && typeof price === "string") {
      check.price = true;
    }

    if (check.name && check.curQ && check.maxQ && check.price) {
      return true;
    }

    return false;
  };

  const handleSuccess = () => {
    setUpdateSuccessObj((prev) => !prev);

    setTimeout(() => {
      setUpdateSuccessObj((prev) => !prev);
    }, 1500);
  };

  const handleCreate = async () => {
    const goodCreate = await createCheck();
    const { itemName, currentQuantity, maxQuantity, price } = newItemObj;

    if (goodCreate) {
      const createData = await createInvItem({
        variables: {
          itemName,
          currentQuantity,
          maxQuantity,
          price,
        },
        refetchQueries: [GET_SHOP_INVENTORY],
      });

      if (createData?.data.addInventoryItem) {
        handleSuccess();
        handleSnack("Item Added", 2000);
      }
    }

    if (goodCreate === false) {
      handleSnack("Missing some information", 2000);
    }

    setNewItemLoading(false);
    return;
  };

  return (
    <div
      id="add-inv-item-height-marker"
      className="add-inv-container"
      ref={AddInvItemRef}
    >
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {newItemLoading && <AdminBDRSpinner message={"Loading..."} />}
      <button
        onClick={() => {
          heightTrigger();
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = AddInvItemRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;

              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              AddInvItemRef.current.scrollIntoView({
                block: "start",
              });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Add Inventory Item</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div
        className={barCollapsed ? "add-inv-content" : "add-inv-content show"}
      >
        {/* cssTextField for adding inventory item */}
        <div
          className={
            updateSuccess
              ? "add-inv-content-box success"
              : "add-inv-content-box"
          }
        >
          <CssTextFieldStandard
            sx={{ width: "95%", m: 0.5 }}
            type="text"
            label={"Item Name"}
            inputMode="text"
            name="itemName"
            value={newItemObj.itemName}
            onChange={handleChange}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: "95%", m: 0.5 }}
            type="text"
            label={"Current Quantity"}
            inputMode="text"
            name="currentQuantity"
            value={newItemObj.currentQuantity}
            onChange={handleChange}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: "95%", m: 0.5 }}
            type="text"
            label={"Max Quantity"}
            inputMode="text"
            name="maxQuantity"
            value={newItemObj.maxQuantity}
            onChange={handleChange}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: "95%" }}
            type="text"
            label={"Price"}
            inputMode="text"
            name="price"
            value={newItemObj.priceStr}
            onChange={handleChange}
            variant="standard"
          />
          <div className="add-item-btn-box">
            <button
              onClick={() => {
                setNewItemLoading(true);
                handleCreate();
              }}
              className="add-item-btn"
            >
              Create Item
            </button>
            <button
              onClick={() => {
                clearItemObj();
              }}
              className="add-item-btn"
            >
              Clear/Add Another
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInvItem;
