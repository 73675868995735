import React, { useEffect, useRef, useState } from "react";
import "./quoteInvoiceEditModal.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";
import NewQuoteItemBox from "../CreateQuoteModal/NewQuoteItemBox";
import { useMutation } from "@apollo/client";
import { CssTextFieldStandardBlack } from "../../../../components/CssTextFields/CssTextFieldStandard";
import CreateQuoteValidator from "../../../../utils/formValidators/createQuoteValidator";
import CalendarTimeSelect from "../CalendarTimeSelect/CalendarTimeSelect";
import { arraysEqual } from "../../../../utils/comparators";
import { UPDATE_QUOTE_INVOICE } from "../../../../utils/GraphQL/mutations";

const logo = require("../../../../assets/images/BDR-logo.png");

const clearedForm = {
  quoteStatus: "Active",
  dateCreated: "",
  createdBy: "",
  items: [],
  quoteTotal: "0",
  subtotal: "",
  customerName: "",
  quoteNum: "",
  expirationDate: "",
  discountPercent: "10",
  quoteNote: "",
  note: "",
  quoteType: "",
  customerId: "",
  assigneeId: "22",
  assigneeName: "All",
};

const clearedUpdates = {
  date: false,
  items: false,
  status: false,
  discount: false,
  total: false,
};

const QuoteInvoiceEditPage = ({
  selectedObj,
  isInvoice,
  handleSnack,
  returnNewObj,
}) => {
  const [quoteForm, setQuoteForm] = useState({ ...clearedForm });
  const [quoteDate, setQuoteDate] = useState("");
  const [quoteDateMills, setQuoteDateMills] = useState("0");
  const [formErrors, setFormErrors] = useState([]);
  const [formUpdates, setFormUpdates] = useState({ ...clearedUpdates });
  const [discountTotal, setDiscountTotal] = useState("0");
  const [docNum, setDocNum] = useState("0");
  const messageInput = useRef();
  const [updateGQLCall] = useMutation(UPDATE_QUOTE_INVOICE);

  const quoteValidator = new CreateQuoteValidator(quoteForm);

  useEffect(() => {
    if (selectedObj) {
      // console.log(selectedObj);

      const items = isInvoice
        ? selectedObj?.invoiceItems
        : selectedObj?.quoteItems;

      const date = isInvoice
        ? selectedObj?.jobScheduledFor
        : selectedObj?.dateCreated;

      const note = isInvoice
        ? selectedObj?.invoiceNote
        : selectedObj?.quoteNote;

      setQuoteDateMills(date);

      const docNum = isInvoice
        ? selectedObj?.invoiceNum
        : selectedObj?.quoteNum;
      setDocNum(docNum);

      setQuoteForm((prev) => ({
        ...prev,
        ...selectedObj,
        items: [...items],
        note: note,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObj, isInvoice]);

  useEffect(() => {
    const date = isInvoice
      ? selectedObj?.jobScheduledFor
      : selectedObj?.dateCreated;
    const dateArr = BAGroupTimeFormat(quoteDateMills, false, true, true)?.split(
      " "
    );
    const formattedDate = `${dateArr[0]} ${dateArr[1]}, ${dateArr[2]}`;
    const difDate = date !== quoteDateMills;

    setFormUpdates((prev) => ({ ...prev, date: difDate }));
    setQuoteDate(formattedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteDateMills]);

  // useEffect(() => {
  //   console.log(quoteForm);
  // }, [quoteForm]);

  useEffect(() => {
    sameItemsCheck(quoteForm?.items);

    const totalArr = quoteForm?.items?.map((item) => {
      return parseInt(
        makeTotal(
          parseInt(item?.price),
          parseInt(item?.quantity),
          item?.discountApplicable
        )
      );
    });

    const subtotalArr = quoteForm?.items?.map((item) => {
      return parseInt(
        makeTotal(parseInt(item?.price), parseInt(item?.quantity), false)
      );
    });

    const totalCheck = isInvoice
      ? selectedObj?.invoiceTotal
      : selectedObj?.quoteTotal;

    const total = totalArr?.reduce((price, price2) => price + price2, 0);
    const subtotal = subtotalArr?.reduce((price, price2) => price + price2, 0);

    const totalStr = total?.toString();
    const difPrice = totalCheck !== totalStr;

    const discountOff = subtotal - total;
    setDiscountTotal(`${discountOff}`);
    setQuoteForm((prev) => ({
      ...prev,
      quoteTotal: `${total}`,
      invoiceTotal: `${total}`,
      subtotal: `${subtotal}`,
    }));
    setFormUpdates((prev) => ({ ...prev, total: difPrice }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteForm?.items, quoteForm?.discountPercent]);

  const addQuoteItem = (newItem) => {
    if (formErrors?.length > 0) {
      clearFormErrors();
    }
    setQuoteForm((prev) => ({
      ...prev,
      items: [...prev?.items, newItem] || [newItem],
    }));
  };

  const makeTotal = (price, quantity, discount) => {
    const total = discount
      ? (
          (((100 - parseInt(quoteForm?.discountPercent)) / 100) *
            price *
            quantity) /
          100
        )
          ?.toFixed(2)
          .replace(".", "")
      : ((price * quantity) / 100)?.toFixed(2).replace(".", "");

    return total;
  };

  const sameItemsCheck = (formItems) => {
    const items = isInvoice
      ? selectedObj?.invoiceItems
      : selectedObj?.quoteItems;
    const filteredItems = items?.map((obj) => {
      return {
        discountApplicable: obj?.discountApplicable,
        itemName: obj?.itemName,
        price: obj?.price,
        quantity: obj?.quantity,
      };
    });

    const filteredFormItems = formItems?.map((obj) => {
      return {
        discountApplicable: obj?.discountApplicable,
        itemName: obj?.itemName,
        price: obj?.price,
        quantity: obj?.quantity,
      };
    });

    const sameItems = arraysEqual(filteredItems, filteredFormItems);
    // has items been edited? have to flip sameItems boolean.
    setFormUpdates((prev) => ({ ...prev, items: !sameItems }));
  };

  const removeQuoteItem = (index) => {
    const arr = Array.from(quoteForm?.items);
    arr?.splice(index, 1);

    handleSnack("Item Removed", 2000);
    setQuoteForm((prev) => ({ ...prev, items: arr }));
  };

  const handleDateChange = (newDateMills) => {
    setQuoteDateMills(newDateMills);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "text") {
      messageInput.current?.scrollIntoView();
    }

    if (name === "discountPercent") {
      value = value.replace(/[^0-9]/gi, "");
      const difDiscount = selectedObj?.discountPercent !== value;
      setFormUpdates((prev) => ({ ...prev, discount: difDiscount }));
    }

    setQuoteForm((prev) => ({ ...prev, [name]: value }));
  };

  const clearFormErrors = () => {
    setFormErrors([]);
  };

  const updateCall = async () => {
    const valid = await quoteValidator.updateValid();
    if (valid !== true) {
      setFormErrors([...valid]);
      return;
    }

    const filteredFormItems = quoteForm?.items?.map((obj) => {
      return {
        discountApplicable: obj?.discountApplicable,
        itemName: obj?.itemName,
        price: obj?.price,
        quantity: obj?.quantity,
      };
    });

    const updateObj = {
      id: quoteForm?.id,
      isInvoice: isInvoice,
      discountPercent: quoteForm?.discountPercent,
      subtotal: quoteForm?.subtotal,
      total: quoteForm?.invoiceTotal,
      dateMills: quoteDateMills,
      itemsArr: [...filteredFormItems],
      note: quoteForm?.note,
    };

    const { data } = await updateGQLCall({
      variables: { ...updateObj },
    }, {fetchPolicy: "network-only"});

    if (data?.updateQuoteInvoice) {
      const newObj = isInvoice
        ? data?.updateQuoteInvoice?.invoice
        : data?.updateQuoteInvoice?.quote;

      if (handleSnack) {
        const text = isInvoice ? "Updated Invoice" : "Updated Quote";
        handleSnack(text, 2500);
      }
      returnNewObj(newObj, isInvoice);
    }
  };

  return quoteForm ? (
    <div className="cust-quote-page">
      <div className="cust-quote-top-info"></div>
      <div className="cust-quote-info-accept-box">
        <div className="cust-quote-company-info">
          <div className="cust-quote-company-info-inner">
            <div className="cust-quote-img-box">
              <img className="cust-quote-logo-img" src={logo} alt="BDR Logo" />
            </div>
            <ul className="cust-quote-ul">
              <li>
                <strong>Biggin's Door Refinishing</strong>
              </li>
              <li>18975 Marbach Ln</li>
              <li>Suite 22-1209</li>
              <li>San Antonio, Tx 78266</li>
            </ul>
          </div>
        </div>
        <div className="cust-quote-accept-box">
          <ul className="cust-quote-ul">
            <li>
              <strong>{isInvoice ? "Invoice #" : "Quote #"}</strong>
            </li>
            <li className="cust-quote-text-right">{docNum}</li>
          </ul>
          <div className="cust-quote-discount-info-box">
            {/* Quote status */}
            {isInvoice === false && (
              <FormControl
                variant="standard"
                sx={{
                  width: "95%",
                  my: 1,
                  background: "#e4e4e4",
                  borderRadius: "3px",
                  border: "1px solid #000",
                  padding: "0 5px",
                }}
              >
                <InputLabel
                  id="quote-discount"
                  sx={{
                    color: "#000",
                    "&.Mui-focused": { color: "#000" },
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  labelId="quote-discount"
                  id="quote-discount-input"
                  name="quoteStatus"
                  disabled
                  value={quoteForm?.quoteStatus}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  disableUnderline={true}
                  sx={{
                    color: "#000",
                    WebkitTextFillColor: "#000",
                    "& .Mui-disabled": {
                      color: "#000 !important",
                      WebkitTextFillColor: "#000",
                    },
                  }}
                  style={{ borderBottom: "none" }}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Expired"}>Expired</MenuItem>
                  <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
                </Select>
              </FormControl>
            )}
            <FormControl
              variant="standard"
              sx={{
                width: "95%",
                my: 1,
                background: formUpdates?.discount ? "#2ff90c85" : "#e4e4e4",
                borderRadius: "3px",
                border: "1px solid #000",
                padding: "0 5px",
              }}
            >
              <InputLabel
                id="quote-discount"
                sx={{
                  color: "#000",
                  "&.Mui-focused": { color: "#000" },
                }}
              >
                Discount Percent
              </InputLabel>
              <Select
                labelId="quote-discount"
                id="quote-discount-input"
                name="discountPercent"
                value={quoteForm?.discountPercent}
                onChange={(event) => {
                  handleChange(event);
                }}
                disableUnderline={true}
                sx={{
                  color: "#000",
                  WebkitTextFillColor: "#000",
                  "& .Mui-disabled": {
                    color: "#000 !important",
                    WebkitTextFillColor: "#000",
                  },
                }}
                style={{ borderBottom: "none" }}
              >
                <MenuItem value={"0"}>0</MenuItem>
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"15"}>15</MenuItem>
                <MenuItem value={"20"}>20</MenuItem>
                <MenuItem value={"25"}>25</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="cust-quote-to-box">
        <div className="cust-quote-to-section">
          <span>
            <strong>To</strong>
          </span>
          <h5 className="cust-quote-to-bubble">
            {capitalizeFirstLetters(quoteForm?.customerName)}
          </h5>
        </div>
        <div
          className={
            formUpdates?.date
              ? "cust-quote-to-section form-updated"
              : "cust-quote-to-section"
          }
        >
          <span>
            <strong>{isInvoice ? "Scheduled For" : "Quote Date"}</strong>
          </span>
          {isInvoice && (
            <h5 className="cust-quote-to-bubble">
              <CalendarTimeSelect
                passedSelectedTime={quoteDateMills}
                returnMill={handleDateChange}
                hrSelect={8}
                hideInput={true}
                passedColor="#000"
                leadingText={quoteDate}
              />
            </h5>
          )}
          {!isInvoice && <h5 className="cust-quote-to-bubble">{quoteDate}</h5>}
        </div>
        <div
          className={
            formUpdates?.total
              ? "cust-quote-to-section form-updated"
              : "cust-quote-to-section"
          }
        >
          <span>
            <strong>Total Amount</strong>
          </span>
          <h5 className="cust-quote-to-bubble">
            {priceStrFormatter(
              isInvoice ? quoteForm?.invoiceTotal : quoteForm?.quoteTotal
            )}
          </h5>
        </div>
      </div>
      {formErrors?.length > 0 && (
        <div className="create-quote-err-box">
          <ul>
            {formErrors?.map((err, index) => {
              return <li key={err + index}>{err}</li>;
            })}
          </ul>
        </div>
      )}
      {quoteForm?.items?.length > 0 && (
        <div
          className={
            formUpdates?.items
              ? "cust-quote-items-container form-updated"
              : "cust-quote-items-container"
          }
        >
          <div className="cust-quote-items-item">
            <span>Item</span>
            <span>Price</span>
            <span>Quantity</span>
            <span>Discount</span>
            <span>Total</span>
          </div>
          <div className="cust-quote-hr"></div>
          {quoteForm?.items?.map((item, index) => {
            const total = makeTotal(
              parseInt(item?.price),
              parseInt(item?.quantity),
              item?.discountApplicable
            );

            return (
              <div
                key={`cust-quote-item-${index}-${item?.itemName}`}
                className="cust-quote-items-item"
              >
                <span>
                  <span className="create-quote-item-rmv-btn-wrapper">
                    <button
                      className="create-quote-item-rmv-btn"
                      onClick={() => {
                        removeQuoteItem(index);
                      }}
                    >
                      X
                    </button>
                  </span>
                  <span className="create-quote-item-name">
                    {item?.itemName}
                  </span>
                </span>
                <span>{priceStrFormatter(item?.price)}</span>
                <span>{item?.quantity}</span>
                <span>
                  {item?.discountApplicable
                    ? quoteForm?.discountPercent === "0"
                      ? "N/A"
                      : `${quoteForm?.discountPercent}%`
                    : "N/A"}
                </span>
                <span>{priceStrFormatter(total)}</span>
              </div>
            );
          })}
        </div>
      )}
      <NewQuoteItemBox
        discountPercent={parseInt(quoteForm?.discountPercent)}
        addQuoteItem={addQuoteItem}
        handleSnack={handleSnack}
      />
      <div className="cust-quote-hr"></div>
      <div
        className={
          formUpdates?.total
            ? "cust-quote-totals-box form-updated"
            : "cust-quote-totals-box"
        }
      >
        <div>
          <span>Subtotal: {priceStrFormatter(quoteForm?.subtotal)}</span>
        </div>
        {discountTotal > 0 && (
          <div>
            <span>- Discount: {priceStrFormatter(discountTotal)}</span>
          </div>
        )}
        <div>
          <strong>
            <span>
              {isInvoice ? "Invoice Total: " : "Quote Total: "}{" "}
              {/* When the total gets updated, it updates both versions of total */}
              {/* The invoiceTotal and the quoteTotal. Interchangeable. */}
              {priceStrFormatter(quoteForm?.quoteTotal)}
            </span>
          </strong>
        </div>
      </div>
      {!isInvoice && (
        <p>
          Quote will remain valid for 90 days after it was issued. Valid until{" "}
          <strong>
            {BAGroupTimeFormat(quoteForm?.expirationDate, false, true)}
          </strong>
          .
        </p>
      )}
      <CssTextFieldStandardBlack
        sx={{
          width: "90%",
          my: 1,
          mx: "auto",
          marginBottom: "3px",
          background: "#e4e4e4",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": { border: "1px solid #000" },
            "& fieldset": { border: "1px solid #000" },
            "&.Mui-focused fieldset": {
              border: "1px solid #000",
            },
          },
        }}
        ref={messageInput}
        label={isInvoice ? "Invoice Note" : "Quote Note"}
        name="note"
        value={quoteForm?.note}
        onChange={handleChange}
        multiline
        minRows={4}
      />
      <div className="create-quote-save-btns-box">
        <button
          name="saveOnly"
          className={
            formErrors?.length > 0
              ? "create-quote-save-button create-quote-btn-err"
              : "create-quote-save-button"
          }
          onClick={() => {
            clearFormErrors();
            updateCall();
          }}
        >
          Update Quote
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default QuoteInvoiceEditPage;
