const phoneNumberFormat = (phoneNumber) => {
  const filteredNumber = phoneNumber.replace(/[^0-9]/gi, "");
  const splitNumber = filteredNumber.split("");
  let fullNumber = filteredNumber;

  if (splitNumber.length >= 4 && splitNumber.length <= 6) {
    fullNumber = filteredNumber.slice(0, 3) + "-" + filteredNumber.slice(3);
  }

  if (splitNumber.length >= 7) {
    fullNumber =
      filteredNumber.slice(0, 3) +
      "-" +
      filteredNumber.slice(3, 6) +
      "-" +
      filteredNumber.slice(6, 10);

  }

  return fullNumber;
};

export default phoneNumberFormat;