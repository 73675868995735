import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_SHOP_ITEM,
  UPDATE_SHOP_ITEM,
} from "../../../../utils/GraphQL/mutations";
import { GET_SHOP_INVENTORY } from "../../../../utils/GraphQL/queries";
import { Snackbar } from "@mui/material";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery } from "@mui/material";
import "./viewShopInventory.css";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import UpdateShopItemModal from "../AdminShopItemUpdateModal/AdminShopItemUpdateModal";

const ViewShopInventory = ({ heightTrigger }) => {
  const ViewShopInventoryRef = useRef();
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [upModalOpen, setUpModalOpen] = useState(false);
  const [updateSuccessObj, setUpdateSuccessObj] = useState({});
  const [selectedItem, setSelectedItem] = useState({
    itemName: "",
    itemId: "",
    index: "",
    currentQuantity: "",
    maxQuantity: "",
    price: "",
    priceStr: "",
  });
  const [ItemLoadingState, setItemLoadingState] = useState(false);
  const [shopItems, setShopItems] = useState([]);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });
  const [deleteItem] = useMutation(DELETE_SHOP_ITEM);
  const [updateItem] = useMutation(UPDATE_SHOP_ITEM);
  const { data: inventoryData } = useQuery(GET_SHOP_INVENTORY);

  useEffect(() => {
    setShopItems(inventoryData?.getInventory);
  }, [inventoryData]);

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const handleDeleteItem = async (id) => {
    const { data: deletedData } = await deleteItem({
      variables: { itemId: id },
      refetchQueries: [GET_SHOP_INVENTORY],
    });

    if (deletedData?.deleteInventoryItem === true) {
      handleSnack("Inventory Item Deleted");
      setItemLoadingState(false);
    }
  };

  const updateShopItem = async (selectedItemObj) => {
    const { itemId, currentQuantity, maxQuantity, price, index } =
      selectedItemObj;
    const { data: updatedData } = await updateItem({
      variables: {
        itemId,
        currentQuantity,
        maxQuantity,
        price,
      },
      refetchQueries: [GET_SHOP_INVENTORY],
    });

    if (updatedData?.updateInventoryItem) {
      handleSnack("Item Updated");
      handleSuccess(index);
    }
  };

  const openUpdateModal = (itemId, index) => {
    const foundItem = shopItems?.filter((item) => item.id === itemId);

    if (foundItem) {
      setSelectedItem({ ...foundItem[0], index: index });
      setUpModalOpen(true);
      return;
    }
    handleSnack("Item Not Found - Please Contact Support");
    return;
  };

  const handleModalClose = () => {
    setUpModalOpen(false);
    setSelectedItem({
      itemName: "",
      itemId: "",
      index: "",
      currentQuantity: "",
      maxQuantity: "",
      price: "",
      priceStr: "",
    });
  };

  const handleSuccess = (index) => {
    setUpdateSuccessObj((state) => ({
      ...state, // <-- copy previous state
      [index]: !state[index], // <-- update value by index key
    }));

    setTimeout(() => {
      setUpdateSuccessObj((state) => ({
        ...state, // <-- copy previous state
        [index]: !state[index], // <-- update value by index key
      }));
    }, 1500);
  };

  return (
    <div
      id="view-shop-inv-height-marker"
      className="shop-inv-container"
      ref={ViewShopInventoryRef}
    >
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <UpdateShopItemModal
        open={upModalOpen}
        close={handleModalClose}
        selectedItemObj={selectedItem}
        updateShopItem={updateShopItem}
      />
      <button
        onClick={() => {
          heightTrigger();
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = ViewShopInventoryRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;

              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              ViewShopInventoryRef.current.scrollIntoView({
                block: "start",
              });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>View Shop Inventory</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "shop-content" : "shop-content show"}>
        <div id="test-height" className="shop-inv-content">
          {ItemLoadingState && <AdminBDRSpinner message={"Loading..."} />}
          {shopItems &&
            shopItems?.map((item, index) => {
              return (
                <div
                  key={`${item?.price}${item?.id}${index}`}
                  className={
                    updateSuccessObj[index]
                      ? "shop-inv-item-box success"
                      : "shop-inv-item-box"
                  }
                >
                  <div className="item-name-delete">
                    <span className="shop-item-name">{item?.itemName}</span>
                    <button
                      className="item-name-delete-btn"
                      onClick={() => {
                        handleSnack(
                          <div className="item-name-delete-confirm">
                            <span className="shop-item-name-confirm">
                              Are you sure you want to delete {item?.itemName}?
                            </span>
                            <button
                              className="item-name-delete-btn confirm"
                              onClick={() => {
                                setItemLoadingState(true);
                                handleDeleteItem(item?.id);
                              }}
                            >
                              <DeleteIcon
                                sx={{ fontSize: "20px", color: "#b40000" }}
                              />
                              (Delete)
                            </button>
                          </div>,
                          10000
                        );
                        // setItemLoadingState(true);
                        // handleDeleteItem(item?.id);
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: "20px", color: "#b40000" }} />
                      (Delete)
                    </button>
                  </div>
                  <button
                    onClick={() => {
                      openUpdateModal(item?.id, index);
                    }}
                    className="shop-item-btn"
                    title="Change Quantity"
                  >
                    <span>Max: </span> <span>{item?.maxQuantity}</span>
                  </button>
                  <button
                    onClick={() => {
                      openUpdateModal(item?.id, index);
                    }}
                    className="shop-item-btn"
                    title="Change Quantity"
                  >
                    <span>Current: </span> <span>{item?.currentQuantity}</span>
                  </button>
                  <button
                    onClick={() => {
                      openUpdateModal(item?.id, index);
                    }}
                    className="shop-item-btn"
                    title="Change Quantity"
                  >
                    <span>Price: </span>{" "}
                    <span>{priceStrFormatter(item?.price)}</span>
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ViewShopInventory;
