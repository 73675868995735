import { Buffer } from "buffer";

const WebAuthnCheckAndStore = async (idArr) => {
  const authAbortController = new AbortController();
  const authAbortSignal = authAbortController.signal;

  //   for await (const item of idArr) {
  const allowedCreds = idArr.map((item) => {
    const idUint8Arr = Buffer.from(item, "base64");
    return {
      id: idUint8Arr,
      type: "public-key",
    };
  });

  const publicKeyCredentialRequestOptions = {
    challenge: Uint8Array.from(idArr[0], (c) => c.charCodeAt(0)),
    allowCredentials: [...allowedCreds],
    timeout: 30000,
  };

  try {
    const assertion = await navigator.credentials.get({
      publicKey: publicKeyCredentialRequestOptions,
      signal: authAbortSignal,
    });

    const storeId = Buffer.from(assertion.rawId).toString("base64");

    console.log(storeId);

    localStorage.setItem("BDRWebAuthnCredId", storeId);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default WebAuthnCheckAndStore;
