import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import {
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  MARK_EMAIL_UNREAD,
  MARK_EMAIL_FLAG,
} from "../../../../utils/GraphQL/mutations";
import FlagIcon from "@mui/icons-material/Flag";

const EmailFlags = ({ emailId, emailRead, handleSnack, getSelectedEmails }) => {
  const [flagAnchorEl, setFlagAnchorEl] = useState(null);
  const [markUnread] = useMutation(MARK_EMAIL_UNREAD);
  const [markFlag] = useMutation(MARK_EMAIL_FLAG);
  const matchesMQ = useMediaQuery("(min-width: 900px)");
  const emailFlagRef = useRef();

  const handleMarkUnread = async () => {
    const { data: unread } = await markUnread({
      variables: { emailId: emailId, read: !emailRead },
    });

    if (unread?.markEmailUnread) {
      if (handleSnack) {
        handleSnack("Email Marked Unread", 2000);
      }
      if (getSelectedEmails) {
        getSelectedEmails();
      }
      handleCloseSubMenu();
      return;
    }

    if (handleSnack) {
      handleSnack("Error...Email not modified.", 3000);
    }

    handleCloseSubMenu();
    return;
  };

  const handleFlag = async (flagValue) => {
    const { data } = await markFlag({
      variables: {
        emailId: emailId,
        flag: flagValue,
      },
    });

    const flagSnack = () => {
      let value = "Flag Removed";

      if (flagValue === "!") {
        value = "Flagged Important";
      }

      if (flagValue === "!!") {
        value = "Flagged Urgent";
      }

      return value;
    };

    if (data?.changeEmailFlag) {
      if (handleSnack) {
        handleSnack(`Email ${flagSnack()}`, 2000);
      }
      if (getSelectedEmails) {
        getSelectedEmails();
      }
      handleCloseSubMenu();
      return;
    }

    if (handleSnack) {
      handleSnack("Error...Email not modified.", 3000);
    }

    handleCloseSubMenu();
    return;
  };

  const handleCloseSubMenu = () => {
    setFlagAnchorEl(null);
  };

  return (
    <>
      <button
        ref={emailFlagRef}
        className="email-page-action-img-button"
        onClick={(event) => {
          setFlagAnchorEl(emailFlagRef?.current);
        }}
      >
        <FlagIcon titleAccess="Mark Unread" />
      </button>

      {/* flag Submenu */}
      <Menu
        id="email-menu-popover"
        anchorEl={flagAnchorEl}
        disableScrollLock={matchesMQ ? true : false}
        anchorOrigin={{
          vertical: matchesMQ ? "top" : "bottom",
          horizontal: matchesMQ ? "left" : "center",
        }}
        transformOrigin={{
          vertical: matchesMQ ? "top" : "top",
          horizontal: matchesMQ ? "right" : "center",
        }}
        open={Boolean(flagAnchorEl)}
        onClose={handleCloseSubMenu}
      >
        <MenuItem
          onClick={() => {
            handleMarkUnread();
          }}
        >
          <Typography textAlign="center">{emailRead ? "Mark Unread" : "Mark Read"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFlag("!");
          }}
        >
          <Typography textAlign="center">Important</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFlag("!!");
          }}
        >
          <Typography textAlign="center">Urgent</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleFlag("");
          }}
        >
          <Typography textAlign="center">Remove Flag</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default EmailFlags;
