import React, { useEffect, useState, useRef } from "react";
import "./metrics.css";
import { useLazyQuery } from "@apollo/client";
import { GET_VISITS } from "../../../../utils/GraphQL/queries";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AdminBDRSpinner from "../../adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import SignAssignment from "../../adminComponents/SignAssingment/SignAssingment";

const Metrics = () => {
  const [getVisits] = useLazyQuery(GET_VISITS);
  const [timeLimit, setTimeLimit] = useState("2419200000");
  const [visits, setVisits] = useState({});
  const [loading, setLoading] = useState(true);
  const [totalMinHeight, setTotalMinHeight] = useState(2000);
  const [pageHeightTrigger, setPageHeightTrigger] = useState(0);
  const [getVisitsTrigger, setGetVisitsTrigger] = useState(false);
  const metricsPageRef = useRef();

  useEffect(() => {
    getAndSetVisits();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLimit, getVisitsTrigger]);

  useEffect(() => {
    if (pageHeightTrigger > 0) {
      handlePageHeight();
    }
  }, [pageHeightTrigger]);

  const heightTrigger = () => {
    setPageHeightTrigger((prev) => prev + 1);
  };

  const getVisitTrigger = () => {
    setGetVisitsTrigger((prev) => !prev);
  };

  const handlePageHeight = () => {
    const shopInvHeight = document.querySelector(
      "#sign-assign-height-marker"
    )?.offsetHeight;
    const navHeight = document.querySelector(
      "#admin-nav-height-marker"
    )?.offsetHeight;
    const headerHeight = document.querySelector(
      "#metrics-hdr-height-marker"
    )?.offsetHeight;

    const totalHeight =
      shopInvHeight + navHeight + headerHeight + window.innerHeight;
    setTotalMinHeight(totalHeight);
  };

  const getAndSetVisits = async () => {
    const time = Date.now().toString();
    let timeLimitChoice = null;
    let conversionRate = "N/A"

    if (timeLimit !== "0") {
      timeLimitChoice = timeLimit;
    }

    const { data } = await getVisits({
      variables: {
        current: time,
        timeLimit: timeLimitChoice || time,
      },
    });

    if (data?.getVisits) {
      // console.log(data?.getVisits);
      const uniqueVisits = data?.getVisits?.uniqueVisits;
      const conversions = data?.getVisits?.conversions;

      if (
        uniqueVisits !== "0" &&
        conversions !== "0"
      ) {
        conversionRate = ((conversions / uniqueVisits) * 100).toFixed(2);
      }

      const signsData = Object.entries(JSON.parse(data?.getVisits?.signs));
      setVisits({ ...data?.getVisits, signs: signsData, conversionRate});
    }

    setLoading(false);
  };

  const handleTimeSelect = (event) => {
    setLoading(true);
    const value = event.target.value;
    setTimeLimit(value);
  };

  return (
    <div
      className="metrics-page"
      ref={metricsPageRef}
      style={{ minHeight: `${totalMinHeight}px` }}
    >
      <SignAssignment
        heightTrigger={heightTrigger}
        getVisitTrigger={getVisitTrigger}
      />
      <div className="metrics-container">
        {loading && <AdminBDRSpinner message={"Loading..."} />}
        <div className="metrics-box">
          <h3 className="metrics-box-hdr">Visit Analytics</h3>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel
              id="time-select"
              sx={{ color: "#fff", "&.Mui-focused": { color: "#05F842" } }}
            >
              Time Parameter
            </InputLabel>
            <Select
              labelId="time-select"
              id="time-select-input"
              value={timeLimit}
              onChange={handleTimeSelect}
              label="Please select a time frame"
              disableUnderline={true}
              sx={{ color: "#fff" }}
              style={{ borderBottom: "1px solid #fff" }}
            >
              <MenuItem value={"604800000"}>Week</MenuItem>
              <MenuItem value={"1209600000"}>2 Weeks</MenuItem>
              <MenuItem value={"2419200000"}>Month</MenuItem>
              <MenuItem value={"31556926000"}>Year</MenuItem>
              <MenuItem value={"0"}>Lifetime</MenuItem>
            </Select>
          </FormControl>
          <div className="metrics-ul-container">
            <div className="metrics-ul-box">
              <h4 className="metrics-box-hdr">Pages</h4>
              <ul className="metrics-ul">
                <li>Unique Visits: {visits?.uniqueVisits}</li>
                <li>Requested Quote: {visits?.conversions}</li>
                <li>Quote Conversion Rate: {visits?.conversionRate === "N/A" ? 
                  visits?.conversionRate
                  : visits?.conversionRate + "%"}</li>
                <li>Home Page: {visits?.home}</li>
                <li>Quote Page: {visits?.quote}</li>
                <li>Our Doors Page: {visits?.ourDoors}</li>
                <li>Contact Page: {visits?.contact}</li>
                <li>About Us Page: {visits?.about}</li>
              </ul>
            </div>
            <div className="metrics-ul-box">
              <h4 className="metrics-box-hdr">Signs</h4>
              {visits?.signs ? (
                <ul className="metrics-ul">
                  {visits.signs?.map((sign, index) => {
                    const key = Date.now() + index;
                    let assignee = sign[0];
                    if (assignee === "0") {
                      return null;
                    }

                    if (/^\d/.test(assignee)) {
                      assignee = `Sign ${assignee}`;
                    }

                    return <li key={key}>{`${assignee}: ${sign[1]} scans`}</li>;
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
