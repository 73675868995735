import React, { useEffect, useState } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router";
import { setContext } from "@apollo/client/link/context";

import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import QuoteStickyTab from "./components/QuoteStickyTab/QuoteStickyTab";
import GetAQuote from "./pages/GetAQuote/GetAQuote";
// import MeetTheTeam from "./pages/MeetTheTeam/MeetTheTeam";
import OurDoors from "./pages/OurDoors/OurDoors";
import Contact from "./pages/Contact/Contact";
import Testimonials from "./pages/Testimonials/Testimonials";
import Protected from "./components/Protected";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AdminDashboard from "./pages/AdminDashboard/AdminPages/AdminDasboard/AdminDashboard";
import Auth from "./utils/auth";
import AdminNavbar from "./pages/AdminDashboard/adminComponents/AdminNav/AdminNavbar";
import AdminImageGroups from "./pages/AdminDashboard/AdminPages/ImageGroups/ImageGroups";
import AdminShopInventory from "./pages/AdminDashboard/AdminPages/AdminShopInventory/AdminShopInventory";
import RefinUpdateList from "./pages/AdminDashboard/AdminPages/RefinUpdateList/RefinUpdateList";
import AdminEmails from "./pages/AdminDashboard/AdminPages/EmailPage/Emails";
import Metrics from "./pages/AdminDashboard/AdminPages/Metrics/Metrics";
import Clients from "./pages/AdminDashboard/AdminPages/ClientsPage/Clients";
import SingleClient from "./pages/AdminDashboard/AdminPages/SingleClientPage/SingleClient";
import SentEmailsPage from "./pages/AdminDashboard/AdminPages/SentEmailsPage/SentEmailsPage";
import BlockedEmailsPage from "./pages/AdminDashboard/AdminPages/BlockedEmails/BlockedEmails";
import CustomerQuotePage from "./pages/CustomerQuotePage/CustomerQuotePage";
import ServicesManagement from "./pages/AdminDashboard/AdminPages/ServicesManagement/ServicesManagement";
import ManageUsers from "./pages/AdminDashboard/AdminPages/ManageUsers/ManageUsers";
import MessagesPage from "./pages/AdminDashboard/AdminPages/MessagesPage/MessagesPage";
import PrivacyPolicy from "./pages/PrivacyPolicy-TOS/PrivacyPolicy";
import TermsOfService from "./pages/PrivacyPolicy-TOS/TermsOfService";
import StripeTransactions from "./pages/AdminDashboard/AdminPages/StripeTransactions/StripeTransactions";
import KeapMergeController from "./pages/AdminDashboard/AdminPages/KeapMergeController/KeapMergeController";
import ShortcutsPage from "./pages/AdminDashboard/AdminPages/ShortcutsPage/ShortcutsPage";
import CustomerInvoicePage from "./pages/CustomerInvoicePage/CustomerInvoicePage";
import KeapFileMerge from "./pages/AdminDashboard/AdminPages/KeapFileMerge/KeapFileMerge";
// import StripePayment from "./pages/AdminDashboard/AdminPages/StripePayment/StripePayment";
const StripePayment = React.lazy(() =>
  import("./pages/AdminDashboard/AdminPages/StripePayment/StripePayment")
);

const httpLink = createHttpLink({
  uri: "/graphql",
});

// Construct request middleware that will attach the JWT token to every request as an `authorization` header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("id_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  //this admin check is a check is if any user is logged in. Not just admin.
  const [adminIsLoggedIn, setAdminIsLoggedIn] = useState(false);
  const [stickyTab, setStickyTab] = useState(true);
  const [displayFooter, setDisplayFooter] = useState(true);
  const [displayNav, setDisplayNav] = useState(true);

  useEffect(() => {
    const authLogged = Auth.loggedIn();

    if (adminIsLoggedIn !== authLogged) {
      setAdminIsLoggedIn(authLogged);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminIsLoggedIn]);

  const changeLoggedIn = async (newState) => {
    setAdminIsLoggedIn(newState);
    return true;
  };

  const changeStickyTab = (newState) => {
    setStickyTab(newState);
    return;
  };

  const changeFooter = (newState) => {
    setDisplayFooter(newState);
    return;
  };

  const showNav = (bool) => {
    setDisplayNav(bool);
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        {displayNav &&
          (adminIsLoggedIn ? (
            <AdminNavbar changeLoggedIn={changeLoggedIn} />
          ) : (
            <Navbar />
          ))}
        <>
          <Routes>
            <Route index path=":scanned" element={<Home />} />
            <Route path="/login-portal">
              <Route
                index
                element={<Login changeLoggedIn={changeLoggedIn} />}
              />
              <Route
                path="payment/:paymentID"
                element={<Login changeLoggedIn={changeLoggedIn} />}
              />
            </Route>
            <Route index path="/quote" element={<GetAQuote />} />
            {/* Meet the team page endpoint removed. Awaiting correct information */}
            {/* <Route index path="/meet-the-team" element={<MeetTheTeam />} /> */}
            <Route index path="/our-doors" element={<OurDoors />} />
            <Route
              index
              path="/contact-us"
              element={<Contact displayHeader={true} />}
            />
            <Route index path="/testimonials" element={<Testimonials />} />
            <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              index
              path="/terms-of-service"
              element={<TermsOfService />}
            />

            <Route path="/client">
              <Route path="view-quote">
                <Route
                  index={true}
                  path=":quoteId"
                  element={
                    <CustomerQuotePage
                      displayNav={setDisplayNav}
                      changeStickyTab={changeStickyTab}
                      changeFooter={changeFooter}
                    />
                  }
                />
              </Route>
              <Route path="view-invoice">
                <Route
                  index={true}
                  path=":invoiceId"
                  element={
                    <CustomerInvoicePage
                      displayNav={setDisplayNav}
                      changeStickyTab={changeStickyTab}
                      changeFooter={changeFooter}
                    />
                  }
                />
              </Route>
            </Route>

            <Route path="/bdr-office">
              <Route
                index
                path="dashboard"
                element={
                  <Protected>
                    <AdminDashboard changeLoggedIn={changeLoggedIn} />
                  </Protected>
                }
              />
              <Route
                path="img-groups"
                f
                element={
                  <Protected>
                    <AdminImageGroups />
                  </Protected>
                }
              />
              <Route
                path="shop-inventory"
                element={
                  <Protected>
                    <AdminShopInventory />
                  </Protected>
                }
              />
              <Route
                path="update-list"
                element={
                  <Protected>
                    <RefinUpdateList />
                  </Protected>
                }
              />
              <Route
                path="email-inbox"
                element={
                  <Protected>
                    <AdminEmails />
                  </Protected>
                }
              />
              <Route
                path="email-sent"
                element={
                  <Protected>
                    <SentEmailsPage />
                  </Protected>
                }
              />
              <Route
                path="email-blocked"
                element={
                  <Protected>
                    <BlockedEmailsPage />
                  </Protected>
                }
              />
              <Route
                path="messages"
                element={
                  <Protected>
                    <MessagesPage />
                  </Protected>
                }
              />
              <Route
                path="metrics"
                element={
                  <Protected>
                    <Metrics />
                  </Protected>
                }
              />
              <Route path="payments">
                <Route
                  path="transactions"
                  element={
                    <Protected>
                      <React.Suspense>
                        <StripeTransactions />
                      </React.Suspense>
                    </Protected>
                  }
                />
                <Route
                  path="process-payment"
                  element={
                    <Protected>
                      <React.Suspense>
                        <StripePayment showNav={showNav} />
                      </React.Suspense>
                    </Protected>
                  }
                >
                  <Route
                    path=":complete"
                    element={
                      <Protected>
                        <React.Suspense>
                          <StripePayment showNav={showNav} />
                        </React.Suspense>
                      </Protected>
                    }
                  />
                  <Route
                    path="payment-id/:paymentId"
                    element={
                      <Protected>
                        <React.Suspense>
                          <StripePayment showNav={showNav} />
                        </React.Suspense>
                      </Protected>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="services-management"
                element={
                  <Protected>
                    <ServicesManagement />
                  </Protected>
                }
              />
              <Route
                path="shortcuts"
                element={
                  <Protected>
                    <ShortcutsPage />
                  </Protected>
                }
              />
              <Route
                path="manage-users"
                element={
                  <Protected permCheck={"admin"}>
                    <ManageUsers />
                  </Protected>
                }
              />
              <Route
                path="keap-merge-controller"
                element={
                  <Protected permCheck={"admin"}>
                    <React.Suspense>
                      <KeapMergeController />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route
                path="keap-file-merge"
                element={
                  <Protected permCheck={"admin"}>
                    <React.Suspense>
                      <KeapFileMerge />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route path="clients">
                <Route
                  index={true}
                  element={
                    <Protected>
                      <Clients />
                    </Protected>
                  }
                />
                <Route path="view-client">
                  <Route
                    index={true}
                    element={
                      <Protected>
                        <Clients />
                      </Protected>
                    }
                  />
                  <Route
                    path=":clientId"
                    element={
                      <Protected>
                        <SingleClient />
                      </Protected>
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Home />} />
          </Routes>
        </>
        {adminIsLoggedIn ? null : stickyTab ? <QuoteStickyTab /> : null}
        {adminIsLoggedIn ? null : displayFooter ? <Footer /> : null}
      </Router>
    </ApolloProvider>
  );
}

export default App;
