import React, { useState, useEffect } from "react";
import "./plusMinusCollapse.css";

const PlusMinusCollapse = ({ plus }) => {
  const [barCollapsed, setBarCollapsed] = useState(true);

  useEffect(() => {
    if (typeof plus === "boolean") {
      setBarCollapsed(plus);
    }
  }, [plus]);

  return (
    <div className="open-collapse">
      <div
        className={
          barCollapsed
            ? "collapse-bar-1 collapsed"
            : "collapse-bar-1 not-collapsed"
        }
      ></div>
      <div
        className={
          barCollapsed
            ? "collapse-bar-2 collapsed"
            : "collapse-bar-2 not-collapsed"
        }
      ></div>
    </div>
  );
};

export default PlusMinusCollapse;
