import React, { useEffect, useState } from "react";
import "./keapMergeController.css";
import Tracking from "../../../../components/TrackingComp";
import { KEAP_CLIENT_FETCH_AND_CREATE } from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import { FormControlLabel, Snackbar, Switch } from "@mui/material";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { CircularProgress } from "@mui/material";

const KeapMergeController = () => {
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });
  const [limitOffsetFetch, setLimitOffsetFetch] = useState({
    limit: "",
    offset: "",
  });
  const [fetchErrors, setFetchErrors] = useState([]);
  const [initialFetch, setInitialFetch] = useState(true);
  const [disableFetchBtn, setDisableFetchBtn] = useState(false);
  const [endOfFetch, setEndOfFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoFetch, setAutoFetch] = useState(false);
  const [autoFetchCounter, setAutoFetchCounter] = useState(null);
  const [makeKeapFetchCall] = useMutation(KEAP_CLIENT_FETCH_AND_CREATE);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      limitOffsetFetch?.limit?.length > 0 &&
      limitOffsetFetch?.offset?.length > 0 &&
      autoFetch &&
      initialFetch === false
    ) {
      if (fetchErrors?.length > 0) {
        setAutoFetch(false);
        handleSnack("Auto fetch paused due to errors.", 3000);
        return;
      }

      if (endOfFetch) {
        setAutoFetch(false);
        handleSnack("No more to fetch...", 3000);
        return;
      }

      let counterId = "";
      if (autoFetchCounter <= 0 && loading === false) {
        setLoading(true);
        handleFetch();
      }

      if (autoFetchCounter > 0) {
        counterId = setInterval(() => {
          setAutoFetchCounter((prev) => prev - 1);
        }, 1000);
      }

      return () => {
        clearInterval(counterId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFetchCounter, autoFetch]);

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const handleFetchObjUpdate = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setLimitOffsetFetch((prev) => ({ ...prev, [name]: value }));
  };

  const handleFetch = async () => {
    const { data } = await makeKeapFetchCall({
      variables: {
        ...limitOffsetFetch,
      },
    });

    // console.log(data?.keapContactQuery);

    if (data?.keapContactQuery?.errors?.length > 0) {
      setFetchErrors([...data?.keapContactQuery?.errors]);
    }

    if (data?.keapContactQuery?.errors?.length === 0) {
      handleSnack("Successful Fetch", 1000);
      setLimitOffsetFetch((prev) => {
        const prevOffset = parseInt(prev.offset);
        const limit = parseInt(prev.limit);
        const nextOffset = (prevOffset + limit)?.toString();

        return { ...prev, offset: nextOffset };
      });
    }

    setInitialFetch(false);
    setLoading(false);

    if (data?.keapContactQuery?.more === false) {
      setDisableFetchBtn(true);
      setEndOfFetch(true);
      setAutoFetch(false);
      setAutoFetchCounter(null);
      return;
    }

    if (autoFetch) {
      setAutoFetchCounter(3);
    }

    setDisableFetchBtn(false);
  };

  return (
    <div className="keap-merge-page-container">
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="keap-merge-box">
        {loading && (
          <div
            onClick={(event) => {
              event?.stopPropagation();
            }}
            className="keap-merge-overlay-circle-box"
          >
            <CircularProgress color="inherit" size={30} />
          </div>
        )}
        <h1>Keap Merge Controller</h1>
        <div className="keap-merge-input-box">
          <CssTextFieldStandard
            sx={{ width: "45%" }}
            type="text"
            label="Fetch Limit"
            name="limit"
            onChange={handleFetchObjUpdate}
            value={limitOffsetFetch?.limit}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: "45%" }}
            type="text"
            label={initialFetch ? "Fetch Offset" : `Next Fetch Offset`}
            name="offset"
            onChange={handleFetchObjUpdate}
            value={limitOffsetFetch?.offset}
            variant="standard"
          />
        </div>
        <div className="keap-merge-btn-box">
          <FormControlLabel
            value="start"
            control={
              <Switch
                checked={autoFetch}
                onChange={(event) => {
                  setAutoFetch(event.target.checked);
                }}
                inputProps={{ "aria-label": "Auto Fetch" }}
              />
            }
            label="Auto-Fetch"
            labelPlacement="start"
          />

          <button
            className={
              endOfFetch ? "keap-merge-btn disabled" : "keap-merge-btn"
            }
            onClick={(event) => {
              handleFetch(event);
              setDisableFetchBtn(true);
              setLoading(true);
            }}
            disabled={disableFetchBtn}
          >
            {initialFetch
              ? "Fetch"
              : endOfFetch
              ? "No More To Fetch"
              : autoFetch
              ? `Fetching${
                  autoFetchCounter > 0
                    ? ` Next ${limitOffsetFetch?.limit} in ${autoFetchCounter}`
                    : ""
                }...`
              : `Fetch Next ${limitOffsetFetch?.limit}`}
          </button>
        </div>
        {fetchErrors?.length > 0 && (
          <div className="keap-merge-err-box">
            <h4>Merge Errors</h4>
            <div>
              <button
                className="keap-merge-btn"
                onClick={() => {
                  setFetchErrors([]);
                }}
              >
                Clear Errors
              </button>
            </div>
            {fetchErrors?.map((err, index) => {
              const splitErr = err?.split("Client Id:");
              const link = `${
                window.location.origin
              }/bdr-office/clients/view-client/${splitErr[1]?.trim()}`;

              return (
                <div
                  key={`merge-err-item-${index}`}
                  className="keap-merge-err-item"
                >
                  {splitErr[0]?.trim()} -{" "}
                  <a
                    className="keap-merge-btn-small-anchor"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Client
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Tracking removeVisit={true} />
    </div>
  );
};

export default KeapMergeController;
