import React, { useState } from "react";
import { REQ_WEB_AUTHN_CHALLENGE } from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import Auth from "../../../../utils/auth";
import { decode } from "cbor-x";
import { Buffer } from "buffer";
import { Button, MenuItem, Typography, Snackbar } from "@mui/material";

const WebAuthnRegister = ({ inNavSmall }) => {
  const [reqChallenge] = useMutation(REQ_WEB_AUTHN_CHALLENGE);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
    duration: 2000,
  });

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms || 2000,
    });
  };

  const getChallenge = async () => {
    const { data } = await reqChallenge();

    if (data?.reqWebAuthnChallenge) {
      return data?.reqWebAuthnChallenge;
    }
  };

  const register = async () => {
    if (navigator.credentials && navigator.credentials.create) {
      const { data: user } = Auth.getProfile();
      const authAbortController = new AbortController();
      const authAbortSignal = authAbortController.signal;
      const challengeCall = await getChallenge();
      const serverString = challengeCall?.challenge;

      const publicKeyCredentialCreationOptions = {
        challenge: Uint8Array.from(serverString, (c) => c.charCodeAt(0)),
        rp: {
          name: "Biggin's Door Refinishing",
          id: "bigginsdoorrefinishing.com",
          // id: "localhost",
        },
        user: {
          id: Uint8Array.from(user._id, (c) => c.charCodeAt(0)),
          name: user.email,
          displayName: user.firstName,
        },
        transports: ["internal", "hybrid"],
        pubKeyCredParams: [
          { alg: -7, type: "public-key" },
          {
            type: "public-key",
            alg: -8,
          },
          {
            type: "public-key",
            alg: -36,
          },
          {
            type: "public-key",
            alg: -37,
          },
          {
            type: "public-key",
            alg: -38,
          },
          {
            type: "public-key",
            alg: -39,
          },
          {
            type: "public-key",
            alg: -257,
          },
          {
            type: "public-key",
            alg: -258,
          },
          {
            type: "public-key",
            alg: -259,
          },
        ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required",
        },
        timeout: 60000,
        attestation: "none",
      };

      try {
        const credential = await navigator.credentials.create({
          publicKey: publicKeyCredentialCreationOptions,
          signal: authAbortSignal,
        });

        if (credential?.id) {
          const utf8Decoder = new TextDecoder("utf-8");
          const decodedClientData = utf8Decoder.decode(
            credential.response.clientDataJSON
          );

          const clientDataObj = JSON.parse(decodedClientData);

          const key64 = Buffer.from(
            credential.response.getPublicKey(),
            "utf-8"
          ).toString("base64");

          fetch("/bdr-office/register-web-authn", {
            method: "POST",
            body: JSON.stringify({ ...clientDataObj, userId: user._id }),
            headers: {
              "Content-Type": "application/json",
            },
          }).then(async () => {
            const uint8Arr = new Uint8Array(
              credential.response.attestationObject
            );

            const { authData } = decode(uint8Arr);

            // get the length of the credential ID
            const dataView = new DataView(new ArrayBuffer(2));
            const idLenBytes = authData.slice(53, 55);
            idLenBytes.forEach((value, index) =>
              dataView.setUint8(index, value)
            );
            const credentialIdLength = dataView.getUint16();

            // get the credential ID and save credentialId to a cookie for incognito persistence
            const credentialId = authData.slice(55, 55 + credentialIdLength);
            const credIdBase64 = Buffer.from(credentialId, "utf-8").toString(
              "base64"
            );

            localStorage.setItem("BDRWebAuthnCredId", credIdBase64);

            fetch("/bdr-office/valid-web-authn-registration", {
              method: "POST",
              body: JSON.stringify({
                pemKey:
                  "-----BEGIN PUBLIC KEY-----\n" +
                  key64 +
                  "\n-----END PUBLIC KEY-----",
                credentialId: credIdBase64,
                userId: user._id,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              if (res.status === 200) {
                handleSnack("Successful Login Registration", 3000);
              }
            });
          });
        }
      } catch (error) {
        alert("Unable to register for login authentication.");
        alert(error);
      }
    } else {
      // Handle the lack of support
      alert("WebAuthn is not supported on this browser.");
    }
  };

  return (
    <>
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {inNavSmall ? (
        <MenuItem onClick={register}>
          <Typography textAlign="center">Register Login</Typography>
        </MenuItem>
      ) : (
        <Button
          onClick={register}
          sx={{
            m: { xl: 2 },
            color: "#ffffff",
            display: "block",
            fontSize: "12px",
          }}
        >
          Register Login
        </Button>
      )}
    </>
  );
};

export default WebAuthnRegister;
