import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./msgImgShuffle.css";
import copyImageToClip from "../../../../utils/copyImageToClip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from '@mui/material/IconButton';

// const imgArr = [
//   {
//     beforeOrAfter: "Before",
//     dateEntered: "1716745370962",
//     imageData:
//       "https://t4.ftcdn.net/jpg/02/95/86/99/360_F_295869928_KLTHuZxQiPXZHut1rK9T7iVYqfriR7Ic.jpg",
//     __typename: "ClientDoorPhoto",
//     _id: "6653749a9e0efe1ebc5a5ed0",
//   },
//   {
//     beforeOrAfter: "Before",
//     dateEntered: "1716745370972",
//     imageData:
//       "https://upload.wikimedia.org/wikipedia/commons/1/18/Dog_Breeds.jpg",
//     __typename: "ClientDoorPhoto",
//     _id: "6653749a9e0efe1ebc5a5eda",
//   },
//   {
//     beforeOrAfter: "Before",
//     dateEntered: "1716745370972",
//     imageData:
//       "https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg",
//     __typename: "ClientDoorPhoto",
//     _id: "6653749a9e0efe1ebc5a5ede",
//   },
//   {
//     beforeOrAfter: "Before",
//     dateEntered: "1716745370962",
//     imageData:
//       "https://t4.ftcdn.net/jpg/02/95/86/99/360_F_295869928_KLTHuZxQiPXZHut1rK9T7iVYqfriR7Ic.jpg",
//     __typename: "ClientDoorPhoto",
//     _id: "6653749a9e0efe1ebc5a5edc",
//   },
//   {
//     beforeOrAfter: "Before",
//     dateEntered: "1716745370972",
//     imageData:
//       "https://upload.wikimedia.org/wikipedia/commons/1/18/Dog_Breeds.jpg",
//     __typename: "ClientDoorPhoto",
//     _id: "6653749a9e0efe1ebc5a5edj",
//   },
//   {
//     beforeOrAfter: "Before",
//     dateEntered: "1716745370972",
//     imageData:
//       "https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg",
//     __typename: "ClientDoorPhoto",
//     _id: "6653749a9e0efe1ebc5a5edn",
//   },
// ];

const MsgImgShuffle = ({
  imgArr,
  handleSnack,
}) => {
  const [selectedImg, setSelectedImg] = useState(0);

  return (
    <div className="msg-img-shuffle-box">
      <div className="msg-img-shuffle-controller-box">
        <IconButton
          className="msg-img-shuffle-btn-icon"
          color="inherit"
          onClick={() => {
            setSelectedImg((prev) => {
              if (prev <= 0) {
                return 0;
              }
              return prev - 1;
            });
          }}
        >
          <ArrowBackIosIcon  fontSize="inherit" />
        </IconButton>
        <button
          className="msg-img-shuffle-btn-copy"
          onPointerUp={async () => {
            const img = document.querySelector(`#img-id-${imgArr[selectedImg]?._id}`);
            const blob = new ClipboardItem({
              "image/png": copyImageToClip(img.src),
            });

            navigator.clipboard.write([blob]);
            handleSnack("Image copied", 1500);
          }}
        >
          <ContentCopyIcon titleAccess="Copy Image" fontSize={"inherit"} />
        </button>
        <IconButton
          className="msg-img-shuffle-btn-icon"
          color="inherit"
          onClick={() => {
            setSelectedImg((prev) => {
              if (prev >= imgArr?.length - 1) {
                return imgArr?.length - 1;
              }
              return prev + 1;
            });
          }}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      </div>
      {imgArr?.length > 0 &&
        imgArr?.map((imgObj, index) => {
          const posNum =
            selectedImg === index
              ? 0
              : selectedImg > index
              ? `${selectedImg - index}l`
              : `${index - selectedImg}r`;

          return (
            <img
              id={`img-id-${imgObj?._id}`}
              className={
                selectedImg === index
                  ? "msg-img-shuffle-img msg-img-shuffle-img-selected"
                  : `msg-img-shuffle-img img-pos-${posNum}`
              }
              key={imgObj?._id}
              src={imgObj?.imageData}
              alt="door text"
            />
          );
        })}
    </div>
  );
};

export default MsgImgShuffle;
