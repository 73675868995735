import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SENT_EMAIL_BY_ID } from "../../../../utils/GraphQL/queries";
import "./sentEmailModal.css";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar } from "@mui/material";
import { Buffer } from "buffer";
import copyImageToClip from "../../../../utils/copyImageToClip";

const blankSnack = {
  visible: false,
  message: "",
  duration: 2000,
};

const SentEmailModal = ({ sentId, closeEmail }) => {
  const [dateFormat, setDateFormat] = useState("");
  const [email, setEmail] = useState({});
  const [emailLoading, setEmailLoading] = useState(true);
  const htmlRef = useRef();
  const [loadEmail] = useLazyQuery(GET_SENT_EMAIL_BY_ID);
  const [openSnack, setOpenSnack] = useState({ ...blankSnack });

  useEffect(() => {
    emailFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentId]);

  useEffect(() => {
    let date = "";
    if (htmlRef.current) {
      htmlRef.current.innerHTML =
        email?.emailHTML ||
        '<h5 style="text-align: center; width: 100%;">No Message Body</h5>';
    }
    const getNum = parseInt(email?.dateCreated);
    if (getNum > 0) {
      date = BAGroupTimeFormat(getNum, true);
    }
    setDateFormat(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const emailFetch = async () => {
    const { data } = await loadEmail({
      variables: {
        sentId: sentId,
      },
    });

    if (data?.getSentEmailById) {
      setEmail({ ...data?.getSentEmailById });
    }

    setEmailLoading(false);
  };

  const handleSnackClose = () => {
    setOpenSnack({ ...blankSnack });
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms || 2000,
    });
  };

  return (
    <>
      {emailLoading && <AdminBDRSpinner message={"Loading..."} />}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div
        className="sent-email-modal-container"
        onClick={() => {
          closeEmail();
        }}
      >
        <div
          className="sent-email-modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <button className="sent-email-modal-close" onClick={closeEmail}>
            Close
          </button>
          <ul className="sent-email-modal-list">
            <li>
            <h5>
                <span className="green-color-text">From:</span>{" "}
                {email?.from}
              </h5>
              <h5>
                <span className="green-color-text">Subject:</span>{" "}
                {email?.emailSubject}
              </h5>
              <span>
                <span className="green-color-text">Date:</span> {dateFormat}
              </span>
            </li>
            <li ref={htmlRef}></li>
          </ul>
          <div className="sent-email-modal-images-container">
            {(email?.attachImgs?.length > 0 ||
              email?.attachPdf?.length > 0) && (
              <>
                <h3 className="page-header sent-email-modal-img-hdr">
                  Attachments:{" "}
                </h3>
                {email?.attachImgs?.length > 0 &&
                  email?.attachImgs?.map((item, index) => {
                    const imgId = "attImgEl" + item._id;
                    const btnId = "attBtnEl" + item._id;

                    const html = (
                      <div
                        key={item._id + index}
                        className="sent-email-modal-images-box"
                      >
                        <div className="sent-email-modal-img-hdr-box">
                          <h5 className="page-header">
                            Attached Image {index + 1}
                          </h5>
                          <button
                            className="sent-email-modal-copy-btn"
                            id={btnId}
                            onPointerUp={async () => {
                              const img = document.querySelector(`#${imgId}`);
                              const blob = new ClipboardItem({
                                "image/png": copyImageToClip(img.src),
                              });

                              navigator.clipboard.write([blob]);
                              handleSnack("Image copied", 1500);
                            }}
                          >
                            Copy <ContentCopyIcon />
                          </button>
                        </div>

                        <img
                          id={imgId}
                          className="sent-email-modal-img"
                          src={item?.imageData}
                          alt={`door pic ${index}`}
                        />
                      </div>
                    );

                    return html;
                  })}
                {email?.attachPdf?.length > 0 &&
                  email?.attachPdf?.map((item, index) => {
                    const html = (
                      <div
                        key={item?.id + index}
                        className="sent-email-modal-pdf-box"
                      >
                        {/iP(hone|od|ad)/.test(navigator.userAgent) ? (
                          <div>
                            <button
                              className="sent-email-modal-copy-btn"
                              onClick={() => {
                                const destructData = item?.fileData.split(";");
                                // base64 string
                                const base64str = destructData[1].split(",")[1];
                                const pdfData = Buffer.from(
                                  base64str,
                                  "base64"
                                );

                                // create the blob object with content-type "application/pdf"
                                var blob = new Blob([pdfData], {
                                  type: "application/pdf",
                                });
                                var url = URL.createObjectURL(blob);

                                window.open(url);
                              }}
                            >
                              View PDF {index + 1}{" "}
                              {item?.fileName && `- ${item?.fileName}`}
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="sent-email-modal-img-hdr-box">
                              <h5 className="page-header">
                                Attached Pdf {index + 1}
                              </h5>
                              <span style={{ fontSize: "smaller" }}>
                                File Name: {item?.fileName}
                              </span>
                            </div>
                            <div className="sent-email-modal-pdf-file">
                              <iframe
                                src={item?.fileData + "#zoom=50"}
                                style={{ width: "300px", height: "370px" }}
                                title={`PDF ${index + 1}`}
                              ></iframe>
                            </div>
                          </>
                        )}
                      </div>
                    );

                    return html;
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SentEmailModal;
