import React, { useEffect, useState } from "react";
import "./singleClient.css";
import { Snackbar } from "@mui/material";
import AdminBDRSpinner from "../../adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import ClientNotesDisplay from "../../adminComponents/ClientNotesDisplay/ClientNotesDisplay";
import {
  GET_CLIENT_BY_ID,
  SINGLE_CLIENT_MSGS_CHECK,
  GET_PHONE_TEXTS_BY_ID,
} from "../../../../utils/GraphQL/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Auth from "../../../../utils/auth";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { useMediaQuery } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import phoneNumberFormat from "../../../../utils/formatters/phoneNumberFormat";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import CreateClientValidator from "../../../../utils/formValidators/createClientValidate";
import {
  UPDATE_CLIENT_INFO,
  ADD_CLIENT_NOTE,
  UPDATE_PHN_MSGS_READ,
} from "../../../../utils/GraphQL/mutations";
import CreateQuoteModal from "../../adminComponents/CreateQuoteModal/CreateQuoteModal";
import ClientQuotesDisplay from "../../adminComponents/ClientQuotesDisplay/ClientQuotesDisplay";
import ViewClientQuoteModal from "../../adminComponents/ViewClientQuoteModal/ViewClientQuoteModal";
import UserTaskCreate from "../../adminComponents/UserTaskModal/UserTaskCreate";
import ClientTasksDisplay from "../../adminComponents/ClientTasksDisplay/ClientTasksDisplay";
import ClientInvoicesDisplay from "../../adminComponents/ClientInvoicesDisplay/ClientInvoicesDisplay";
import ViewClientInvoiceModal from "../../adminComponents/ViewClientInvoiceModal/ViewClientInvoiceModal";
import SendEmailModal from "../../adminComponents/SendEmail/SendEmailModal";
import ClientTextMesDisplay from "../../adminComponents/ClientTextMesDisplay/ClientTextMesDisplay";
import copyStringToClipboard from "../../../../utils/formatters/copyStringToClipboard";
import QuoteInvoiceEditModal from "../../adminComponents/QuoteInvoiceEditModal/QuoteInvoiceEditModal";
import ClientPicsDisplay from "../../adminComponents/ClientPicsDisplay/ClientPicsDisplay";
import ClientEmailDisplay from "../../adminComponents/ClientEmailDisplay/ClientEmailDisplay";

const clientObjCleared = {
  clientId: "",
  firstName: "",
  lastName: "",
  spouseName: "",
  phoneNumberIds: [
    {
      phoneNumber: "",
      numberType: "",
      title: "",
      unreadMessages: false,
    },
  ],
  primaryEmail: "",
  secondaryEmail: "",
  streetAddress: "",
  city: "",
  state: "Texas",
  zipCode: "",
  gateCode: "",
  clientNotes: {
    note: "",
    dateCreated: "",
    dateUpdated: "",
    updatedBy: "",
    createdBy: "",
  },
  clientStatus: "Lead",
  quotes: [],
  clientPhotoIds: [],
};

const clearedNote = {
  note: "",
  dateCreated: "",
  dateUpdated: "",
  updatedBy: "",
  createdBy: "",
};

const emailObjBlank = {
  type: "blank",
  // bigginsdoorrefinishing.com/client/view-quote/*quoteId
  // bigginsdoorrefinishing.com/client/view-invoice/*invoiceId
  quoteLink: [],
  invoiceLink: [],
  toFirstName: "",
  attachArr: [],
};

const SingleClient = () => {
  const { clientId } = useParams();
  const [totalMinHeight, setTotalMinHeight] = useState(2000);
  const [getClientInfo] = useLazyQuery(GET_CLIENT_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [getPhnById] = useLazyQuery(GET_PHONE_TEXTS_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [newMsgsCheck] = useLazyQuery(SINGLE_CLIENT_MSGS_CHECK, {
    fetchPolicy: "network-only",
  });
  const [updateCurrentClient] = useMutation(UPDATE_CLIENT_INFO, {
    fetchPolicy: "network-only",
  });
  const [updatePhnMsgsRead] = useMutation(UPDATE_PHN_MSGS_READ, {
    fetchPolicy: "network-only",
  });
  const [createNewNoteGQL] = useMutation(ADD_CLIENT_NOTE);
  const [bdrLoader, setBdrLoader] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [showTexts, setShowTexts] = useState(false);
  const [userName, setUserName] = useState("");
  const [loaderMess, setLoaderMess] = useState("");

  // quote and invoice editing states
  const [openQuoteInvoiceEditModal, setOpenQuoteInvoiceEditModal] =
    useState(false);
  const [selectedToEdit, setSelectedToEdit] = useState({});
  const [selectedToEditIsInvoice, setSelectedToEditIsInvoice] = useState(false);

  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [selectedPhnId, setSelectedPhnId] = useState("");
  const [noteSaveError, setNoteSaveError] = useState(false);
  const [pageHeightTrigger, setPageHeightTrigger] = useState(0);
  const [errorsArr, setErrorsArr] = useState([]);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [newClientNote, setNewClientNote] = useState({ ...clearedNote });
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [invoicesCollapse, setInvoicesCollapse] = useState(true);
  const [clickTrigger, setClickTrigger] = useState(0);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailModalObj, setEmailModalObj] = useState({ ...emailObjBlank });
  const [refreshCounter, setRefreshCounter] = useState(0);

  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });
  const [clientObj, setClientObj] = useState({ ...clientObjCleared });
  const [updateClientForm, setClientForm] = useState({ ...clientObjCleared });

  // Form Validator
  const updateValidate = new CreateClientValidator(updateClientForm);

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePageHeight();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientId?.length === 24) {
      changeSaving(true, "Loading...");
      fetchClientInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    if (selectedInvoice?.id) {
      openInvoiceModal(selectedInvoice?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientObj]);

  useEffect(() => {
    if (pageHeightTrigger > 0) {
      handlePageHeight();
    }
  }, [pageHeightTrigger]);

  useEffect(() => {
    if (selectedPhnId?.length > 0) {
      markMsgsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhnId]);

  useEffect(() => {
    let id = setTimeout(() => {
      if (refreshCounter < 50) {
        checkMsgs();
        setRefreshCounter((prev) => prev + 1);
      }
    }, 6000);
    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const getUser = async () => {
    const user = await Auth.getProfile();

    if (user?.data.firstName) {
      setUserName(user?.data.firstName);
    }
    return;
  };

  const heightTrigger = () => {
    setPageHeightTrigger((prev) => prev + 1);
  };

  const closeShowTexts = (changed) => {
    setSelectedPhnId("");
    setShowTexts(false);
    if (changed) {
      fetchClientInfo();
    }
  };

  const quotesDisplayTrigger = () => {
    setClickTrigger((prev) => prev + 1);
  };

  const openQuoteModal = (quoteId) => {
    if (quoteId === "") {
      setSelectedQuote({});
      return;
    }

    const selected = clientObj?.quotes?.filter((quote) => {
      return quote?.id === quoteId ? quote : null;
    })[0];

    if (selected) {
      setSelectedQuote({ ...selected });
    }
  };

  const openInvoiceModal = (invoiceId) => {
    if (invoiceId === "") {
      setSelectedInvoice({});
      return;
    }

    const selected = clientObj?.invoices?.filter((invoice) => {
      return invoice?.id === invoiceId ? invoice : null;
    })[0];

    if (selected) {
      setSelectedInvoice({ ...selected });
    }
  };

  const changeSaving = (bool, mes) => {
    setLoaderMess(mes);
    setBdrLoader(bool);
  };

  const handleMsgCount = (msgsArr) => {
    const sent = [];
    const received = [];
    msgsArr?.map((text) => {
      if (text?.outgoingBool === true) {
        sent?.push(text);
        return true;
      }
      received?.push(text);
      return true;
    });

    sent?.sort((a, b) => {
      const aNum = parseInt(a?.sentReceivedTime);
      const bNum = parseInt(b?.sentReceivedTime);
      return aNum < bNum ? 1 : -1;
    });

    const unread = received?.filter((item) => {
      if (item.sentReceivedTime > sent[0]?.sentReceivedTime) {
        return item;
      }
      return null;
    });

    return unread?.length;
  };

  const markMsgsRead = async () => {
    clientObj?.phoneNumberIds?.forEach(async (item) => {
      if (item?._id === selectedPhnId) {
        if (item?.unreadMessages === true) {
          const options = {
            phnId: selectedPhnId,
            localEpoch: Date.now().toString(),
          };

          const { data } = await updatePhnMsgsRead({
            variables: options,
          });

          if (data?.updatePhnMsgsRead) {
            setClientObj((prev) => {
              const updatedPhn = prev?.phoneNumberIds?.map((item) => {
                if (item?._id === selectedPhnId) {
                  return { ...item, unreadMessages: false };
                }
                return item;
              });

              return { ...prev, phoneNumberIds: updatedPhn };
            });
          }
        }
      }
    });
  };

  const checkMsgs = async () => {
    const ids = clientObj?.phoneNumberIds?.map((item) => {
      return item?._id;
    });
    const { data } = await newMsgsCheck({
      variables: {
        phnIdArr: ids,
      },
    });

    if (data?.checkSingleClientNewMsgs) {
      const update = data?.checkSingleClientNewMsgs !== selectedPhnId;

      const { data: updatedData } = await getPhnById({
        variables: {
          dontUpdate: update,
          phoneId: data?.checkSingleClientNewMsgs,
          localEpoch: Date.now().toString(),
        },
      });

      if (updatedData?.getPhoneNumTextsById) {
        setClientObj((prev) => {
          const updatedPhn = prev?.phoneNumberIds?.map((item) => {
            if (item?._id === updatedData?.getPhoneNumTextsById?._id) {
              return { ...updatedData?.getPhoneNumTextsById };
            }
            return item;
          });

          return { ...prev, phoneNumberIds: updatedPhn };
        });

        setRefreshCounter(0);
      }
    }
  };

  const handlePageHeight = () => {
    setTimeout(() => {
      const navHeight = document.querySelector(
        "#admin-nav-height-marker"
      )?.offsetHeight;
      const singleClientContHeight = document.querySelector(
        "#single-client-page-container-height"
      )?.offsetHeight;
      const headerHeight = document.querySelector(
        "#single-client-page-hdr-marker"
      )?.offsetHeight;

      const totalHeight =
        navHeight + singleClientContHeight + headerHeight + window.innerHeight;
      setTotalMinHeight(totalHeight);
    }, 1000);
  };

  const fetchClientInfo = async () => {
    const { data } = await getClientInfo({
      variables: { clientId },
      fetchPolicy: "network-only",
    });
    console.log(data?.getClientById);

    if (data?.getClientById) {
      // sorting newest first
      const sortedQuotes = [...data?.getClientById?.quotes]?.sort(
        (quote1, quote2) => {
          return parseInt(quote2?.dateCreated) - parseInt(quote1?.dateCreated);
        }
      );

      const filteredDataPrev = data?.getClientById?.phoneNumberIds?.map(
        (obj) => {
          return {
            numberType: obj?.numberType,
            phoneNumber: obj?.phoneNumber,
            _id: obj?._id,
            title: obj?.title,
            textMessages: obj?.textMessages,
            unreadMessages: obj?.unreadMessages,
          };
        }
      );

      const filteredDataUpdate = data?.getClientById?.phoneNumberIds?.map(
        (obj) => {
          return {
            numberType: obj?.numberType,
            phoneNumber: obj?.phoneNumber,
            _id: obj?._id,
            title: obj?.title,
            textMessages: obj?.textMessages,
            unreadMessages: obj?.unreadMessages,
          };
        }
      );

      setClientObj({
        ...data?.getClientById,
        phoneNumberIds: filteredDataPrev,
        quotes: [...sortedQuotes],
      });
      setClientForm({
        ...data?.getClientById,
        phoneNumberIds: filteredDataUpdate,
      });
    }

    changeSaving(false, "");
    return;
  };

  const barCollapsing = (func) => {
    setBarCollapsed(func);
  };

  const invoiceCollapsing = (func) => {
    setInvoicesCollapse(func);
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "clientNotes") {
      const date = Date.now().toString();

      setNewClientNote(() => ({
        note: value,
        dateCreated: date,
        dateUpdated: "",
        updatedBy: "",
        createdBy: userName,
      }));

      return;
    }

    if (
      name === "firstName" ||
      name === "lastName" ||
      name === "spouseName" ||
      name === "streetAddress" ||
      name === "city" ||
      name === "state"
    ) {
      value = capitalizeFirstLetters(value);
    }

    if (name === "primaryEmail" || name === "secondaryEmail") {
      value = value.toLowerCase();
    }

    if (name === "zipCode") {
      value = value.replace(/[^0-9-]/gi, "");
    }

    setClientForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneNumChange = (event, index) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "phoneNumber") {
      value = phoneNumberFormat(value);
    }

    setClientForm((prev) => {
      const numbersArr = [...prev?.phoneNumberIds];
      numbersArr[index][name] = value;

      return {
        ...prev,
        phoneNumberIds: [...numbersArr],
      };
    });
  };

  const addClientNumber = () => {
    setClientForm((prev) => {
      const numbersArr = [...prev?.phoneNumberIds];

      numbersArr.push({
        phoneNumber: "",
        numberType: "",
        title: "",
      });

      return {
        ...prev,
        phoneNumberIds: [...numbersArr],
      };
    });
  };

  const removeClientNumber = () => {
    setClientForm((prev) => {
      const numbersArr = [...prev?.phoneNumberIds];

      numbersArr.pop();

      return {
        ...prev,
        phoneNumberIds: [...numbersArr],
      };
    });
  };

  const clearErrors = () => {
    setErrorsArr([]);
  };

  const handleNewNoteSave = async () => {
    if (newClientNote?.note?.length <= 0) {
      handleSnack("Note Error", 2500);
      setNoteSaveError(true);
      changeSaving(false, "");
      return;
    }

    if (newClientNote?.note?.length > 0) {
      const { data } = await createNewNoteGQL({
        variables: {
          clientId,
          noteObj: { ...newClientNote },
        },
      });

      if (data?.addClientNote) {
        setNewClientNote({ ...clearedNote });
        handleSnack("Note Saved", 2500);
        setNoteSaveError(false);
        changeSaving(false, "");
        fetchClientInfo();
        return;
      }

      handleSnack("Note Error", 2500);
      changeSaving(false, "");
      return;
    }
  };

  const handleUpdatedTasksArr = (taskUpdated) => {
    if (taskUpdated) {
      const updatedArr = clientObj?.clientTasks?.map((task) => {
        if (task?.id === taskUpdated?.id) {
          return taskUpdated;
        }
        return task;
      });

      setClientObj((prev) => ({
        ...prev,
        clientTasks: [...updatedArr],
      }));
    }
  };

  const handleSaveClient = async () => {
    if (editClient === false) {
      setEditClient(true);
      return;
    }

    clearErrors();

    const valid = await updateValidate.createClientIsValid();
    let newNote = null;

    if (newClientNote?.note?.length > 0) {
      const date = Date.now().toString();
      newNote = { ...newClientNote, dateCreated: date };
    }

    const strippedNumbers = updateClientForm?.phoneNumberIds?.map((item) => {
      return {
        _id: item?._id,
        phoneNumber: item?.phoneNumber,
        numberType: item?.numberType,
        title: `${updateClientForm?.firstName} ${updateClientForm?.lastName}-${updateClientForm?.clientId}`,
      };
    });

    if (valid === true) {
      const { data } = await updateCurrentClient({
        variables: {
          ...updateClientForm,
          phoneNumberIds: strippedNumbers,
          clientId,
          clientNotes: newNote,
        },
      });

      if (data?.updateClient?._id) {
        setNewClientNote({ ...clearedNote });
        changeSaving(false, "");
        handleSnack("Client Updated", 2500);
        fetchClientInfo();
        setEditClient(false);
        return;
      }
    }

    setErrorsArr(valid);
    handleSnack("Something went wrong. Please try again.", 2500);
    changeSaving(false, "");
  };

  const addPhotoIdToClient = (id) => {
    setClientObj((prev) => ({
      ...prev,
      clientPhotoIds: [...prev?.clientPhotoIds, id],
    }));
  };

  const changeParentForEmailModal = (bool) => {
    setOpenEmailModal(bool);
  };

  const openEditInvoiceQuoteModal = (isInvoiceBool, objId) => {
    const selected = isInvoiceBool
      ? clientObj?.invoices?.filter((invoice) => {
          return invoice?.id === objId ? invoice : null;
        })[0]
      : clientObj?.quotes?.filter((quote) => {
          return quote?.id === objId ? quote : null;
        })[0];

    if (selected) {
      setOpenQuoteInvoiceEditModal(true);
      setSelectedToEdit(selected);
      setSelectedToEditIsInvoice(isInvoiceBool);
    }
  };

  const updateInvoiceQuote = (obj, isInvoiceBool) => {
    if (isInvoiceBool) {
      const updatedArr = clientObj?.invoices?.map((invoice) => {
        if (obj?.id === invoice?.id) {
          return obj;
        }
        return invoice;
      });

      setClientObj((prev) => ({
        ...prev,
        invoices: [...updatedArr],
      }));
    }

    if (!isInvoiceBool) {
      const updatedArr = clientObj?.quotes?.map((quote) => {
        if (obj?.id === quote?.id) {
          return obj;
        }
        return quote;
      });

      setClientObj((prev) => ({
        ...prev,
        quotes: [...updatedArr],
      }));
    }

    closeEditInvoiceQuoteModal();
  };

  const closeEditInvoiceQuoteModal = (bool) => {
    setOpenQuoteInvoiceEditModal(false);
    setSelectedToEdit({});
    const body = document.querySelector("body");

    body.style.overflow = "auto";
  };

  const emailObjHandler = ({
    type,
    quoteLink,
    invoiceLink,
    toFirstName,
    attachArr,
  }) => {
    // bigginsdoorrefinishing.com/client/view-quote/*quoteId
    // bigginsdoorrefinishing.com/client/view-invoice/*invoiceId
    // const multiQuotes = quoteLink ? [...quoteLink] : []
    setEmailModalObj({
      type,
      quoteLink: quoteLink ? [...quoteLink] : [],
      invoiceLink: invoiceLink || "",
      toFirstName: toFirstName || clientObj?.firstName,
      attachArr: attachArr || [],
    });
    return type;
  };

  return (
    <div
      className="single-client-page"
      style={{ minHeight: `${totalMinHeight}px` }}
      id="single-client-height-marker"
    >
      {bdrLoader && <AdminBDRSpinner message={loaderMess} />}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {openEmailModal && (
        <SendEmailModal
          sendTo={clientObj?.primaryEmail}
          templateObj={emailModalObj}
          changeParent={changeParentForEmailModal}
          handleSnack={handleSnack}
        />
      )}
      {openQuoteInvoiceEditModal && (
        <QuoteInvoiceEditModal
          selectedObj={selectedToEdit}
          isInvoice={selectedToEditIsInvoice}
          openBool={openQuoteInvoiceEditModal}
          closeModal={closeEditInvoiceQuoteModal}
          handleSnack={handleSnack}
          returnNewObj={updateInvoiceQuote}
        />
      )}
      {selectedQuote?.id?.length === 24 && (
        <ViewClientQuoteModal
          selectedQuote={selectedQuote}
          closeQuoteModal={openQuoteModal}
          handleSnack={handleSnack}
          queryClient={fetchClientInfo}
          emailObjHandler={emailObjHandler}
          changeParentForEmailModal={changeParentForEmailModal}
        />
      )}
      {selectedInvoice?.id?.length === 24 && (
        <ViewClientInvoiceModal
          selectedInvoice={selectedInvoice}
          closeInvoiceModal={openInvoiceModal}
          handleSnack={handleSnack}
          queryClient={fetchClientInfo}
          emailObjHandler={emailObjHandler}
          changeParentForEmailModal={changeParentForEmailModal}
          clientFLName={`${clientObj?.firstName} ${clientObj?.lastName}`}
        />
      )}
      {clientObj?.firstName?.length > 0 && (
        <div
          className="single-client-page-container"
          id="single-client-page-container-height"
        >
          <div className="single-client-note-btn-box show-note-btn">
            {editClient && (
              <button
                className="single-client-btn"
                onClick={() => {
                  setEditClient(false);
                }}
              >
                Cancel
              </button>
            )}
            {!editClient && (
              <UserTaskCreate
                handleSnack={handleSnack}
                clientObj={clientObj}
                queryTasks={fetchClientInfo}
              />
            )}
            <button
              className="single-client-btn"
              onClick={() => {
                handleSaveClient();
              }}
            >
              {editClient ? "Save Client" : "Edit Client Info"}
            </button>
          </div>
          {errorsArr?.length > 0 && (
            <div className="single-client-errors-box">
              <ul>
                {errorsArr?.map((err, index) => {
                  return <li key={err + index}>{err}</li>;
                })}
              </ul>
            </div>
          )}
          <div className="single-client-info-container">
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={
                editClient
                  ? `First Name - Prev: ${clientObj?.firstName}`
                  : "First Name"
              }
              name="firstName"
              onChange={handleChange}
              onBlur={handleChange}
              value={
                editClient ? updateClientForm?.firstName : clientObj?.firstName
              }
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={
                editClient
                  ? `Last Name - Prev: ${clientObj?.lastName}`
                  : "Last Name"
              }
              name="lastName"
              onChange={handleChange}
              onBlur={handleChange}
              value={
                editClient ? updateClientForm?.lastName : clientObj?.lastName
              }
              variant="standard"
            />
            {(clientObj?.spouseName || editClient) && (
              <CssTextFieldStandard
                sx={{ width: "95%", my: 1 }}
                type="text"
                inputProps={{ readOnly: !editClient }}
                label={
                  editClient
                    ? `Spouse's Name - Prev: ${clientObj?.spouseName}`
                    : "Spouse's Name"
                }
                name="spouseName"
                onChange={handleChange}
                onBlur={handleChange}
                value={
                  editClient
                    ? updateClientForm?.spouseName
                    : clientObj?.spouseName
                }
                variant="standard"
              />
            )}
            {!editClient &&
              clientObj?.phoneNumberIds?.length > 0 &&
              clientObj?.phoneNumberIds?.map((obj, index) => {
                const unreadCount =
                  obj?.unreadMessages === true
                    ? handleMsgCount(obj?.textMessages)
                    : 0;
                const selected =
                  obj._id === selectedPhnId
                    ? "single-client-phone-box single-client-phone-box-selected"
                    : "single-client-phone-box";
                return (
                  <div
                    key={"phoneNumberCreationObj" + obj._id + index}
                    className="single-client-copy-btn-box"
                  >
                    {navigator.clipboard && (
                      <button
                        className="single-client-copy-btn phn-copy-btn"
                        onClick={async () => {
                          copyStringToClipboard(
                            clientObj?.phoneNumberIds[index]?.phoneNumber,
                            handleSnack("Phone Number Copied", 2500)
                          );
                        }}
                      >
                        <ContentCopyIcon
                          sx={{ fontSize: "18px" }}
                          titleAccess="Copy Phone Number"
                        />
                      </button>
                    )}
                    <button
                      className={selected}
                      onClick={() => {
                        setSelectedPhnId(obj._id);
                        setShowTexts(true);
                      }}
                    >
                      <CssTextFieldStandard
                        sx={{ width: "55%", my: 1 }}
                        type="tel"
                        inputProps={{
                          maxLength: 12,
                          inputMode: "numeric",
                          readOnly: !editClient,
                        }}
                        label={`Phone Number ${index + 1}`}
                        name="phoneNumber"
                        onChange={(event) => {
                          handlePhoneNumChange(event, index);
                        }}
                        value={clientObj?.phoneNumberIds[index]?.phoneNumber}
                        variant="standard"
                      />
                      <span className="single-client-type-span">
                        Type: {clientObj?.phoneNumberIds[index]?.numberType}
                      </span>
                      {unreadCount > 0 && (
                        <span className="single-client-unread-span">
                          {unreadCount}
                        </span>
                      )}
                    </button>
                  </div>
                );
              })}
            {editClient && (
              <>
                <div className="single-client-add-phones-btn-box">
                  <button
                    className="single-client-btn"
                    onClick={addClientNumber}
                  >
                    Add Phone Number
                  </button>
                  {updateClientForm?.phoneNumberIds?.length > 1 && (
                    <button
                      className="single-client-btn"
                      onClick={removeClientNumber}
                    >
                      Remove Number
                    </button>
                  )}
                </div>
                {updateClientForm?.phoneNumberIds?.length > 0 &&
                  updateClientForm?.phoneNumberIds?.map((obj, index) => {
                    const prevData =
                      clientObj?.phoneNumberIds[index]?.phoneNumber;
                    return (
                      <div
                        key={"phoneNumberUpdateObj" + obj._id + index}
                        className="single-client-phone-box"
                      >
                        <CssTextFieldStandard
                          sx={{ width: "55%", my: 1 }}
                          type="tel"
                          inputProps={{
                            maxLength: 12,
                            inputMode: "numeric",
                            readOnly: !editClient,
                          }}
                          label={
                            editClient
                              ? `Prev: ${prevData || "None"}`
                              : `Phone Number ${index + 1}`
                          }
                          name="phoneNumber"
                          onChange={(event) => {
                            handlePhoneNumChange(event, index);
                          }}
                          value={
                            updateClientForm?.phoneNumberIds[index]?.phoneNumber
                          }
                          variant="standard"
                        />
                        <div className="single-client-type-span">
                          <FormControl
                            style={{ alignSelf: "flex-end" }}
                            variant="standard"
                            sx={{ m: 1, minWidth: "90px" }}
                          >
                            <InputLabel
                              id="num-type-select"
                              sx={{
                                color: "#05F842",
                                "&.Mui-focused": { color: "#05F842" },
                              }}
                            >
                              Number Type
                            </InputLabel>
                            <Select
                              labelId="phone-type-select"
                              id="phone-type-select-input"
                              name="numberType"
                              disabled={!editClient}
                              value={
                                editClient
                                  ? updateClientForm?.phoneNumberIds[index]
                                      ?.numberType
                                  : clientObj?.phoneNumberIds[index]?.numberType
                              }
                              onChange={(event) => {
                                handlePhoneNumChange(event, index);
                              }}
                              disableUnderline={true}
                              sx={{
                                color: "#fff",
                                WebkitTextFillColor: "#fff",
                                "& .Mui-disabled": {
                                  color: "#fff !important",
                                  WebkitTextFillColor: "#fff",
                                },
                              }}
                              style={{ borderBottom: "1px solid #fff" }}
                            >
                              <MenuItem value={"Home"}>Home</MenuItem>
                              <MenuItem value={"Cell"}>Cell</MenuItem>
                              <MenuItem value={"Work"}>Work</MenuItem>
                              <MenuItem value={"Spouse"}>Spouse</MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
            <CssTextFieldStandard
              sx={{
                width:
                  smaller900 || (!clientObj?.secondaryEmail && !editClient)
                    ? "95%"
                    : "45%",
                my: 1,
              }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={
                editClient
                  ? `Primary Email - Prev: ${clientObj?.primaryEmail}`
                  : "Primary Email"
              }
              name="primaryEmail"
              onChange={handleChange}
              onBlur={handleChange}
              value={
                editClient
                  ? updateClientForm?.primaryEmail
                  : clientObj?.primaryEmail
              }
              variant="standard"
            />
            {(clientObj?.secondaryEmail || editClient) && (
              <CssTextFieldStandard
                sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
                type="text"
                inputProps={{ readOnly: !editClient }}
                label={
                  editClient
                    ? `Secondary Email - Prev: ${clientObj?.secondaryEmail}`
                    : "Secondary Email (optional)"
                }
                name="secondaryEmail"
                onChange={handleChange}
                onBlur={handleChange}
                value={
                  editClient
                    ? updateClientForm?.secondaryEmail
                    : clientObj?.secondaryEmail
                }
                variant="standard"
              />
            )}
            {navigator.clipboard && !editClient && (
              <div className="single-client-copy-btn-box">
                <button
                  className="single-client-copy-btn"
                  onClick={async () => {
                    const address = `${clientObj?.streetAddress}, ${
                      clientObj?.city
                    } ${
                      /texas/gi.test(clientObj?.state) === true
                        ? "TX"
                        : clientObj?.state
                    } ${clientObj?.zipCode}`;
                    copyStringToClipboard(
                      address,
                      handleSnack("Address Copied", 2500)
                    );
                  }}
                >
                  <ContentCopyIcon
                    sx={{ fontSize: "18px" }}
                    titleAccess="Copy Address"
                  />{" "}
                  - Copy Address
                </button>
              </div>
            )}
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={
                editClient
                  ? `Street Address - Prev: ${clientObj?.streetAddress}`
                  : "Street Address"
              }
              name="streetAddress"
              onChange={handleChange}
              onBlur={handleChange}
              value={
                editClient
                  ? updateClientForm?.streetAddress
                  : clientObj?.streetAddress
              }
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={editClient ? `City - Prev: ${clientObj?.city}` : "City"}
              name="city"
              onChange={handleChange}
              onBlur={handleChange}
              value={editClient ? updateClientForm?.city : clientObj?.city}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "42%" : "20%", m: 1 }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={editClient ? `State - Prev: ${clientObj?.state}` : "State"}
              name="state"
              onChange={handleChange}
              onBlur={handleChange}
              value={editClient ? updateClientForm?.state : clientObj?.state}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "42%" : "20%", m: 1 }}
              type="text"
              label={
                editClient
                  ? `Zip Code - Prev: ${clientObj?.zipCode}`
                  : "Zip Code"
              }
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                readOnly: !editClient,
              }}
              name="zipCode"
              onChange={handleChange}
              onBlur={handleChange}
              value={
                editClient ? updateClientForm?.zipCode : clientObj?.zipCode
              }
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
              type="text"
              inputProps={{ readOnly: !editClient }}
              label={
                editClient
                  ? `Gate Code - Prev: ${clientObj?.gateCode}`
                  : "Gate Code"
              }
              name="gateCode"
              onChange={handleChange}
              onBlur={handleChange}
              value={
                editClient ? updateClientForm?.gateCode : clientObj?.gateCode
              }
              variant="standard"
            />
            {editClient && (
              <>
                <div
                  className={
                    newClientNote?.note?.length > 0
                      ? "single-client-note-btn-box show-note-btn"
                      : "single-client-note-btn-box"
                  }
                >
                  <button
                    className="single-client-btn"
                    onClick={() => {
                      clearErrors();
                      changeSaving(true, "Saving...");
                      handleNewNoteSave();
                    }}
                  >
                    Save Note
                  </button>
                </div>
                {noteSaveError && (
                  <div>
                    <ul>
                      <li>Please enter note before saving</li>
                    </ul>
                  </div>
                )}
                <CssTextFieldStandard
                  sx={{ width: "95%", my: 1 }}
                  label="Add Note:"
                  inputProps={{ readOnly: !editClient }}
                  name="clientNotes"
                  value={newClientNote?.note}
                  onChange={handleChange}
                  multiline
                  minRows={5}
                />
              </>
            )}
          </div>
          {showTexts ? (
            <div
              id="client-text-display-div-scroll"
              className="single-client-functions-box"
            >
              <ClientTextMesDisplay
                textMesIncArr={clientObj?.phoneNumberIds}
                closeMessages={closeShowTexts}
                handleSnack={handleSnack}
                selectedPhnId={selectedPhnId}
              />
            </div>
          ) : (
            <>
              <div className="single-client-functions-box">
                <div className="single-client-actions-btn-box">
                  <button
                    className="single-client-actions-btn"
                    onClick={() => {
                      setShowTexts(true);
                      setSelectedPhnId(clientObj?.phoneNumberIds[0]?._id);
                    }}
                  >
                    Messages
                  </button>
                </div>
                <ClientEmailDisplay 
                  heightTrigger={heightTrigger}
                  handleSnack={handleSnack}
                  clientEmail={clientObj?.primaryEmail}
                />
                {clientObj?.clientNotes?.length > 0 && (
                  <ClientNotesDisplay
                    heightTrigger={heightTrigger}
                    changeSaving={changeSaving}
                    handleSnack={handleSnack}
                    notesArr={clientObj?.clientNotes}
                    clientId={clientId}
                    queryClient={fetchClientInfo}
                    gateCode={clientObj?.gateCode}
                    invoicesArr={clientObj?.invoices}
                    phnNumArr={clientObj?.phoneNumberIds}
                  />
                )}
                <ClientPicsDisplay
                  photoIdsArr={clientObj?.clientPhotoIds}
                  heightTrigger={heightTrigger}
                  handleSnack={handleSnack}
                  clientId={clientObj?._id}
                  addPhotoIdToClient={addPhotoIdToClient}
                />
                <ClientInvoicesDisplay
                  heightTrigger={heightTrigger}
                  invoicesArr={clientObj?.invoices}
                  openInvoiceModal={openInvoiceModal}
                  barCollapsed={invoicesCollapse}
                  setBarCollapsed={invoiceCollapsing}
                  handleSnack={handleSnack}
                  openEditInvoiceQuoteModal={openEditInvoiceQuoteModal}
                />
                <ClientQuotesDisplay
                  heightTrigger={heightTrigger}
                  quotesArr={clientObj?.quotes}
                  openQuoteModal={openQuoteModal}
                  barCollapsed={barCollapsed}
                  setBarCollapsed={barCollapsing}
                  clickTrigger={clickTrigger}
                  handleSnack={handleSnack}
                  openEditInvoiceQuoteModal={openEditInvoiceQuoteModal}
                />
                <ClientTasksDisplay
                  heightTrigger={heightTrigger}
                  incTasks={clientObj?.clientTasks}
                  handleSnack={handleSnack}
                  addUpdatedTaskToArr={handleUpdatedTasksArr}
                  refetch={fetchClientInfo}
                />
                <div className="single-client-actions-btn-box">
                  <CreateQuoteModal
                    clientObj={clientObj}
                    buttonCSS={"single-client-actions-btn"}
                    handleSnack={handleSnack}
                    fetchClientInfo={fetchClientInfo}
                    quotesDisplayTrigger={quotesDisplayTrigger}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleClient;
