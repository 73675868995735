import React, { useEffect, useRef, useState } from "react";
import "./privacyPolicy-TOS.css";
// import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";

const PrivacyPolicy = () => {
  //   const navigate = useNavigate();
  const mobileMedia = useMediaQuery("(max-width: 900px)");
  const [navScroll, setNavScroll] = useState({
    top: null,
    toc: null,
    topClass: "privacy-tos-quick-anchor-btn",
    tocClass: "privacy-tos-quick-anchor-btn",
    boxClass: "privacy-tos-quick-anchor-box",
  });
  const TOCRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navHeight = document.querySelector("#bdr-navbar")?.offsetHeight;
    const elHeight = TOCRef.current?.scrollHeight;
    const elOffsetTop = TOCRef.current?.offsetTop;

    const handleLog = () => {
      const setTop = window.scrollY > window.innerHeight;
      const setTOC = elOffsetTop + navHeight + elHeight < window.scrollY;
      if (setTop !== navScroll?.top || setTOC !== navScroll.toc) {
        const box = setTop
          ? setTOC
            ? "privacy-tos-quick-anchor-box anchor-box-anim-2"
            : "privacy-tos-quick-anchor-box anchor-box-anim-1"
          : "privacy-tos-quick-anchor-box";
        setNavScroll({
          top: setTop,
          toc: setTOC,
          topClass: setTop
            ? "privacy-tos-quick-anchor-btn anchor-in"
            : "privacy-tos-quick-anchor-btn",
          tocClass: setTOC
            ? "privacy-tos-quick-anchor-btn anchor-in"
            : "privacy-tos-quick-anchor-btn",
          boxClass: box,
        });
      }
    };

    // Bind the event listener
    document.addEventListener("scroll", handleLog);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("scroll", handleLog);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (num) => {
    let finalScroll = 1;
    if (num !== "top") {
      const id = num === "toc" ? "#privacy-toc" : `#privacy-tos-item-${num}`;
      let centerScroll = document.querySelector(id)?.offsetTop;

      const nav = document.querySelector("#bdr-navbar")?.offsetHeight;
      finalScroll = mobileMedia ? centerScroll - nav : centerScroll;
    }

    window.scrollTo({ top: finalScroll, left: 0, behavior: "smooth" });
  };

  return (
    <div className="privacy-tos-page">
      <div className={navScroll?.boxClass}>
        <button
          onClick={() => handleScroll("top")}
          className={navScroll?.topClass}
        >
          Top
        </button>
        <button
          onClick={() => handleScroll("toc")}
          className={navScroll?.tocClass}
        >
          TOC
        </button>
      </div>
      <div className="privacy-tos-content">
        <h1>Privacy Policy</h1>
        <span className="privacy-tos-updated">Last updated April 04, 2024</span>
        <p className="privacy-tos-para">
          This privacy notice for Leanne R Turner (doing business as BDR
          Services) ("we," "us," or "our"), describes how and why we might
          collect, store, use, and/or share ("process") your information when
          you use our services ("Services"), such as when you: Visit our website
          at www.bigginsdoorrefinishing.com, contract with us for the refinish,
          maintenance or consultation in regards to (a) door/s, contact us to
          request quotes for such work . Engage with us in other related ways,
          including any sales or marketing.
          <br />
          <br />
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          leanne@bigginsdoorrefinishing.com.
        </p>
        <h4>SUMMARY OF KEY POINTS</h4>
        <p className="privacy-tos-para">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <ul className="privacy-tos-ul">
          <li>
            <div className="privacy-tos-li-hdr">
              What personal information do we process?
            </div>
            <p className="privacy-tos-para">
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with us and the
              Services.
              {/* Learn more about personal information you disclose to us. */}
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              Do we rent, sell or lease any personal information?
            </div>
            <p className="privacy-tos-para">
              Your information will never be sold, rented or leased. It is
              collected with the sole purpose of providing communication around
              our Services directly from us to you.
              {/* Learn more about personal information you disclose to us. */}
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              Do we share your personal information with third parties for
              marketing or promotional purposes?
            </div>
            <p className="privacy-tos-para">
              No, we do not. No information is, or will ever be, shared with third parties/affiliates for
              marketing or promotional purposes.
              {/* Learn more about personal information you disclose to us. */}
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              Do we process any sensitive personal information?
            </div>
            <p className="privacy-tos-para">
              We do not process sensitive personal information.
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              Do we receive any information from third parties?
            </div>
            <p className="privacy-tos-para">
              We do not receive any information from third parties.
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              How do we process your information?
            </div>
            <p className="privacy-tos-para">
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We process your information
              only when we have a valid legal reason to do so.
              {/* Learn more about how we process your information. */}
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              In what situations and with which parties do we share personal
              information?
            </div>
            <div className="privacy-tos-para">
              We may use third-party service providers to assist us with
              providing and marketing our products and services to you and we
              may share your information with such third parties for these
              limited purposes
              <ul>
                <li>
                  We use services provided by Twilio for email and text message
                  communications. Any information shared is only within such
                  scope and limited to necessary information to process
                  communication requests.
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              How do we keep your information safe?
            </div>
            <p className="privacy-tos-para">
              We have organizational and technical processes and procedures in
              place to protect your personal information. No electronic
              transmission can be guaranteed to be 100% secure, but we implement
              the robust technologies in effort of keeping your information
              safe.
              {/* Learn more about how we keep your information safe. */}
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">What are your rights?</div>
            <p className="privacy-tos-para">
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information.
              {/* Learn more about your privacy rights. */}
            </p>
          </li>
          <li>
            <div className="privacy-tos-li-hdr">
              How do you exercise your rights?
            </div>
            <p className="privacy-tos-para">
              The easiest way to exercise your rights is by submitting a data
              subject access request, or by contacting us. We will consider and
              act upon any request in accordance with applicable data protection
              laws.
            </p>
          </li>
        </ul>
        <h2>PRIVACY POLICY IN FULL</h2>
        <br />
        <div ref={TOCRef} id="privacy-toc">
          <h5>TABLE OF CONTENTS</h5>
          <ul className="privacy-tos-ul-numbered">
            <li>
              <button
                onClick={() => handleScroll(1)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                WHAT INFORMATION DO WE COLLECT?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(2)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                HOW DO WE PROCESS YOUR INFORMATION?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(3)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                EMAIL AND TEXT MESSAGE COMMUNICATIONS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(4)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(5)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(6)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                HOW DO WE KEEP YOUR INFORMATION SAFE?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(7)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                WHAT ARE YOUR PRIVACY RIGHTS?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(8)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(9)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                DO WE MAKE UPDATES TO THIS NOTICE?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(10)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(11)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </button>
            </li>
          </ul>
        </div>

        <h5 id="privacy-tos-item-1">1. WHAT INFORMATION DO WE COLLECT?</h5>
        <p className="privacy-tos-para">
          We collect personal information that you voluntarily provide to us
          when you express an interest in obtaining information about us or our
          Services or otherwise when you contact us. The personal information
          that we collect depends on the context of your interactions with us
          and the Services. We do not process sensitive information. All
          personal information that you provide to us must be true, complete,
          and accurate.
        </p>
        <div className="privacy-tos-para">
          The personal information we collect may include the following:
          <ul>
            <li>Names</li>
            <li>Phone Numbers</li>
            <li>Email Addresses</li>
            <li>Mailing Address</li>
            <li>Contact Preferences</li>
            <li>Billing Address</li>
            <li>Pictures of Associated Work</li>
          </ul>
        </div>
        <h5 id="privacy-tos-item-2">2. HOW DO WE PROCESS YOUR INFORMATION?</h5>
        <p className="privacy-tos-para">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including: To deliver
          and facilitate delivery of services to the client. We may process your
          information to provide you with the requested service. To respond to
          client inquiries/offer support to clients. We may process your
          information to respond to your inquiries and solve any potential
          issues you might have with the requested service. We may process your
          information to follow up with maintenance reminders for work
          completed.
        </p>
        <div className="privacy-tos-para">
          You can opt out of our marketing or reminder emails and/or messages at
          any time. Learn how to opt out of text/email communications
        </div>
        <h5 id="privacy-tos-item-3">
          3. EMAIL AND TEXT MESSAGE COMMUNICATIONS
        </h5>
        <div className="privacy-tos-para">
          If you wish to unsubscribe from our email campaigns, please click on
          the Unsubscribe link at the bottom of any marketing email sent from
          us. If you wish to stop receiving text messages from us, reply STOP,
          QUIT, CANCEL, OPT-OUT, or UNSUBSCRIBE to any text message sent from
          us.
        </div>
        <h5 id="privacy-tos-item-4">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h5>
        <div className="privacy-tos-para">
          No information will be shared with third parties/affiliates for
          marketing or promotional purposes. We may use only authorized
          third-party service providers, listed within this section, to assist
          us with providing and marketing our products and services to you and
          we may share your information to the most minimal capacity with such
          third parties for these limited purposes.
          <ul>
            <li>
              We use services provided by Twilio for email and text message
              communications. Any information shared is only within such scope
              and limited to necessary information to process communication
              requests. We do this only at the moment of communication requests
              being sent.
            </li>
          </ul>
        </div>
        <h5 id="privacy-tos-item-5">
          5. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h5>
        <p className="privacy-tos-para">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). When we have no ongoing
          legitimate business need to process your personal information, we will
          either delete or anonymize such information, or, if this is not
          possible (for example, because your personal information has been
          stored in backup archives), then we will securely store your personal
          information and isolate it from any further processing until deletion
          is possible.
        </p>
        <h5 id="privacy-tos-item-6">
          6. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h5>
        <p className="privacy-tos-para">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cyber criminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h5 id="privacy-tos-item-7">7. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <p className="privacy-tos-para">
          In Short: You may review, change, or terminate your stored information
          with us at any time by sending a data termination request to{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>
          .
        </p>
        <p className="privacy-tos-para">
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          <button
            onClick={() => handleScroll(11)}
            className="privacy-tos-toc-links"
          >
            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
          </button>{" "}
          below. However, please note that this will not affect the lawfulness
          of the processing before its withdrawal nor, when applicable law
          allows, will it affect the processing of your personal information
          conducted in reliance on lawful processing grounds other than consent.
        </p>
        <p className="privacy-tos-para">
          Opting out of marketing and promotional communications: You can
          unsubscribe from our marketing and promotional communications at any
          time by clicking on the unsubscribe link in the emails that we send,
          replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or
          by contacting us using the details provided in the section{" "}
          <button
            onClick={() => handleScroll(11)}
            className="privacy-tos-toc-links"
          >
            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
          </button>{" "}
          below. You will then be removed from the marketing lists. However, we
          may still communicate with you — for example, to send you
          service-related messages that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes. If you have questions or comments about your
          privacy rights, you may email us at{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>
        </p>
        <h5 id="privacy-tos-item-8">
          8. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h5>
        <p className="privacy-tos-para">
          In Short: If you are a resident of the United States, you are granted
          specific rights regarding access to your personal information.
        </p>
        <p className="privacy-tos-para">
          What categories of personal information do we collect? <br />
          We collect the following categories of personal information:
        </p>
        <table className="privacy-tos-collect-info-table">
          <tbody>
            <tr>
              <td>
                A. Identifiers:
                <br /> Contact details, such as real name, alias, postal
                address, telephone or mobile contact number, email address.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                B. Protected classification characteristics under state or
                federal law:
                <br /> Gender and date of birth
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                C. Commercial information:
                <br />
                Transaction information, purchase history, financial details,
                and payment information
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                D. Biometric information:
                <br />
                Fingerprints and voiceprints
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                E. Internet or other similar network activity:
                <br />
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems, and advertisements
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                F. Geolocation data:
                <br />
                Device location
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                G. Audio, electronic, visual, or similar information:
                <br />
                Images and audio, video or call recordings created in connection
                with our business activities. Images received from our customers
                and images of completed work.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                H. Professional or employment-related information:
                <br />
                Business contact details in order to provide you our Services at
                a business level or job title, work history, and professional
                qualifications.
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                I. Education Information:
                <br />
                Student records and directory information
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                J. Inferences drawn from collected personal information:
                <br />
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual’s preferences and characteristics
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>K. Sensitive personal Information</td>
              <td>NO</td>
            </tr>
          </tbody>
        </table>
        <p className="privacy-tos-para">
          We will use and retain the collected personal information as needed to
          provide the Services or for: Category A - As long as the user has an
          account with us We may also collect other personal information outside
          of these categories through instances where you interact with us in
          person, online, or by phone or mail in the context of: Receiving help
          through our customer support channels; Participation in customer
          surveys or contests; and Facilitation in the delivery of our Services
          and to respond to your inquiries.
        </p>
        <p className="privacy-tos-para">
          How do we use and share your personal information?
          <br /> Learn about how we use your personal information in the
          section,
          <button
            onClick={() => handleScroll(2)}
            className="privacy-tos-toc-links"
          >
            "HOW DO WE PROCESS YOUR INFORMATION?"
          </button>
          .
          <br />
          <br />
          Will your information be shared with anyone else?
          <br /> We may disclose your personal information with our service
          providers pursuant to a written contract between us and each service
          provider. Learn more about how we disclose personal information to in
          the section,
          <button
            onClick={() => handleScroll(4)}
            className="privacy-tos-toc-links"
          >
            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
          </button>
          .
          <br />
          <br />
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information. We have not disclosed, sold, or shared
          any personal information to third parties for a business or commercial
          purpose. We will not sell or share personal information belonging to
          website visitors, users, and other consumers.
        </p>
        <h5 id="privacy-tos-item-9">9. DO WE MAKE UPDATES TO THIS NOTICE?</h5>
        <p className="privacy-tos-para">
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws. <br />
          <br /> We may update this privacy notice from time to time. The
          updated version will be indicated by an updated "Revised" date and the
          updated version will be effective as soon as it is accessible. We
          encourage you to review this privacy notice frequently to be informed
          of how we are protecting your information.
        </p>
        <h5 id="privacy-tos-item-10">
          10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h5>
        <p className="privacy-tos-para">
          You have the right to request access to the personal information we
          collect from you, change that information, or delete it. To request to
          review, update, or delete your personal information, please contact us
          in the ways listed in the{" "}
          <button
            onClick={() => handleScroll(11)}
            className="privacy-tos-toc-links"
          >
            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
          </button>{" "}
          section.
        </p>
        <h5 id="privacy-tos-item-11">
          11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h5>
        <div className="privacy-tos-para">
          If you have questions or comments about this notice, you may email us
          at{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>
          <br />
          or contact us by post at:
          <ul className="privacy-tos-ul-no-dec">
            <li>Leanne R Turner</li>
            <li>18975 Marbach Ln</li>
            <li>Suite 22-1209</li>
            <li>San Antonio, TX 78266 United States</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
