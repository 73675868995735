import React, { useEffect, useRef, useState } from "react";
import "./emailPreview.css";
import Auth from "../../../../utils/auth";

const unsubResponses = [
  "Why are you trying to unsubscribe? You're sending the email!",
  "Seriously?",
  "You're still trying?",
  "Stop it please...",
  "Come on, no more. Thank you!",
  "I said no more!",
  "You better stop!",
  "I'm warning you....",
  "It's pointless to unsubscribe. It doesn't work for you. So stop it.",
  "STOP IT!!!",
  "Dang it. I warned you! You click it again and you're gonna have consequences!",
];

const EmailPreview = ({
  text,
  previewType,
  toFirstName,
  quoteLinks,
  invoiceLinks,
  receivedPhoto,
}) => {
  const textBox = useRef();
  const [links, setLinks] = useState([]);
  const [unsubCounter, setUnsubCounter] = useState(0);

  useEffect(() => {
    // console.log(quoteLinks, invoiceLinks);
    if (previewType === "blank") {
      setLinks([]);
    }

    if (
      previewType === "invoiceTemplate" ||
      previewType === "receiptTemplate"
    ) {
      if (invoiceLinks?.length > 0) {
        setLinks([invoiceLinks]);
        return;
      }
      setLinks([]);
    }

    if (previewType === "quoteTemplate") {
      const filtered = quoteLinks?.filter((item) => item?.length > 0);
      setLinks([...filtered]);
    }
  }, [quoteLinks, invoiceLinks, previewType]);

  useEffect(() => {
    if (unsubCounter > 11) {
      window.alert("You done did it now! Hahaha");
      logoutHandler();
      return;
    }
    const num = unsubCounter - 1;
    if (unsubCounter > 0) {
      window.alert(unsubResponses[num]);
    }
  }, [unsubCounter]);

  const logoutHandler = async () => {
    const loggedOut = await Auth.logout();

    if (loggedOut) {
      window.location.reload();
    }
  };

  useEffect(() => {
    const newLineRegex = /\t|\n|\r/g;
    const formattedText = text?.replaceAll(newLineRegex, "<br />") || "";
    textBox.current.innerHTML = formattedText;
  }, [text]);

  return (
    <div id="bdr-container" className="eml-preview-container">
      <div className="eml-preview-box">
        <div id="bdr-header-box" className="eml-preview-hdr-box">
          <img
            id="bdr-hdr-img"
            className="eml-preview-hdr-img"
            alt="company logo"
            src="https://bigginsdoorrefinishing.com/static/media/BDR-logo.c51974f422b2db51cff6.png"
            height="60px"
            width="60px"
          />
          <div className="eml-preview-hdr-bg">
            <div>
              <div>
                <h1 id="bdr-hdr" className="eml-preview-hdr">
                  Biggin's Door Refinishing
                </h1>
              </div>
            </div>
          </div>
        </div>
        {(previewType === "invoiceTemplate" ||
          previewType === "quoteTemplate" ||
          previewType === "receiptTemplate") && (
          <h3>{`Hi${toFirstName && " " + toFirstName},`}</h3>
        )}
        {previewType === "quoteTemplate" && (
          <div className="eml-preview-text-box">
            Thank you for contacting Biggin's Door Refinishing
            {receivedPhoto && " and for sending the picture of your door"}!
          </div>
        )}
        {previewType === "invoiceTemplate" && (
          <div className="eml-preview-text-box">
            Thank you for choosing Biggin's Door Refinishing!
          </div>
        )}
        <p ref={textBox} id="bdr-text-box" className="eml-preview-text-box">
          {/* {formattedText} */}
        </p>
        {links?.length > 0 && (
          <div className="eml-preview-links-box">
            {links?.map((link, index) => {
              const buttonTitle =
                links?.length === 1
                  ? `View ${
                      previewType === "invoiceTemplate"
                        ? "Invoice"
                        : previewType === "receiptTemplate"
                        ? "Receipt"
                        : "Quote"
                    }`
                  : `View ${
                      previewType === "invoiceTemplate"
                        ? "Invoice"
                        : previewType === "receiptTemplate"
                        ? "Receipt"
                        : "Quote"
                    } ${index + 1}`;

              return (
                <button
                  key={`eml-preview-link-${index}`}
                  className="eml-preview-links"
                >
                  {buttonTitle}
                </button>
              );
            })}
          </div>
        )}
        <div className="eml-preview-hor-line"></div>
        <p>
          {(previewType === "invoiceTemplate" ||
            previewType === "quoteTemplate") && (
            <>
              <strong>Notes:</strong>
              <br />
              {previewType === "invoiceTemplate" && (
                <>
                  Thank you for contracting with Biggin's Door Refinishing!
                  <br />
                  <br />
                </>
              )}
              CHECK OUT OUR WEBSITE!!
              <br />
              <a href="https://bigginsdoorrefinishing.com">
                www.bigginsdoorrefinishing.com
              </a>
              <br />
              <br />
              LOOK AT OUR REVIEWS ON GOOGLE AND NEXT DOOR APP!!!
              <br />
              <a href="https://www.google.com/search?q=biggins+door+refinishing">
                https://www.google.com/search?q=biggins+door+refinishing
              </a>
              <br />
              <a href="https://nextdoor.com/pages/biggins-door-refinishing-san-antonio-tx/recommend/">
                https://nextdoor.com/pages/biggins-door-refinishing-san-antonio-tx/recommend/
              </a>
              <br />
              <br />
              Attached, you will find your{" "}
              {previewType === "invoiceTemplate" ? "invoice" : "estimate"} from
              Biggin's Door Refinishing.
              <br />
              This would be the total price and as a courtesy, we will replace
              the door sweep on the bottom of the door, if this is needed.
              <br />
              We will also check the foam/ rubber weatherstripping around the
              inside of the door frame.
              <br />
              <br />
              OUR PROCESS:
              <br />
              We refinish the door on site by removing the door from the hinges.
              They usually set up a tent at the end of your driveway, near the
              curb and this is where they will refinish your door. They strip
              and sand your door to take it all the way down to the bare wood,
              removing any current products from the wood. They then will stain
              to the color of your choice. We then apply multiple coats of high
              UV protection, marine grade Spar-Urethane finish. We will place a
              cover over the entry to close it off.
              <br />
              <br />
              MAINTENANCE PROGRAM:
              <br />
              There is a Maintenance Program that we offer, with prices starting
              at $126. The time frame that this is needed in will be determined
              by the amount of sun and rain exposure your particular door gets.
              (Price may vary, i.e. single door, double door, jambs etc.)
              <br />
              <br />
              Thank you for giving us an opportunity to earn your business, we
              look forward to serving you!
              <br />
              <br />
            </>
          )}
          Neil & Leanne Turner
          <br />
          Biggin's Door Refinishing
          <br />
          <a href="tel:+12105897431">210-589-7431</a>
          <br />
          <a href="mailto: leanne@bigginsdoorrefinishing.com">
            leanne@bigginsdoorrefinishing.com
          </a>
          <br />
          <a href="https://bigginsdoorrefinishing.com">
            www.bigginsdoorrefinishing.com
          </a>
        </p>
        <div className="eml-preview-unsub">
          <button
            onClick={() => {
              setUnsubCounter((prev) => prev + 1);
            }}
            className="eml-preview-anchor"
          >
            Unsubscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailPreview;
