import React from "react";
// import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./adminRadioForm.css"

const radioStyle = {
  radioLabels: "#fff",
  radioCircles: "#000",
  radioCirclesChecked: "#05F842",
};

const AdminRadioForm = (props) => {
  const {
    name,
    question,
    choices,
    choiceDefault,
    formId,
    choiceStateFunction,
    endComponent,
    mediaQueryWidth,
  } = props;

  const mediaQuery = useMediaQuery(mediaQueryWidth || "(min-width: 900px)");

  return (
    <FormControl id={formId} className="admin-radio-form">
      <FormLabel sx={{ color: "#fff", "&.Mui-focused": { color: "#fff" } }}>
        <h3 className="admin-radio-form-labels">{question}</h3>
      <RadioGroup
        aria-labelledby={formId}
        defaultValue={choiceDefault}
        onChange={choiceStateFunction}
        name={name}
        {...(mediaQuery ? { row: true } : { row: false })}
        className="admin-radio-center-box"
      >
        {choices.map((choice, index) => {
          return (
            <FormControlLabel
              key={choice + index}
              value={choice}
              sx={{ color: radioStyle.radioLabels }}
              control={
                <Radio
                  sx={{
                    color: radioStyle.radioCircles,
                    "&.Mui-checked": {
                      color: radioStyle.radioCirclesChecked,
                    },
                  }}
                />
              }
              label={choice}
            />
          );
        })}
      </RadioGroup>
      {endComponent ? endComponent : null}
      </FormLabel>
    </FormControl>
  );
};

export default AdminRadioForm;
