import React from "react";
import { Box } from "@mui/material";
import SocialIcons from "../SocialIcons/SocialIcons";
import "./footer.css";
import { useNavigate } from "react-router";

const angies1 = require("../../assets/images/angies2014.png");
const angies2 = require("../../assets/images/angies2015.png");
const angies3 = require("../../assets/images/angies2016.png");
const angies4 = require("../../assets/images/angies2017.png");

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box
      className="footer-box"
      sx={{ margin: "0px 0px 10px", padding: "10px 0px 30px" }}
    >
      <div className="footer-bg-box">
        <div className="img-box">
          <img className="footer-img" src={angies1} alt="" />
          <img className="footer-img" src={angies2} alt="" />
          <img className="footer-img" src={angies3} alt="" />
          <img className="footer-img" src={angies4} alt="" />
        </div>
        <div className="footer-social">
          <SocialIcons />
        </div>
        <h3 className="footer-hdr">Est. 2012</h3>
        <div className="footer-privacy-tos-box">
          <button onClick={() => navigate("/privacy-policy")} className="footer-btn">
            Privacy Policy
          </button>
          <button onClick={() => navigate("/terms-of-service")} className="footer-btn">
            Terms of Service
          </button>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
