import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  NEW_VISIT,
  UPDATE_VISIT,
  DELETE_VISIT,
} from "../utils/GraphQL/mutations";

const Tracking = ({ page, convertQuote, removeVisit }) => {
  const [newVisit] = useMutation(NEW_VISIT);
  const [updateVisit] = useMutation(UPDATE_VISIT);
  const [deleteVisit] = useMutation(DELETE_VISIT);
  const { scanned } = useParams();

  useEffect(() => {
    if (removeVisit !== true) {
      visitorCheck(page, convertQuote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (convertQuote) {
      updateQuoted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertQuote]);

  useEffect(() => {
    if (removeVisit === true) {
      visitDeleteHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeVisit]);

  const updateQuoted = async () => {
    const visitId = sessionStorage.getItem("bdr-visitor");

    if (convertQuote === true) {
      await updateVisit({
        variables: {
          updateVisitId: visitId,
          convertQuote: convertQuote,
        },
      });

      return;
    }

    return;
  };

  const visitDeleteHandler = async () => {
    const visitId = sessionStorage.getItem("bdr-visitor");

    if (!visitId) {
      return;
    }

    if (removeVisit === true) {
      const { data } = await deleteVisit({
        variables: {
          deleteVisitId: visitId,
        },
      });

      if (data?.deleteVisit === true) {
        sessionStorage.removeItem("bdr-visitor");
      }

      return;
    }

    return;
  };

  const visitorCheck = async (pageVisited) => {
    const nonVisitor = localStorage.getItem("bdr-non-visitor");
    if (nonVisitor === "true") {
      return;
    }

    const visitId = sessionStorage.getItem("bdr-visitor");
    const time = Date.now();

    if (!visitId) {
      const { data } = await newVisit({
        variables: {
          timeVisited: time.toString(),
          signNum: scanned,
          pageVisited,
        },
      });

      if (data?.newVisitor?.id) {
        sessionStorage.setItem("bdr-visitor", data?.newVisitor?.id);
      }

      return;
    }

    if (visitId) {
      await updateVisit({
        variables: {
          updateVisitId: visitId,
          page: pageVisited || "home",
        },
      });
    }

    return;
  };

  return <></>;
};

export default Tracking;
