import capitalizeFirstLetters from "./capitalizeFirstLetters";

const nameListFormat = (nameArr) => {
  const convertObjToArr = [...nameArr];

  if (!convertObjToArr.length) {
    return;
  }

  const names = convertObjToArr.map((refin) => {
    if (/chad/gi.test(refin)) {
      return "Goose";
    }
    return capitalizeFirstLetters(refin);
  });

  if (names.length === 1) {
    return names[0];
  }

  const lastOfNames = names.pop();

  const restOfNames = names.join(", ");

  return `${restOfNames} & ${lastOfNames}`;
};

export default nameListFormat;
