import { Navigate } from "react-router-dom";
import Auth from "../utils/auth";

const Protected = ({ children, permCheck }) => {
  const isLoggedIn = Auth.loggedIn()
  const perm = Auth.getPermissions()

  if (perm === false){
    return <Navigate to="/login-portal" replace />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login-portal" replace />;
  }
  
  if (permCheck) {
    if (permCheck !== perm){
      return <Navigate to="/bdr-office/dashboard" replace />;
    }
  }

  return children;
};

export default Protected;
