import React, { useState, useRef, useEffect } from "react";
import "./msgPageTextDisplay.css";
import { SEND_SMS_TEXT_STRIPPED_RETURN } from "../../../../utils/GraphQL/mutations";
import {
  GET_PHONE_TEXTS_BY_ID,
  QUERY_CLIENT_PHOTOS,
} from "../../../../utils/GraphQL/queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copyImageToClip from "../../../../utils/copyImageToClip";
import MsgImgShuffle from "../MsgImgShuffle/MsgImgShuffle";
import OfficeShortcuts from "../OfficeShortcuts/OfficeShortcuts";

// const clearText = {
//   _id: "",
//   phoneNumber: "",
//   numberType: "",
//   title: "",
//   recentTime: "",
//   textMessages: [],
//   unreadMessages: false,
//   hasTexts: true,
// };

const MsgPageTextDisplay = ({
  phoneId,
  handleSnack,
  closeMessages,
  queryPhoneMsgTrigger,
}) => {
  const [sendSMSgql] = useMutation(SEND_SMS_TEXT_STRIPPED_RETURN, {
    fetchPolicy: "network-only",
  });
  const [queryPhoneNumberCall] = useLazyQuery(GET_PHONE_TEXTS_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [queryPhoto] = useLazyQuery(QUERY_CLIENT_PHOTOS, {
    fetchPolicy: "network-only",
  });
  const [shadowOn, setShadowOn] = useState(false);
  const smaller900 = useMediaQuery("(max-width:900px)");
  //   const [fetchNewInfo, setFetchNewInfo] = useState(false);
  const [sendMesInput, setSendMesInput] = useState("");
  const [textMes, setTextMes] = useState([]);
  const [textMesImgIdsArr, setTextMesImgIdsArr] = useState([]);
  const [queryForImgs, setQueryForImgs] = useState(true);
  const [newMessageTrigger, setNewMessageTriggered] = useState(false);
  const [initLoadingDone, setInitLoadingDone] = useState(false);
  const [prevPhoneId, setPrevPhoneId] = useState("");
  const textMesBoxRef = useRef();
  const shadowTopRef = useRef();
  const shadowBottomRef = useRef();
  const textMesRef = useRef();
  const sendMesRef = useRef();
  const shadowWrapper = useRef();
  const [hoverScrollObj, setHoverScrollObj] = useState({
    hoverUp: false,
    hoverDown: false,
    currentScroll: 0,
    totalScroll: 0,
    initScroll: true,
  });

  useEffect(() => {
    if (phoneId) {
      if (prevPhoneId !== "" && prevPhoneId !== phoneId) {
        clearState();
      }
      if (prevPhoneId === phoneId) {
        setNewMessageTriggered(true);
      }
      queryPhoneNum(prevPhoneId);
      // setPrevPhoneId(phoneId);
    }
    // eslint-disable-next-line
  }, [phoneId, queryPhoneMsgTrigger]);

  useEffect(() => {
    if (phoneId) {
      const textMesBoxEl = textMesRef.current;
      const totalScroll =
        textMesBoxEl?.scrollHeight - textMesBoxEl?.offsetHeight;
      setHoverScrollObj((prev) => ({
        ...prev,
        totalScroll,
      }));

      if (textMesBoxEl?.offsetHeight < textMesBoxEl?.scrollHeight) {
        setShadowOn(true);
        const handleShadow = () => {
          const contentScroll =
            textMesBoxEl?.scrollHeight - textMesBoxEl?.offsetHeight;

          var currentScroll = textMesBoxEl.scrollTop / contentScroll;
          if (shadowTopRef.current && shadowBottomRef.current) {
            shadowTopRef.current.style.opacity = currentScroll;
            shadowBottomRef.current.style.opacity = 1 - currentScroll;
          }
        };

        // Bind the event listener
        textMesBoxEl.addEventListener("scroll", handleShadow);
        // textMesBoxEl?.scroll(textMesBoxEl.scrollHeight)
        return () => {
          // Unbind the event listener on clean up
          textMesBoxEl.removeEventListener("scroll", handleShadow);
        };
      }

      if (textMesBoxEl.offsetHeight >= textMesBoxEl.scrollHeight) {
        setShadowOn(false);
      }
    }
  }, [textMes, phoneId]);

  useEffect(() => {
    const textMesBoxEl = textMesRef.current;
    const currentScroll = Math.floor(textMesBoxEl?.scrollTop);
    let id = "";

    // Stop state change and scrolling operation
    if (hoverScrollObj?.initScroll === false) {
      if (
        hoverScrollObj?.currentScroll >= hoverScrollObj?.totalScroll ||
        hoverScrollObj?.currentScroll <= 0
      ) {
        if (
          hoverScrollObj?.hoverDown === true ||
          hoverScrollObj?.hoverUp === true
        ) {
          setHoverScrollObj((prev) => ({
            ...prev,
            hoverDown: false,
            hoverUp: false,
          }));
        }
        return;
      }
    }

    if (hoverScrollObj?.hoverUp === true) {
      id = setTimeout(() => {
        textMesBoxEl?.scrollTo({
          top: currentScroll - 100,
          behavior: "smooth",
        });
        setHoverScrollObj((prev) => ({
          ...prev,
          // initScroll: false,
          currentScroll: currentScroll - 100,
        }));
      }, 100);
    }

    if (hoverScrollObj?.hoverDown === true) {
      id = setTimeout(() => {
        textMesBoxEl?.scrollTo({
          top: currentScroll + 100,
          behavior: "smooth",
        });
        setHoverScrollObj((prev) => ({
          ...prev,
          // initScroll: false,
          currentScroll: currentScroll + 100,
        }));
      }, 100);
    }

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverScrollObj]);

  useEffect(() => {
    const textMesBox = textMesRef.current;

    if (initLoadingDone || newMessageTrigger) {
      textMesBox?.scrollTo({
        top: textMesBox.scrollHeight,
        behavior: "auto",
      });

      setInitLoadingDone(false);
      setNewMessageTriggered(false);
      setPrevPhoneId(phoneId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initLoadingDone]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (smaller900 === true) {
        const navHeight = document.querySelector(
          "#admin-nav-height-marker"
        )?.offsetHeight;
        const pagePos = textMesBoxRef.current.offsetTop;

        const totalMobileScroll = pagePos - navHeight - 5;
        window.scrollTo({
          left: 0,
          top: totalMobileScroll,
          behavior: "auto",
        });
      }

      if (smaller900 === false) {
        window.scrollTo({
          left: 0,
          top: textMesBoxRef.current.offsetTop - 5,
          behavior: "auto",
        });
      }
    }, 50);
    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textMes, smaller900, textMesImgIdsArr]);

  useEffect(() => {
    if (textMesImgIdsArr?.length > 0 && queryForImgs) {
      if (textMesImgIdsArr?.length <= 10) {
        const idOnlyArrOfArr = textMesImgIdsArr?.filter((item) => {
          if (item === null) {
            return null;
          }

          if (item[0]?.imageData || item[0]?.__typename === "ClientDoorPhoto") {
            return null;
          }

          return item;
        });
        const noNull = idOnlyArrOfArr?.filter((item) => item !== null);
        if (noNull?.length > 0) {
          const idArr = noNull?.flat();
          queryPhotoById(idArr);
          return;
        } else {
          setInitLoadingDone(true);
          return;
        }
      }

      if (textMesImgIdsArr?.length > 10) {
        const sliceNum = textMesImgIdsArr?.length - 10;
        const slicedArr = textMesImgIdsArr?.slice(sliceNum);
        const idOnlyArrOfArr = slicedArr?.filter((item) => {
          if (item === null) {
            return null;
          }

          if (item[0]?.imageData || item[0]?.__typename === "ClientDoorPhoto") {
            return null;
          }

          return item;
        });
        const noNull = idOnlyArrOfArr?.filter((item) => item !== null);
        if (noNull?.length > 0) {
          const idArr = noNull?.flat();
          queryPhotoById(idArr);
          return;
        } else {
          setInitLoadingDone(true);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textMesImgIdsArr]);

  const clearState = () => {
    setTextMes([]);
    setTextMesImgIdsArr([]);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "sendMessageText") {
      const updated = value?.replace(
        /([!?.]\s+)([a-z])/g,
        function (m, first, second) {
          return first + second.toUpperCase();
        }
      );

      setSendMesInput(capitalize(updated));

      return;
    }
  };

  //   const scrollInputIntoView = () => {
  //     const sendRef = sendMesRef.current;
  //     const elHeight = sendRef?.offsetHeight;
  //     const scroll =
  //       window.scrollY +
  //       sendMesRef.current.getBoundingClientRect().top -
  //       window?.visualViewport.height +
  //       elHeight +
  //       80;
  //     window?.scrollTo({
  //       top: scroll,
  //       behavior: "smooth",
  //     });
  //   };

  //   const inputBlur = () => {
  //     setRefocusMes((prev) => !prev);
  //   };

  const queryPhotoById = async (idArr) => {
    const { data } = await queryPhoto({
      variables: {
        pictureIdArr: idArr,
      },
    });

    setQueryForImgs(false);
    if (data?.getImageById) {
      setTextMesImgIdsArr((prev) => {
        const originalMapped = prev?.map((idStringArr) => {
          if (idStringArr === null) {
            return idStringArr;
          }

          const obj = idStringArr?.map((idString) => {
            const matched = data?.getImageById?.map((imageObj) => {
              if (imageObj?._id === idString) {
                return imageObj;
              }

              return null;
            });

            return matched?.filter((item) => item !== null);
          });

          return obj?.flat()?.length > 0 ? obj?.flat() : idStringArr;
        });

        return [...originalMapped];
      });
    }

    if (prevPhoneId !== phoneId || newMessageTrigger) {
      setInitLoadingDone(true);
    }
  };

  const queryPhoneNum = async (prevNumId) => {
    const localEpoch = Date.now().toString();
    const { data } = await queryPhoneNumberCall({
      variables: {
        phoneId,
        localEpoch,
      },
    });

    if (data?.getPhoneNumTextsById) {
      const sorted = [...data?.getPhoneNumTextsById?.textMessages]?.sort(
        (a, b) => {
          const aNum = parseInt(a?.sentReceivedTime);
          const bNum = parseInt(b?.sentReceivedTime);
          return aNum > bNum ? 1 : -1;
        }
      );

      // if new phone id has been passed. Wipe all prev img ids in arr
      if (prevNumId === "" || prevNumId !== phoneId) {
        // set an empty reference arr with ids at index for img loading
        const imgArr = sorted?.map((textObj) => {
          if (textObj?.attachedImgs?.length > 0) {
            return textObj?.attachedImgs;
          }
          return null;
        });

        setTextMesImgIdsArr([...imgArr]);
      }

      // if current phone num is just being updated. Splice for new img ids
      if (prevNumId === phoneId) {
        // set an empty reference arr with ids at index for img loading
        const imgArr = sorted
          ?.slice(textMesImgIdsArr?.length, sorted?.length)
          ?.map((textObj) => {
            if (textObj?.attachedImgs?.length > 0) {
              return textObj?.attachedImgs;
            }
            return null;
          });

        setTextMesImgIdsArr((prev) => [...prev, ...imgArr]);
      }

      setQueryForImgs(true);
      setTextMes({ ...data?.getPhoneNumTextsById, textMessages: [...sorted] });
    }
  };

  const handleHoverScroll = (scrollUp, stateChange) => {
    const name = scrollUp ? "hoverUp" : "hoverDown";
    setHoverScrollObj((prev) => ({
      ...prev,
      currentScroll: prev.currentScroll - 1,
      initScroll: true,
      [name]: stateChange,
    }));
  };

  const sendMessageCall = async () => {
    const date = Date.now().toString();
    const obj = {
      textBody: sendMesInput,
      localTime: date,
      phoneNumId: textMes?._id || null,
      sendToNum: textMes?.phoneNumber || null,
    };

    const { data } = await sendSMSgql({
      variables: { ...obj },
    });

    if (data?.sendSMS?._id) {
      // if successful message send
      const objForUpdate = {
        body: obj?.textBody,
        outgoingBool: true,
        sentReceivedTime: date,
        twilioSID: null,
      };
      //   setFetchNewInfo(true);
      setTextMes((prev) => ({
        ...prev,
        textMessages: [...prev?.textMessages, objForUpdate],
      }));

      setInitLoadingDone(true);
      setSendMesInput("");

      return;
    }

    if (handleSnack) {
      handleSnack("Something went wrong...Please try again!", 2500);
    }
  };

  return (
    <div ref={textMesBoxRef} className="msg-page-texts-box">
      {/* <div className="msg-page-texts-hdr-box">
        <button
          className="msg-page-texts-btn"
          onClick={() => {
            closeMessages(fetchNewInfo);
          }}
        >
          Close
        </button>
      </div> */}
      {phoneId?.length > 0 ? (
        <div className="msg-page-texts-text-and-num-box">
          <div ref={shadowWrapper} className="msg-page-texts-shadow-wrapper">
            {shadowOn && (
              <>
                <div
                  ref={shadowTopRef}
                  className="msg-page-texts-shadow msg-page-texts-shadow--top"
                ></div>
                {!smaller900 && (
                  <>
                    <button
                      onMouseOver={() => {
                        handleHoverScroll(true, true);
                      }}
                      onMouseOut={() => {
                        handleHoverScroll(true, false);
                      }}
                      className="msg-page-texts-hvr-scroll msg-page-texts-hvr-scroll-top"
                    ></button>
                    <button
                      onMouseOver={() => {
                        handleHoverScroll(false, true);
                      }}
                      onMouseOut={() => {
                        handleHoverScroll(false, false);
                      }}
                      className="msg-page-texts-hvr-scroll msg-page-texts-hvr-scroll-bottom"
                    ></button>
                  </>
                )}

                <div
                  ref={shadowBottomRef}
                  className="msg-page-texts-shadow msg-page-texts-shadow--bottom"
                ></div>
              </>
            )}

            <div ref={textMesRef} className="msg-page-texts-mes-box">
              {textMes?.textMessages && textMes?.textMessages?.length > 0 ? (
                <>
                  {textMes?.textMessages?.map((mesObj, index) => {
                    const msg = mesObj?.body;
                    const timeStr = BAGroupTimeFormat(
                      mesObj?.sentReceivedTime,
                      true,
                      true
                    );

                    const today = /today/gi.test(timeStr);
                    const oneYear = 1000 * 60 * 60 * 24 * 365;
                    const olderThenAYear =
                      Date.now() - oneYear > parseInt(mesObj?.sentReceivedTime);

                    const time = today
                      ? `${timeStr?.split("@")[1]}`
                      : olderThenAYear
                      ? `${timeStr?.split(",")[1]?.replace(/@/gi, "-")}`
                      : `${BAGroupTimeFormat(
                          mesObj?.sentReceivedTime,
                          true,
                          false
                        )?.replace(/@/gi, "-")}`;

                    return (
                      <div
                        key={`text-item-${index}`}
                        className={`msg-page-texts-mes-box-${
                          mesObj?.outgoingBool ? "out" : "inc"
                        } mes-box-scale`}
                        // ${mesObj?.attachedImgs?.length > 0 ? "mes-box-scale" : ""}
                      >
                        <div
                          className={`msg-page-texts-mes-item ${
                            mesObj?.outgoingBool
                              ? "msg-page-texts-mes-item-out"
                              : ""
                          }`}
                        >
                          {mesObj?.attachedImgs?.length > 0 &&
                          textMesImgIdsArr?.[index]?.[0]?.length === 24 ? (
                            textMes?.textMessages?.length - 10 < index ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <></>
                            )
                          ) : mesObj?.attachedImgs?.length === 1 ? (
                            textMesImgIdsArr[index]?.map(
                              (imgObj, indexOfImg) => {
                                const imgId = "imgEl-" + imgObj._id;
                                const btnId = "btnEl-" + imgObj._id;

                                return (
                                  <div
                                    key={`text-img-key-${indexOfImg}`}
                                    className="msg-page-texts-mes-item-single-img-box"
                                    // style={{ height: `${maxImgHeight}px` }}
                                  >
                                    <img
                                      className="msg-page-text-img"
                                      src={imgObj?.imageData}
                                      alt="text"
                                      id={imgId}
                                    />
                                    <button
                                      className="msg-page-texts-btn-copy"
                                      id={btnId}
                                      onPointerUp={async () => {
                                        const img = document.querySelector(
                                          `#${imgId}`
                                        );
                                        const blob = new ClipboardItem({
                                          "image/png": copyImageToClip(img.src),
                                        });

                                        navigator.clipboard.write([blob]);
                                        handleSnack("Image copied", 1500);
                                      }}
                                    >
                                      <ContentCopyIcon
                                        titleAccess="Copy Image"
                                        fontSize={"inherit"}
                                      />
                                    </button>
                                  </div>
                                );
                              }
                            )
                          ) : mesObj?.attachedImgs?.length > 1 ? (
                            <MsgImgShuffle
                              imgArr={textMesImgIdsArr[index]}
                              handleSnack={handleSnack}
                            />
                          ) : (
                            <></>
                          )}
                          <div
                            className={`msg-page-texts-mes-item ${
                              mesObj?.outgoingBool
                                ? ""
                                : "msg-page-texts-mes-item-inc"
                            }`}
                          >
                            {textMesImgIdsArr?.[index]?.[0]?.length === 24 &&
                              textMes?.textMessages?.length - 10 > index && (
                                <button
                                  onClick={() => {
                                    queryPhotoById(textMesImgIdsArr[index]);
                                  }}
                                  className="msg-page-texts-btn-smaller"
                                >
                                  Load Images
                                </button>
                              )}
                            <div>{msg}</div>
                            <div className="msg-page-texts-mes-item-date">{`- ${time}`}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className="msg-page-texts-messenger-box">
            <CssTextFieldStandard
              sx={{ width: "75%", m: 0.5 }}
              inputRef={sendMesRef}
              type="text"
              label={"Message"}
              name="sendMessageText"
              onChange={handleChange}
              // onFocus={scrollInputIntoView}
              // onBlur={inputBlur}
              value={sendMesInput}
              variant="standard"
              multiline
              minRows={1}
            />
            <button
              className={
                sendMesInput?.length > 0
                  ? "msg-page-texts-btn"
                  : "msg-page-texts-btn send-text-dis"
              }
              disabled={!sendMesInput?.length > 0}
              onClick={sendMessageCall}
            >
              Send
            </button>
            <OfficeShortcuts handleSnack={handleSnack} passedBtnCss={"msg-page-texts-btn"}/>
          </div>
        </div>
      ) : (
        <h4 className="msg-page-none">No number selected</h4>
      )}
    </div>
  );
};

export default MsgPageTextDisplay;
