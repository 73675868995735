import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./navbar.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const pages = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Request a Quote",
    href: "/quote",
  },
  // Meet the team page removed from navbar. Awaiting correct information
  // {
  //   name: "Meet The Team",
  //   href: "/meet-the-team",
  // },
  {
    name: "Our Refinished Doors",
    href: "/our-doors",
  },
  {
    name: "Contact",
    href: "/contact-us",
  },
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [staticOrientation, setStaticOrientation] = useState(false);
  const matchesMQ = useMediaQuery("(min-width: 900px)");

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", (event) => {
    if (event.matches) {
      // Portrait mode
      setStaticOrientation(false);
    } else {
      // Landscape
      setStaticOrientation(true);
    }
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      id="bdr-navbar"
      position={matchesMQ ? "static" : staticOrientation ? "static" : "sticky"}
      sx={{ backgroundColor: "transparent" }}
    >
      <Container maxWidth="100vw" className="navbar">
        <Toolbar disableGutters>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: { md: "column", xl: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "center", md: "flex-start", xl: "center" },
            }}
          >
            <h1 className="nav-hdr">Biggin's Door Refinishing</h1>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                background: "#595959",
                borderRadius: "5px",
                boxShadow: "0px 0px 10px #000",
              }}
            >
              <IconButton
                size="small"
                aria-label="page menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name}>
                    <a
                      key={page.name}
                      className="nav-links-hov small"
                      role="navigation"
                      name={page.name}
                      href={page.href}
                    >
                      {page.name}
                    </a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "space-around",
                width: { md: "100%", xl: "fit-content" },
                background: "#595959c2",
                backdropFilter: "blur(2px)",
                borderRadius: "0px",
                borderBottom: "1px #fff solid",
                borderTop: "1px #fff solid",
                boxShadow: "0px 0px 15px #000",
              }}
              textAlign="center"
            >
              {pages.map((page) => (
                <a
                  key={page.name}
                  className="nav-links-hov"
                  role="navigation"
                  name={page.name}
                  href={page.href}
                >
                  {page.name}
                </a>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
