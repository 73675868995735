import React, { useEffect, useState, useRef } from "react";
import "./messagesPage.css";
import { Snackbar } from "@mui/material";
import AdminBDRSpinner from "../../adminComponents/AdminBDRSpinner/AdminBDRSpinner";
import { GET_PHONE_INFO_FOR_TEXTS } from "../../../../utils/GraphQL/queries";
import { useLazyQuery } from "@apollo/client";
// import ClientSearchInput from "../../adminComponents/ClientSearchInput/ClientSearchInput";
import { useNavigate } from "react-router";
import MsgPageTextDisplay from "../../adminComponents/MsgPageTextDisplay/MsgPageTextDisplay";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MsgPageContactOptions from "../../adminComponents/MsgPageContactOptions/MsgPageContactOptions";
import CreateClient from "../../adminComponents/CreateClient/CreateClient";
import RefreshIcon from "@mui/icons-material/Refresh";

const MessagesPage = () => {
  const navigate = useNavigate();
  const [totalHeight, setTotalHeight] = useState(800);
  const [msgLoading, setMsgLoading] = useState(true);
  const [phoneNumArr, setPhoneNumArr] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [queryPhoneMsgTrigger, setQueryPhoneMsgTrigger] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const subMenuRefs = useRef([]);
  const [createNewClientDisplay, setCreateNewClientDisplay] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [createClientPhnObj, setCreateClientPhnObj] = useState({});
  const [queryPhonesGQLCall] = useLazyQuery(GET_PHONE_INFO_FOR_TEXTS, {
    fetchPolicy: "network-only",
  });
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    queryStrippedPhoneInfo(false);
    const id = setTimeout(() => {
      handlePageHeight();
    }, 80);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      if (refreshCounter < 20) {
        queryStrippedPhoneInfo(true);
        setRefreshCounter((prev) => prev + 1);
      }
    }, 7000);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const handlePageHeight = () => {
    const navHeight = document.querySelector(
      "#admin-nav-height-marker"
    )?.offsetHeight;

    const totalHeight = window.innerHeight - navHeight;
    setTotalHeight(totalHeight);
  };

  const navClient = (id) => {
    navigate(`/bdr-office/clients/view-client/${id}`);
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const refreshReset = (queryBool) => {
    setRefreshCounter(0);
    if (queryBool) {
      queryStrippedPhoneInfo(false);
    }
  };

  const handleNewClientDisplay = (bool) => {
    setCreateNewClientDisplay(bool);
    if (bool === true) {
      const phn = phoneNumArr?.filter((obj) => obj?._id === selectedId);
      setCreateClientPhnObj({ ...phn[0] });
    }
    if (bool === false) {
      setCreateClientPhnObj({});
    }
  };

  const queryStrippedPhoneInfo = async (bool) => {
    const { data } = await queryPhonesGQLCall({
      variables: {
        useUnreadSearch: bool,
      },
    });

    if (data?.getPhonesInfoForText === null) {
      setMsgLoading(false);
      return;
    }

    if (data?.getPhonesInfoForText) {
      subMenuRefs.current = subMenuRefs.current.slice(
        0,
        data?.getPhonesInfoForText?.length
      );
      setPhoneNumArr([...data?.getPhonesInfoForText]);

      // If selected phn has new msgs, trigger requery event
      data?.getPhonesInfoForText?.forEach((obj) => {
        if (obj._id === selectedId) {
          if (obj?.unreadMessages) {
            setQueryPhoneMsgTrigger((prev) => prev + 1);
          }
          return;
        }
      });

      setMsgLoading(false);
      return;
    }
  };

  const handleAnchorElContactOptMenu = async (index) => {
    if (index === true) {
      setAnchorEl(null);
      return;
    }

    setAnchorEl(subMenuRefs.current[index]);
  };

  const handlePhnUpdate = (newObj) => {
    setPhoneNumArr((prev) => {
      const update = prev?.map((prevObj) => {
        if (prevObj?._id === newObj._id) {
          return newObj;
        }
        return prevObj;
      });

      update?.sort((a, b) => {
        return parseInt(b?.recentTime) - parseInt(a?.recentTime);
      });

      return [...update];
    });
  };

  return (
    <div className="text-msgs-page" style={{ height: `${totalHeight}px` }}>
      {msgLoading && <AdminBDRSpinner message={"Loading..."} />}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MsgPageContactOptions
        handleClose={handleAnchorElContactOptMenu}
        anchorEl={anchorEl}
        handleSnack={handleSnack}
        selectedId={selectedId}
        handleUpdate={handlePhnUpdate}
        handleNewClientDisplay={handleNewClientDisplay}
      />
      <div className="text-msgs-container">
        {createNewClientDisplay ? (
          <CreateClient
            setOpenDisplay={handleNewClientDisplay}
            handleSnack={handleSnack}
            dropdown={false}
            passedPhnObj={createClientPhnObj}
            queryMessages={queryStrippedPhoneInfo}
          />
        ) : (
          <div className="text-msgs-numbers-box">
            {refreshCounter >= 20 && (
              <div className="text-msgs-refresh-box">
                Message Fetching Paused...
                <button
                  className="text-msgs-img-btn"
                  onClick={() => refreshReset(true)}
                >
                  <RefreshIcon titleAccess="Refresh Messages" />
                </button>
              </div>
            )}
            {phoneNumArr?.length > 0 ? (
              <>
                {phoneNumArr?.map((num, index) => {
                  // title: Taylor Turner-64e294418636a37efcd7daaa
                  const name = num?.title ? num?.title?.split("-")[0] : null;
                  const clientId = num?.title
                    ? num?.title?.split("-")[1]
                    : null;
                  const unread = num?.unreadMessages === true ? true : false;
                  return (
                    <div
                      key={`text-msgs-number-item-${index}`}
                      className={
                        selectedId === num?._id
                          ? "text-msgs-numbers-item text-item-selected"
                          : "text-msgs-numbers-item"
                      }
                      onClick={() => {
                        setSelectedId(num?._id);
                        refreshReset(false);
                      }}
                    >
                      {name && (
                        <div className="text-msgs-item-name-opt-container">
                          <div className="text-msgs-item-has-name-box">
                            <div
                              className={
                                unread
                                  ? "text-msgs-item-title text-title-strong"
                                  : "text-msgs-item-title"
                              }
                            >
                              {name}
                            </div>
                            <div className="text-msgs-item-num">
                              {num?.phoneNumber}
                            </div>
                          </div>
                          <div>
                            <button
                              className="text-msgs-btn text-view-client-btn"
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                navClient(clientId);
                              }}
                            >
                              View Client
                            </button>
                            <IconButton
                              ref={(el) => (subMenuRefs.current[index] = el)}
                              size="small"
                              aria-label="page menu"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() => {
                                handleAnchorElContactOptMenu(index);
                              }}
                              color="inherit"
                            >
                              <MenuIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                      {!name && (
                        <div className="text-msgs-item-name-opt-container">
                          <div
                            className={
                              unread
                                ? "text-msgs-item-title text-title-strong"
                                : "text-msgs-item-title"
                            }
                          >
                            {num?.phoneNumber}
                          </div>
                          <IconButton
                            ref={(el) => (subMenuRefs.current[index] = el)}
                            size="small"
                            aria-label="page menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => {
                              handleAnchorElContactOptMenu(index);
                            }}
                            color="inherit"
                          >
                            <MenuIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <h4>No Messages To Display</h4>
            )}
          </div>
        )}
        <div className="text-msgs-selected-box">
          <MsgPageTextDisplay
            queryPhoneMsgTrigger={queryPhoneMsgTrigger}
            phoneId={selectedId}
            handleSnack={handleSnack}
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
