import React, { useEffect, useState, useRef } from "react";

import "./adminShopInventory.css";
import ViewShopInventory from "../../adminComponents/ViewAdjustInventory/ViewShopInventory";
import AddInvItem from "../../adminComponents/AdminAddInvItem/AdminAddInvItem";

const AdminShopInventory = () => {
  const [totalMinHeight, setTotalMinHeight] = useState(2000);
  const [pageHeightTrigger, setPageHeightTrigger] = useState(0);
  const ShopInventoryPage = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (pageHeightTrigger > 0) {
      handlePageHeight()
    }
  }, [pageHeightTrigger]);
  
  const heightTrigger = () => {
    setPageHeightTrigger(prev => prev + 1)
  };

  const handlePageHeight = () => {
    const shopInvHeight = document.querySelector(
      "#view-shop-inv-height-marker"
    )?.offsetHeight;
    const navHeight = document.querySelector(
      "#admin-nav-height-marker"
    )?.offsetHeight;
    const headerHeight = document.querySelector(
      "#shop-inv-page-header"
    )?.offsetHeight;

    const totalHeight = shopInvHeight + navHeight + headerHeight + window.innerHeight;
    setTotalMinHeight(totalHeight)
  };

  return (
    <div ref={ShopInventoryPage} style={{minHeight: `${totalMinHeight}px`}} className="admin-container">
      <ViewShopInventory heightTrigger={heightTrigger}/>
      <AddInvItem heightTrigger={heightTrigger}/>
    </div>
  );
};

export default AdminShopInventory;
