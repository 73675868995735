import { gql } from "@apollo/client";

export const QUERY_USER = gql`
  query user($username: String!) {
    userByUsername(username: $username) {
      _id
      username
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query currUser {
    getCurrentUser {
      _id
      firstName
      lastName
      email
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
        recentTime
        textMessages {
          body
          sentReceivedTime
          outgoingBool
          twilioSID
          attachedImgs
        }
        unreadMessages
        hasTexts
      }
      permission
      initLogin
      failedLogins
      streetAddress
      city
      state
      zipCode
      refinReqInv {
        reqItemId
        reqItemCount
      }
      active
    }
  }
`;

export const QUERY_REVIEWS = gql`
  query getReviews {
    getReviews {
      _id
      customerName
      starRating
      review
    }
  }
`;

export const QUERY_CLIENT_PHOTOS = gql`
  query GetImageById($pictureIdArr: [String]) {
    getImageById(pictureIdArr: $pictureIdArr) {
      _id
      beforeOrAfter
      imageData
      dateEntered
    }
  }
`;

export const QUERY_RECENT_BA_GROUPS = gql`
  query GetRecentBAGs(
    $localTimeEpoch: String
    $page: String
    $count: String
    $useRecents: Boolean
  ) {
    getRecentBAGs(
      localTimeEpoch: $localTimeEpoch
      page: $page
      count: $count
      useRecents: $useRecents
    ) {
      pageNum
      nextPage
      useRecents
      count
      BAGData {
        _id
        beforeId {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        afterId {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        weekGalleryDisplay
        dateEntered
        refinishers
        stainColor
      }
    }
  }
`;

export const QUERY_MORE_BA_GROUPS = gql`
  query GetMoreDoors($localTimeEpoch: String) {
    getMoreDoors(localTimeEpoch: $localTimeEpoch) {
      _id
      beforeId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      afterId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      weekGalleryDisplay
      dateEntered
      refinishers
      stainColor
    }
  }
`;

export const QUERY_FINEST_JOBS = gql`
  query getFinestJobs {
    getFinestJobs {
      _id
      BAGroupIds {
        _id
        beforeId {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        afterId {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        weekGalleryDisplay
        dateEntered
        refinishers
        stainColor
      }
    }
  }
`;

export const GET_ONE_BAG = gql`
  query getBAGById($selectedId: String!) {
    getBAGById(selectedId: $selectedId) {
      _id
      beforeId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      afterId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      weekGalleryDisplay
      dateEntered
      refinishers
      stainColor
    }
  }
`;

export const GET_SHOP_INVENTORY = gql`
  query GetShopInventory {
    getInventory {
      id
      itemName
      currentQuantity
      maxQuantity
      price
    }
  }
`;

export const GET_INC_EMAILS = gql`
  query GetIncEmails(
    $getArchived: [Boolean]
    $page: String
    $limit: String
    $searching: Boolean
  ) {
    getIncEmails(
      getArchived: $getArchived
      page: $page
      limit: $limit
      searching: $searching
    ) {
      emails {
        attachments
        dateCreated
        emailSubject
        flag
        emailRead
        from
        id
        to
        chainedEmailIds
      }
      totalDoc
    }
  }
`;

export const GET_CLIENT_EMAILS = gql`
  query getClientEmails($clientEmail: String!) {
    getClientEmails(clientEmail: $clientEmail) {
      attachments
      dateCreated
      emailSubject
      flag
      emailRead
      from
      id
      to
      chainedEmailIds
    }
  }
`;

export const GET_EMAIL_BY_ID = gql`
  query Query($emailId: String!, $getMore: Boolean!) {
    getEmailById(emailId: $emailId, getMore: $getMore) {
      currentEmail {
        id
        emailText
        html
        emailSubject
        flag
        dateCreated
        from
        to
        attachments
        emailRead
        attachImgs {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        attachPdf {
          id
          fileData
          fileName
          dateEntered
        }
      }
      emailChain {
        id
        emailText
        html
        emailSubject
        flag
        dateCreated
        from
        to
        attachments
        emailRead
        attachImgs {
          _id
          imageData
          beforeOrAfter
          dateEntered
        }
        attachPdf {
          id
          fileData
          fileName
          dateEntered
        }
      }
      moreInChain
      newerId
    }
  }
`;

export const GET_ALL_USERS = gql`
  query Query {
    getAllUsers {
      _id
      firstName
      lastName
      email
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
        recentTime
        textMessages {
          body
          sentReceivedTime
          outgoingBool
          twilioSID
          attachedImgs
        }
        unreadMessages
        hasTexts
      }
      permission
      initLogin
      failedLogins
      streetAddress
      city
      state
      zipCode
      refinReqInv {
        reqItemId
        reqItemCount
      }
      active
    }
  }
`;

export const GET_VISITS = gql`
  query GetVisits($current: String!, $timeLimit: String!) {
    getVisits(current: $current, timeLimit: $timeLimit) {
      uniqueVisits
      home
      about
      contact
      ourDoors
      quote
      conversions
      signs
    }
  }
`;

export const GET_SIGN_ASSIGNMENT = gql`
  query GetSignAssign {
    getSignAssign {
      id
      signName
      signNum
    }
  }
`;

export const GET_RECENT_CLIENTS = gql`
  query GetRecentClients {
    getRecentClients {
      _id
      clientIds {
        _id
        firstName
        lastName
        clientStatus
      }
    }
  }
`;

export const GET_CLIENT_BY_ID = gql`
  query GetClientById($clientId: String!) {
    getClientById(clientId: $clientId) {
      _id
      firstName
      lastName
      spouseName
      phoneNumberIds {
        _id
        phoneNumber
        numberType
        title
        recentTime
        textMessages {
          body
          sentReceivedTime
          outgoingBool
          twilioSID
          attachedImgs
        }
        unreadMessages
        hasTexts
      }
      primaryEmail
      secondaryEmail
      streetAddress
      city
      state
      zipCode
      gateCode
      clientNotes {
        _id
        note
        dateCreated
        dateUpdated
        updatedBy
        createdBy
      }
      clientStatus
      quotes {
        id
        quoteStatus
        quoteAccepted
        quoteType
        quoteViewed {
          timestamp
          isUser
        }
        dateCreated
        createdBy
        quoteItems {
          itemName
          price
          quantity
          discountApplicable
        }
        quoteTotal
        subtotal
        customerName
        quoteNum
        expirationDate
        discountPercent
        quoteNote
        customerId
      }
      invoices {
        id
        invoicePaid
        jobScheduledFor
        relatedTaskId
        dateCreated
        createdBy
        customerId
        invoiceNum
        quoteId
        invoiceNote
        paidBy
        invoiceItems {
          itemName
          price
          quantity
          discountApplicable
        }
        invoiceTotal
        subtotal
        discountPercent
        customerName
      }
      clientTasks {
        id
        assigneeName
        assigneeId
        taskType
        note
        associatedClientId
        associatedClientName
        active
        dateCreated
        dueDate
      }
      keapClientId
      keapClientMergeErrors
      clientPhotoIds
    }
  }
`;

export const GET_BARE_SENT_EMAILS = gql`
  query GetSentEmails {
    getBareSentEmails {
      id
      attachments
      dateCreated
      emailSubject
      from
      to
    }
  }
`;

export const GET_SENT_EMAIL_BY_ID = gql`
  query GetSentEmailById($sentId: String!) {
    getSentEmailById(sentId: $sentId) {
      id
      emailHTML
      emailSubject
      dateCreated
      from
      to
      attachments
      attachImgs {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      attachPdf {
        id
        fileData
        fileName
        dateEntered
      }
    }
  }
`;

export const GET_UNSUBS = gql`
  query GetUnsubs {
    getUnsubs {
      id
      contact
    }
  }
`;

export const IS_UNSUB = gql`
  query Query($contact: String!) {
    isUnsub(contact: $contact)
  }
`;

export const GET_SERVICE_ITEMS = gql`
  query GetServicesItems {
    getServicesItems {
      id
      itemName
      discountApplicable
      price
      jobType
    }
  }
`;

export const GET_BLOCKED_EMAILS = gql`
  query GetBlockedEmails {
    getBlockedEmails {
      id
      email
      emailsReceived
    }
  }
`;

export const GET_RECENT_CREATED_BAGS = gql`
  query GetRecentCreatedBAGs {
    getRecentCreatedBAGs {
      _id
      beforeId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      afterId {
        _id
        imageData
        beforeOrAfter
        dateEntered
      }
      weekGalleryDisplay
      dateEntered
      refinishers
      stainColor
    }
  }
`;

export const GET_QUOTE_COUNT = gql`
  query getQuoteCount {
    getQuoteCount
  }
`;

export const GET_OUTWARD_CLIENT_QUOTE = gql`
  query getCustomerOutwardQuote(
    $quoteId: String!
    $localEpoch: String!
    $isUser: Boolean!
  ) {
    getCustomerOutwardQuote(
      quoteId: $quoteId
      localEpoch: $localEpoch
      isUser: $isUser
    ) {
      dateCreated
      quoteItems {
        itemName
        price
        quantity
        discountApplicable
      }
      quoteTotal
      subtotal
      customerName
      quoteNum
      expirationDate
      discountPercent
      quoteNote
      quoteAccepted
      quoteStatus
    }
  }
`;

export const CLIENT_SEARCH = gql`
  query SearchClients($searchParam: String) {
    searchClients(searchParam: $searchParam) {
      _id
      firstName
      lastName
      primaryEmail
      phoneNumberIds {
        _id
        phoneNumber
      }
    }
  }
`;

export const GET_QUOTE_HISTORY = gql`
  query GetCustomerQuoteHistory($quoteId: String!) {
    getCustomerQuoteHistory(quoteId: $quoteId) {
      timestamp
      isUser
    }
  }
`;

export const GET_USER_WEB_AUTHN_IDS = gql`
  query Query {
    getUserWebAuthnIds
  }
`;

export const GET_USER_INFO_FOR_TASKS = gql`
  query GetUsersNamesForTasks {
    getUsersNamesForTasks {
      _id
      firstName
      lastName
    }
  }
`;

export const GET_TASK_BY_ID = gql`
  query GetTaskById($taskId: String!) {
    getTaskById(taskId: $taskId) {
      id
      assigneeName
      assigneeId
      taskType
      note
      associatedClientId
      associatedClientName
      active
      dateCreated
      dueDate
    }
  }
`;

export const GET_TASK_FOR_USERS = gql`
  query GetTasksForUser($sort: String) {
    getTasksForUser(sort: $sort) {
      id
      assigneeName
      assigneeId
      taskType
      note
      associatedClientId
      associatedClientName
      active
      dateCreated
      dueDate
    }
  }
`;

export const GET_OUTWARD_CLIENT_INVOICE = gql`
  query GetClientOutwardInvoice($invoiceId: String!) {
    getClientOutwardInvoice(invoiceId: $invoiceId) {
      id
      jobScheduledFor
      invoiceNote
      invoiceNum
      invoiceItems {
        itemName
        price
        quantity
        discountApplicable
      }
      invoiceTotal
      subtotal
      discountPercent
      customerName
      invoicePaid
    }
  }
`;

export const GET_PHONE_INFO_FOR_TEXTS = gql`
  query getPhonesInfoForText($useUnreadSearch: Boolean) {
    getPhonesInfoForText(useUnreadSearch: $useUnreadSearch) {
      _id
      phoneNumber
      title
      unreadMessages
      recentTime
    }
  }
`;

export const GET_PHONE_TEXTS_BY_ID = gql`
  query GetPhoneNumTextsById(
    $phoneId: String!
    $localEpoch: String!
    $dontUpdate: Boolean
  ) {
    getPhoneNumTextsById(
      phoneId: $phoneId
      localEpoch: $localEpoch
      dontUpdate: $dontUpdate
    ) {
      _id
      phoneNumber
      numberType
      title
      recentTime
      textMessages {
        body
        sentReceivedTime
        outgoingBool
        twilioSID
        attachedImgs
      }
      unreadMessages
      hasTexts
    }
  }
`;

export const SINGLE_CLIENT_MSGS_CHECK = gql`
  query Query($phnIdArr: [String]) {
    checkSingleClientNewMsgs(phnIdArr: $phnIdArr)
  }
`;

export const GET_STRIPE_TRANSACTIONS = gql`
  query GetStripeTransactions {
    getStripeTransactions {
      id
      stripePIId
      amount
      tip
      dateCreated
    }
  }
`;

export const GET_KEY_SHORTCUTS = gql`
  query GetKeyShortcuts {
    getKeyShortcuts {
      id
      title
      text
    }
  }
`;
