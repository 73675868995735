class FormTest {
  constructor(formState) {
    this.name = formState.nameInput;
    this.email = formState.emailInput;
    this.phoneNumber = formState.phoneNumberInput;
    this.numberType = formState.numberType;
    this.heardABoutUs = formState.heardAboutUs;
    this.otherInput = formState.otherInput;
  }

  async testName() {
    return this.name === "" ? false : true;
  }

  async testEmail() {
    const emailRegex = /.+@.+\..+/;
    return emailRegex.test(this.email);
  }

  async testPhone() {
    if (this.phoneNumber > 0 && this.phoneNumber < 12) {
        return false;
    }

    const phoneRegex = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return phoneRegex.test(this.phoneNumber);
  }
  
  async testNumberType() {
    if (this.numberType?.length <= 0 || this.numberType === "") {
        return false;
    }

    return true;
  }

  async testOther() {
    if (this.heardABoutUs === "Other") {
      return this.otherInput === "Other" || this.otherInput === ""
        ? false
        : true;
    }

    return true;
  }

  async formValid() {
    if (
      (await this.testName()) &&
      (await this.testEmail()) &&
      (await this.testPhone()) &&
      (await this.testOther()) &&
      (await this.testNumberType()) 
    ) {
      return true;
    }

    return false;
  }
}

export default FormTest;
