import React, { useState, useRef, useEffect } from "react";
import "./clientInvoicesDisplay.css";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import copyStringToClipboard from "../../../../utils/formatters/copyStringToClipboard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";

const ClientInvoicesDisplay = ({
  heightTrigger,
  invoicesArr,
  openInvoiceModal,
  barCollapsed,
  setBarCollapsed,
  handleSnack,
  openEditInvoiceQuoteModal,
}) => {
  const [hideOver, setHideOver] = useState(true);
  const [collapseTrigger, setCollapseTrigger] = useState(null);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const clientInvoicesDiv = useRef();

  useEffect(() => {
    if (barCollapsed === true) {
      setHideOver(true);
    }

    if (barCollapsed === false) {
      setTimeout(() => {
        setHideOver(false);
      }, 800);
    }
  }, [barCollapsed]);

  useEffect(() => {
    if (collapseTrigger !== null) {
      if (heightTrigger) {
        heightTrigger();
      }

      const id = setTimeout(() => {
        if (smaller900 === true && barCollapsed === true) {
          const navHeight = document.querySelector(
            "#admin-nav-height-marker"
          )?.offsetHeight;
          const pagePos = clientInvoicesDiv.current.offsetTop;

          const totalMobileScroll = pagePos - navHeight - 5;
          window.scrollTo(0, totalMobileScroll);
        }

        // if (smaller900 === false && barCollapsed === true) {
        //   clientInvoicesDiv.current.scrollIntoView({ block: "start" });
        // }
      }, 500);

      setBarCollapsed(collapseTrigger);

      return () => clearTimeout(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseTrigger]);

  const openBarCollapse = () => {
    setCollapseTrigger((prev) => {
      if (prev === null) {
        return false;
      }

      return !prev;
    });
  };

  return (
    <div
      id="client-invoices-height-marker"
      className="client-invoices-container"
      ref={clientInvoicesDiv}
    >
      <button
        onClick={openBarCollapse}
        className="header-buttons-collapse-sticky"
      >
        <div>Invoices</div>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div
        className={
          barCollapsed
            ? "content-hide"
            : hideOver
            ? "content-show-small"
            : "content-show-small show-over"
        }
      >
        {invoicesArr?.length > 0 ? (
          <div className="client-invoices-info-display">
            <ul>
              {invoicesArr?.map((invoice, index) => {
                return (
                  <li key={`invoice-item-${invoice?.id}-${index}`}>
                    <div
                      className="client-invoices-item-box"
                      onClick={() => {
                        openInvoiceModal(invoice?.id);
                      }}
                    >
                      <div className="invoice-item-info-box">
                        {invoice?.invoicePaid ? (
                          <div className="client-invoices-paid-icon-box">
                            <span className={`client-invoices-paid-icon`}>
                              Paid
                            </span>
                          </div>
                        ) : (
                          <div className="client-invoices-paid-icon-box">
                            <span className={`client-invoices-pending-icon`}>
                              Pending...
                            </span>
                          </div>
                        )}
                        {/* <span className={`client-invoices-circle`} /> */}
                        <span>
                          {`${invoice?.invoiceNum} - ${BAGroupTimeFormat(
                            invoice?.jobScheduledFor,
                            false,
                            true,
                            true
                          )}`}
                        </span>
                      </div>
                      <div className="invoice-item-actions-box">
                        {navigator.clipboard && (
                          <button
                            className="client-invoices-quotes-copy-btn "
                            onClick={async (event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              const host = window.location.host;
                              const domain = /localhost/gi.test(host) ? host : "https://bigginsdoorrefinishing.com"
                              const address = `${domain}/client/view-invoice/${invoice?.id}`;
                              copyStringToClipboard(
                                address,
                                handleSnack("Invoice Link Copied", 2500)
                              );
                            }}
                          >
                            <InsertLinkIcon
                              sx={{ fontSize: "18px" }}
                              titleAccess="Copy Invoice Link"
                            />
                          </button>
                        )}
                        <button
                          className="client-invoices-quotes-copy-btn"
                          onClick={async (event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            openEditInvoiceQuoteModal(true, invoice?.id);
                          }}
                        >
                          <EditIcon
                            sx={{ fontSize: "18px" }}
                            titleAccess="Edit Invoice"
                          />
                        </button>
                        {navigator.clipboard && (
                          <button
                            className="client-invoices-quotes-copy-btn"
                            onClick={async (event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              const host = window.location.host;
                              const domain = /localhost/gi.test(host) ? host : "https://bigginsdoorrefinishing.com"
                              const address = `${domain}/login-portal/payment/${invoice?.id}`;
                              copyStringToClipboard(
                                address,
                                handleSnack("Payment Link Copied", 2500)
                              );
                            }}
                          >
                            <AttachMoneyIcon
                              sx={{ fontSize: "18px" }}
                              titleAccess="Copy Payment Link"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="client-invoices-none-display">
            No Invoices to Display
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientInvoicesDisplay;
