import React, { useEffect, useRef, useState } from "react";
import { SEND_EMAIL_TEMPLATE } from "../../../../utils/GraphQL/mutations";
import { IS_UNSUB } from "../../../../utils/GraphQL/queries";
import "./sendEmailModal.css";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import AdminRadioForm from "../../adminComponents/AdminRadioForm/AdminRadioForm";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Auth from "../../../../utils/auth";
import SendEmailValidator from "../../../../utils/formValidators/sendEmailFormValidator";
import { useMutation, useLazyQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import EmailPreview from "../EmailPreview/EmailPreview";
import OfficeShortcuts from "../OfficeShortcuts/OfficeShortcuts";

const blankEmailFormTemp = {
  to: "",
  from: "",
  subject: "",
  text: "",
  type: "blank",
  attachArr: [],
  quoteLink: [],
  invoiceLink: [],
  toFirstName: "",
  receivedPhoto: false,
};

const SendEmailModal = ({ changeParent, sendTo, templateObj, handleSnack }) => {
  const mediaOver900 = useMediaQuery("(min-width: 900px)");
  const [sendEmailMutation] = useMutation(SEND_EMAIL_TEMPLATE);
  const [unsubCheck] = useLazyQuery(IS_UNSUB);
  const [openSend, setOpenSend] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [recipientUnsub, setRecipientUnsub] = useState(false);
  const [attachUpload, setAttachUpload] = useState(false);
  const [secondQuote, setSecondQuote] = useState(false);
  const [secondQuoteLink, setSecondQuoteLink] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [attachNames, setAttachNames] = useState([]);
  const [errorsArr, setErrorsArr] = useState([]);
  const messageInput = useRef();
  const [emailForm, setEmailForm] = useState({
    ...blankEmailFormTemp,
  });
  const [cursor, setCursor] = useState(null);
  const toInputRef = useRef();
  const firstNameRef = useRef();

  useEffect(() => {
    const input = toInputRef.current;

    if (input?.setSelectionRange) {
      input?.setSelectionRange(cursor, cursor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toInputRef, emailForm?.to]);

  useEffect(() => {
    const input = firstNameRef.current;

    if (input?.setSelectionRange) {
      input?.setSelectionRange(cursor, cursor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstNameRef, emailForm?.toFirstName]);

  // Form Validator
  const emailValidate = new SendEmailValidator(emailForm);

  useEffect(() => {
    const body = document.querySelector("body");

    if (openSend === true) {
      body.style.overflow = "hidden";
    }
    if (openSend === false) {
      body.style.overflow = "auto";
    }

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSend]);

  useEffect(() => {
    setEmailForm((prev) => ({ ...prev, attachArr: attachments }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    if (sendTo) {
      handlePassedSendTo(sendTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendTo]);

  useEffect(() => {
    if (templateObj) {
      setEmailForm((prev) => ({ ...prev, ...templateObj }));

      if (templateObj?.type) {
        handleTemplateSelect(templateObj?.type);
      }

      if (templateObj?.to) {
        handlePassedSendTo(templateObj?.to);
      }

      if (templateObj?.attachArr?.length > 0) {
        setAttachments([...templateObj?.attachArr]);
        setAttachNames(["BDR-Invoice.pdf"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateObj]);

  const getUser = async () => {
    const user = await Auth.getProfile();
    if (user?.data.firstName) {
      const upperName = capitalizeFirstLetters(user?.data.firstName);
      const lowerName = user?.data.firstName?.toLowerCase();

      setEmailForm((prev) => ({
        ...prev,
        from: `${upperName} - BDR Services <${lowerName}@bigginsdoorrefinishing.com>`,
      }));
    }
    return;
  };

  const handleUnsubCheck = async () => {
    const { data } = await unsubCheck({
      variables: {
        contact: emailForm?.to,
      },
    });

    if (typeof data?.isUnsub === "boolean") {
      setRecipientUnsub(data?.isUnsub);
    }
  };

  const closeEmail = () => {
    setEmailForm({
      ...blankEmailFormTemp,
    });
    setOpenSend(false);
    if (changeParent) {
      changeParent(false);
    }
  };

  const handlePassedSendTo = (recipient) => {
    const emailToSendTo = recipient?.match(/<(.*?)>/)?.[1] || recipient;
    const emailRegex = /.+@.+\..+/;

    if (emailToSendTo && emailRegex.test(emailToSendTo)) {
      const email = emailToSendTo.toLowerCase();

      setEmailForm((prev) => ({ ...prev, to: email }));
    }
  };

  const handleTemplateSelect = (value) => {
    // , subject: "Door Refinishing Quote"
    if (value === "quoteTemplate") {
      setEmailForm((prev) => ({
        ...prev,
        type: value,
        subject: "Door Refinishing Quote",
        text: `Here is the quote for refinishing that we've discussed, please let me know if you have any questions!`,
      }));

      return;
    }

    if (value === "invoiceTemplate") {
      setEmailForm((prev) => ({
        ...prev,
        type: value,
        subject: "Door Refinishing Invoice",
        text: `Here is the invoice for the work that we've scheduled. If you have any questions, please feel free to contact us!`,
      }));

      return;
    }

    if (value === "receiptTemplate") {
      setEmailForm((prev) => ({
        ...prev,
        type: value,
        subject: "Door Service Receipt",
        text: "Here is your paid invoice/receipt for your completed door service. We appreciate your business.\n\nHave a wonderful day! ",
      }));

      return;
    }

    if (value === "blank") {
      setEmailForm((prev) => ({
        ...prev,
        type: value,
        subject: "",
        text: "",
      }));

      return;
    }

    setEmailForm((prev) => ({ ...prev, type: value }));
  };

  const clearAttachments = () => {
    setAttachments([]);
    setAttachNames([]);
  };

  const uploadAttachments = async (event) => {
    clearAttachments();
    const files = event.target.files;
    const count = files?.length;

    if (files?.length === 0) {
      setAttachUpload(false);
      if (handleSnack) {
        handleSnack(`No files selected`);
      }
      return;
    }

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

    for await (const file of files) {
      setAttachNames((prev) => [...prev, file?.name]);
      const base64 = await toBase64(file);
      setAttachments((prev) => [...prev, base64]);
    }

    setAttachUpload(false);
    if (handleSnack) {
      handleSnack(`Attached ${count} ${count > 1 ? "files" : "file"}`);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (event.target.selectionStart) {
      setCursor(event.target.selectionStart);
    } else {
      setCursor(0);
    }

    if (name === "receivedPhoto") {
      const bool = value === "Yes" ? true : false;

      setEmailForm((prev) => {
        return { ...prev, [name]: bool };
      });

      return;
    }

    if (name === "to") {
      value = value.toLowerCase();

      setEmailForm((prev) => {
        return { ...prev, [name]: value };
      });

      return;
    }

    if (name === "quoteLink") {
      setEmailForm((prev) => {
        return { ...prev, [name]: [value] };
      });

      return;
    }

    if (name === "toFirstName") {
      value = capitalizeFirstLetters(value);

      setEmailForm((prev) => {
        return { ...prev, [name]: value };
      });

      return;
    }

    if (name === "text") {
      messageInput.current?.scrollIntoView();
    }

    setEmailForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const clearErrors = () => {
    setErrorsArr([]);
  };

  const handleSecondQuote = (event) => {
    let value = event.target.value;
    value = value.toLowerCase();

    setSecondQuoteLink(value);
  };

  const sendEmail = async () => {
    try {
      const valid = await emailValidate.emailMesValid();

      let links = [...emailForm?.quoteLink] || [];
      if (secondQuote) {
        links = [...emailForm?.quoteLink, secondQuoteLink];
      }

      if (valid === true) {
        const { data } = await sendEmailMutation({
          variables: {
            ...emailForm,
            quoteLink: links,
          },
        });

        if (data?.sendEmailTemp === true) {
          if (handleSnack) {
            handleSnack(`Email sent to ${emailForm.to}`, 3000);
          }
          clearAttachments();
          setEmailForm({
            ...blankEmailFormTemp,
          });
          setSecondQuoteLink("");
        }

        if (data?.sendEmailTemp === false) {
          if (handleSnack) {
            handleSnack(`Something went wrong. Please try again.`, 3000);
          }
        }

        setEmailSending(false);
        return;
      }

      setErrorsArr(valid);
      setEmailSending(false);
      return;
    } catch (error) {
      console.log(error?.message || error);
      setEmailSending(false);
      if (handleSnack) {
        handleSnack(`Something went wrong. Please try again.`, 3000);
      }
    }
  };

  return (
    <>
      <div
        className="send-email-modal-container"
        // bg click closes modal
        // onClick={() => {
        //   closeEmail();
        // }}
      >
        {attachUpload && <AdminBDRSpinner message={"Uploading..."} />}
        {emailSending && <AdminBDRSpinner message={"Sending..."} />}
        <div
          className="send-email-modal-content"
          style={{
            width: !mediaOver900 ? "95%" : "65%",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="send-email-input-box">
            <button className="send-email-modal-close" onClick={closeEmail}>
              <div className="send-email-modal-close-x">X</div>
            </button>
            <div className="send-email-header-temp-box">
              <h1 className="send-email-modal-header">Compose Email</h1>
              <FormControl
                style={{ alignSelf: "flex-end" }}
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  id="time-select"
                  sx={{
                    color: "#05F842",
                    "&.Mui-focused": { color: "#05F842" },
                  }}
                >
                  Email Template
                </InputLabel>
                <Select
                  labelId="email-temp-select"
                  id="email-temp-select-input"
                  value={emailForm?.type}
                  onChange={(event) => handleTemplateSelect(event.target.value)}
                  label="Please Select Email Template"
                  disableUnderline={true}
                  sx={{ color: "#fff" }}
                  style={{ borderBottom: "1px solid #fff" }}
                >
                  <MenuItem value={"blank"}>None</MenuItem>
                  <MenuItem value={"quoteTemplate"}>Quote</MenuItem>
                  <MenuItem value={"invoiceTemplate"}>Invoice</MenuItem>
                  <MenuItem value={"receiptTemplate"}>Receipt</MenuItem>
                </Select>
              </FormControl>
            </div>
            {(errorsArr ||
              recipientUnsub ||
              /@yahoo.com$/gi.test(emailForm?.to)) && (
              <ul className="send-email-error-ul">
                {errorsArr?.map((err, index) => {
                  return <li key={err + index}>{err}</li>;
                })}
                {recipientUnsub && (
                  <li>
                    The Email Recipient has requested to be unsubscribed. Please
                    use another form of contact.
                  </li>
                )}
                {/@yahoo.com$/gi.test(emailForm?.to) && (
                  <li>
                    Please give warning of email going to Yahoo spam inbox
                  </li>
                )}
              </ul>
            )}
            <CssTextFieldStandard
              sx={{ width: "90%", my: 1 }}
              inputRef={toInputRef}
              type="text"
              label={`To:`}
              name="to"
              inputMode="text"
              value={emailForm.to}
              onFocus={clearErrors}
              onChange={handleChange}
              onBlur={handleUnsubCheck}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "90%", my: 1 }}
              type="text"
              label={`From:`}
              name="from"
              inputMode="text"
              onFocus={clearErrors}
              value={emailForm.from}
              onChange={handleChange}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "90%", my: 1 }}
              type="text"
              label={`Subject:`}
              name="subject"
              inputMode="text"
              onFocus={clearErrors}
              value={emailForm.subject}
              onChange={handleChange}
              variant="standard"
            />
            {/* Quote template handling */}
            {emailForm?.type === "quoteTemplate" && (
              <>
                <div className="send-email-quote-link-box">
                  <div className="send-email-quote-link-field">
                    <CssTextFieldStandard
                      sx={{ width: "100%", my: 1 }}
                      ref={messageInput}
                      label="Quote Link (Required)"
                      name="quoteLink"
                      onFocus={clearErrors}
                      value={emailForm.quoteLink}
                      onChange={handleChange}
                      variant="standard"
                    />
                  </div>
                  <button
                    className="send-email-add-quote-btn"
                    onClick={() => {
                      setSecondQuote((prev) => !prev);
                    }}
                  >
                    {secondQuote
                      ? "Remove Additional Quote"
                      : "Add Another Quote"}
                  </button>
                </div>
                {secondQuote && (
                  <div className="send-email-quote-link-box">
                    <div className="send-email-quote-link-field">
                      <CssTextFieldStandard
                        sx={{ width: "100%", my: 1 }}
                        ref={messageInput}
                        label="Additional Quote Link (Required)"
                        name="secondQuoteLink"
                        onFocus={clearErrors}
                        value={secondQuoteLink}
                        onChange={handleSecondQuote}
                        variant="standard"
                      />
                    </div>
                    <button
                      className="send-email-add-quote-btn"
                      onClick={() => {
                        setSecondQuote((prev) => !prev);
                      }}
                    >
                      {secondQuote
                        ? "Remove Additional Quote"
                        : "Add Another Quote"}
                    </button>
                  </div>
                )}
                <CssTextFieldStandard
                  sx={{ width: "90%", my: 1 }}
                  ref={messageInput}
                  inputRef={firstNameRef}
                  label="Client's First Name"
                  name="toFirstName"
                  onFocus={clearErrors}
                  value={emailForm.toFirstName}
                  onChange={handleChange}
                  variant="standard"
                />
                <AdminRadioForm
                  choiceStateFunction={handleChange}
                  name="receivedPhoto"
                  question={"Have we received a photo of the customer's door?"}
                  formId={"received-photo-radio"}
                  choiceDefault={"No"}
                  choices={["Yes", "No"]}
                />
              </>
            )}

            {/* Invoice template handling */}
            {emailForm?.type === "invoiceTemplate" && (
              <>
                <div className="send-email-quote-link-box">
                  {/* <div className="send-email-quote-link-field"> */}
                  <CssTextFieldStandard
                    sx={{ width: "100%", my: 1 }}
                    ref={messageInput}
                    label="Invoice Link (Required)"
                    name="invoiceLink"
                    onFocus={clearErrors}
                    value={emailForm.invoiceLink}
                    onChange={handleChange}
                    variant="standard"
                  />
                  {/* </div> */}
                </div>
                <CssTextFieldStandard
                  sx={{ width: "90%", my: 1 }}
                  ref={messageInput}
                  label="Client's First Name"
                  name="toFirstName"
                  onFocus={clearErrors}
                  value={emailForm.toFirstName}
                  onChange={handleChange}
                  variant="standard"
                />
              </>
            )}
            {/* Receipt template handling */}
            {emailForm?.type === "receiptTemplate" && (
              <>
                <div className="send-email-quote-link-box">
                  {/* <div className="send-email-quote-link-field"> */}
                  <CssTextFieldStandard
                    sx={{ width: "100%", my: 1 }}
                    ref={messageInput}
                    label="Invoice Link (Required)"
                    name="invoiceLink"
                    onFocus={clearErrors}
                    value={emailForm.invoiceLink}
                    onChange={handleChange}
                    variant="standard"
                  />
                  {/* </div> */}
                </div>
                <CssTextFieldStandard
                  sx={{ width: "90%", my: 1 }}
                  ref={messageInput}
                  label="Client's First Name"
                  name="toFirstName"
                  onFocus={clearErrors}
                  value={emailForm.toFirstName}
                  onChange={handleChange}
                  variant="standard"
                />
              </>
            )}
            <div>
              <OfficeShortcuts handleSnack={handleSnack} />
            </div>
            <CssTextFieldStandard
              sx={{ width: "90%", my: 1 }}
              ref={messageInput}
              label="Message"
              name="text"
              onFocus={clearErrors}
              value={emailForm.text}
              onChange={handleChange}
              multiline
              minRows={5}
            />
            <div className="send-email-preview-cont send-email-less-900">
              <h4
                className="send-email-modal-header"
                style={{ marginTop: "4%" }}
              >
                Email Preview:{" "}
              </h4>
              <div
                // ref={emailPreviewBoxBig}
                className="send-email-preview-box"
              >
                <EmailPreview
                  toFirstName={emailForm?.toFirstName}
                  previewType={emailForm?.type}
                  text={emailForm?.text}
                  quoteLinks={
                    secondQuote
                      ? [...emailForm?.quoteLink, secondQuoteLink]
                      : [...emailForm?.quoteLink]
                  }
                  invoiceLinks={emailForm?.invoiceLink}
                  receivedPhoto={emailForm?.receivedPhoto}
                />
              </div>
            </div>
            <div className="send-email-options-box">
              <label className="send-email-icon-button">
                <input
                  multiple
                  onChange={(event) => {
                    setAttachUpload(true);
                    uploadAttachments(event);
                  }}
                  type="file"
                  accept="image/*,application/pdf"
                  name="emailAttachments"
                />
                <AttachFileIcon />
              </label>
              <button
                className="send-email-send-btn"
                onClick={() => {
                  setEmailSending(true);
                  sendEmail();
                }}
              >
                Send
              </button>
            </div>
            {attachNames?.length > 0 && (
              <>
                <button
                  className="send-email-modal-attach-clear"
                  onClick={() => {
                    clearAttachments();
                    if (handleSnack) {
                      handleSnack(
                        `${
                          attachNames?.length > 1 ? "Attachments" : "Attachment"
                        } Removed`
                      );
                    }
                  }}
                >
                  Clear Attached
                </button>
                <h3>Attachments: </h3>
                <span style={{ fontSize: "smaller" }}>
                  (File names will be adjusted upon sending email)
                </span>
                <ul>
                  {attachNames?.map((att, index) => {
                    return <li key={att + index}>{att}</li>;
                  })}
                </ul>
              </>
            )}
          </div>
        </div>

        <div className="send-email-preview-cont send-email-greater-900">
          <h4 className="send-email-modal-header" style={{ marginTop: "4%" }}>
            Email Preview:{" "}
          </h4>
          <div className="send-email-preview-box">
            <EmailPreview
              toFirstName={emailForm?.toFirstName}
              previewType={emailForm?.type}
              text={emailForm?.text}
              quoteLinks={
                secondQuote
                  ? [...emailForm?.quoteLink, secondQuoteLink]
                  : [...emailForm?.quoteLink]
              }
              invoiceLinks={emailForm?.invoiceLink}
              receivedPhoto={emailForm?.receivedPhoto}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SendEmailModal;
