const copyImageToClip = async (imgSrc) => {
  return new Promise((res) => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.onload = () => {
      canvas.width = 600;
      canvas.height = 800;
      ctx.drawImage(img, 0, 0, 600, 800);
      canvas.toBlob((blob) => {
        res(blob)        
      }, "image/png");
    };

    img.src = imgSrc;
  });
};

export default copyImageToClip;
