import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Snackbar } from "@mui/material";
import { GET_SERVICE_ITEMS } from "../../../../utils/GraphQL/queries";
import {
  DELETE_SERVICE_ITEM,
  UPDATE_SERVICE_ITEM,
  ADD_SERVICE_ITEM,
} from "../../../../utils/GraphQL/mutations";
import "./servicesManagement.css";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import UpdateServiceItemModal from "../../adminComponents/UpdateServiceItemModal/UpdateServiceItemModal";

const blankItem = {
  itemName: "",
  itemId: "",
  discountApplicable: false,
  index: "",
  price: "",
  priceStr: "",
  jobType: "Refinish",
};

const ServicesManagement = () => {
  const [servicesItems, setServicesItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [createItem, setCreateItem] = useState(false);
  const [editItemObj, setEditItemObj] = useState({ ...blankItem });
  const [servicesFetch] = useLazyQuery(GET_SERVICE_ITEMS);
  const [deleteCall] = useMutation(DELETE_SERVICE_ITEM);
  const [updateCall] = useMutation(UPDATE_SERVICE_ITEM);
  const [createItemCall] = useMutation(ADD_SERVICE_ITEM);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    servicesCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const servicesCall = async () => {
    const { data } = await servicesFetch({
      fetchPolicy: "network-only",
    });

    if (data?.getServicesItems) {
      setServicesItems([...data?.getServicesItems]);
    }
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const addItem = async (item) => {
    const { data } = await createItemCall({
      variables: {
        itemName: item?.itemName,
        discountApplicable: item?.discountApplicable,
        price: item?.price,
        jobType: item?.jobType,
      },
    });

    if (data?.addServicesItem) {
      setEditItemObj({ ...blankItem });
      handleSnack("Item Created", 2000);
      setServicesItems((prev) => [...prev, data?.addServicesItem]);

      return;
    }

    setEditItemObj({ ...blankItem });
    handleSnack("Something went wrong...Please try again", 2000);
  };

  const editItem = async (updatedObj) => {
    const { data } = await updateCall({
      variables: {
        itemId: updatedObj?.itemId,
        itemName: updatedObj?.itemName,
        discountApplicable: updatedObj?.discountApplicable,
        price: updatedObj?.price,
        jobType: updatedObj?.jobType,
      },
    });

    if (data?.updateServicesItem) {
      setEditItemObj({ ...blankItem });
      handleSnack("Item Updated", 2000);
      setServicesItems((prev) => {
        const filtered = prev?.map((item) => {
          if (item?.id !== updatedObj?.itemId) {
            return item;
          }
          return { ...data?.updateServicesItem };
        });
        return [...filtered];
      });

      return;
    }

    setEditItemObj({ ...blankItem });
    handleSnack("Something went wrong...Please try again", 2000);
  };

  const deleteItem = async (itemId) => {
    const { data } = await deleteCall({
      variables: {
        itemId,
      },
    });

    if (data?.deleteServicesItem === true) {
      handleSnack("Item Deleted", 2000);
      setServicesItems((prev) => {
        const filtered = prev?.filter((item) => item.id !== itemId);

        return [...filtered];
      });
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    setEditItemObj({ ...blankItem });
  };

  return (
    <div className="ser-man-page">
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <UpdateServiceItemModal
        close={closeModal}
        selectedItemObj={editItemObj}
        open={openModal}
        updateServiceItem={editItem}
        createItem={createItem}
        createItemFunc={addItem}
      />
      <div className="ser-man-container">
        <div className="ser-man-item-create-box">
          <button
            className="ser-man-action-img-button"
            onClick={() => {
              setCreateItem(true);
              setOpenModal(true);
            }}
          >
            <AddIcon titleAccess="Edit" />
          </button>
        </div>
        <table className="ser-man-table-box">
          <tbody>
            <tr>
              <th>Item</th>
              <th>Apply Discount</th>
              <th>Price</th>
              <th>Job Type</th>
              <th className="ser-man-item-actions">Actions</th>
            </tr>

            {servicesItems?.length > 0 &&
              servicesItems?.map((item, index) => {
                const type = item?.jobType
                  ? /refinish/gi.test(item?.jobType)
                    ? "R"
                    : "M"
                  : "N/A";

                return (
                  <tr key={item?.id}>
                    <td>{item?.itemName}</td>
                    <td>
                      {item?.discountApplicable ? (
                        <span className="ser-man-green-text">
                          <CheckIcon />
                        </span>
                      ) : (
                        <span className="ser-man-red-text">
                          <strong>X</strong>
                        </span>
                      )}
                    </td>
                    <td>{priceStrFormatter(item?.price)}</td>
                    <td>{type} </td>
                    <td className="ser-man-item-actions">
                      <button
                        className="ser-man-action-img-button"
                        onClick={() => {
                          setEditItemObj(item);
                          setCreateItem(false);
                          setOpenModal(true);
                        }}
                      >
                        <EditIcon titleAccess="Edit" />
                      </button>
                      <button
                        className="ser-man-action-img-button"
                        onClick={() => {
                          deleteItem(item?.id);
                        }}
                      >
                        <DeleteIcon titleAccess="Delete" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServicesManagement;
