import React, { useState, useRef, useEffect } from "react";
import "./clientTasksDisplay.css";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import TaskDisplayModal from "./TaskModal";

const ClientTasksDisplay = ({
  heightTrigger,
  incTasks,
  handleSnack,
  refetch,
  addUpdatedTaskToArr
}) => {
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [openSingleTaskModal, setOpenSingleTaskModal] = useState(false);
  const [tasksArr, setTasksArr] = useState([]);
  const [selectedTask, setSelectedTask] = useState("");
  const smaller900 = useMediaQuery("(max-width:900px)");
  const clientTasksDiv = useRef();

  useEffect(() => {
    if (incTasks?.length > 0) {
      const inactiveArr = [];
      const activeArr = incTasks?.filter((task) => {
        if (task?.active === true) {
          return task;
        }
        inactiveArr?.push(task);
        return null;
      });

      const activeSort = activeArr?.toSorted((a, b) => {
        return a?.dueDate > b?.dueDate ? 1 : -1;
      });
      const inactiveSort = inactiveArr?.toSorted((a, b) => {
        return a?.dueDate > b?.dueDate ? 1 : -1;
      });

      setTasksArr([...activeSort, ...inactiveSort]);
    }
  }, [incTasks]);

  const openBarCollapse = () => {
    if (heightTrigger) {
      heightTrigger();
    }

    setTimeout(() => {
      if (smaller900 === true && barCollapsed === true) {
        const navHeight = document.querySelector(
          "#admin-nav-height-marker"
        )?.offsetHeight;
        const pagePos = clientTasksDiv.current.offsetTop;

        const totalMobileScroll = pagePos - navHeight - 5;
        window.scrollTo(0, totalMobileScroll);
      }

      if (smaller900 === false && barCollapsed === true) {
        clientTasksDiv.current.scrollIntoView({ block: "start" });
      }
    }, 500);

    setBarCollapsed((prev) => !prev);
  };

  const handleTaskModal = (bool) => {
    setOpenSingleTaskModal(bool);
  };

  return (
    <div
      id="client-tasks-height-marker"
      className="client-tasks-container"
      ref={clientTasksDiv}
    >
      <TaskDisplayModal
        openModal={openSingleTaskModal}
        setOpenModal={handleTaskModal}
        handleSnack={handleSnack}
        handleUpdatedTasksArr={addUpdatedTaskToArr}
        taskId={selectedTask}
        refetch={refetch}
      />
      <button
        onClick={openBarCollapse}
        className="header-buttons-collapse-sticky"
      >
        <div>Tasks</div>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div
        className={
          barCollapsed ? "content-hide" : "content-show-small show-over"
        }
      >
        {tasksArr?.length > 0 ? (
          <div className="client-tasks-info-display">
            <ul>
              {tasksArr?.map((task, index) => {
                let bulletColor = task?.active ? "green" : "red";
                const pastDue = parseInt(task?.dueDate) < Date.now();
                const dueClass = task?.active
                  ? pastDue
                    ? "client-task-due-color-red"
                    : "client-task-due-color-green"
                  : "client-task-due-color-white";

                return (
                  <li key={`client-task-item-${task?.id}-${index}`}>
                    <button
                      className={task?.active ? "client-tasks-item-box" : "client-tasks-item-box client-tasks-item-inactive"}
                      onClick={() => {
                        setSelectedTask(task?.id);
                        handleTaskModal(true);
                      }}
                    >
                      <span className="client-tasks-item-box-item-1">
                        <span
                          className={`client-tasks-circle client-task-bullet-color-${bulletColor}`}
                        />
                        <span>
                          {task?.taskType} - {task?.assigneeName}
                        </span>
                      </span>
                      <span className={dueClass}>Due: </span>
                      <span>{`${BAGroupTimeFormat(
                        task?.dueDate,
                        false,
                        true,
                        true
                      )}`}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="client-tasks-none-display">No Tasks to Display</div>
        )}
      </div>
    </div>
  );
};

export default ClientTasksDisplay;
