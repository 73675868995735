import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./socialIcons.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const bigStyle = {
  height: "40px",
  width: "40px",
};

const littleStyle = {
  height: "30px",
  width: "30px",
};
const xSmallStyle = {
  height: "22px",
  width: "22px",
};



const SocialIcons = () => {
  const bigger900 = useMediaQuery("(min-width: 900px)");
  const smaller600 = useMediaQuery("(max-width: 600px)");

  return (
    <div className="social-icons-container">
      <a href="https://www.facebook.com/bigginsdoorrefinishing/">
        <FacebookIcon
          style={bigger900 ? bigStyle : smaller600 ? xSmallStyle : littleStyle}
        />
      </a>
      <a href="https://www.instagram.com/bigginsdoors/?hl=en">
        <InstagramIcon
          style={bigger900 ? bigStyle : smaller600 ? xSmallStyle : littleStyle}
        />
      </a>
      <a href="tel:12105897431">
        <PhoneInTalkIcon
          style={bigger900 ? bigStyle : smaller600 ? xSmallStyle : littleStyle}
        />
      </a>
      <a href="sms:12105897431">
        <MessageIcon
          style={bigger900 ? bigStyle : smaller600 ? xSmallStyle : littleStyle}
        />
      </a>
      <a href="mailto: leanne@bigginsdoorrefinishing.com">
        <MailIcon
          style={bigger900 ? bigStyle : smaller600 ? xSmallStyle : littleStyle}
        />
      </a>
    </div>
  );
};

export default SocialIcons;
