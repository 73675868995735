import React, { useState, useEffect, useRef } from "react";
import "./finestJobsController.css";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_FINEST_JOBS } from "../../../../utils/GraphQL/mutations";
import { QUERY_FINEST_JOBS } from "../../../../utils/GraphQL/queries";
import DeleteIcon from "@mui/icons-material/Delete";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import { useMediaQuery } from "@mui/material";

const FinestJobsController = ({ newGroupId, heightTrigger }) => {
  const finestControllerScrollRef = useRef();
  const [newIdState, setNewIdState] = useState("");
  const [displayIdInput, setDisplayIdInput] = useState(true);
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [finestIds, setFinestIds] = useState([]);
  const [finestData, setFinestData] = useState();
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [queryFinest, { loading: finestLoading }] = useLazyQuery(
    QUERY_FINEST_JOBS,
    { errorPolicy: "all" }
  );
  const [updateFinest, { loading: updateFinestLoading }] =
    useMutation(UPDATE_FINEST_JOBS);

  useEffect(() => {
    queryFinest({
      onCompleted: (data, error) => {
        setFinestData(data?.getFinestJobs.BAGroupIds);
        data?.getFinestJobs.BAGroupIds.forEach((group) => {
          setFinestIds((prev) => [...prev, group._id]);
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeFinestIdsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finestData]);

  useEffect(() => {
    if (newGroupId) {
      setNewIdState(newGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newGroupId]);

  //Turns input display on and off depending on length
  //of finest jobs. Only 8 allowed
  const changeFinestIdsState = () => {
    if (finestData?.length >= 8) {
      setDisplayIdInput(false);
      return;
    }

    if (finestData?.length < 8) {
      setDisplayIdInput(true);
      return;
    }
  };

  //cuts id and group from the finest array
  const handleIdRemove = async (groupId) => {
    clearFinestIds();
    const data = await Promise.resolve(async (resolve) => {
      const newState = finestIds.filter((id) => {
        const stringId = groupId.toString();
        return id !== stringId;
      });

      resolve = newState;
      return resolve;
    });

    await data().then(async (newIds) => {
      await updateFinest({
        variables: { BAGroupIdArr: newIds },
        onCompleted: async (data) => {
          if (data?.updateFinestJobs.BAGroupIds.length) {
            setFinestData([...data?.updateFinestJobs.BAGroupIds]);
            data?.updateFinestJobs.BAGroupIds.forEach((group) => {
              setFinestIds((prev) => [...prev, group._id]);
            });
          }
        },
      });
    });

    return;
  };

  const handleIdInput = async () => {
    clearFinestIds();
    await updateFinest({
      variables: { BAGroupIdArr: [...finestIds, newIdState] },
      onCompleted: async (data) => {
        if (data?.updateFinestJobs.BAGroupIds.length) {
          setFinestData([...data.updateFinestJobs.BAGroupIds]);
          data?.updateFinestJobs.BAGroupIds.forEach((group) => {
            setFinestIds((prev) => [...prev, group._id]);
          });
        }
      },
    });
  };

  const handleNewIdState = (event) => {
    setNewIdState(event.target.value);
  };
  const clearFinestIds = () => {
    setFinestIds([]);
  };

  return (
    <div
      ref={finestControllerScrollRef}
      id="admin-fj-height-marker"
      className="finest-uploaded-container"
    >
      {finestLoading ||
        (updateFinestLoading && <AdminBDRSpinner message={"Loading..."} />)}
      <button
        onClick={() => {
          setTimeout(() => {
            heightTrigger()
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = finestControllerScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              finestControllerScrollRef.current.scrollIntoView({
                block: "start",
              });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 300);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Finest Carousel Ids</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        {displayIdInput && (
          <div className="finest-id-input">
            <CssTextFieldStandard
              sx={{ width: "95%", my: 1 }}
              fontSize="8px"
              type="text"
              label={`Enter photo Id`}
              inputProps={{ inputMode: "text" }}
              name="photoId"
              value={newIdState}
              onChange={handleNewIdState}
              variant="standard"
            />
            <button
              type="submit"
              className="finest-dark-button"
              onClick={handleIdInput}
            >
              Save Id
            </button>
          </div>
        )}
        <div className="finest-ids-box">
          {finestData &&
            finestData.map((group, index) => {
              return (
                <div
                  key={group?._id + index}
                  className="finest-image-container"
                >
                  <div className="finest-image-box">
                    <img
                      className="finest-image-display"
                      src={group?.beforeId.imageData}
                      alt=""
                    />
                    <img
                      className="finest-image-display"
                      src={group?.afterId.imageData}
                      alt=""
                    />
                  </div>
                  <span className="finest-small-id-text">
                    {group._id}
                    <button
                      className="delete-finest-button"
                      onClick={() => {
                        handleIdRemove(group._id);
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: "14px", color: "#ff6060" }} />
                    </button>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FinestJobsController;
