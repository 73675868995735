import React, { useState, useRef } from "react";
import { DEACTIVATE_USER } from "../../../../utils/GraphQL/mutations";
import { Snackbar } from "@mui/material";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import "./listAllUsers.css";
import { useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_ALL_USERS } from "../../../../utils/GraphQL/queries";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";

const ListAllUsers = () => {
  const listUsersScrollRef = useRef();
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [deactivateUserProf] = useMutation(DEACTIVATE_USER);
  const { data: usersData, loading: usersLoading } = useQuery(GET_ALL_USERS);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const deactivateUser = async (userId) => {
    const deactivated = await deactivateUserProf({
      variables: { idDelete: userId },
      refetchQueries: [{ query: GET_ALL_USERS }],
    });

    if (deactivated) {
      setOpenSnack({
        visible: true,
        message: "Account Deactivated",
        duration: 3000,
      });

      return;
    }

    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });

    return;
  };

  return (
    <div
      ref={listUsersScrollRef}
      id="list-user-height-marker"
      className="list-user-container"
    >
      {" "}
      {usersLoading && <AdminBDRSpinner message={"Loading..."} />}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <button
        onClick={() => {
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = listUsersScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              listUsersScrollRef.current.scrollIntoView({ block: "start" });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>List All Users</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="list-user-content">
          {usersData &&
            usersData?.getAllUsers.map((user, index) => {
              return (
                <div className="user-list-box" key={user?.firstName + index}>
                  <div className="user-name-box">
                    <h3>
                      {user?.firstName} {user?.lastName}
                    </h3>
                    <h5>Perm: {user?.permission}</h5>
                    <h5 className="user-email-word-break">Email: {user?.email}</h5>
                  </div>
                  <button
                    className="user-deactivate-btn"
                    onClick={() => {
                      handleSnack(
                        <div className="user-deactivate-confirm">
                          <span className="user-deactivate-text-confirm">
                            deactivate {user?.firstName}'s Profile?
                          </span>
                          <button
                            className="user-deactivate-btn"
                            onClick={() => {
                              handleSnack(
                                <div className="user-deactivate-confirm">
                                  <span className="user-deactivate-text-confirm">
                                    Are you sure?
                                  </span>
                                  <button
                                    className="user-deactivate-btn"
                                    onClick={() => {
                                      deactivateUser(user._id);
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        fontSize: "20px",
                                        color: "#b40000",
                                      }}
                                    />
                                    (Deactivate)
                                  </button>
                                </div>,
                                10000
                              );
                            }}
                          >
                            <DeleteIcon
                              sx={{ fontSize: "20px", color: "#b40000" }}
                            />
                            (Deactivate)
                          </button>
                        </div>,
                        10000
                      );
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: "20px", color: "#b40000" }} />
                    (Deactivate)
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ListAllUsers;
