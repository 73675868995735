import React, { useEffect, useState } from "react";
import "./adminDashboard.css";
import Auth from "../../../../utils/auth";
import Tracking from "../../../../components/TrackingComp";
import {
  GET_USER_WEB_AUTHN_IDS,
  GET_TASK_FOR_USERS,
} from "../../../../utils/GraphQL/queries";
import { useLazyQuery } from "@apollo/client";
import WebAuthnCheckAndStore from "../../../../utils/WebAuthnCheckAndStore";
import AdminDashboardTasks from "./AdminDashboardTasks";
import { Snackbar, useMediaQuery } from "@mui/material";
import TaskDisplayModal from "../../adminComponents/ClientTasksDisplay/TaskModal";
import { useNavigate } from "react-router";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [getUserWebAuthnIds] = useLazyQuery(GET_USER_WEB_AUTHN_IDS);
  const [openSingleTaskModal, setOpenSingleTaskModal] = useState(false);
  const [noRedirect, setNoRedirect] = useState(false);
  const [userPerm, setUserPerm] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const below900MQ = useMediaQuery("(max-width: 900px)");
  const [getUserTasks] = useLazyQuery(GET_TASK_FOR_USERS, {
    fetchPolicy: "no-cache",
  });
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    checkWebAuthnLocalAndStore();
    getPaymentUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasks = async (sort) => {
    // update tasks
    const { data } = await getUserTasks({
      variables: {
        sort,
      },
    });

    if (data?.getTasksForUser) {
      setTasks([...data?.getTasksForUser]);
    }
  };

  const getPaymentUrl = () => {
    const paymentId = sessionStorage.getItem("bdr-redirect-payment-id");
    sessionStorage.removeItem("bdr-redirect-payment-id");

    setNoRedirect(!paymentId);

    if (paymentId) {
      navigate(`/bdr-office/payments/process-payment/payment-id/${paymentId}`)
    }
    return;
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const checkWebAuthnLocalAndStore = async () => {
    const user = await Auth.getProfile();
    if (user?.data?.permission) {
      setUserPerm(user?.data?.permission);
    }
    const localWebAuthn = localStorage.getItem("BDRWebAuthnCredId");

    if (
      (user?.data?.usesWebAuthn && localWebAuthn) ||
      !user?.data?.usesWebAuthn
    ) {
      return;
    }

    const { data } = await getUserWebAuthnIds();

    if (data?.getUserWebAuthnIds?.length > 0) {
      WebAuthnCheckAndStore(data?.getUserWebAuthnIds);
    }

    return;
  };

  const handleTaskModal = (bool) => {
    if (bool === false) {
      setSelectedTask("");
    }
    setOpenSingleTaskModal(bool);
  };

  const handleSelectedTask = (taskId) => {
    setSelectedTask(taskId);
  };

  const handleUpdatedTasksArr = (taskUpdated) => {
    if (taskUpdated) {
      const updatedArr = tasks?.map((task) => {
        if (task?.id === taskUpdated?.id) {
          return taskUpdated;
        }
        return task;
      });

      setTasks([...updatedArr]);
    }
  };

  return (
    <div className="admin-container">
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {(userPerm === "admin" || userPerm === "office") && noRedirect && (
        <>
          <TaskDisplayModal
            openModal={openSingleTaskModal}
            setOpenModal={handleTaskModal}
            taskId={selectedTask}
            handleSnack={handleSnack}
            refetch={getTasks}
            handleUpdatedTasksArr={handleUpdatedTasksArr}
          />
          <AdminDashboardTasks
            handleSnack={handleSnack}
            queryTasks={getTasks}
            tasks={tasks}
            handleSelectedTask={handleSelectedTask}
            handleUpdatedTasksArr={handleUpdatedTasksArr}
            handleTaskModal={handleTaskModal}
            useTaskModal={below900MQ}
          />{" "}
        </>
      )}
      <Tracking removeVisit={true} />
    </div>
  );
};

export default AdminDashboard;
