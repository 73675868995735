import dateSuffix from "./dateSuffix";

const BAGroupTimeFormat = (milliseconds, getTime, includeYear, removeDayPrefix) => {
  if (!milliseconds) {
    return;
  }

  const removePrefix = removeDayPrefix || false;

  const incMills = parseInt(milliseconds);
  if (typeof incMills !== "number") {
    return incMills;
  }
  // one day in milliseconds
  const dayMills = 86400000;

  // gets current time for testing against and build date string
  const curMills = Date.now();
  const curTime = new Date(curMills);
  const curMonth = curTime.getMonth();
  const curDay = curTime.getDate();
  const curYear = curTime.getFullYear().toString();

  // incoming milliseconds to use against check and return date string
  const incTime = new Date(incMills);
  const incTimeSplit = incTime.toString().split(" ");
  const incYearCheck = incTimeSplit[3].toString();
  const incYear = includeYear ? `${incTimeSplit[3]} ` : "";
  const incMonth = incTime.getMonth();
  const incDay = incTime.getDate();
  const incDayOfWeek = incTimeSplit[0];
  const incMonthString = incTimeSplit[1];
  let incDayString = incTimeSplit[2];
  
  const getTimeFunc = () => {
    let hour =
      incTime.getHours() > 12
        ? Math.floor(incTime.getHours() - 12)
        : incTime.getHours();

    // if hour is 0 (12:00am), change it to 12
    if (hour === 0) {
      hour = 12;
    }

    const minutes =
      (incTime.getMinutes() < 10 ? "0" : "") + incTime.getMinutes();

    // set `am` or `pm`
    const periodOfDay = incTime.getHours() >= 12 ? "pm" : "am";

    return `@ ${hour}:${minutes} ${periodOfDay}`;
  }

  //separate the day format. instead of "May 01", "May 1".
  if (incDayString.split("")[0] === "0") {
    incDayString = incDayString.split("")[1];
  }

  const getDayPrefix = (incMonth) => {
    // return date string if date is today
    const sameYear = curYear === incYearCheck;
    const today = incMonth === curMonth && incDay === curDay;
    if (today && sameYear) {
      return "Today";
    }

    // return date string if date is yesterday
    const yester = incMonth === curMonth - 1 && incMills >= curMills - dayMills;
    const yester2 = incMonth === curMonth && incDay === curDay - 1;
    if ((yester || yester2) && sameYear) {
      return "Yesterday";
    }

    return incDayOfWeek;
  };

  const dayPrefixResult = getDayPrefix(incMonth);
  const daySuffix = dateSuffix(incDayString)
  const formattedTime = getTime ? getTimeFunc() : "";
  // return other formatted dates in a string
  return `${removePrefix ? "" : `${dayPrefixResult},`} ${incMonthString} ${daySuffix} ${incYear}${formattedTime}`.trim();
};

export default BAGroupTimeFormat;
