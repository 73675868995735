import React, { useState, useRef, useEffect } from "react";
import "./clientEmailDisplay.css";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GET_CLIENT_EMAILS } from "../../../../utils/GraphQL/queries";
import { CircularProgress } from "@mui/material";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import ReplyIcon from "@mui/icons-material/Reply";
import EmailFlags from "../../adminComponents/EmailFlags/EmailFlags";
import EmailModal from "../EmailModal/EmailModal";
import SendEmailModal from "../SendEmail/SendEmailModal";

const ClientEmailDisplay = ({ heightTrigger, handleSnack, clientEmail }) => {
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [emailOpenId, setEmailOpenId] = useState("");
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [openSendReply, setOpenSendReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendReplyObj, setSendReplyObj] = useState(false);
  const [clientEmailArr, setClientEmailArr] = useState([]);
  const [getClientEmailsGQL] = useLazyQuery(GET_CLIENT_EMAILS, {
    fetchPolicy: "network-only",
  });
  const smaller900 = useMediaQuery("(max-width:900px)");
  const clientEmailsDiv = useRef();

  useEffect(() => {
    if (barCollapsed === true) {
      const id = setTimeout(() => {
        setLoading(false);
      }, 600);

      return () => clearTimeout(id);
    }

    if (barCollapsed === false) {
      if (clientEmail) {
        setLoading(true);
        getEmails(clientEmail);
      }

      if (!clientEmail || clientEmail?.length === 0) {
        setLoading(false);
      }

      if (heightTrigger) {
        heightTrigger();
      }

      const id = setTimeout(() => {
        if (smaller900 === true && barCollapsed === true) {
          const navHeight = document.querySelector(
            "#admin-nav-height-marker"
          )?.offsetHeight;
          const pagePos = clientEmailsDiv.current.offsetTop;

          const totalMobileScroll = pagePos - navHeight - 5;
          window.scrollTo(0, totalMobileScroll);
        }

        // if (smaller900 === false && barCollapsed === true) {
        //   clientEmailsDiv.current.scrollIntoView({ block: "start" });
        // }
      }, 500);

      return () => clearTimeout(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCollapsed]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (emailModalOpen === true) {
      body.style.overflow = "hidden";
    }
    if (emailModalOpen === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailModalOpen]);

  const openBarCollapse = () => {
    setBarCollapsed((prev) => !prev);
  };

  const openEmail = (id) => {
    setEmailOpenId(id);
    setEmailModalOpen(true);
  };

  const closeEmail = (event) => {
    setEmailOpenId("");
    setEmailModalOpen(false);
    getEmails();
  };

  const getEmails = async () => {
    const { data } = await getClientEmailsGQL({
      variables: {
        clientEmail,
      },
      fetchPolicy: "network-only",
    });

    console.log(data?.getClientEmails);
    const emailsArr = [...data?.getClientEmails];

    if (emailsArr?.length === 0) {
      setClientEmailArr([]);
    } else {
      setClientEmailArr([...emailsArr]);
    }
    setLoading(false);
  };

  const prepareReplyEmail = (emailObj) => {
    setSendReplyObj({
      to: emailObj?.from,
      subject: `Re: ${emailObj?.emailSubject}`,
    });

    if (emailModalOpen) {
      closeEmail();
    }

    setOpenSendReply(true);
  };

  const changeParentForEmailModal = (bool) => {
    setOpenSendReply(bool);
  };

  return (
    <div
      id="client-pics-height-marker"
      className="client-pics-container"
      ref={clientEmailsDiv}
    >
      {emailModalOpen && (
        <EmailModal
          prepareReply={prepareReplyEmail}
          emailId={emailOpenId}
          closeEmail={closeEmail}
          changeEmail={openEmail}
        />
      )}
      {/* Send email modal for replies */}
      {openSendReply && (
        <SendEmailModal
          handleSnack={handleSnack}
          changeParent={changeParentForEmailModal}
          templateObj={sendReplyObj}
        />
      )}
      <button
        onClick={openBarCollapse}
        className="header-buttons-collapse-sticky"
      >
        <div>Emails</div>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        {loading && (
          <div className="client-pics-loading-box">
            <CircularProgress color="inherit" size={20} />
          </div>
        )}
        {!loading &&
          clientEmailArr?.length > 0 &&
          clientEmailArr?.map((email, index) => {
            let date = undefined;
            const getNum = parseInt(email?.dateCreated);
            if (getNum > 0) {
              date = BAGroupTimeFormat(getNum, true);
            }

            const itemClassName = `${
              email?.emailRead ? "email-item client-read-email" : "email-item"
            } ${email?.flag === "!" ? "email-item-important" : ""} ${
              email?.flag === "!!" ? "email-item-urgent" : ""
            }`;

            return (
              <div
                className="email-item-wrapper"
                key={"emails-number:" + email?.id + index}
              >
                <div className={itemClassName}>
                  <div className="email-open-btns">
                    <button
                      name={email?.id}
                      className="email-info-section"
                      onClick={() => {
                        openEmail(email?.id);
                      }}
                    >
                      <div className="text-overflow-ellipsis">
                        <span className="email-item-date">Subject:</span>{" "}
                        {email?.emailSubject}
                      </div>
                    </button>
                    <button
                      className="email-info-section"
                      onClick={() => {
                        openEmail(email?.id);
                      }}
                    >
                      <div>
                        <span className="email-item-date">Date: </span>{" "}
                        {date || "Missing Date"}
                      </div>
                    </button>
                  </div>
                  <div className="email-actions-box">
                    <div
                      className={
                        email?.attachments > 0
                          ? "email-has-attach att-error"
                          : "email-has-attach"
                      }
                    >
                      att: {email?.attachments}
                    </div>
                    <button
                      className="email-archive-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        prepareReplyEmail(email);
                      }}
                    >
                      <ReplyIcon titleAccess="Reply" />
                    </button>
                    {/* Flag component */}
                    <EmailFlags
                      emailRead={email?.emailRead}
                      getSelectedEmails={getEmails}
                      handleSnack={handleSnack}
                      emailId={email?.id}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        {!loading && clientEmailArr?.length === 0 && (
          <div className="client-pics-none-display">No Emails to Display</div>
        )}
      </div>
    </div>
  );
};

export default ClientEmailDisplay;
