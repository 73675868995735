import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_BLOCKED_EMAILS,
  GET_UNSUBS,
} from "../../../../utils/GraphQL/queries";
import "./blockedEmails.css";
import AdminBDRSpinner from "../../adminComponents/AdminBDRSpinner/AdminBDRSpinner";

const BlockedEmailsPage = () => {
  const [unsubInfo, setUnsubInfo] = useState([]);
  const [blockedInfo, setBlockedInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalBlocked, setTotalBlocked] = useState(0);
  const [totalUnsubbed, setTotalUnsubbed] = useState(0);
  const [getUnsubs] = useLazyQuery(GET_UNSUBS);
  const [getBlocked] = useLazyQuery(GET_BLOCKED_EMAILS);

  useEffect(() => {
    window.scrollTo(0, 0);
    getUnsubInfo();
    getBlockedInfo();
    // eslint-disable-next-line
  }, []);

  const getUnsubInfo = async () => {
    const { data } = await getUnsubs({
      fetchPolicy: "network-only",
    });

    setTotalUnsubbed(data?.getUnsubs?.length)

    setUnsubInfo([...data?.getUnsubs]);
    setLoading(false);
  };

  const getBlockedInfo = async () => {
    const { data } = await getBlocked({
      fetchPolicy: "network-only",
    });

    // console.log(data?.getBlockedEmails)
    let total = 0;

    for (let index = 0; index < data?.getBlockedEmails?.length; index++) {
      const num = parseInt(data?.getBlockedEmails[index]?.emailsReceived) || 1;
      total = total + num;
    }

    setTotalBlocked(total)
    setBlockedInfo([...data?.getBlockedEmails]);
    setLoading(false);
  };

  return (
    <div className="blocked-emails-page">
      {loading && <AdminBDRSpinner message={"Loading..."} />}
      <div className="blocked-emails-page-container">
        <div className="blocked-emails-totals-box">
          <span>Total Blocked: {totalBlocked}</span>
          <span>Total Unsubbed: {totalUnsubbed}</span>
        </div>
        {(unsubInfo || blockedInfo) && (
          <ul className="blocked-emails-ul">
            {unsubInfo?.map((unsub, index) => {
              return (
                <li key={unsub?.id} className="blocked-emails-li">
                  Unsubbed - {unsub?.contact}
                </li>
              );
            })}
            {blockedInfo?.map((blocked, index) => {
              return (
                <li key={blocked?.id} className="blocked-emails-li">
                  Blocked - {blocked?.emailsReceived}{" "}
                  {blocked?.emailsReceived > 1 ? "times" : "time"} -{" "}
                  {blocked?.email}djkfhslfrkuh
                </li>
              );
            })}
          </ul>
        )}
        {unsubInfo?.length <= 0 && <h4>No Unsubbed/Blocked Emails</h4>}
      </div>
    </div>
  );
};

export default BlockedEmailsPage;
