import React, { useEffect, useState } from "react";
import "./sendEmailModal.css";
import { Snackbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SendEmailModal from "./SendEmailModal";

const clearReplySubject = {
  subject: ""
}

const SendEmailOpenBtn = ({ changeParentStyle, sendEmailBtnMes, sendTo, replySubject }) => {
  const under600 = useMediaQuery("(max-width: 600px)");
  const [openSend, setOpenSend] = useState(false);
  const [replySub, setReplySub] = useState({...clearReplySubject});

  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
    duration: 2000,
  });

  useEffect(() => {
    const body = document.querySelector("body");

    if (openSend === true) {
      if (replySubject) {
        setReplySub({
          subject: `Re: ${replySubject}`
        })
      }
      
      body.style.overflow = "hidden";
    }
    if (openSend === false) {
      setReplySub({...clearReplySubject})
      body.style.overflow = "auto";
    }

    if (changeParentStyle) {
        changeParentStyle(openSend)
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSend, replySubject]);

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms || 2000,
    });
  };

  const changeParentForEmailModal = (bool) => {
    setOpenSend(bool);
  };

  return (
    <>
      <button
        className={
          under600 ? "send-email-img-button" : "send-email-start-button"
        }
        onClick={(event) => {
          event.stopPropagation();
          setOpenSend(true);
        }}
      >
        {sendEmailBtnMes}
      </button>
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {openSend && (
        <SendEmailModal
          sendTo={sendTo}
          handleSnack={handleSnack}
          changeParent={changeParentForEmailModal}
          templateObj={replySub}
        />
      )}
    </>
  );
};

export default SendEmailOpenBtn;
