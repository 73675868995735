import React from "react";
import "./adminBDRSpinner.css";

const AdminBDRSpinner = ({ message }) => {
  return (
    <div className="admin-over-loader-pos">
      <div className="admin-over-loader">
        <div className="admin-spinner-container">
          <div className="admin-loader-content">
            <h3>{message}</h3>
          </div>
          <div className="admin-bdr-spinner"></div>
        </div>
      </div>
    </div>
  );
};

export default AdminBDRSpinner;
