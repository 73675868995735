import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import "./adminNavbar.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Auth from "../../../../utils/auth";
import WebAuthnRegister from "../WebAuthn/WebAuthnRegister";
import UserInfoModal from "../UserInfoModal/UserInfoModal";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const getPages = (permission) => {
  let pages = [];
  let manageSubmenu = [];
  let emailsSubmenu = [];
  let paymentsSubmenu = [];

  switch (permission) {
    case "admin":
      pages = [
        {
          name: "Dashboard",
          href: "/bdr-office/dashboard",
        },
        {
          name: "Payments",
        },
        {
          name: "Manage",
        },
        {
          name: "Clients",
          href: "/bdr-office/clients",
        },
        {
          name: "Emails",
          href: "/bdr-office/email-inbox",
        },
        {
          name: "Messages",
          href: "/bdr-office/messages",
        },
        {
          name: "Settings",
        },
      ];

      manageSubmenu = [
        {
          name: "Services",
          href: "/bdr-office/services-management",
        },
        {
          name: "Image Groups",
          href: "/bdr-office/img-groups",
        },
        {
          name: "Metrics",
          href: "/bdr-office/metrics",
        },
        {
          name: "Shortcuts",
          href: "/bdr-office/shortcuts",
        },
        {
          name: "Shop Inv.",
          href: "/bdr-office/shop-inventory",
        },
        {
          name: "Users",
          href: "/bdr-office/manage-users",
        },
      ];

      emailsSubmenu = [
        {
          name: "Inbox",
          href: "/bdr-office/email-inbox",
        },
        {
          name: "Sent Emails",
          href: "/bdr-office/email-sent",
        },
        {
          name: "Unsubbed/Blocked Emails",
          href: "/bdr-office/email-blocked",
        },
      ];
      
      paymentsSubmenu = [
        {
          name: "Process Payment",
          href: "/bdr-office/payments/process-payment",
        },
        {
          name: "Transactions",
          href: "/bdr-office/payments/transactions",
        },
      ];
      break;

    case "refinisher":
      pages = [
        {
          name: "Dashboard",
          href: "/bdr-office/dashboard",
        },
        {
          name: "Payments",
        },
        {
          name: "Update List",
          href: "/bdr-office/update-list",
        },
        {
          name: "Settings",
        },
      ];

      paymentsSubmenu = [
        {
          name: "Process Payment",
          href: "/bdr-office/payments/process-payment",
        },
        {
          name: "Transactions",
          href: "/bdr-office/payments/transactions",
        },
      ];
      break;

    case "office":
      pages = [
        {
          name: "Dashboard",
          href: "/bdr-office/dashboard",
        },
        {
          name: "Payments",
        },
        {
          name: "Manage",
        },
        {
          name: "Clients",
          href: "/bdr-office/clients",
        },
        {
          name: "Emails",
          href: "/bdr-office/email-inbox",
        },
        {
          name: "Messages",
          href: "/bdr-office/messages",
        },
        {
          name: "Settings",
        },
      ];

      emailsSubmenu = [
        {
          name: "Inbox",
          href: "/bdr-office/email-inbox",
        },
        {
          name: "Sent Emails",
          href: "/bdr-office/email-sent",
        },
      ];

      manageSubmenu = [
        {
          name: "Image Groups",
          href: "/bdr-office/img-groups",
        },
        {
          name: "Shortcuts",
          href: "/bdr-office/shortcuts",
        },
      ];

      paymentsSubmenu = [
        {
          name: "Process Payment",
          href: "/bdr-office/payments/process-payment",
        },
        {
          name: "Transactions",
          href: "/bdr-office/payments/transactions",
        },
      ];
      break;

    default:
      pages = [
        {
          name: "Dashboard",
          href: "/bdr-office/dashboard",
        },
        {
          name: "Settings",
        },
      ];
  }

  return { pages, emailsSubmenu, manageSubmenu, paymentsSubmenu };
};

const AdminNavbar = ({ changeLoggedIn }) => {
  const [anchorElNav, setAnchorElNav] = useState(false);
  const [emailAnchorEl, setEmailAnchorEl] = useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(false);
  const [paymentsAnchorEl, setPaymentsAnchorEl] = useState(false);
  const [manageAnchorEl, setManageAnchorEl] = useState(false);
  const subAnchor = useRef();
  const [navPages, setNavPages] = useState([]);
  const [emailSubPages, setEmailSubPages] = useState([]);
  const [manageSubPages, setManageSubPages] = useState([]);
  const [paymentsSubPages, setPaymentsSubPages] = useState([]);
  const navigate = useNavigate();
  const matchesMQ = useMediaQuery("(min-width: 900px)");
  const [staticOrientation, setStaticOrientation] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [profileName, setProfileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    //listening for portrait or landscape view changes
    let portrait = window.matchMedia("(orientation: portrait)");

    const changeOrientation = (event) => {
      if (event.matches) {
        // Portrait mode
        setStaticOrientation(false);
      } else {
        // Landscape
        setStaticOrientation(true);
      }
    };

    portrait.addEventListener("change", changeOrientation);

    return () => {
      portrait.removeEventListener("change", changeOrientation);
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");

    if (modalOpen === true) {
      body.style.overflow = "hidden";
    }
    if (modalOpen === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    getPagesArrAndSaveUserInfo();
  }, []);

  const getPagesArrAndSaveUserInfo = async () => {
    const { data: user } = await Auth.getProfile();
    const lastInitial = user?.lastName?.trim()?.split("")[0];
    const profileName = capitalizeFirstLetters(
      `${user?.firstName} ${lastInitial}.`
    );

    const pageArr = getPages(user.permission);
    setNavPages([...pageArr?.pages]);
    setEmailSubPages([...pageArr?.emailsSubmenu]);
    setManageSubPages([...pageArr?.manageSubmenu]);
    setPaymentsSubPages([...pageArr?.paymentsSubmenu]);
    setUserInfo(user);
    setProfileName(profileName);
    setModalOpen(user?.initLogin);
  };

  const updateModalHandler = (state) => {
    setModalOpen(state);
  };

  const logoutHandler = async () => {
    const loggedOut = await Auth.logout();

    const adminNavOut = await changeLoggedIn(false);

    if (loggedOut && adminNavOut) {
      navigate("/login-portal");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  const handleOpenSubMenu = (event, targetName) => {
    if (targetName === "EmailsSub") {
      setEmailAnchorEl(event?.currentTarget);
      return;
    }

    if (targetName === "ManageSub") {
      setManageAnchorEl(event?.currentTarget);
      return;
    }

    if (targetName === "SettingsSub") {
      setSettingsAnchorEl(event?.currentTarget);
      return;
    }
    
    if (targetName === "PaymentsSub") {
      setPaymentsAnchorEl(event?.currentTarget);
      return;
    }
  };

  const handleCloseSubMenu = () => {
    setEmailAnchorEl(null);
    setManageAnchorEl(null);
    setSettingsAnchorEl(null);
    setPaymentsAnchorEl(null);
  };

  return (
    <AppBar
      position={
        matchesMQ ? "relative" : staticOrientation ? "relative" : "sticky"
      }
      sx={{ backgroundColor: "transparent", zIndex: "50" }}
    >
      <Container
        maxWidth="100vw"
        id="admin-nav-height-marker"
        className="admin-navbar"
      >
        {/* User Info Update Modal */}
        {modalOpen && (
          <UserInfoModal
            user={userInfo}
            updateModalHandler={updateModalHandler}
          />
        )}

        <Toolbar disableGutters>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: { md: "column", xl: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "center", md: "flex-start", xl: "center" },
            }}
          >
            {matchesMQ ? null : (
              <div className="admin-nav-hdr-container">
                <h1 className="admin-nav-hdr">BDR Admin</h1>
              </div>
            )}

            {/* Small Box Display */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                background: "#595959",
                borderRadius: "5px",
                boxShadow: "0px 0px 10px #000",
                margin: "10px 0px",
              }}
            >
              <IconButton
                size="small"
                aria-label="page menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {navPages?.map((page) => {
                  const thisPage =
                    page?.name === "Settings" ? profileName : page?.name;

                  if (
                    page?.name !== "Emails" &&
                    page?.name !== "Manage" &&
                    page?.name !== "Settings" && 
                    page?.name !== "Payments"
                  ) {
                    return (
                      <MenuItem
                        key={thisPage}
                        onClick={() => {
                          navigate(page.href);
                          handleCloseNavMenu();
                        }}
                      >
                        <Typography textAlign="center">{thisPage}</Typography>
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem
                      ref={subAnchor}
                      key={page?.name}
                      onClick={(event) => {
                        handleOpenSubMenu(event, `${page?.name}Sub`);
                      }}
                    >
                      <span>{thisPage}</span>
                      <span className="admin-nav-hdr-sml-submenu-title-arrow">
                        <ArrowDropDownIcon />
                      </span>
                    </MenuItem>
                  );
                })}
                {/* <WebAuthnRegister inNavSmall={true} /> */}
                {/* <MenuItem onClick={logoutHandler}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem> */}
              </Menu>
            </Box>

            {/* Large Box Display */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "space-around",
                width: { md: "100%", xl: "fit-content" },
                background: "#595959",
                borderRadius: "10px",
                boxShadow: "0px 0px 15px #000",
                margin: "10px 0px",
              }}
              textAlign="center"
            >
              {navPages.map((page) => {
                const thisPage =
                  page?.name === "Settings" ? profileName : page?.name;

                if (
                  page?.name !== "Emails" &&
                  page?.name !== "Manage" &&
                  page?.name !== "Settings" &&
                  page?.name !== "Payments"
                ) {
                  return (
                    <Button
                      key={thisPage}
                      onClick={() => {
                        navigate(page.href);
                      }}
                      sx={{
                        m: { xl: 2 },
                        color: "white",
                        display: "block",
                        fontSize: "12px",
                      }}
                    >
                      {thisPage}
                    </Button>
                  );
                }

                return (
                  <Button
                    ref={subAnchor}
                    key={thisPage}
                    onClick={(event) => {
                      handleOpenSubMenu(event, `${page?.name}Sub`);
                    }}
                    sx={{
                      m: { xl: 2 },
                      color: "white",
                      display: "block",
                      fontSize: "12px",
                    }}
                  >
                    {thisPage} <ArrowDropDownIcon />
                  </Button>
                );
              })}
              {/* <WebAuthnRegister inNavSmall={false} /> */}
              {/* <Button
                onClick={logoutHandler}
                sx={{
                  m: { xl: 2 },
                  color: "white",
                  display: "block",
                  fontSize: "12px",
                }}
              >
                Logout
              </Button> */}
            </Box>
          </Box>
        </Toolbar>

        {/* Emails Nav Submenu */}
        <Menu
          id="email-menu-popover"
          anchorEl={emailAnchorEl}
          disableScrollLock={matchesMQ ? true : false}
          anchorOrigin={{
            vertical: matchesMQ ? "bottom" : "top",
            horizontal: matchesMQ ? "center" : "left",
          }}
          transformOrigin={{
            vertical: matchesMQ ? "top" : "top",
            horizontal: matchesMQ ? "center" : "right",
          }}
          open={Boolean(emailAnchorEl)}
          onClose={handleCloseSubMenu}
        >
          {emailSubPages &&
            emailSubPages?.map((page, index) => {
              return (
                <MenuItem
                  key={page?.name + index}
                  onClick={() => {
                    navigate(page?.href);
                    handleCloseSubMenu();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page?.name}</Typography>
                </MenuItem>
              );
            })}
        </Menu>

        {/* Settings Nav Submenu */}
        <Menu
          id="settings-menu-popover"
          anchorEl={settingsAnchorEl}
          disableScrollLock={matchesMQ ? true : false}
          anchorOrigin={{
            vertical: matchesMQ ? "bottom" : "top",
            horizontal: matchesMQ ? "center" : "left",
          }}
          transformOrigin={{
            vertical: matchesMQ ? "top" : "top",
            horizontal: matchesMQ ? "center" : "right",
          }}
          open={Boolean(settingsAnchorEl)}
          onClose={handleCloseSubMenu}
        >
          <WebAuthnRegister inNavSmall={true} />
          <MenuItem
            onClick={() => {
              handleCloseSubMenu();
              handleCloseNavMenu();
              updateModalHandler(true);
            }}
          >
            <Typography textAlign="center">Update User</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              // navigate(page?.href);
              // handleCloseSubMenu();
              // handleCloseNavMenu();
              logoutHandler();
            }}
          >
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>

        {/* Manage Nav Submenu */}
        <Menu
          id="manage-menu-popover"
          anchorEl={manageAnchorEl}
          disableScrollLock={matchesMQ ? true : false}
          anchorOrigin={{
            vertical: matchesMQ ? "bottom" : "top",
            horizontal: matchesMQ ? "center" : "left",
          }}
          transformOrigin={{
            vertical: matchesMQ ? "top" : "top",
            horizontal: matchesMQ ? "center" : "right",
          }}
          open={Boolean(manageAnchorEl)}
          onClose={handleCloseSubMenu}
        >
          {manageSubPages &&
            manageSubPages?.map((page, index) => {
              return (
                <MenuItem
                  key={page?.name + index}
                  onClick={() => {
                    navigate(page?.href);
                    handleCloseSubMenu();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page?.name}</Typography>
                </MenuItem>
              );
            })}
        </Menu>
       
        {/* Payments Nav Submenu */}
        <Menu
          id="payments-menu-popover"
          anchorEl={paymentsAnchorEl}
          disableScrollLock={matchesMQ ? true : false}
          anchorOrigin={{
            vertical: matchesMQ ? "bottom" : "top",
            horizontal: matchesMQ ? "center" : "left",
          }}
          transformOrigin={{
            vertical: matchesMQ ? "top" : "top",
            horizontal: matchesMQ ? "center" : "right",
          }}
          open={Boolean(paymentsAnchorEl)}
          onClose={handleCloseSubMenu}
        >
          {paymentsSubPages &&
            paymentsSubPages?.map((page, index) => {
              return (
                <MenuItem
                  key={page?.name + index}
                  onClick={() => {
                    navigate(page?.href);
                    handleCloseSubMenu();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page?.name}</Typography>
                </MenuItem>
              );
            })}
        </Menu>
      </Container>
    </AppBar>
  );
};

export default AdminNavbar;
