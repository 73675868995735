import React, { useState, useEffect } from "react";
// Component Imports
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import QuoteRadioForm from "../../components/QuoteRadioForm/QuoteRadioForm";
import CssTextField from "../../components/CssTextFields/CssTextFieldOutline";
import {
  CssTextFieldStandard,
  MyFormHelperText,
} from "../../components/CssTextFields/CssTextFieldStandard";
import Tracking from "../../components/TrackingComp";
import { Helmet } from "react-helmet";

// Styles Imports
import "./getAQuote.css";

// Utilities Imports
import { useMutation } from "@apollo/client";
import { SEND_MESSAGE } from "../../utils/GraphQL/mutations";
import capitalizeFirstLetters from "../../utils/formatters/capitalizeFirstLetters";
import FormTest from "../../utils/formValidators/quoteFormInputCheck";
import phoneNumberFormat from "../../utils/formatters/phoneNumberFormat";
import PictureUpload from "../../components/PictureUpload/PictureUpload";
import TransitionsModal from "../../components/Modal/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox } from "@mui/material";

const GetAQuote = () => {
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [gotQuoted, setGotQuoted] = useState(false);
  const [loadingSaved, setLoadingSaved] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [modalText, setModalText] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    nameInput: "",
    emailInput: "",
    phoneNumberInput: "",
    numberType: "",
    textConsent: true,
    intExtChoice: "Exterior",
    discountChoice: "None",
    heardAboutUs: "",
    otherInput: "",
    imageIdArr: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.document.title = "Request A Quote";
  }, []);

  //for triggering error helper texts on inputs when they fail the checks
  const [errorState, setErrorState] = useState({
    emailInput: false,
    phoneNumberInput: false,
    otherInput: false,
  });

  // added loading state for fetch call
  const changeLoading = async (newState) => {
    setLoadingSaved(newState);
    return;
  };

  // form validation class
  const formInputCheck = new FormTest(formState);

  // Handles inputs changes to state
  const handleChange = async (event) => {
    let value = event.target.value;

    if (event.target.name === "nameInput") {
      value = await capitalizeFirstLetters(value);
    }

    if (event.target.name === "phoneNumberInput") {
      value = await phoneNumberFormat(value);
    }

    if (event.target.name === "numberType") {
      // let bool = false;
      // if (value === "Cell") {
      //   bool = true;
      // }
      setFormState({
        ...formState,
        // textConsent: bool,
        [event.target.name]: value,
      });
      return;
    }

    if (event.target.name === "textConsent") {
      setFormState({
        ...formState,
        textConsent: event.target.checked,
      });
      return;
    }

    setFormState({
      ...formState,
      [event.target.name]: value,
    });
  };

  // validation check handler for on phone number, email and "other" option inputs
  const checkError = async (event) => {
    let value = event.target.value;

    //value has to be flipped because the methods return true for valid input
    if (event.target.name === "otherInput") {
      value = await formInputCheck.testOther();
      setErrorState({
        ...errorState,
        [event.target.name]: !value,
      });

      return;
    }

    if (event.target.name === "numberType") {
      value = await formInputCheck.testNumberType();
    }

    //if the incoming value for email or phone is empty, clear error
    if (value === "") {
      setErrorState({
        ...errorState,
        [event.target.name]: false,
      });

      return;
    }

    if (event.target.name === "emailInput") {
      value = await formInputCheck.testEmail();
    }

    if (event.target.name === "phoneNumberInput") {
      value = await formInputCheck.testPhone();
    }

    //value has to be flipped because the methods return true for valid input
    setErrorState({
      ...errorState,
      [event.target.name]: !value,
    });
  };

  //meant for clearing error state and message when input is focused on
  const clearError = (event) => {
    const name = event.target?.name || event;
    setErrorState({
      ...errorState,
      [name]: false,
    });
  };

  //server call for sending form submission to email
  const sendContactEmail = async (event) => {
    event.preventDefault();
    // const validType = await formInputCheck.testNumberType();
    // if (!validType) {

    // }
    const validForm = await formInputCheck.formValid();

    if (validForm) {
      setGotQuoted(true);

      setModalText({
        title: "Thank you for contacting BDR Services!",
        description:
          "We will go over your information and get a quote worked up for you. If you have any questions please feel free to contact us!",
        home: true,
        time: 8000,
      });
      handleOpen();

      sendMessage({
        variables: formState,
      });
    }

    if (!validForm) {
      setModalText({
        title: "Oops...",
        description: "All form details need to be completed",
        home: false,
        time: 2000,
      });
      handleOpen();
    }

    return;
  };

  const changePhotoIds = (photoId) => {
    setFormState((prevState) => ({
      ...prevState,
      imageIdArr: [...prevState.imageIdArr, photoId],
    }));
  };

  // modal controls
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  //sticky upload controls
  const changeSticky = (newState) => setIsSticky(newState);

  return (
    <div className="quote-page">
      <Helmet>
        <title>Request A Quote</title>
        <meta
          name="description"
          content="Fill out our online form and we will get a quote prepared for you."
        />
      </Helmet>
      <TransitionsModal
        handleClose={handleClose}
        modalOpen={modalOpen}
        modalText={modalText}
      />
      <h2 className="page-header">
        Tell us about <span className="underline">Your Door</span> and we will
        get you a quote!
      </h2>
      <div className="page-container">
        <div className="page-box">
          <div className="input-form">
            <CssTextFieldStandard
              sx={{ width: "95%", my: 1 }}
              label={"Name"}
              name="nameInput"
              onChange={handleChange}
              value={formState.nameInput}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "95%", my: 1 }}
              label={"Email"}
              name="emailInput"
              onChange={handleChange}
              onBlur={checkError}
              onFocus={clearError}
              value={formState.emailInput}
              variant="standard"
            />
            <MyFormHelperText
              errorText={"Please enter a valid email."}
              formError={errorState.emailInput}
            />
            <div className="get-quote-phone-num-box">
              <CssTextFieldStandard
                sx={{ width: "65%", my: 1 }}
                type="tel"
                label={`Phone Number`}
                inputProps={{ maxLength: 12, inputMode: "numeric" }}
                name="phoneNumberInput"
                onChange={handleChange}
                onBlur={checkError}
                onFocus={clearError}
                value={formState.phoneNumberInput}
                variant="standard"
              />
              <FormControl
                style={{ alignSelf: "flex-end" }}
                variant="standard"
                sx={{ m: 1, width: "30%" }}
              >
                <InputLabel
                  id="num-type-select"
                  sx={{
                    color: "#05F842",
                    "&.Mui-focused": { color: "#05F842" },
                  }}
                >
                  Type
                </InputLabel>
                <Select
                  labelId="phone-type-select"
                  id="phone-type-select-input"
                  name="numberType"
                  value={formState?.numberType}
                  onChange={handleChange}
                  onBlur={checkError}
                  onFocus={() => {
                    clearError("numberType");
                  }}
                  disableUnderline={true}
                  sx={{
                    color: "#fff",
                    WebkitTextFillColor: "#fff",
                    "& .Mui-disabled": {
                      color: "#fff !important",
                      WebkitTextFillColor: "#fff",
                    },
                  }}
                  style={{ borderBottom: "1px solid #fff" }}
                >
                  <MenuItem value={"Home"}>Home</MenuItem>
                  <MenuItem value={"Cell"}>Cell</MenuItem>
                  <MenuItem value={"Work"}>Work</MenuItem>
                  <MenuItem value={"Spouse"}>Spouse</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <MyFormHelperText
              errorText={"Please enter valid phone number"}
              formError={errorState.phoneNumberInput}
            />
            <MyFormHelperText
              errorText={"Please enter a valid phone type."}
              formError={errorState.numberType}
            />
            <MyFormHelperText
              noBG={true}
              errorText={
                <div className="quote-consent-box">
                  <Checkbox
                    sx={{
                      color: "#ffffff",
                      "&.Mui-checked": {
                        color: "#05F842",
                      },
                    }}
                    checked={formState?.textConsent}
                    onChange={handleChange}
                    name="textConsent"
                  />
                  <div className="quote-consent-words-box">
                    <div>
                      I agree to receive text messages from Biggin's Door
                      Refinishing at the number provided above. I understand
                      this can include marketing or other information regarding
                      quote/appointment details. Reply STOP to opt out. Msg &
                      Data rates apply.
                    </div>
                    <div className="quote-priv-tos-links-box">
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="quote-link-btn"
                      >
                        Privacy Policy
                      </a>
                      {"|"}
                      <a
                        href="/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="quote-link-btn"
                      >
                        Terms of Service
                      </a>
                    </div>
                  </div>
                </div>
              }
              // formError={formState?.numberType === "Cell"}
              formError={true}
            />
          </div>

          <QuoteRadioForm
            choiceStateFunction={handleChange}
            name="intExtChoice"
            question={"Would it be just the exterior that you want refinished?"}
            formId={"door-side-radios"}
            choiceDefault={"Exterior"}
            choices={["Exterior", "Interior", "Both Sides"]}
          />
          <QuoteRadioForm
            choiceStateFunction={handleChange}
            name="discountChoice"
            question={
              "Would a military, senior, teacher or first responder discount apply?"
            }
            formId={"discount-radio"}
            choiceDefault={"None"}
            choices={[
              "Military",
              "Senior",
              "Teacher",
              "First responder",
              "None",
            ]}
          />
          <QuoteRadioForm
            choiceStateFunction={handleChange}
            name="heardAboutUs"
            question={"How did you hear about us?"}
            formId={"discount-radio"}
            choiceDefault={null}
            choices={[
              "TheHomeMag",
              "Friend, Family or Neighbor",
              "Met a refinisher",
              "Next Door App",
              "Google",
              "Other",
            ]}
            endComponent={
              <Collapse in={formState.heardAboutUs === "Other"}>
                <CssTextField
                  sx={{ width: "100%" }}
                  focused
                  label={"Other"}
                  onChange={handleChange}
                  onBlur={checkError}
                  onFocus={clearError}
                  value={formState.otherInput}
                  name="otherInput"
                />
                <MyFormHelperText
                  errorText={"Please enter a response."}
                  formError={errorState.otherInput}
                />
              </Collapse>
            }
          />
        </div>
        <div className="page-box">
          <div className={isSticky ? "sticky-upload" : ""}>
            <h3 className="upload-section-header">
              Please upload a photo of your door
            </h3>
            <PictureUpload
              changeSticky={changeSticky}
              changeLoading={changeLoading}
              changePhotoIds={changePhotoIds}
            />
          </div>
        </div>
      </div>
      <div className="quote-submit-consent">
        <div className="quote-submit-consent-inner">
          By submitting, you agree to Biggin's Door Refinishing{" "}
          <a
            href="/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className="quote-link-btn"
          >
            Terms of Service
          </a>
          .
        </div>
      </div>

      <Button
        disabled={loadingSaved}
        id="submit-button"
        onClick={sendContactEmail}
      >
        <h3>Submit</h3>
      </Button>
      <Tracking page={"quote"} convertQuote={gotQuoted} />
    </div>
  );
};

export default GetAQuote;
