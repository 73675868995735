import React, { useEffect } from "react";
import "./quoteInvoiceEditModal.css";
import QuoteInvoiceEditPage from "./QuoteInvoiceEditPage";

const QuoteInvoiceEditModal = ({
  selectedObj,
  isInvoice,
  openBool,
  closeModal,
  handleSnack,
  returnNewObj
}) => {
  useEffect(() => {
    const body = document.querySelector("body");

    if (openBool === true) {
      body.style.overflow = "hidden";
    }

    if (openBool === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBool]);

  return (
    <div
      className="quote-invoice-edit-modal-container"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className="quote-invoice-edit-modal-content"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <button
          className="quote-invoice-edit-modal-close"
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>
        <QuoteInvoiceEditPage
          selectedObj={selectedObj}
          isInvoice={isInvoice}
          handleSnack={handleSnack}
          returnNewObj={returnNewObj}
        />
      </div>
    </div>
  );
};

export default QuoteInvoiceEditModal;
