import React, { useEffect, useState } from "react";
import "./finestJobsTiles.css";
import { useLazyQuery } from "@apollo/client";
import { QUERY_FINEST_JOBS } from "../../../utils/GraphQL/queries";
import BdrSpinner from "../../BdrSpinner/BdrSpinner";
import useMediaQuery from "@mui/material/useMediaQuery";

const FinestJobsTiles = ({ paginationCB, paginationState }) => {
  //returns true if below 900px
  const mediaQuery = useMediaQuery("(max-width: 900px)");
  const [carouselState, setCarouselState] = useState({
    selectionValue: 1,
    pauseTimerValue: false,
    timerId: undefined,
  });
  const [touchState, setTouchState] = useState({
    touchStart: null,
    touchEnd: null,
  });
  const [finestGroups, setFinestGroups] = useState({});
  const [finestGroupLoading, setFinestGroupLoading] = useState(false);
  const [queryFinest] = useLazyQuery(QUERY_FINEST_JOBS);

  useEffect(() => {
    if (paginationState) {
      setFinestGroupLoading(true);
      queryCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState]);

  useEffect(() => {
    startTimer();
    // eslint-disable-next-line
  }, []);

  //checks the swiped direction after touch end
  //return boolean true if swiped right
  useEffect(() => {
    checkDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchState.touchEnd]);

  useEffect(() => {
    timerFunc();
    // eslint-disable-next-line
  }, [carouselState.pauseTimerValue]);

  const queryCall = async () => {
    const { data } = await queryFinest({});

    if (data?.getFinestJobs) {
      setFinestGroups({ ...data });
      setFinestGroupLoading(false);
      paginationCB();
    }
    return;
  };

  //changes the selected expanded box
  const changeSelected = () => {
    setCarouselState((prev) => {
      if (prev.pauseTimerValue === true) {
        clearInterval(prev.timerId);

        return { ...prev, timerId: null };
      }
      if (prev.selectionValue === 8 || undefined || prev.selectionValue > 8) {
        return { ...prev, selectionValue: 1 };
      }

      return { ...prev, selectionValue: prev.selectionValue + 1 };
    });
  };

  //changes the selected expanding box on hover and clears timers
  const hoverSelected = (event) => {
    if (event.target.name === undefined) {
      return;
    }
    pauseTimer();
    const selectedByHover = Number(event.target.name);

    setCarouselState((prev) => ({
      ...prev,
      selectionValue: selectedByHover,
    }));

    return;
  };

  //calls the change expanded box function after set time
  const timerFunc = () => {
    if (carouselState.pauseTimerValue === false) {
      clearInterval(carouselState.timerId);

      const timerId = setInterval(() => {
        changeSelected();
      }, 3000);

      setCarouselState((prev) => ({
        ...prev,
        timerId: timerId,
      }));
      return;
    }

    if (carouselState.pauseTimerValue === true) {
      clearInterval(carouselState.timerId);
      return;
    }
  };

  const startTimer = () => {
    setCarouselState((prev) => ({
      ...prev,
      pauseTimerValue: false,
    }));
  };

  const pauseTimer = () => {
    setCarouselState((prev) => ({
      ...prev,
      pauseTimerValue: true,
    }));
  };

  const touchStartHor = (event) => {
    setTouchState((prev) => ({
      ...prev,
      touchStart: event.changedTouches[0].screenX,
    }));
  };

  const touchEndHor = (event) => {
    setTouchState((prev) => ({
      ...prev,
      touchEnd: event.changedTouches[0].screenX,
    }));
  };

  const checkDirection = () => {
    let swipeRight = null;
    //left swipe is false
    if (touchState.touchEnd > touchState.touchStart) {
      swipeRight = false;
    }
    //right swipe is RIGHT(true)
    if (touchState.touchEnd < touchState.touchStart) {
      swipeRight = true;
    }

    //prevents initial render from breaking carousel
    if (swipeRight === undefined || swipeRight === null) return;

    setCarouselState((prev) => {
      // clear timer change selected function
      clearInterval(prev.timerId);

      //reset carousel if already at end
      if (swipeRight === true && prev.selectionValue >= 8) {
        return {
          ...prev,
          selectionValue: (prev.selectionValue = 1),
        };
      }

      //go to end of carousel if already at beginning
      if (swipeRight === false && prev.selectionValue <= 1) {
        return {
          ...prev,
          selectionValue: (prev.selectionValue = 8),
        };
      }

      if (swipeRight === true) {
        return {
          ...prev,
          selectionValue: prev.selectionValue + 1,
        };
      }

      if (swipeRight === false) {
        return {
          ...prev,
          selectionValue: prev.selectionValue - 1,
        };
      }
    });

    //restart carousel 3sec timer function
    timerFunc();
  };

  return (
    <div className="finest-container">
      {finestGroupLoading ? (
        <BdrSpinner message={"Loading..."} />
      ) : (
        finestGroups?.getFinestJobs &&
        finestGroups?.getFinestJobs.BAGroupIds.map((group, index) => {
          const groupNum = index + 1;
          return (
            <div
              key={group.beforeId._id + index}
              onMouseOver={mediaQuery ? null : hoverSelected}
              onMouseLeave={mediaQuery ? null : startTimer}
              onTouchStart={mediaQuery ? touchStartHor : null}
              onTouchEnd={mediaQuery ? touchEndHor : null}
              name={groupNum}
              className={
                carouselState.selectionValue === groupNum
                  ? "expanding-box selected"
                  : "expanding-box"
              }
            >
              <img
                name={groupNum}
                src={group.beforeId.imageData}
                alt="Door Before"
              />
              <img
                name={groupNum}
                src={group.afterId.imageData}
                alt="Door After"
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default FinestJobsTiles;
