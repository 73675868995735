import React, { useEffect, useState } from "react";
import "./adminShopItemUpdateModal.css";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import {
  priceStrFormatter,
  quantityFormatter,
  removePriceStrFormatter,
} from "../../../../utils/formatters/priceStrFormatter";

const UpdateShopItemModal = ({
  close,
  selectedItemObj,
  open,
  updateShopItem,
}) => {
  const [changedItemObj, setChangedItemObj] = useState({
    itemName: "",
    itemId: "",
    index: "",
    currentQuantity: "",
    maxQuantity: "",
    price: "",
    priceStr: "",
  });

  useEffect(() => {
    if (selectedItemObj && selectedItemObj !== undefined) {
      const priceStr = priceStrFormatter(selectedItemObj?.price);
      setChangedItemObj({
        ...selectedItemObj,
        priceStr: priceStr || "",
        itemId: selectedItemObj?.id,
      });
    }
  }, [selectedItemObj]);

  const clearItemObj = () => {
    setChangedItemObj((prev) => ({
      itemName: "",
      itemId: "",
      index: "",
      currentQuantity: "",
      maxQuantity: "",
      price: "",
      priceStr: "",
    }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "price") {
      const toNumStr = removePriceStrFormatter(value);
      const newPriceStr = priceStrFormatter(toNumStr);
      setChangedItemObj((prev) => ({
        ...prev,
        price: toNumStr,
        priceStr: newPriceStr,
      }));

      return;
    }
    //sets only number strings for positive and negative
    let finalQuantity = quantityFormatter(value);

    setChangedItemObj((prev) => ({
      ...prev,
      [name]: finalQuantity,
    }));
  };

  const updateCheck = async () => {
    const { itemName, currentQuantity, maxQuantity, price } = changedItemObj;
    const check = {
      name: false,
      curQ: false,
      maxQ: false,
      price: false,
    };

    if (itemName.length !== 0 && typeof itemName === "string") {
      check.name = true;
    }
    if (currentQuantity.length !== 0 && typeof currentQuantity === "string") {
      check.curQ = true;
    }
    if (maxQuantity.length !== 0 && typeof maxQuantity === "string") {
      check.maxQ = true;
    }
    if (price.length !== 0 && typeof price === "string") {
      check.price = true;
    }

    if (check.name && check.curQ && check.maxQ && check.price) {
      return true;
    }

    return false;
  };

  return (
    <div className={open ? "shop-item-update-show" : "shop-item-update-hidden"}>
      <div
        onClick={(event) => {
          event.stopPropagation();
          clearItemObj();
          close();
        }}
        className="shop-item-update-item-modal-bg"
      ></div>
      <div className="shop-item-update-item-modal-box">
        <div className="shop-item-update-item-modal-hdr">
          <h2>{selectedItemObj?.itemName}</h2>
          <button
            onClick={(event) => {
              event.stopPropagation();
              clearItemObj();
              close();
            }}
            className="close-modal-x-btn"
          >
            X
          </button>
        </div>
        <CssTextFieldStandard
          sx={{ width: "95%", my: 1 }}
          type="text"
          label={`Max Quantity (prev): ${selectedItemObj?.maxQuantity}`}
          name="maxQuantity"
          inputMode="text"
          value={changedItemObj.maxQuantity}
          onChange={handleChange}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: "95%", my: 1 }}
          type="text"
          label={`Current Quantity (prev): ${selectedItemObj?.currentQuantity}`}
          name="currentQuantity"
          inputMode="text"
          value={changedItemObj.currentQuantity}
          onChange={handleChange}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: "95%", my: 1 }}
          type="tel"
          label={`Current Price (prev): ${priceStrFormatter(
            selectedItemObj?.price
          )}`}
          inputMode="tel"
          name="price"
          value={changedItemObj.priceStr}
          onChange={handleChange}
          variant="standard"
        />
        <button
          className="shop-item-update-modal-item-btn"
          onClick={async () => {
            const goodUpdate = await updateCheck();
            if (goodUpdate) {
              updateShopItem(changedItemObj);
            }
            close();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateShopItemModal;
