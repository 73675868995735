const capitalizeFirstLetters = (string) => {
    if (string) {
      const splitName = string.split(' ');
    
    const fullCapName = splitName.map((eachName) => {
      return eachName.charAt(0).toUpperCase() + eachName.slice(1);
    })
   
    return fullCapName.join(" ")
    }

    return string
  }

  export default capitalizeFirstLetters;