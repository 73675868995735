import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./homeImageCarousel.css";
import { useQuery } from "@apollo/client";
import { QUERY_FINEST_JOBS } from "../../../utils/GraphQL/queries";

const HomeImageCarousel = () => {
  const [index, setIndex] = useState(0);
  const [allItems, setAllItems] = useState([]);
  const { data: images } = useQuery(QUERY_FINEST_JOBS);

  useEffect(() => {
    if (images?.getFinestJobs?.BAGroupIds?.length > 0) {
      setItems();
    }
    // eslint-disable-next-line
  }, [images?.getFinestJobs?.BAGroupIds]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const setItems = () => {
    images?.getFinestJobs?.BAGroupIds?.map((imgGroup, index) => {
      const key1 = imgGroup?.beforeId?._id + index;
      const key2 = imgGroup?.afterId?._id + index;

      setAllItems((prev) => {
        return [
          ...prev,
          <Carousel.Item key={key1}>
            <div className="before-after-wrapper">
              <h4 className="before-after">Before</h4>
            </div>
            <img
              className="carousel-img"
              src={imgGroup?.beforeId?.imageData}
              alt="Before Pic"
            />
          </Carousel.Item>,
          <Carousel.Item key={key2}>
            <div className="before-after-wrapper">
              <h4 className="before-after">After</h4>
            </div>
            <img
              className="carousel-img"
              src={imgGroup?.afterId?.imageData}
              alt="After Pic"
            />
          </Carousel.Item>,
        ];
      });

      return index;
    });
  };

  return (
    <div className="carousel-container">
      <Carousel activeIndex={index} indicators={false} onSelect={handleSelect}>
        {allItems?.length > 0 &&
          allItems?.map(item => {
            return item
          })
          }
      </Carousel>
    </div>
  );
};

export default HomeImageCarousel;
