import React, { useState, useRef, useEffect } from "react";
import "./clientPicsDisplay.css";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { QUERY_CLIENT_PHOTOS } from "../../../../utils/GraphQL/queries";
import { CircularProgress } from "@mui/material";
import copyImageToClip from "../../../../utils/copyImageToClip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImageModal from "../ImageModal/ImageModal";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import resizeImage from "../../../../utils/formatters/resizeImage";
import { NEW_CLIENT_PHOTO } from "../../../../utils/GraphQL/mutations";
// import RotateRightIcon from "@mui/icons-material/RotateRight";

const ClientPicsDisplay = ({
  heightTrigger,
  photoIdsArr,
  handleSnack,
  clientId,
  addPhotoIdToClient,
}) => {
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [noPhotos, setNoPhotos] = useState(false);
  const [photosArr, setPhotosArr] = useState([]);
  const [savePhoto] = useMutation(NEW_CLIENT_PHOTO);
  const [getImagesGQL] = useLazyQuery(QUERY_CLIENT_PHOTOS, {
    fetchPolicy: "network-only",
  });
  const [files, setFiles] = useState();
  const [uploadedImageTempUrl, setUploadedImageTempUrl] = useState([]);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const clientPicsDiv = useRef();

  useEffect(() => {
    if (barCollapsed === true) {
      const id = setTimeout(() => {
        setLoading(false);
        setNoPhotos(false);
      }, 600);

      return () => clearTimeout(id);
    }

    if (barCollapsed === false) {
      if (photoIdsArr) {
        setLoading(true);
        setNoPhotos(false);
        getPhotos(photoIdsArr);
      }

      if (!photoIdsArr || photoIdsArr?.length === 0) {
        setLoading(false);
        setNoPhotos(true);
      }

      if (heightTrigger) {
        heightTrigger();
      }

      const id = setTimeout(() => {
        if (smaller900 === true && barCollapsed === true) {
          const navHeight = document.querySelector(
            "#admin-nav-height-marker"
          )?.offsetHeight;
          const pagePos = clientPicsDiv.current.offsetTop;

          const totalMobileScroll = pagePos - navHeight - 5;
          window.scrollTo(0, totalMobileScroll);
        }

        // if (smaller900 === false && barCollapsed === true) {
        //   clientPicsDiv.current.scrollIntoView({ block: "start" });
        // }
      }, 500);

      return () => clearTimeout(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCollapsed, photoIdsArr]);

  useEffect(() => {
    createTemps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const openBarCollapse = () => {
    setBarCollapsed((prev) => !prev);
  };

  const modalHandler = (open, imgData) => {
    if (open === true) {
      setSelectedImg(imgData);
    }

    setModalOpen(open);
  };

  const handleModalClose = () => {
    modalHandler(false);
  };

  const handleFileChange = (event) => {
    clearTempUrls();
    if (event.target.files.length === 0) {
    }

    if (event.target.files) {
      setFiles([...event.target.files]);

      return;
    }
  };

  const handleFileSave = async (event) => {
    clearTempUrls();
    if (files?.length === 0) {
      return;
    }

    for await (const file of files) {
      const date = Date.now();
      const resizedImg = await resizeImage(file);

      if ((await resizedImg.length) > 0) {
        const photoData = await savePhoto({
          variables: {
            imageData: resizedImg,
            dateEntered: date.toString(),
            assignToClientId: clientId,
          },
        });

        if (photoData.data) {
            console.log(photoData.data)
          addPhotoIdToClient(photoData.data?._id);
          setPhotosArr((prev) => {
            return [
              ...prev,
              { _id: photoData.data?._id, imageData: resizedImg },
            ];
          });
        }
      }

      return;
    }
  };

  const clearTempUrls = () => {
    setUploadedImageTempUrl([]);
  };

  const createTemps = async () => {
    const b64toBlob = (
      b64Data,
      contentType = "image/jpeg",
      sliceSize = 512
    ) => {
      const splitData = b64Data.split(",")[1];
      const byteCharacters = atob(splitData);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    if (files) {
      for (const file of files) {
        const newSize = await resizeImage(file);
        const blob = b64toBlob(newSize);
        const url = URL.createObjectURL(blob);
        const name = file.name;

        setUploadedImageTempUrl((uploadedImageTempUrl) => [
          ...uploadedImageTempUrl,
          { url, name },
        ]);
      }

      //  handleUploadSubmit();

      return;
    }
  };

  const getPhotos = async (ids) => {
    if (photosArr?.length > 0) {
      setLoading(false);
      return;
    }

    const { data } = await getImagesGQL({
      variables: {
        pictureIdArr: [...ids],
      },
    });
    console.log(data);

    if (data?.getImageById?.length > 0) {
      setLoading(false);
      setPhotosArr([...data?.getImageById]);
      return;
    }

    setLoading(false);
    setNoPhotos(true);
    return;
  };

  return (
    <div
      id="client-pics-height-marker"
      className="client-pics-container"
      ref={clientPicsDiv}
    >
      <ImageModal
        modalOpen={modalOpen}
        handleClose={handleModalClose}
        imgSrc={selectedImg}
      />
      <button
        onClick={openBarCollapse}
        className="header-buttons-collapse-sticky"
      >
        <div>Client Photos</div>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="client-pics-upload-box">
          <label className="client-pics-upload-btn">
            <input
              onChange={handleFileChange}
              type="file"
              accept="image/*"
              name="clientImageUpload"
              multiple
            />
            <FileUploadIcon />{" "}
            {uploadedImageTempUrl?.length > 0
              ? "Change Photo(s)"
              : "Add Photo(s)"}
          </label>
          {uploadedImageTempUrl?.length > 0 && (
            <button className="client-pics-upload-btn" onClick={handleFileSave}>
              Save To Client
            </button>
          )}
          {uploadedImageTempUrl?.length > 0 && (
            <div className="client-pics-uploaded-images-box">
              {uploadedImageTempUrl.map((file) => {
                return (
                  <img
                    className="client-pics-uploaded-img"
                    key={file.name + Date.now()}
                    src={file.url}
                    alt="Admin upload"
                    onClick={() => {
                      modalHandler(true, file.url);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
        {loading && (
          <div className="client-pics-loading-box">
            <CircularProgress color="inherit" size={20} />
          </div>
        )}
        {!loading && photosArr?.length > 0 && (
          <div className="client-pics-images-container">
            {photosArr?.map((item, index) => {
              const imgId = "imgEl" + item._id;
              const btnId = "btnEl" + item._id;

              const html = (
                <div key={item._id + index} className="client-pics-img-box">
                  <button
                    className="client-pics-copy-btn"
                    id={btnId}
                    onPointerUp={async () => {
                      const img = document.querySelector(`#${imgId}`);
                      const blob = new ClipboardItem({
                        "image/png": copyImageToClip(img.src),
                      });

                      navigator.clipboard.write([blob]);
                      handleSnack("Image copied", 1500);
                    }}
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </button>
                  <img
                    id={imgId}
                    className="client-pics-img"
                    src={item?.imageData}
                    alt={`door pic ${index}`}
                    onClick={() => {
                      modalHandler(true, item?.imageData);
                    }}
                  />
                </div>
              );

              return html;
            })}
          </div>
        )}
        {!loading && noPhotos && (
          <div className="client-pics-none-display">No Pictures to Display</div>
        )}
      </div>
    </div>
  );
};

export default ClientPicsDisplay;
