import React, { useEffect } from "react";
import "./manageUsers.css";
import InviteNewUser from "../../adminComponents/InviteUser/InviteUser";
import ListAllUsers from "../../adminComponents/ListAllUsers/ListAllUsers";

const ManageUsers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="manage-users-container">
      <InviteNewUser open={true} />
      <ListAllUsers />
    </div>
  );
};

export default ManageUsers;
