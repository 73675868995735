import React, { useState, useRef, useEffect } from "react";
import "./clientQuotesDisplay.css";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import copyStringToClipboard from "../../../../utils/formatters/copyStringToClipboard";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ClientQuotesDisplay = ({
  heightTrigger,
  quotesArr,
  openQuoteModal,
  barCollapsed,
  setBarCollapsed,
  clickTrigger,
  handleSnack,
  openEditInvoiceQuoteModal,
}) => {
  const [hideOver, setHideOver] = useState(true);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const clientQuotesDiv = useRef();

  useEffect(() => {
    if (barCollapsed === true) {
      setHideOver(true);
    }

    if (barCollapsed === false) {
      setTimeout(() => {
        setHideOver(false);
      }, 800);
    }
  }, [barCollapsed]);

  useEffect(() => {
    if (clickTrigger > 0) {
      openBarCollapse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickTrigger]);

  const openBarCollapse = () => {
    if (heightTrigger) {
      heightTrigger();
    }

    setTimeout(() => {
      if (smaller900 === true && barCollapsed === true) {
        const navHeight = document.querySelector(
          "#admin-nav-height-marker"
        )?.offsetHeight;
        const pagePos = clientQuotesDiv.current.offsetTop;

        const totalMobileScroll = pagePos - navHeight - 5;
        window.scrollTo(0, totalMobileScroll);
      }

      // if (smaller900 === false && barCollapsed === true) {
      //   clientQuotesDiv.current.scrollIntoView({ block: "start" });
      // }
    }, 500);

    setBarCollapsed((prev) => !prev);
  };

  return (
    <div
      id="client-quotes-height-marker"
      className="client-quotes-container"
      ref={clientQuotesDiv}
    >
      <button
        onClick={openBarCollapse}
        className="header-buttons-collapse-sticky"
      >
        <div>Quotes</div>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div
        className={
          barCollapsed
            ? "content-hide"
            : hideOver
            ? "content-show-small"
            : "content-show-small show-over"
        }
      >
        {quotesArr?.length > 0 ? (
          <div className="client-quotes-info-display">
            <ul>
              {quotesArr?.map((quote, index) => {
                let bulletColor = /active/gi.test(quote?.quoteStatus)
                  ? "yellow"
                  : /invoiced/gi.test(quote?.quoteStatus)
                  ? "green"
                  : "red"; // default to expired;

                const viewCount = quote?.quoteViewed?.filter(
                  (view) => view?.isUser === false
                )?.length;

                return (
                  <li key={`quote-item-${quote?.id}-${index}`}>
                    <div
                      className="client-quote-item-box"
                      onClick={() => {
                        openQuoteModal(quote?.id);
                      }}
                    >
                      <div className="client-quote-item-info-box">
                        <span
                          className={`client-quote-circle bullet-color-${bulletColor}`}
                        />
                        <span>
                          {`${quote?.quoteNum}`}

                          {` - ${BAGroupTimeFormat(
                            quote?.dateCreated,
                            false,
                            true,
                            true
                          )} - ${viewCount}`}
                          <VisibilityIcon
                            sx={{
                              fontSize: "18px",
                              margin: "auto 3px",
                              padding: "0 0 2px",
                            }}
                            titleAccess="Quote Views"
                          />
                        </span>
                      </div>
                      <div className="client-quote-item-actions-box">
                        {navigator.clipboard && (
                          <button
                            className="client-invoices-quotes-copy-btn"
                            onClick={async (event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              const address = `https://bigginsdoorrefinishing.com/client/view-quote/${quote?.id}`;
                              copyStringToClipboard(
                                address,
                                handleSnack("Quote Link Copied", 2500)
                              );
                            }}
                          >
                            <InsertLinkIcon
                              sx={{ fontSize: "18px" }}
                              titleAccess="Copy Quote Link"
                            />
                          </button>
                        )}
                        <button
                          className="client-invoices-quotes-copy-btn"
                          onClick={async (event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            openEditInvoiceQuoteModal(false, quote?.id);
                          }}
                        >
                          <EditIcon
                            sx={{ fontSize: "16px" }}
                            titleAccess="Edit Quote"
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="client-quotes-none-display">No Quotes to Display</div>
        )}
      </div>
    </div>
  );
};

export default ClientQuotesDisplay;
