import React, { useState, useEffect, useRef } from "react";
import "./signAssignment.css";
import { useMutation, useLazyQuery } from "@apollo/client";
import { DELETE_SIGN_ASSIGN } from "../../../../utils/GraphQL/mutations";
import { GET_SIGN_ASSIGNMENT } from "../../../../utils/GraphQL/queries";
import DeleteIcon from "@mui/icons-material/Delete";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import { useMediaQuery } from "@mui/material";
import { Snackbar } from "@mui/material";
import UpdateSignAssignModal from "../SignAssignModal/SignAssignModal";

const SignAssignment = ({ heightTrigger, getVisitTrigger }) => {
  const SignAssignScrollRef = useRef();
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [modalOpen, setModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(true);
  const [updateSuccessObj, setUpdateSuccessObj] = useState({});
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });
  const [changedSign, setChangedSign] = useState({
    signNum: "",
    signName: "",
  });
  const [deleteSign] = useMutation(DELETE_SIGN_ASSIGN);
  const [querySigns, { data: signAssignment }] = useLazyQuery(
    GET_SIGN_ASSIGNMENT,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    querySigns();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSignAssign = (signNum, signName, index) => {
    if (signNum && signName) {
      setChangedSign({ signNum, signName });
      setSelectedIndex(index);
      setCreateNew(false);
      setModalOpen(true);

      return;
    }

    handleSnack("Assignment Not Found - Please Contact Support");
    return;
  };

  const newSignAssign = () => {
    setCreateNew(true);
    setModalOpen(true);
    return;
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  const deleteSignAssign = async (id) => {
    const { data: deleted } = await deleteSign({
      variables: { id },
      refetchQueries: [GET_SIGN_ASSIGNMENT],
    });

    if (deleted?.deleteSignAssignment === true) {
      getVisitTrigger()
      handleSnack("Sign Assignment Deleted");
    }

    setLoading(false);
  };

  const handleModalClose = (success) => {
    setModalOpen(false);
    if (success === true) {
      handleSuccess(selectedIndex);
      handleSnack("Sign Assignment Updated");
      querySigns();
      getVisitTrigger()
    }

    if (success === "new") {
      handleSnack("Sign Assignment Created");
      querySigns();
      getVisitTrigger()
    }

    return;
  };

  const handleSuccess = (index) => {
    setUpdateSuccessObj((state) => ({
      ...state, // <-- copy previous state
      [index]: !state[index], // <-- update value by index key
    }));

    setTimeout(() => {
      setUpdateSuccessObj((state) => ({
        ...state, // <-- copy previous state
        [index]: !state[index], // <-- update value by index key
      }));
    }, 1500);
  };

  return (
    <div
      ref={SignAssignScrollRef}
      id="sign-assign-height-marker"
      className="sign-assignment-container"
    >
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />

      <UpdateSignAssignModal
        selectedSignObj={changedSign}
        open={modalOpen}
        close={handleModalClose}
        createNew={createNew}
      />

      {loading && <AdminBDRSpinner message={"Loading..."} />}
      <button
        onClick={() => {
          heightTrigger();
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = SignAssignScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              SignAssignScrollRef.current.scrollIntoView({
                block: "start",
              });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 300);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Sign Assignments</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        {signAssignment?.getSignAssign?.length > 0 &&
          signAssignment?.getSignAssign?.map((item, index) => {
            return (
              <div
                key={`${item?.signName}${item?.id}${index}`}
                className={
                  updateSuccessObj[index]
                    ? "sign-assignment-box success"
                    : "sign-assignment-box"
                }
              >
                <div className="sign-list-box">
                  <span className="sign-list-item">
                    Sign Number: {item?.signNum}
                  </span>
                  <span className="sign-list-item">
                    Assigned To: {item?.signName}
                  </span>
                </div>
                <div className="sign-func-box">
                  <button
                    className="sign-delete-btn"
                    onClick={() => {
                      updateSignAssign(item?.signNum, item?.signName, index);
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="sign-delete-btn"
                    onClick={() => {
                      handleSnack(
                        <div className="sign-list-box-confirm">
                          <span className="sign-list-item-confirm">
                            Are you sure you want to delete sign {item?.signNum}
                            ?
                          </span>
                          <button
                            className="sign-delete-btn confirm"
                            onClick={() => {
                              setLoading(true);
                              deleteSignAssign(item?.id);
                            }}
                          >
                            <DeleteIcon
                              sx={{ fontSize: "20px", color: "#b40000" }}
                            />
                            (Delete)
                          </button>
                        </div>,
                        10000
                      );
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: "20px", color: "#b40000" }} />
                    (Delete)
                  </button>
                </div>
              </div>
            );
          })}
        <div className="sign-new-btn-box">
          <button className="sign-new-btn" onClick={newSignAssign}>
            Create New Sign Assignment
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignAssignment;
