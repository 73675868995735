import React from "react";
import "./bdrSpinner.css";

const BdrSpinner = ({ message }) => {
  return (
    <div className="spinner-container">
      <div className="loader-content">
        <h3>{message}</h3>
      </div>
      <div className="bdr-spinner"></div>
    </div>
  );
};

export default BdrSpinner;
