import React, { useState, useEffect, useRef } from "react";
import Rating from "@mui/material/Rating";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./reviewCarousel.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from "@apollo/client";
import { QUERY_REVIEWS } from "../../../utils/GraphQL/queries";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import OnVisible, { setDefaultProps } from "react-on-visible";

const ReviewCarousel = () => {
  const mediaQuery = useMediaQuery("(max-width: 900px)");
  const { data: queryData } = useQuery(QUERY_REVIEWS);
  const [state, setState] = useState(false);
  const [playPause, setPlayPause] = useState(true);
  const [allReviews, setAllReviews] = useState([]);
  const helloRef = useRef(null);
  const goodbyeRef = useRef(null);
  const nodeRef = state ? helloRef : goodbyeRef;
  const [touchState, setTouchState] = useState({
    touchStart: null,
    touchEnd: null,
  });
  const [carouselState, setCarouselState] = useState({
    currentBg: undefined,
    pauseTimerValue: Boolean,
    timerId: undefined,
  });

  setDefaultProps({
    bounce: true,
    visibleClassName: "review-vis",
    percent: 10,
  });

  useEffect(() => {
    if (queryData?.getReviews.length > 0) {
      setAllReviews(() => {
        const arr = Object.values(queryData.getReviews);
        return arr;
      });
    }
  }, [queryData]);

  useEffect(() => {
    if (allReviews?.length > 0) {
      startTimer();
      changeSelected();
    }
    // eslint-disable-next-line
  }, [allReviews]);

  useEffect(() => {
    timerFunc();
    // eslint-disable-next-line
  }, [carouselState.pauseTimerValue]);

  //checks the swiped direction after touch end
  //return boolean true if swiped right
  useEffect(() => {
    checkDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchState.touchEnd]);

  //changes the selected expanding box on hover and clears timers
  const hoverSelected = () => {
    setPlayPause(false);
    const pauseSelect = carouselState.currentBg;

    pauseTimer();

    setCarouselState((prev) => ({
      ...prev,
      currentBg: pauseSelect,
    }));

    return;
  };

  const changeSelected = () => {
    setCarouselState((prev) => {
      setState((state) => !state);

      if (prev.currentBg === undefined) {
        const randomBG = Math.floor(Math.random() * allReviews?.length);

        return { ...prev, currentBg: randomBG };
      }

      if (prev.currentBg === allReviews?.length - 1) {
        return { ...prev, currentBg: 0 };
      }

      return { ...prev, currentBg: prev.currentBg + 1 };
    });
  };

  const checkDirection = () => {
    let swipeRight = null;
    //left swipe is false
    if (touchState.touchEnd > touchState.touchStart) {
      swipeRight = false;
    }
    //right swipe is RIGHT(true)
    if (touchState.touchEnd < touchState.touchStart) {
      swipeRight = true;
    }

    //prevents initial render from breaking carousel
    if (swipeRight === undefined || swipeRight === null) 
    {return};
    setState((state) => !state);

    setCarouselState((prev) => {
      // clear timer change selected function
      clearInterval(prev.timerId);

      //reset carousel if already at end
      if (swipeRight === true && prev.currentBg >= allReviews?.length - 1) {
        return {
          ...prev,
          currentBg: 0,
        };
      }

      //go to end of carousel if already at beginning
      if (swipeRight === false && prev.currentBg <= 0) {
        return {
          ...prev,
          currentBg: (allReviews?.length - 1),
        };
      }

      if (swipeRight === true) {
        return {
          ...prev,
          currentBg: prev.currentBg + 1,
        };
      }

      if (swipeRight === false) {
        return {
          ...prev,
          currentBg: prev.currentBg - 1,
        };
      }
    });

    //restart carousel 8sec timer function
    startTimer();
  };

  //calls the change expanded box function after set time
  const timerFunc = () => {
    if (carouselState.pauseTimerValue === false) {
      clearInterval(carouselState.timerId);

      const timerId = setInterval(() => {
        changeSelected();
      }, 8000);

      setCarouselState((prev) => ({
        ...prev,
        timerId: timerId,
      }));
      return;
    }

    if (carouselState.pauseTimerValue === true) {
      clearInterval(carouselState.timerId);
      return;
    }
  };

  const startTimer = () => {
    setPlayPause(true);
    setCarouselState((prev) => ({
      ...prev,
      pauseTimerValue: false,
    }));
  };

  const pauseTimer = () => {
    setCarouselState((prev) => ({
      ...prev,
      pauseTimerValue: true,
    }));
  };

  const handleClickPause = () => {
    if (playPause === true) {
      pauseTimer();
      setPlayPause(false)
    }

    if (playPause === false) {
      startTimer();
    }
  }

  const touchStartHor = (event) => {
    setTouchState((prev) => ({
      ...prev,
      touchStart: event.changedTouches[0].screenX,
    }));
  };

  const touchEndHor = (event) => {
    setTouchState((prev) => ({
      ...prev,
      touchEnd: event.changedTouches[0].screenX,
    }));
  };

  return (
    <OnVisible>
      <div className="reviews-absolute-box">
        <div id="review-animation-box" className="review-invis">
          <SwitchTransition>
            <CSSTransition
              key={state}
              nodeRef={nodeRef}
              addEndListener={(done) => {
                nodeRef.current.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div
                className="review-box"
                onMouseOver={mediaQuery ? null : hoverSelected}
                onMouseLeave={mediaQuery ? null : startTimer}
                onTouchStart={mediaQuery ? touchStartHor : null}
                onTouchEnd={mediaQuery ? touchEndHor : null}
                onClick={mediaQuery ? handleClickPause : null}
                name={carouselState.currentBg}
              >
                <h3>The customers say what?!</h3>
                {carouselState?.currentBg === undefined ? null : (
                  <div ref={nodeRef} className="review-carousel">
                    <p>{allReviews[carouselState.currentBg]?.review}</p>
                    <div className="flex-start">
                      <h3>
                        - {allReviews[carouselState.currentBg]?.customerName}
                      </h3>
                      <Rating
                        name="half-rating-read"
                        value={Number(
                          allReviews[carouselState.currentBg]?.starRating
                        )}
                        precision={0.5}
                        readOnly
                      />
                    </div>
                  </div>
                )}
                {mediaQuery ? (
                  <span className="reviews-playing">{playPause ? <PlayArrowIcon /> : <PauseIcon />} Swipe for more - Tap to {playPause ? "pause" : "play"}</span>
                ) : playPause ? (
                  <span className="reviews-playing">
                    <PlayArrowIcon /> <span>(Hover to pause)</span>
                  </span>
                ) : (
                  <span className="reviews-playing">
                    <PauseIcon />
                  </span>
                )}
                <div className="playing-bar-box">
                  <div className="reviews-playing-bar"></div>
                  <div
                    className={nodeRef && playPause ? "playing-animation" : null}
                  ></div>
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </OnVisible>
  );
};

export default ReviewCarousel;
