import React, { useEffect, useState } from "react";
import {
  GET_TASK_BY_ID,
  GET_USER_INFO_FOR_TASKS,
} from "../../../../utils/GraphQL/queries";
import {
  MARK_USER_TASK_COMPLETED,
  UPDATE_USER_TASK,
} from "../../../../utils/GraphQL/mutations";
import "./clientTasksDisplay.css";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import CalendarTimeSelect from "../../adminComponents/CalendarTimeSelect/CalendarTimeSelect";
import ClientSearchInput from "../../adminComponents/ClientSearchInput/ClientSearchInput";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";

const clearTaskObj = {
  id: "",
  assigneeName: "",
  assigneeId: "",
  taskType: "",
  note: "",
  associatedClientId: "",
  associatedClientName: "",
  active: true,
  dateCreated: "",
  dueDate: "",
};

const TaskDisplayModal = ({
  handleSnack,
  taskId,
  openModal,
  setOpenModal,
  refetch,
  handleUpdatedTasksArr,
}) => {
  const [getTask] = useLazyQuery(GET_TASK_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [updateUserTaskGQLCall] = useMutation(UPDATE_USER_TASK);
  const [taskObj, setTaskObj] = useState({ ...clearTaskObj });
  const [pastDue, setPastDue] = useState(false);
  const navigate = useNavigate();
  const [clientAlreadyAssigned, setClientAlreadyAssigned] = useState(false);
  const [taskObjUpdateBool, setTaskObjUpdateBool] = useState(false);
  const [addClientOpenSearch, setAddClientOpenSearch] = useState(false);
  const [markTaskCompleteGQLCall] = useMutation(MARK_USER_TASK_COMPLETED);
  const [afterLastUserIndex, setAfterLastUserIndex] = useState(100);
  const [selectedUserTaskIndex, setSelectedUserTaskIndex] = useState(100);
  const [getUsersInfo, { data: usersInfo }] = useLazyQuery(
    GET_USER_INFO_FOR_TASKS,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    const body = document.querySelector("body");

    if (openModal === true) {
      body.style.overflow = "hidden";
    }
    if (openModal === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (taskId) {
      makeTaskCall();
      if (!usersInfo?.getUsersNamesForTasks) {
        getUsersInfo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, openModal]);

  useEffect(() => {
    if (usersInfo?.getUsersNamesForTasks?.length > 0 && openModal === true) {
      const selectIndexesLength = usersInfo?.getUsersNamesForTasks?.length;

      if (taskObj?.assigneeId === "22") {
        setSelectedUserTaskIndex(selectIndexesLength);
      } else if (taskObj?.assigneeId === "11") {
        setSelectedUserTaskIndex(selectIndexesLength + 1);
      } else {
        usersInfo?.getUsersNamesForTasks.forEach((obj, index) => {
          if (obj?._id === taskObj?.assigneeId) {
            setSelectedUserTaskIndex(index);
          }
        });
      }

      setAfterLastUserIndex(selectIndexesLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersInfo, openModal]);

  const closeModal = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setTaskObj({ ...clearTaskObj });
    setTaskObjUpdateBool(false);
    setPastDue(false);
    setClientAlreadyAssigned(false);
    setOpenModal(false);
  };

  const makeTaskCall = async () => {
    if (taskId) {
      const { data } = await getTask({
        variables: { taskId: taskId },
      });

      if (data?.getTaskById?.id) {
        const pastDue = parseInt(data?.getTaskById?.dueDate) < Date.now();
        setPastDue(pastDue);
        setTaskObj({ ...data?.getTaskById });
        if (data?.getTaskById?.associatedClientId?.length > 0) {
          setClientAlreadyAssigned(true);
        }
      }
    }
  };

  const unMarkTask = async () => {
    const { data } = await markTaskCompleteGQLCall({
      variables: {
        taskId,
        active: true,
      },
    });

    if (data?.markCompletedTask) {
      handleSnack("Task Restored", 3000);
      if (refetch) {
        refetch();
      }

      if (!taskObj?.active) {
        makeTaskCall();
      }

      setOpenModal(true);
      return;
    }
  };

  const markCompleted = async () => {
    const { data } = await markTaskCompleteGQLCall({
      variables: {
        taskId,
        active: false,
      },
    });

    if (data?.markCompletedTask) {
      handleSnack(
        <div className="admin-task-snack">
          <span className="admin-task-item-snack">Task Completed.</span>
          <button
            className="admin-task-snack-btn"
            onClick={() => {
              unMarkTask(taskId);
            }}
          >
            Restore Task?
          </button>
        </div>,
        8000
      );

      if (refetch) {
        refetch();
      }
      setOpenModal(false);
      return;
    }
  };

  const searchResultSet = (result) => {
    setTaskObjUpdateBool(true);
    setAddClientOpenSearch(false);
    setTaskObj((prev) => ({
      ...prev,
      associatedClientId: result?._id,
      associatedClientName: `${result?.firstName} ${result?.lastName}`,
    }));
  };

  const handleObjChangeForUpdate = async (eventTarget) => {
    setTaskObjUpdateBool(true);
    //event = string when calendar mills updates
    if (typeof eventTarget === "string") {
      setTaskObj((prev) => ({
        ...prev,
        dueDate: eventTarget,
      }));

      return;
    }

    if (typeof eventTarget !== "string") {
      const { name, value } = eventTarget;

      if (name === "user-task-assignee-update") {
        setSelectedUserTaskIndex(value);
        const selectedUser = usersInfo?.getUsersNamesForTasks[value];
        setTaskObj((prev) => ({
          ...prev,
          assigneeName:
            value === afterLastUserIndex
              ? "All"
              : `${selectedUser?.firstName} ${selectedUser?.lastName}`,
          assigneeId: value === afterLastUserIndex ? "22" : selectedUser?._id,
        }));

        return;
      }

      setTaskObj((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }
  };

  const handleTaskUpdate = async () => {
    const { data } = await updateUserTaskGQLCall({
      variables: {
        ...taskObj,
        prevAssignedClient: clientAlreadyAssigned,
      },
    });

    if (data?.updateUserTask) {
      handleUpdatedTasksArr({ ...data?.updateUserTask });
      handleSnack("Task Updated", 2000);
      closeModal();
      return;
    }

    handleSnack("Something went wrong...", 2000);
  };

  return (
    <>
      {openModal && taskObj?.id !== "" && (
        <div className="client-tasks-modal-container" onClick={closeModal}>
          <div
            className="client-tasks-modal-content"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="client-tasks-modal-close-container">
              <h3 className="client-tasks-modal-header">Task: </h3>
              {pastDue && (
                <h2 className="client-task-due-color-red">Past Due</h2>
              )}
              <button onClick={closeModal} className="close-modal-x-btn">
                X
              </button>
            </div>
            <ul className="client-tasks-modal-ul">
              <li>
                <span className="client-tasks-modal-li-marker"></span>
                <span className="green-color-text client-tasks-li-margin">
                  Client:
                </span>
                {taskObj?.associatedClientName?.length > 0 ? (
                  <button
                    className="client-tasks-start-button"
                    onClick={() => {
                      navigate(
                        `/bdr-office/clients/view-client/${taskObj?.associatedClientId}`
                      );
                    }}
                  >
                    {taskObj?.associatedClientName} - View
                  </button>
                ) : (
                  <>
                    <span>N/A</span>
                    <button
                      className="client-tasks-modal-add-btn"
                      onClick={() => {
                        setAddClientOpenSearch((prev) => !prev);
                      }}
                    >
                      {addClientOpenSearch ? "Cancel" : "+"}
                    </button>
                  </>
                )}
              </li>
              {addClientOpenSearch && (
                <li>
                  <div className="client-tasks-modal-search-div">
                    <ClientSearchInput
                      setWidth={"95%"}
                      returnResults={searchResultSet}
                    />
                  </div>
                </li>
              )}
              <li>
                <span className="client-tasks-modal-li-marker"></span>
                <span className="green-color-text client-tasks-li-margin">
                  Task Type:
                </span>
                <FormControl variant="standard" sx={{ my: 1 }}>
                  <Select
                    labelId="user-task-type-select"
                    id="user-task-type-select-input"
                    name="taskType"
                    value={taskObj?.taskType}
                    disabled={/reminder/gi.test(taskObj?.taskType)}
                    onChange={(event) => {
                      handleObjChangeForUpdate(event.target);
                    }}
                    disableUnderline={true}
                    sx={{
                      color: "#fff",
                      WebkitTextFillColor: "#fff",
                      "&& .Mui-disabled": {
                        color: "#fff !important",
                        WebkitTextFillColor: "#fff",
                      },
                    }}
                    style={{
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    <MenuItem value="F/U">F/U</MenuItem>
                    <MenuItem value="Schedule">Schedule</MenuItem>
                    <MenuItem value="Send Payment Receipt">
                      Send Payment Receipt
                    </MenuItem>
                    <MenuItem value="Appt / Invoice">Appt / Invoice</MenuItem>
                    <MenuItem value="Add To Ledger">Add To Ledger</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                    {/reminder/gi.test(taskObj?.taskType) && (
                      <MenuItem value="Reminder">Reminder</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </li>
              <li>
                <span className="client-tasks-modal-li-marker"></span>
                <span className="green-color-text client-tasks-li-margin">
                  Status:
                </span>
                {taskObj?.active ? "Active" : "Completed"}
              </li>
              <li>
                <span className="client-tasks-modal-li-marker"></span>
                <span className="green-color-text client-tasks-li-margin">
                  Assigned to:
                </span>
                <FormControl variant="standard" sx={{ mx: 0.8 }}>
                  <Select
                    labelId="user-task-assignee-update"
                    id="user-task-assignee-update-input"
                    name="user-task-assignee-update"
                    value={selectedUserTaskIndex}
                    onChange={(event) => {
                      handleObjChangeForUpdate(event.target);
                    }}
                    disabled={taskObj?.assigneeId === "11"}
                    disableUnderline={true}
                    sx={{
                      color: "#fff",
                      WebkitTextFillColor: "#fff",
                      "&& .Mui-disabled": {
                        color: "#fff !important",
                        WebkitTextFillColor: "#fff",
                      },
                    }}
                    style={{ borderBottom: "1px solid #fff" }}
                  >
                    {usersInfo?.getUsersNamesForTasks?.length > 0 &&
                      usersInfo?.getUsersNamesForTasks?.map(
                        (userInfo, index) => {
                          const username = `${userInfo?.firstName} ${userInfo?.lastName}`;

                          return (
                            <MenuItem key={userInfo?._id} value={index}>
                              {username?.trim()}
                            </MenuItem>
                          );
                        }
                      )}
                    <MenuItem value={afterLastUserIndex}>All</MenuItem>
                    {taskObj?.assigneeId === "11" && (
                      <MenuItem value={afterLastUserIndex + 1}>System</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </li>
              <li>
                <span className="client-tasks-modal-li-marker"></span>
                <span className="green-color-text client-tasks-li-margin">
                  Created:
                </span>
                {BAGroupTimeFormat(taskObj?.dateCreated)}
              </li>
              <li>
                <span className="client-tasks-modal-li-marker"></span>
                <div className="client-tasks-modal-cal-div">
                  <CalendarTimeSelect
                    hideInput={true}
                    returnMill={handleObjChangeForUpdate}
                    leadingText={
                      <>
                        <span className="green-color-text client-tasks-li-margin">
                          Due Date:
                        </span>
                        {BAGroupTimeFormat(taskObj?.dueDate)}
                      </>
                    }
                    passedSelectedTime={taskObj?.dueDate}
                  />
                </div>
              </li>
              <li>
                <CssTextFieldStandard
                  sx={{ width: "100%", my: 0.5 }}
                  label="Note:"
                  name="note"
                  value={taskObj?.note}
                  onChange={(event) => handleObjChangeForUpdate(event.target)}
                  multiline
                  minRows={2}
                />
              </li>
            </ul>
            <div className="client-tasks-modal-mark-complete-box">
              {taskObjUpdateBool && (
                <button
                  className="client-tasks-start-button"
                  onClick={handleTaskUpdate}
                >
                  Update
                </button>
              )}
              <button
                className="client-tasks-start-button"
                onClick={() => {
                  if (taskObj?.active) {
                    markCompleted(taskObj?.id);
                    return;
                  }
                  unMarkTask(taskObj?.id);
                  return;
                }}
              >
                {taskObj?.active ? "Mark Completed" : "Mark Incomplete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskDisplayModal;
