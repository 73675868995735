import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  maxWidth: "95dvw",
  maxHeight: "95dvh",
  background: "linear-gradient(to left top,#34113F, #2A8FBD)",
  border: "3px solid #fff",
  color: "#fff",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const imgStyle = {
  maxHeight: "90dvh",
  maxWidth: "90dvw",
  border: "1px solid #fff",
  borderRadius: "8px",
  boxShadow: "-3px 3px 6px #000000",
};

const ImageModal = ({ modalOpen, handleClose, imgSrc }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            {imgSrc && (
              <img
                style={imgStyle}
                onClick={handleClose}
                src={imgSrc}
                alt="BDR"
              />
            )}
            
            {!imgSrc && (
              <h2>Image Unavailable</h2>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ImageModal;
