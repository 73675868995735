import React, { useEffect, useRef, useState } from "react";
import "./privacyPolicy-TOS.css";
// import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";

const TermsOfService = () => {
  //   const navigate = useNavigate();
  const mobileMedia = useMediaQuery("(max-width: 900px)");
  const [navScroll, setNavScroll] = useState({
    top: null,
    toc: null,
    topClass: "privacy-tos-quick-anchor-btn",
    tocClass: "privacy-tos-quick-anchor-btn",
    boxClass: "privacy-tos-quick-anchor-box",
  });
  const TOCRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navHeight = document.querySelector("#bdr-navbar")?.offsetHeight;
    const elHeight = TOCRef.current?.scrollHeight;
    const elOffsetTop = TOCRef.current?.offsetTop;

    const handleLog = () => {
      const setTop = window.scrollY > window.innerHeight;
      const setTOC = elOffsetTop + navHeight + elHeight < window.scrollY;
      if (setTop !== navScroll?.top || setTOC !== navScroll.toc) {
        const box = setTop
          ? setTOC
            ? "privacy-tos-quick-anchor-box anchor-box-anim-2"
            : "privacy-tos-quick-anchor-box anchor-box-anim-1"
          : "privacy-tos-quick-anchor-box";
        setNavScroll({
          top: setTop,
          toc: setTOC,
          topClass: setTop
            ? "privacy-tos-quick-anchor-btn anchor-in"
            : "privacy-tos-quick-anchor-btn",
          tocClass: setTOC
            ? "privacy-tos-quick-anchor-btn anchor-in"
            : "privacy-tos-quick-anchor-btn",
          boxClass: box,
        });
      }
    };

    // Bind the event listener
    document.addEventListener("scroll", handleLog);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("scroll", handleLog);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (num) => {
    let finalScroll = 1;
    if (num !== "top") {
      const id = num === "toc" ? "#tos-toc" : `#privacy-tos-item-${num}`;
      let centerScroll = document.querySelector(id)?.offsetTop;

      const nav = document.querySelector("#bdr-navbar")?.offsetHeight;
      finalScroll = mobileMedia ? centerScroll - nav : centerScroll;
    }

    window.scrollTo({ top: finalScroll, left: 0, behavior: "smooth" });
  };

  return (
    <div className="privacy-tos-page">
      <div className={navScroll?.boxClass}>
        <button
          onClick={() => handleScroll("top")}
          className={navScroll?.topClass}
        >
          Top
        </button>
        <button
          onClick={() => handleScroll("toc")}
          className={navScroll?.tocClass}
        >
          TOC
        </button>
      </div>
      <div className="privacy-tos-content">
        <h1>Terms of Service</h1>
        <span className="privacy-tos-updated">Last updated April 11, 2024</span>
        <p className="privacy-tos-para">
          We are BDR Services ("we," "us," or "our"). We operate , as well as
          any other related products and services that refer or link to these
          legal terms (the "Legal Terms") (collectively, the "Services").
        </p>
        <div className="privacy-tos-para">
          You can contact us by email to{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>{" "}
          or by mail to{" "}
          <ul className="privacy-tos-ul-no-dec">
            <li>Leanne R Turner</li>
            <li>18975 Marbach Ln</li>
            <li>Suite 22-1209</li>
            <li>San Antonio, TX 78266 United States</li>
          </ul>
        </div>
        <p className="privacy-tos-para">
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ("you"), and Leanne
          R Turner, concerning your access to and use of the Services. You agree
          that by accessing the Services, you have read, understood, and agreed
          to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL
          OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
          SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p className="privacy-tos-para">
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. We reserve the right, in our sole discretion, to
          make changes or modifications to these Legal Terms at any time and for
          any reason. We will alert you about any changes by updating the "Last
          updated" date of these Legal Terms, and you waive any right to receive
          specific notice of each such change. It is your responsibility to
          periodically review these Legal Terms to stay informed of updates. You
          will be subject to, and will be deemed to have been made aware of and
          to have accepted, the changes in any revised Legal Terms by your
          continued use of the Services after the date such revised Legal Terms
          are posted.
        </p>
        <p className="privacy-tos-para">
          We recommend that you print a copy of these Legal Terms for your
          records.
        </p>
        <div ref={TOCRef} id="tos-toc">
          <h5>TABLE OF CONTENTS</h5>
          <ul className="privacy-tos-ul-numbered">
            <li>
              <button
                onClick={() => handleScroll(1)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                OUR SERVICES
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(2)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                INTELLECTUAL PROPERTY RIGHTS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(3)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                USER REPRESENTATIONS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(4)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                PROHIBITED ACTIVITIES
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(5)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                USER GENERATED CONTRIBUTIONS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(6)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                CONTRIBUTION LICENSE
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(7)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                SERVICES MANAGEMENT
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(8)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                TERM AND TERMINATION
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(9)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                MODIFICATIONS AND INTERRUPTIONS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(10)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                GOVERNING LAW
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(11)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                DISPUTE RESOLUTION
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(12)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                CORRECTIONS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(13)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                DISCLAIMER
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(14)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                LIMITATIONS OF LIABILITY
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(15)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                INDEMNIFICATION
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(16)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                USER DATA
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(17)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(18)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                MISCELLANEOUS
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScroll(19)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                MOBILE MESSAGING
              </button>
              <ul>
                <li>
                  <button
                    onClick={() => handleScroll("19a")}
                    className="privacy-tos-toc-links privacy-tos-link-anim"
                  >
                    a. Services Description
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScroll("19b")}
                    className="privacy-tos-toc-links privacy-tos-link-anim"
                  >
                    b. User Opt-In
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScroll("19c")}
                    className="privacy-tos-toc-links privacy-tos-link-anim"
                  >
                    c. User Opt-Out and Support
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScroll("19d")}
                    className="privacy-tos-toc-links privacy-tos-link-anim"
                  >
                    d. Disclaimer of Warranty and Liability
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleScroll("19e")}
                    className="privacy-tos-toc-links privacy-tos-link-anim"
                  >
                    e. Modifications
                  </button>
                </li>
              </ul>
            </li>
            <li>
              <button
                onClick={() => handleScroll(20)}
                className="privacy-tos-toc-links privacy-tos-link-anim"
              >
                CONTACT US
              </button>
            </li>
          </ul>
        </div>

        <h5 id="privacy-tos-item-1">1. OUR SERVICES</h5>
        <p className="privacy-tos-para">
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
        </p>
        <h5 id="privacy-tos-item-2">2. INTELLECTUAL PROPERTY RIGHTS</h5>
        <p className="privacy-tos-para">
          <strong>Our intellectual property</strong>
        </p>
        <p className="privacy-tos-para">
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the "Content"), as well as the
          trademarks, service marks, and logos contained therein (the "Marks").
        </p>
        <p className="privacy-tos-para">
          The Content and Marks are provided in or through the Services "AS IS"
          for your personal, non-commercial use to view similar projects for
          potential examples of completed Services.
        </p>
        <p className="privacy-tos-para">
          <strong>Your use of our Services</strong>
        </p>
        <p className="privacy-tos-para">
          Except as set out in this section or elsewhere in our Legal Terms, no
          part of the Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p className="privacy-tos-para">
          If you wish to make any use of the Services, Content, or Marks other
          than as set out in this section or elsewhere in our Legal Terms,
          please address your request to:{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>
          . If we ever grant you the permission to post, reproduce, or publicly
          display any part of our Services or Content, you must identify us as
          the owners or licensors of the Services, Content, or Marks and ensure
          that any copyright or proprietary notice appears or is visible on
          posting, reproducing, or displaying our Content.
        </p>
        <div className="privacy-tos-para">
          We reserve all rights not expressly granted to you in and to the
          Services, Content, and Marks.
        </div>
        <div className="privacy-tos-para">
          Any breach of these Intellectual Property Rights will constitute a
          material breach of our Legal Terms and your right to use our Services
          will terminate immediately.
        </div>
        <div className="privacy-tos-para">
          <strong>Your submissions</strong>
        </div>
        <div className="privacy-tos-para">
          Please review this section and the "PROHIBITED ACTIVITIES" section
          carefully prior to using our Services to understand the (a) rights you
          give us and (b) obligations you have when you post or upload any
          content through the Services.
        </div>
        <div className="privacy-tos-para">
          Submissions: By directly sending us any question, comment, suggestion,
          idea, feedback, or other information about the Services
          ("Submissions"), you agree to assign to us all intellectual property
          rights in such Submission. You agree that we shall own this Submission
          and be entitled to its unrestricted use and dissemination for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you.
        </div>
        <div className="privacy-tos-para">
          You are responsible for what you upload: By sending us Submissions
          through any part of the Services you: confirm that you have read and
          agree with our "PROHIBITED ACTIVITIES" and will not send, publish,
          upload, or transmit through the Services any Submission that is
          illegal, harassing, hateful, harmful, defamatory, obscene, bullying,
          abusive, discriminatory, threatening to any person or group, sexually
          explicit, false, inaccurate, deceitful, or misleading; to the extent
          permissible by applicable law, waive any and all moral rights to any
          such Submission; warrant that any such Submission are original to you
          or that you have the necessary rights and licenses to submit such
          Submissions and that you have full authority to grant us the
          above-mentioned rights in relation to your Submissions; and warrant
          and represent that your Submissions do not constitute confidential
          information. You are solely responsible for your Submissions and you
          expressly agree to reimburse us for any and all losses that we may
          suffer because of your breach of (a) this section, (b) any third
          party’s intellectual property rights, or (c) applicable law.
        </div>
        <h5 id="privacy-tos-item-3">3. USER REPRESENTATIONS</h5>
        <div className="privacy-tos-para">
          By using the Services, you represent and warrant that: (1) you have
          the legal capacity and you agree to comply with these Legal Terms; (2)
          you are not a minor in the jurisdiction in which you reside; (3) you
          will not access the Services through automated or non-human means,
          whether through a bot, script or otherwise; (4) you will not use the
          Services for any illegal or unauthorized purpose; and (5) your use of
          the Services will not violate any applicable law or regulation.
        </div>
        <div className="privacy-tos-para">
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Services
          (or any portion thereof).
        </div>
        <h5 id="privacy-tos-item-4">4. PROHIBITED ACTIVITIES</h5>
        <p className="privacy-tos-para">
          You may not access or use the Services for any purpose other than that
          for which we make the Services available. The Services may not be used
          in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        <p className="privacy-tos-para">
          As a user of the Services, you agree not to:
          <br />
          Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us. Trick,
          defraud, or mislead us and other users, especially in any attempt to
          learn sensitive account information.
        </p>
        <p className="privacy-tos-para">
          Circumvent, disable, or otherwise interfere with security-related
          features of the Services, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Services and/or the Content contained therein.
        </p>
        <p className="privacy-tos-para">
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Services. Use any information obtained from the Services in order to
          harass, abuse, or harm another person. Make improper use of our
          support services or submit false reports. Use the Services in a manner
          inconsistent with any applicable laws or regulations. Engage in
          unauthorized framing of or linking to the Services.
        </p>
        <p className="privacy-tos-para">
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, that interferes with any party’s
          uninterrupted use and enjoyment of the Services or modifies, impairs,
          disrupts, alters, or interferes with the use, features, functions,
          operation, or maintenance of the Services.
        </p>
        <p className="privacy-tos-para">
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools. Delete the copyright or
          other proprietary rights notice from any Content. Attempt to
          impersonate another user or person or use the username of another
          user. Upload or transmit (or attempt to upload or to transmit) any
          material that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as "spyware" or "passive
          collection mechanisms" or "pcms").
        </p>
        <p className="privacy-tos-para">
          Interfere with, disrupt, or create an undue burden on the Services or
          the networks or services connected to the Services. Harass, annoy,
          intimidate, or threaten any of our employees or agents engaged in
          providing any portion of the Services to you. Attempt to bypass any
          measures of the Services designed to prevent or restrict access to the
          Services, or any portion of the Services.
        </p>
        <p className="privacy-tos-para">
          Copy or adapt the Services' software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code. Except as permitted by
          applicable law, decipher, decompile, disassemble, or reverse engineer
          any of the software comprising or in any way making up a part of the
          Services. Except as may be the result of standard search engine or
          Internet browser usage, use, launch, develop, or distribute any
          automated system, including without limitation, any spider, robot,
          cheat utility, scraper, or offline reader that accesses the Services,
          or use or launch any unauthorized script or other software.
        </p>
        <p className="privacy-tos-para">
          Make any unauthorized use of the Services, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email. Use the Services as part
          of any effort to compete with us or otherwise use the Services and/or
          the Content for any revenue-generating endeavor or commercial
          enterprise.
        </p>
        <h5 id="privacy-tos-item-5">5. USER GENERATED CONTRIBUTIONS</h5>
        <p className="privacy-tos-para">
          The Services does not offer users to submit or post content. We may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Services, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Services and through third-party websites. When you create or make
          available any Contributions, you thereby represent and warrant that:
        </p>
        <h5 id="privacy-tos-item-6">6. CONTRIBUTION LICENSE</h5>
        <p className="privacy-tos-para">
          You and Services agree that we may access, store, process, and use any
          information and personal data that you provide.
        </p>
        <p className="privacy-tos-para">
          By submitting suggestions or other feedback regarding the Services,
          you agree that we can use and share such feedback for any purpose
          without compensation to you.
        </p>
        <p className="privacy-tos-para">
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Services. You
          are solely responsible for your Contributions to the Services and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </p>
        <h5 id="privacy-tos-item-7">7. SERVICES MANAGEMENT</h5>
        <p className="privacy-tos-para">
          We reserve the right, but not the obligation, to: (1) monitor the
          Services for violations of these Legal Terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Legal Terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services
          or otherwise disable any content that may be in any way burdensome to
          our systems; and (5) otherwise manage the Services in a manner
          designed to protect our rights and property and to facilitate the
          proper functioning of the Services.
        </p>
        <h5 id="privacy-tos-item-8">8. TERM AND TERMINATION</h5>
        <p className="privacy-tos-para">
          These Legal Terms shall remain in full force and effect while you use
          the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR INFORMATION
          THAT YOU SUBMITTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.
        </p>
        <p className="privacy-tos-para">
          If we terminate or suspend your account for any reason, you are
          prohibited from Services under your name, a fake or borrowed name, or
          the name of any third party, even if you may be acting on behalf of
          the third party. In addition to terminating or suspending your
          account, we reserve the right to take appropriate legal action,
          including without limitation pursuing civil, criminal, and injunctive
          redress.
        </p>
        <h5 id="privacy-tos-item-9">9. MODIFICATIONS AND INTERRUPTIONS</h5>
        <p className="privacy-tos-para">
          We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Services. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services.
        </p>
        <p className="privacy-tos-para">
          We cannot guarantee the Services will be available at all times. We
          may experience hardware, software, or other problems or need to
          perform maintenance related to the Services, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify the Services
          at any time or for any reason without notice to you. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience
          caused by your inability to access or use the Services during any
          downtime or discontinuance of the Services. Nothing in these Legal
          Terms will be construed to obligate us to maintain and support the
          Services or to supply any corrections, updates, or releases in
          connection therewith.
        </p>
        <h5 id="privacy-tos-item-10">10. GOVERNING LAW</h5>
        <p className="privacy-tos-para">
          These Legal Terms shall be governed by and defined following the laws
          of Texas. BDR Services and yourself irrevocably consent that the
          courts of Bexar County shall have exclusive jurisdiction to resolve
          any dispute which may arise in connection with these Legal Terms.
        </p>
        <h5 id="privacy-tos-item-11">11. DISPUTE RESOLUTION</h5>
        <p className="privacy-tos-para">
          <strong>Informal Negotiations</strong>
        </p>
        <p className="privacy-tos-para">
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Legal Terms (each a "Dispute"
          and collectively, the "Disputes") brought by either you or us
          (individually, a "Party" and collectively, the "Parties"), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least <strong>30</strong>{" "}
          days before initiating arbitration. Such informal negotiations
          commence upon written notice from one Party to the other Party.
        </p>
        <p className="privacy-tos-para">
          <strong>Exceptions to Informal Negotiations</strong>
        </p>
        <p className="privacy-tos-para">
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations: (a) any Disputes
          seeking to enforce or protect, or concerning the validity of, any of
          the intellectual property rights of a Party; (b) any Dispute related
          to, or arising from, allegations of theft, piracy, invasion of
          privacy, or unauthorized use; and (c) any claim for injunctive relief.
          If this provision is found to be illegal or unenforceable, then
          neither Party will elect to arbitrate any Dispute falling within that
          portion of this provision found to be illegal or unenforceable and
          such Dispute shall be decided by a court of competent jurisdiction
          within the courts listed for jurisdiction above, and the Parties agree
          to submit to the personal jurisdiction of that court.
        </p>
        <h5 id="privacy-tos-item-12">12. CORRECTIONS</h5>
        <p className="privacy-tos-para">
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
        </p>
        <h5 id="privacy-tos-item-13">13. DISCLAIMER</h5>
        <p className="privacy-tos-para">
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
          ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN,
          (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
          SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
          (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
          LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
          CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
          SERVICES.
        </p>
        <h5 id="privacy-tos-item-14">14. LIMITATIONS OF LIABILITY</h5>
        <p className="privacy-tos-para">
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER
          OF THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND
          INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
          THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
          YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY
          TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <h5 id="privacy-tos-item-15">15. INDEMNIFICATION</h5>
        <p className="privacy-tos-para">
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) use of the
          Services; (2) breach of these Legal Terms; (3) any breach of your
          representations and warranties set forth in these Legal Terms; (4)
          your violation of the rights of a third party, including but not
          limited to intellectual property rights; or (5) any overt harmful act
          toward any other user of the Services. Notwithstanding the foregoing,
          we reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and you agree to cooperate, at your expense, with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </p>
        <h5 id="privacy-tos-item-16">16. USER DATA</h5>
        <p className="privacy-tos-para">
          We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as
          data relating to your use of the Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.
        </p>
        <h5 id="privacy-tos-item-17">
          17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </h5>
        <p className="privacy-tos-para">
          Visiting the Services, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </p>
        <h5 id="privacy-tos-item-18">18. MISCELLANEOUS</h5>
        <p className="privacy-tos-para">
          These Legal Terms and any policies or operating rules posted by us on
          the Services or in respect to the Services constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Legal Terms shall
          not operate as a waiver of such right or provision. These Legal Terms
          operate to the fullest extent permissible by law. We may assign any or
          all of our rights and obligations to others at any time. We shall not
          be responsible or liable for any loss, damage, delay, or failure to
          act caused by any cause beyond our reasonable control. If any
          provision or part of a provision of these Legal Terms is determined to
          be unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Legal Terms and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Legal
          Terms or use of the Services. You agree that these Legal Terms will
          not be construed against us by virtue of having drafted them. You
          hereby waive any and all defenses you may have based on the electronic
          form of these Legal Terms and the lack of signing by the parties
          hereto to execute these Legal Terms.
        </p>
        <h5 id="privacy-tos-item-19">19. MOBILE MESSAGING</h5>
        <p id="privacy-tos-item-19a" className="privacy-tos-para">
          <strong>a. Services Description:</strong>
          <br /> We may send promotional and transactional mobile messages in
          various formats through the Services. Promotional messages advertise
          our Services and may include [promotions, specials, other marketing
          offers and contact follow ups]. Transactional messages relate to an
          existing or ongoing transaction and may include [notifications and
          updates, appointment reminders, annual maintenance reminders and other
          transaction-related information]. No information will be shared with
          third parties/affiliates for marketing or promotional purposes. Mobile
          messages may be sent using an automated technology, including an
          autodialer, automated system, or automatic telephone dialing system.
          Message frequency will vary. You agree that we, our affiliates, and
          any third-party service providers may send you messages regarding the
          foregoing topics or any topic and that such messages and/or calls may
          be made or placed using different telephone numbers or short codes,
          except in connection with marketing purposes. We do not charge for
          mobile messages sent through the Services but you are responsible for
          any message and data rates imposed by your mobile provider, as
          standard data and message rates may apply for short message service
          and multimedia message alerts.
        </p>
        <p id="privacy-tos-item-19b" className="privacy-tos-para">
          <strong>b. User Opt-In:</strong>
          <br />
          By providing your mobile phone number to us, you are voluntarily
          opting in to the Services and you agree to receive recurring mobile
          messages from us at the mobile phone number associated with your
          opt-in, even if such number is registered on any state or federal “Do
          Not Call” list. You agree that any mobile phone number you provide to
          us is a valid mobile phone number of which you are the owner or
          authorized user. If you change your mobile phone number or are no
          longer the owner or authorized user of the mobile phone number, you
          agree to promptly notify us at{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>
          . Your participation in the Services does not require that you make
          any purchase from us and your participation in the Services is
          completely voluntary.
        </p>
        <p id="privacy-tos-item-19c" className="privacy-tos-para">
          <strong>c. User Opt-Out and Support:</strong>
          <br />
          You may opt-out of the Services at any time. If you wish to opt-out of
          the Services and stop receiving mobile messages from us, or you no
          longer agree to these Mobile Messaging Terms, reply STOP, QUIT,
          CANCEL, OPT-OUT, and/or UNSUBSCRIBE to the mobile number the message
          is sent from. You may continue to receive text messages for a short
          period while we process your request and you may receive a one-time
          opt-out confirmation message. You understand and agree that the
          foregoing is the only reasonable method of opting out. If you want to
          pursue use of our Services again, just sign up as you did the first
          time, and we will start sending messages to you again. For support,
          reply HELP to any mobile message from us.
          <br />
          <br />
          Our mobile messaging platform may not recognize requests that modify
          the foregoing commands, and you agree that we and our service
          providers will not be liable for failing to honor requests that do not
          comply with the requirements in these Mobile Messaging Terms. We may
          also change the telephone number or short code we use to operate our
          Services and we will notify you of any such change. You acknowledge
          that any requests sent to a telephone number or short code that has
          been changed may not be received by us and we will not be responsible
          for failing to honor a request sent to a telephone number or short
          code that has been changed.
        </p>
        <p id="privacy-tos-item-19d" className="privacy-tos-para">
          <strong>d. Disclaimer of Warranty and Liability:</strong>
          <br /> The Services is offered on an “as-is” basis and may not be
          available in all areas, at all times, or on all mobile providers. You
          agree that neither we nor our service providers will be liable for any
          failed, delayed, or misdirected delivery of any mobile message or
          information sent through the Services.
        </p>
        <p id="privacy-tos-item-19e" className="privacy-tos-para">
          <strong>e. Modifications:</strong>
          <br /> We may modify or cancel the Services or any of its features at
          any time, with or without notice. To the extent permitted by
          applicable law, we may also modify these Mobile Messaging Terms at any
          time. Any such modification will take effect when it is posted to our
          website. You agree to review these Mobile Messaging Terms periodically
          to ensure that you are aware of any modifications. Your continued
          participation in the Services will constitute your acceptance of those
          modifications.
        </p>
        <h5 id="privacy-tos-item-20">20. HOW CAN YOU CONTACT US?</h5>
        <div className="privacy-tos-para">
          If you have questions or comments, you may email us at{" "}
          <a
            className="privacy-tos-toc-links"
            href="mailto:leanne@bigginsdoorrefinishing.com"
          >
            leanne@bigginsdoorrefinishing.com
          </a>
          <br />
          or contact us by post at:
          <ul className="privacy-tos-ul-no-dec">
            <li>Leanne R Turner</li>
            <li>18975 Marbach Ln</li>
            <li>Suite 22-1209</li>
            <li>San Antonio, TX 78266 United States</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
