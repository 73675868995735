import React, { useState, useRef, useEffect } from "react";
import "./adminDashboard.css";
import {
  MARK_USER_TASK_COMPLETED,
  UPDATE_USER_TASK,
} from "../../../../utils/GraphQL/mutations";
import { GET_USER_INFO_FOR_TASKS } from "../../../../utils/GraphQL/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import UserTaskCreate from "../../adminComponents/UserTaskModal/UserTaskCreate";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Auth from "../../../../utils/auth";
import CalendarTimeSelect from "../../adminComponents/CalendarTimeSelect/CalendarTimeSelect";
import ClientSearchInput from "../../adminComponents/ClientSearchInput/ClientSearchInput";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";

const clearTaskObj = {
  id: "",
  assigneeName: "",
  assigneeId: "",
  taskType: "",
  note: "",
  associatedClientId: "",
  associatedClientName: "",
  active: true,
  dateCreated: "",
  dueDate: "",
};

const AdminDashboardTasks = ({
  tasks,
  queryTasks,
  handleSnack,
  handleSelectedTask,
  handleTaskModal,
  useTaskModal,
  handleUpdatedTasksArr,
}) => {
  const [markTaskCompleteGQLCall] = useMutation(MARK_USER_TASK_COMPLETED);
  const [updateUserTaskGQLCall] = useMutation(UPDATE_USER_TASK);
  const [getUsersInfo, { data: usersInfo }] = useLazyQuery(
    GET_USER_INFO_FOR_TASKS,
    { fetchPolicy: "network-only" }
  );
  const [afterLastUserIndex, setAfterLastUserIndex] = useState(100);
  const [selectedTaskSortId, setSelectedTaskSortId] = useState("");
  const [perm, setPerm] = useState("");
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [selectedUserTaskIndex, setSelectedUserTaskIndex] = useState(0);
  const [taskObjUpdateBool, setTaskObjUpdateBool] = useState(false);
  const [taskObj, setTaskObj] = useState({ ...clearTaskObj });
  const [pastDue, setPastDue] = useState(false);
  const [shadowOn, setShadowOn] = useState(false);
  const [addClientOpenSearch, setAddClientOpenSearch] = useState(false);
  const [clientAlreadyAssigned, setClientAlreadyAssigned] = useState(false);
  const navigate = useNavigate();
  const tasksBoxRef = useRef();
  const shadowTopRef = useRef();
  const shadowBottomRef = useRef();
  const taskItemsBox = useRef();
  const shadowWrapper = useRef();

  useEffect(() => {
    const { data: user } = Auth.getProfile();
    if (user.permission) {
      setPerm(user.permission);
      if (user.permission === "admin") {
        getUsersInfo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usersInfo?.getUsersNamesForTasks?.length > 0) {
      const index = usersInfo?.getUsersNamesForTasks?.length;
      const currentId = Auth?.getProfile()?.data?._id;
      const currentUserIndex = usersInfo?.getUsersNamesForTasks?.map(
        (user, index) => {
          if (user?._id === currentId) {
            return index;
          }
          return false;
        }
      );

      const filteredIndex = currentUserIndex?.filter(
        (item) => item !== false
      )[0];

      setAfterLastUserIndex(index);
      setSelectedUserIndex(filteredIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersInfo]);

  useEffect(() => {
    queryTasks(selectedTaskSortId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskSortId]);

  useEffect(() => {
    const tasksBoxEl = taskItemsBox.current;

    if (tasksBoxEl.offsetHeight < tasksBoxEl.scrollHeight) {
      setShadowOn(true);
      const handleShadow = () => {
        const contentScroll = tasksBoxEl.scrollHeight - tasksBoxEl.offsetHeight;

        var currentScroll = tasksBoxEl.scrollTop / contentScroll;
        shadowTopRef.current.style.opacity = currentScroll;
        shadowBottomRef.current.style.opacity = 1 - currentScroll;
      };

      // Bind the event listener
      tasksBoxEl.addEventListener("scroll", handleShadow);
      return () => {
        // Unbind the event listener on clean up
        tasksBoxEl.removeEventListener("scroll", handleShadow);
      };
    }

    if (tasksBoxEl.offsetHeight >= tasksBoxEl.scrollHeight) {
      setShadowOn(false);
    }
  }, [tasks]);

  const unMarkTask = async (taskId) => {
    const { data } = await markTaskCompleteGQLCall({
      variables: {
        taskId,
        active: true,
      },
    });

    if (data?.markCompletedTask) {
      handleSnack("Task Restored", 3000);
      const id = perm === "admin" ? selectedTaskSortId : "";
      queryTasks(id);
      return;
    }
  };

  const markCompleted = async (taskId) => {
    const { data } = await markTaskCompleteGQLCall({
      variables: {
        taskId,
        active: false,
      },
    });

    if (data?.markCompletedTask) {
      handleSnack(
        <div className="admin-task-snack">
          <span className="admin-task-item-snack">Task Completed.</span>
          <button
            className="admin-task-snack-btn"
            onClick={() => {
              unMarkTask(taskId);
            }}
          >
            Restore Task?
          </button>
        </div>,
        8000
      );
      const id = perm === "admin" ? selectedTaskSortId : "";
      queryTasks(id);
      return;
    }
  };

  const handleChangeAssigneeSort = (index) => {
    const id =
      afterLastUserIndex === index
        ? "all"
        : usersInfo?.getUsersNamesForTasks[index]?._id;
    setSelectedTaskSortId(id);
    setSelectedUserIndex(index);
  };

  const searchResultSet = (result) => {
    setTaskObjUpdateBool(true);
    setAddClientOpenSearch(false);
    setTaskObj((prev) => ({
      ...prev,
      associatedClientId: result?._id,
      associatedClientName: `${result?.firstName} ${result?.lastName}`,
    }));
  };

  const handleObjChangeForUpdate = async (eventTarget) => {
    setTaskObjUpdateBool(true);
    //event = string when calendar mills updates
    if (typeof eventTarget === "string") {
      setTaskObj((prev) => ({
        ...prev,
        dueDate: eventTarget,
      }));

      return;
    }

    if (typeof eventTarget !== "string") {
      const { name, value } = eventTarget;

      if (name === "user-task-assignee-update") {
        setSelectedUserTaskIndex(value);
        const selectedUser = usersInfo?.getUsersNamesForTasks[value];
        setTaskObj((prev) => ({
          ...prev,
          assigneeName:
            value === afterLastUserIndex
              ? "All"
              : `${selectedUser?.firstName} ${selectedUser?.lastName}`,
          assigneeId: value === afterLastUserIndex ? "22" : selectedUser?._id,
        }));

        return;
      }

      setTaskObj((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }
  };

  const handleTaskUpdate = async () => {
    const { data } = await updateUserTaskGQLCall({
      variables: {
        ...taskObj,
        prevAssignedClient: clientAlreadyAssigned
      },
    });

    if (data?.updateUserTask) {
      setNonModalTask({ ...data?.updateUserTask });
      handleUpdatedTasksArr({ ...data?.updateUserTask });
      handleSnack("Task Updated", 2000);
      return;
    }

    handleSnack("Something went wrong...", 2000);
  };

  const setNonModalTask = (task) => {
    setTaskObj({ ...task });
    setTaskObjUpdateBool(false);
    setClientAlreadyAssigned(task?.associatedClientId?.length > 0)

    if (task?.assigneeId === "22") {
      setSelectedUserTaskIndex(afterLastUserIndex);
    } else {
      usersInfo?.getUsersNamesForTasks.forEach((obj, index) => {
        if (obj?._id === task?.assigneeId) {
          setSelectedUserTaskIndex(index);
        }
      });
    }

    const pastDue = parseInt(task?.dueDate) < Date.now();
    setPastDue(pastDue);
  };

  return (
    <div ref={tasksBoxRef} className="admin-tasks-box">
      <div className="admin-tasks-hdr-box">
        <h4>Tasks:</h4>
        <UserTaskCreate queryTasks={queryTasks} handleSnack={handleSnack} />
      </div>
      <div className="admin-tasks-sort-box">
        {usersInfo?.getUsersNamesForTasks?.length > 0 && perm === "admin" && (
          <FormControl variant="standard" sx={{ my: 0.5, minWidth: "100%" }}>
            <InputLabel
              id="user-task-select"
              sx={{
                color: "#05F842",
                "&.Mui-focused": { color: "#05F842" },
              }}
            >
              Sort Tasks
            </InputLabel>
            <Select
              labelId="user-task-select"
              id="user-task-select-input"
              name="user-task-select"
              value={selectedUserIndex}
              onChange={(event) => {
                handleChangeAssigneeSort(event.target.value);
              }}
              disableUnderline={true}
              sx={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                "& .Mui-disabled": {
                  color: "#fff !important",
                  WebkitTextFillColor: "#fff",
                },
              }}
              style={{ borderBottom: "1px solid #fff" }}
            >
              {usersInfo?.getUsersNamesForTasks?.length > 0 &&
                usersInfo?.getUsersNamesForTasks?.map((userInfo, index) => {
                  const username = `${userInfo?.firstName} ${userInfo?.lastName}`;

                  return (
                    <MenuItem key={userInfo?._id} value={index}>
                      {username?.trim()}
                    </MenuItem>
                  );
                })}
              <MenuItem value={afterLastUserIndex}>All</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>

      <div className="admin-tasks-btns-and-display">
        <div ref={shadowWrapper} className="shadow-wrapper">
          {shadowOn && (
            <>
              <div ref={shadowTopRef} className="shadow shadow--top">
                /\
              </div>
              <div ref={shadowBottomRef} className="shadow shadow--bottom">
                \/
              </div>
            </>
          )}

          <div ref={taskItemsBox} className="admin-tasks-btns-box">
            {tasks?.length > 0 ? (
              tasks?.map((task, index) => {
                let typeNameText = task?.associatedClientName?.length
                  ? `${task?.taskType} - ${task?.associatedClientName}`
                  : task?.taskType;
                const pastDue = parseInt(task?.dueDate) < Date.now();

                return (
                  <div
                    key={`${task?.id}-${index}`}
                    className={
                      useTaskModal === false
                        ? taskObj?.id === task?.id
                          ? "admin-task-item task-item-selected"
                          : "admin-task-item"
                        : "admin-task-item"
                    }
                  >
                    <button
                      onClick={() => {
                        markCompleted(task?.id);
                      }}
                      className="admin-page-action-img-button"
                    >
                      <CheckBoxOutlineBlankIcon
                        sx={{ color: pastDue ? "#f00000" : "#05F842" }}
                        titleAccess="Mark Completed"
                      />
                    </button>
                    <button
                      onClick={() => {
                        if (useTaskModal) {
                          handleSelectedTask(task?.id);
                          handleTaskModal(true);
                          return;
                        }
                        setNonModalTask(task);
                      }}
                      className="admin-task-item-info-box task-item-selected"
                    >
                      <div className="admin-task-type-span">{typeNameText}</div>
                      <div className="admin-task-due-no-overflow">
                        Due: {BAGroupTimeFormat(task?.dueDate)}
                      </div>
                      <div className="admin-task-type-span-note">
                        {task?.note}
                      </div>
                    </button>
                  </div>
                );
              })
            ) : (
              <h3>No Tasks To Display</h3>
            )}
          </div>
        </div>

        {useTaskModal === false && (
          <>
            {taskObj?.id ? (
              <div className="admin-tasks-non-modal-display">
                {pastDue && (
                  <h2
                    className="client-task-due-color-red"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Past Due
                  </h2>
                )}
                <ul className="client-tasks-modal-ul">
                  <li>
                    <span className="client-tasks-modal-li-marker"></span>
                    <span className="green-color-text client-tasks-li-margin">
                      Client:
                    </span>
                    {taskObj?.associatedClientName?.length > 0 ? (
                      <button
                        className="client-tasks-start-button"
                        onClick={() => {
                          navigate(
                            `/bdr-office/clients/view-client/${taskObj?.associatedClientId}`
                          );
                        }}
                      >
                        {taskObj?.associatedClientName} - View
                      </button>
                    ) : (
                      <>
                        <span>N/A</span>
                        <button
                          className="client-tasks-modal-add-btn"
                          onClick={() => {
                            setAddClientOpenSearch((prev) => !prev);
                          }}
                        >
                          {addClientOpenSearch ? "Cancel" : "+"}
                        </button>
                      </>
                    )}
                  </li>
                  {addClientOpenSearch && (
                    <li>
                      <div className="client-tasks-modal-search-div">
                        <ClientSearchInput
                          setWidth={"95%"}
                          returnResults={searchResultSet}
                        />
                      </div>
                    </li>
                  )}
                  <li>
                    <span className="client-tasks-modal-li-marker"></span>
                    <span className="green-color-text client-tasks-li-margin">
                      Task Type:
                    </span>
                    <FormControl variant="standard" sx={{ my: 1 }}>
                      <Select
                        labelId="user-task-type-select"
                        id="user-task-type-select-input"
                        name="taskType"
                        value={taskObj?.taskType}
                        onChange={(event) => {
                          handleObjChangeForUpdate(event.target);
                        }}
                        disableUnderline={true}
                        sx={{
                          color: "#fff",
                          WebkitTextFillColor: "#fff",
                          "& .Mui-disabled": {
                            color: "#fff !important",
                            WebkitTextFillColor: "#fff",
                          },
                        }}
                        style={{ borderBottom: "1px solid #fff" }}
                      >
                        <MenuItem value="F/U">F/U</MenuItem>
                        <MenuItem value="Schedule">Schedule</MenuItem>
                        <MenuItem value="Send Payment Receipt">
                          Send Payment Receipt
                        </MenuItem>
                        <MenuItem value="Appt / Invoice">
                          Appt / Invoice
                        </MenuItem>
                        <MenuItem value="Add To Ledger">Add To Ledger</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </li>
                  <li>
                    <span className="client-tasks-modal-li-marker"></span>
                    <span className="green-color-text client-tasks-li-margin">
                      Status:
                    </span>
                    {taskObj?.active ? "Active" : "Completed"}
                  </li>
                  <li>
                    <span className="client-tasks-modal-li-marker"></span>
                    <span className="green-color-text client-tasks-li-margin">
                      Assigned to:
                    </span>
                    <FormControl variant="standard" sx={{ mx: 0.8 }}>
                      <Select
                        labelId="user-task-assignee-update"
                        id="user-task-assignee-update-input"
                        name="user-task-assignee-update"
                        value={selectedUserTaskIndex}
                        onChange={(event) => {
                          handleObjChangeForUpdate(event.target);
                        }}
                        disableUnderline={true}
                        sx={{
                          color: "#fff",
                          WebkitTextFillColor: "#fff",
                          "& .Mui-disabled": {
                            color: "#fff !important",
                            WebkitTextFillColor: "#fff",
                          },
                        }}
                        style={{ borderBottom: "1px solid #fff" }}
                      >
                        {usersInfo?.getUsersNamesForTasks?.length > 0 &&
                          usersInfo?.getUsersNamesForTasks?.map(
                            (userInfo, index) => {
                              const username = `${userInfo?.firstName} ${userInfo?.lastName}`;

                              return (
                                <MenuItem key={userInfo?._id} value={index}>
                                  {username?.trim()}
                                </MenuItem>
                              );
                            }
                          )}
                        <MenuItem value={afterLastUserIndex}>All</MenuItem>
                      </Select>
                    </FormControl>
                  </li>
                  <li>
                    <span className="client-tasks-modal-li-marker"></span>
                    <span className="green-color-text client-tasks-li-margin">
                      Created:
                    </span>
                    {BAGroupTimeFormat(taskObj?.dateCreated)}
                  </li>
                  <li>
                    <span className="client-tasks-modal-li-marker"></span>
                    <div className="client-tasks-modal-cal-div">
                      <CalendarTimeSelect
                        hideInput={true}
                        returnMill={handleObjChangeForUpdate}
                        leadingText={
                          <>
                            <span className="green-color-text client-tasks-li-margin">
                              Due Date:
                            </span>
                            {BAGroupTimeFormat(taskObj?.dueDate)}
                          </>
                        }
                        passedSelectedTime={taskObj?.dueDate}
                      />
                    </div>
                  </li>
                  <li>
                    <CssTextFieldStandard
                      sx={{ width: "100%", my: 0.5 }}
                      label="Note:"
                      name="note"
                      value={taskObj?.note}
                      onChange={(event) =>
                        handleObjChangeForUpdate(event.target)
                      }
                      multiline
                      minRows={2}
                    />
                  </li>
                </ul>
                <div className="client-tasks-modal-mark-complete-box">
                  {taskObjUpdateBool && (
                    <button
                      className="client-tasks-start-button"
                      onClick={handleTaskUpdate}
                    >
                      Update
                    </button>
                  )}
                  <button
                    className="client-tasks-start-button"
                    onClick={() => {
                      if (taskObj?.active) {
                        markCompleted(taskObj?.id);
                        return;
                      }
                      unMarkTask(taskObj?.id);
                      return;
                    }}
                  >
                    {taskObj?.active ? "Mark Completed" : "Mark Incomplete"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="admin-tasks-non-modal-display no-task-display">
                <h3>No Task Selected</h3>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboardTasks;
