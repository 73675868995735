import React, { useEffect, useState, useRef } from "react";
import "./recentBAGsDropdown.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_RECENT_CREATED_BAGS } from "../../../../utils/GraphQL/queries";
import { DELETE_BA_GROUP } from "../../../../utils/GraphQL/mutations";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { Button, Snackbar, useMediaQuery } from "@mui/material";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import ImgGroupModal from "../../../../components/Modal/imgGroupModal";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";

const RecentBAGsDropdown = ({ heightTrigger }) => {
  const [loading, setLoading] = useState(false);
  const [barCollapsed, setBarCollapsed] = useState(true);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [getBAGs] = useLazyQuery(GET_RECENT_CREATED_BAGS);
  const [imgData, setImgData] = useState([]);
  const recentBAGsScrollRef = useRef();
  const [modalGroupArrId, setModalGroupArrId] = useState(Number);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [deleteBAGCall] = useMutation(DELETE_BA_GROUP);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
    duration: 2000,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (imgData?.length === 0 && barCollapsed === false) {
      setLoading(true);
      getRecentBAGs();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCollapsed]);

  const getRecentBAGs = async () => {
    const { data } = await getBAGs({
      fetchPolicy: "network-only",
    });

    setImgData([...data?.getRecentCreatedBAGs]);
    setLoading(false);
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms || 2000,
    });
  };

  // modal controls
  const handleImgOpen = () => setImgModalOpen(true);
  const handleImgClose = () => setImgModalOpen(false);

  const imgModalEvent = (event) => {
    handleImgOpen();
    if (event.target.id === "") {
      setModalGroupArrId(event.target.parentNode.id);
      return;
    }

    setModalGroupArrId(event.target.id);
  };

  const deleteBAG = async (id) => {
    const { data } = await deleteBAGCall({
      variables: {
        baGroupId: id,
      },
    });

    if (data?.deleteBAGroup === true) {
      handleSnack("Group Deleted", 2000);
      setLoading(true);
      getRecentBAGs();
      return;
    }

    handleSnack("Something went wrong...", 2500);
  };

  const deleteBAGSnack = (id) => {
    handleSnack(
      <div className="recent-bags-snack">
        <span>Delete Image Group?</span>
        <button
          className="recent-bags-action-img-button"
          onClick={(event) => {
            event.stopPropagation();
            deleteBAG(id);
            handleSnackClose();
          }}
        >
          <CheckIcon
            sx={{ fontSize: "20px", color: "#05F842" }}
            titleAccess="Yes"
          />
        </button>
        <button
          className="recent-bags-action-img-button"
          onClick={(event) => {
            event.stopPropagation();
            handleSnackClose();
          }}
        >
          <BlockIcon
            sx={{ fontSize: "20px", color: "#c80404" }}
            titleAccess="No"
          />
        </button>
      </div>,
      6000
    );
  };

  return (
    <div
      id="admin-pic-lookup-height-marker"
      className="recent-bags-container"
      ref={recentBAGsScrollRef}
    >
      {loading && <AdminBDRSpinner message={"Loading..."} />}
      {imgModalOpen ? (
        <ImgGroupModal
          BAGroup={imgData[modalGroupArrId]}
          handleImgClose={handleImgClose}
          imgModalOpen={imgModalOpen}
        />
      ) : null}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <button
        onClick={() => {
          if (heightTrigger) {
            heightTrigger();
          }
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = recentBAGsScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              recentBAGsScrollRef.current.scrollIntoView({ block: "start" });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);

          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Recent BAGs</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        {imgData?.length > 0 && (
          <div className="recent-bags-dropdown">
            {imgData?.map((singleGroupData, index) => {
              return (
                <div
                  className="recent-bags-images-box"
                  key={singleGroupData?._id + index}
                >
                  <Button
                    className="recent-bags-img-box"
                    id={index}
                    onClick={imgModalEvent}
                  >
                    <img
                      className="recent-bags-img"
                      src={singleGroupData?.beforeId?.imageData}
                      alt={"Door Project Before" + (index + 1)}
                    />
                    <img
                      className="recent-bags-img"
                      src={singleGroupData?.afterId?.imageData}
                      alt={"Door Project After" + (index + 1)}
                    />
                  </Button>
                  <span className="recent-bags-stain-hdr">
                    {singleGroupData?.stainColor}
                  </span>
                  <span className="recent-bags-stain-hdr">
                    {BAGroupTimeFormat(
                      singleGroupData?.dateEntered,
                      false,
                      true
                    )}{" "}
                    -{""}
                    <button
                      className="recent-bags-action-img-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteBAGSnack(singleGroupData?._id);
                      }}
                    >
                      <DeleteIcon
                        titleAccess="Delete"
                        sx={{ fontSize: "13px" }}
                      />
                    </button>
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentBAGsDropdown;
