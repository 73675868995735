import React, { useState, useRef, useEffect } from "react";
import "./clientTextMesDisplay.css";
import { SEND_SMS_TEXT_STRIPPED_RETURN } from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { useMediaQuery } from "@mui/material";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";

const ClientTextMesDisplay = ({
  textMesIncArr,
  handleSnack,
  closeMessages,
  selectedPhnId,
}) => {
  const [sendSMSgql] = useMutation(SEND_SMS_TEXT_STRIPPED_RETURN, {
    fetchPolicy: "network-only",
  });
  const [shadowOn, setShadowOn] = useState(false);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [refocusMes, setRefocusMes] = useState(false);
  const [sendMesInput, setSendMesInput] = useState("");
  const [textMes, setTextMes] = useState([]);
  const [selectedNum, setSelectedNum] = useState(0);
  const textMesBoxRef = useRef();
  const shadowTopRef = useRef();
  const shadowBottomRef = useRef();
  const textMesRef = useRef();
  const sendMesRef = useRef();
  const shadowWrapper = useRef();
  const [hoverScrollObj, setHoverScrollObj] = useState({
    hoverUp: false,
    hoverDown: false,
    currentScroll: 0,
    totalScroll: 0,
    initScroll: true,
  });

  useEffect(() => {
    if (textMesIncArr?.length) {
      const sortedMsgs = textMesIncArr?.map((phnObj) => {
        const sorted = [...phnObj?.textMessages]?.sort((a, b) => {
          const aNum = parseInt(a?.sentReceivedTime);
          const bNum = parseInt(b?.sentReceivedTime);
          return aNum > bNum ? 1 : -1;
        });

        return { ...phnObj, textMessages: [...sorted] };
      });
      // console.log(textMesIncArr);
      setTextMes([...sortedMsgs]);
    }
  }, [textMesIncArr]);

  useEffect(() => {
    textMesIncArr?.map((obj, index) => {
      if (obj._id === selectedPhnId) {
        setSelectedNum(index);
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhnId]);

  useEffect(() => {
    const textMesBoxEl = textMesRef.current;
    const totalScroll = textMesBoxEl?.scrollHeight - textMesBoxEl?.offsetHeight;
    setHoverScrollObj((prev) => ({
      ...prev,
      totalScroll,
    }));

    if (textMesBoxEl.offsetHeight < textMesBoxEl.scrollHeight) {
      setShadowOn(true);
      const handleShadow = () => {
        const contentScroll =
          textMesBoxEl.scrollHeight - textMesBoxEl.offsetHeight;

        var currentScroll = textMesBoxEl.scrollTop / contentScroll;
        shadowTopRef.current.style.opacity = currentScroll;
        shadowBottomRef.current.style.opacity = 1 - currentScroll;
      };

      // Bind the event listener
      textMesBoxEl.addEventListener("scroll", handleShadow);
      // textMesBoxEl?.scroll(textMesBoxEl.scrollHeight)
      return () => {
        // Unbind the event listener on clean up
        textMesBoxEl.removeEventListener("scroll", handleShadow);
      };
    }

    if (textMesBoxEl.offsetHeight >= textMesBoxEl.scrollHeight) {
      setShadowOn(false);
    }
  }, [textMes]);

  useEffect(() => {
    const textMesBoxEl = textMesRef.current;
    const currentScroll = Math.floor(textMesBoxEl?.scrollTop);
    let id = "";

    // Stop state change and scrolling operation
    if (hoverScrollObj?.initScroll === false) {
      if (
        hoverScrollObj?.currentScroll >= hoverScrollObj?.totalScroll ||
        hoverScrollObj?.currentScroll <= 0
      ) {
        if (
          hoverScrollObj?.hoverDown === true ||
          hoverScrollObj?.hoverUp === true
        ) {
          setHoverScrollObj((prev) => ({
            ...prev,
            hoverDown: false,
            hoverUp: false,
          }));
        }
        return;
      }
    }

    if (hoverScrollObj?.hoverUp === true) {
      id = setTimeout(() => {
        textMesBoxEl?.scrollTo({
          top: currentScroll - 100,
          behavior: "smooth",
        });
        setHoverScrollObj((prev) => ({
          ...prev,
          initScroll: false,
          currentScroll: currentScroll - 100,
        }));
      }, 100);
    }

    if (hoverScrollObj?.hoverDown === true) {
      id = setTimeout(() => {
        textMesBoxEl?.scrollTo({
          top: currentScroll + 100,
          behavior: "smooth",
        });
        setHoverScrollObj((prev) => ({
          ...prev,
          initScroll: false,
          currentScroll: currentScroll + 100,
        }));
      }, 100);
    }

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverScrollObj]);

  useEffect(() => {
    const textMesBox = textMesRef.current;
    textMesBox?.scrollTo({ top: textMesBox.scrollHeight, behavior: "smooth" });
    // textMesBoxRef?.current?.scrollIntoView({block: "start"})
    const id = setTimeout(() => {
      if (smaller900 === true) {
        const navHeight = document.querySelector(
          "#admin-nav-height-marker"
        )?.offsetHeight;
        const pagePos = textMesBoxRef.current.offsetTop;

        const totalMobileScroll = pagePos - navHeight - 5;
        window.scrollTo(0, totalMobileScroll);
      }

      if (smaller900 === false) {
        window.scrollTo(0, textMesBoxRef.current.offsetTop - 5);
      }
    }, 500);
    return () => clearTimeout(id);
  }, [textMes, selectedNum, refocusMes, smaller900]);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "sendMessageText") {
      setSendMesInput(value);

      return;
    }
  };

  const scrollInputIntoView = () => {
    const sendRef = sendMesRef.current;
    const elHeight = sendRef?.offsetHeight;
    const scroll =
      window.scrollY +
      sendMesRef.current.getBoundingClientRect().top -
      window?.visualViewport.height +
      elHeight +
      80;
    window?.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
  };

  const inputBlur = () => {
    setRefocusMes((prev) => !prev);
  };

  const handleHoverScroll = (scrollUp, stateChange) => {
    const name = scrollUp ? "hoverUp" : "hoverDown";
    setHoverScrollObj((prev) => ({
      ...prev,
      currentScroll: prev.currentScroll - 1,
      initScroll: true,
      [name]: stateChange,
    }));
  };

  const sendMessageCall = async () => {
    const date = Date.now().toString();
    const obj = {
      textBody: sendMesInput,
      localTime: date,
      phoneNumId: textMes[selectedNum]?._id || null,
      sendToNum: textMes[selectedNum]?.phoneNumber || null,
    };

    const { data } = await sendSMSgql({
      variables: { ...obj },
    });

    if (data?.sendSMS?._id) {
      // if successful message send
      const objForUpdate = {
        body: obj?.textBody,
        outgoingBool: true,
        sentReceivedTime: date,
        twilioSID: null,
      };
      setTextMes((prev) => {
        const newArr = prev?.map((phnObj) => {
          if (phnObj?._id !== data?.sendSMS?._id) {
            return phnObj;
          }
          return {
            ...phnObj,
            textMessages: [...phnObj?.textMessages, objForUpdate],
          };
        });

        return newArr;
      });
      setSendMesInput("");

      return;
    }

    if (handleSnack) {
      handleSnack("Something went wrong...Please try again!", 2500);
    }
  };

  return (
    <div ref={textMesBoxRef} className="client-texts-box">
      <div className="client-texts-hdr-box">
        <h4>Messages</h4>
        <button
          className="client-texts-btn"
          onClick={() => {
            closeMessages(false);
          }}
        >
          Close
        </button>
      </div>
      <div className="client-texts-text-and-num-box">
        <div ref={shadowWrapper} className="client-texts-shadow-wrapper">
          {shadowOn && (
            <>
              <div
                ref={shadowTopRef}
                className="client-texts-shadow client-texts-shadow--top"
              ></div>
              {!smaller900 && (
                <>
                  <button
                    onMouseOver={() => {
                      handleHoverScroll(true, true);
                    }}
                    onMouseOut={() => {
                      handleHoverScroll(true, false);
                    }}
                    className="client-texts-hvr-scroll client-texts-hvr-scroll-top"
                  ></button>
                  <button
                    onMouseOver={() => {
                      handleHoverScroll(false, true);
                    }}
                    onMouseOut={() => {
                      handleHoverScroll(false, false);
                    }}
                    className="client-texts-hvr-scroll client-texts-hvr-scroll-bottom"
                  ></button>
                </>
              )}

              <div
                ref={shadowBottomRef}
                className="client-texts-shadow client-texts-shadow--bottom"
              ></div>
            </>
          )}

          <div ref={textMesRef} className="client-texts-mes-box">
            {textMes[selectedNum]?.textMessages?.length > 0 ? (
              <>
                {textMes[selectedNum]?.textMessages?.map((mesObj, index) => {
                  const timeStr = BAGroupTimeFormat(
                    mesObj?.sentReceivedTime,
                    true,
                    true
                  );

                  const today = /today/gi.test(timeStr);
                  const oneYear = 1000 * 60 * 60 * 24 * 365;
                  const olderThenAYear =
                    Date.now() - oneYear > parseInt(mesObj?.sentReceivedTime);

                  const time = today
                    ? `${timeStr?.split("@")[1]}`
                    : olderThenAYear
                    ? `${timeStr?.split(",")[1]?.replace(/@/gi, "-")}`
                    : `${BAGroupTimeFormat(
                        mesObj?.sentReceivedTime,
                        true,
                        false
                      )?.replace(/@/gi, "-")}`;

                  return (
                    <div
                      key={`text-item-${index}`}
                      className={`client-texts-mes-box-${
                        mesObj?.outgoingBool ? "out" : "inc"
                      }`}
                    >
                      <div
                        className={`client-texts-mes-item client-texts-mes-item-${
                          mesObj?.outgoingBool ? "out" : "inc"
                        }`}
                      >
                        <div>{mesObj?.body}</div>
                        <div className="client-texts-mes-item-date">{`- ${time}`}</div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="client-texts-messenger-box">
          <CssTextFieldStandard
            sx={{ width: "75%", m: 0.5 }}
            inputRef={sendMesRef}
            type="text"
            label={"Message"}
            name="sendMessageText"
            onChange={handleChange}
            onFocus={scrollInputIntoView}
            onBlur={inputBlur}
            value={sendMesInput}
            variant="standard"
            multiline
            minRows={1}
          />
          <button
            className={
              sendMesInput?.length > 0
                ? "client-texts-btn"
                : "client-texts-btn send-text-dis"
            }
            disabled={!sendMesInput?.length > 0}
            onClick={sendMessageCall}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientTextMesDisplay;
