import React, { useState, useRef, useEffect } from "react";
import "./createClient.css";
import {
  CssTextFieldStandard,
  MyFormHelperText,
} from "../../../../components/CssTextFields/CssTextFieldStandard";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import phoneNumberFormat from "../../../../utils/formatters/phoneNumberFormat";
import { CREATE_NEW_CLIENT } from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";
import CreateClientValidator from "../../../../utils/formValidators/createClientValidate";
import { useNavigate } from "react-router";
import Auth from "../../../../utils/auth";
import pasteKeapNote from "../../../../utils/formatters/pasteKeapNote";
import pasteKeapAddress from "../../../../utils/formatters/pasteKeapAddress";

const clearForm = {
  firstName: "",
  lastName: "",
  spouseName: "",
  primaryEmail: "",
  secondaryEmail: "",
  streetAddress: "",
  city: "",
  state: "Texas",
  zipCode: "",
  gateCode: "",
  clientNotes: {
    note: "",
    dateCreated: "1692587955080",
    dateUpdated: "",
    updatedBy: "",
    createdBy: "Taylor",
  },
  clientStatus: "Lead",
};

const CreateClient = ({
  heightTrigger,
  recentClientsCall,
  handleSnack,
  changeSaving,
  dropdown,
  setOpenDisplay,
  passedPhnObj,
  queryMessages,
}) => {
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [errorsArr, setErrorsArr] = useState([]);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const createClientDiv = useRef();
  const [createNewClientGQL] = useMutation(CREATE_NEW_CLIENT);
  const [newClientForm, setClientForm] = useState({
    ...clearForm,
    phoneNumberIds: [
      {
        phoneNumber: "",
        numberType: "",
        title: "",
      },
    ],
  });
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [selectionRange, setSelectionRange] = useState(null);
  const inputRef = useRef();

  // Form Validator
  const createValidate = new CreateClientValidator({
    ...newClientForm,
    existingPhn: passedPhnObj?._id,
  });

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (selectionRange) {
      inputRef?.current?.setSelectionRange(selectionRange, selectionRange);
    }
  }, [selectionRange]);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (
      name === "firstName" ||
      name === "lastName" ||
      name === "spouseName" ||
      name === "streetAddress" ||
      name === "city" ||
      name === "state"
    ) {
      value = capitalizeFirstLetters(value);
    }

    if (name === "primaryEmail" || name === "secondaryEmail") {
      value = value.toLowerCase();
      const noMailTo = value?.replace(/mailto:/gm, "");
      value = noMailTo;
    }

    if (name === "zipCode") {
      value = value.replace(/[^0-9-]/gi, "");
    }

    setClientForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneNumChange = (event, index) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "phoneNumber") {
      const numOnly = value?.replace(/[^0-9]/gi, "");
      value = phoneNumberFormat(numOnly);
    }

    setClientForm((prev) => {
      const numbersArr = [...prev?.phoneNumberIds];
      numbersArr[index][name] = value;

      return {
        ...prev,
        phoneNumberIds: [...numbersArr],
      };
    });
  };

  const getUser = async () => {
    const user = await Auth.getProfile();

    if (user?.data.firstName) {
      setUserName(user?.data.firstName);
    }
    return;
  };

  const handleNoteChange = (event) => {
    let target = event.target;
    let value = target.value;
    let selectionRangeVal = null;

    //regex to look behind and only find double asterisk that
    //isn't preceded by another asterisk. only finding the closing asterisks.
    const breakOutOfAsterisk = (prevNote, forwardBool) => {
      const regex = forwardBool
        ? /(?<=\*\*)([ \t]?\w{1}[\w\W\s]*?)(?=\*{2})/gm
        : /\*{4}/gim;
      const cursorStart = forwardBool
        ? target.selectionStart - 1
        : target.selectionStart;
      const matched = prevNote?.match(regex);
      const indexOfObjArr = matched?.map((str) => {
        const start = prevNote?.indexOf(str);
        const end = start + str?.length;
        return { start, end };
      });
      // console.log("matched", matched);
      // console.log("indexOfObjArr", indexOfObjArr);
      // console.log("cursorStart", cursorStart);

      let returnedItem = { cursorPos: null, prevNote: null };

      indexOfObjArr?.forEach((obj) => {
        let splicedNote = "";

        if (forwardBool) {
          if (obj?.start <= cursorStart && obj?.end >= cursorStart) {
            const afterAsterisk = obj?.end + 2;
            splicedNote =
              prevNote?.slice(0, afterAsterisk) +
              "\n" +
              prevNote?.slice(afterAsterisk);
            returnedItem = {
              cursorPos: afterAsterisk + 1,
              prevNote: splicedNote,
            };
          }
        }

        if (!forwardBool) {
          if (obj?.start <= cursorStart && obj?.end > cursorStart) {
            splicedNote =
              prevNote?.slice(0, obj?.start) + prevNote?.slice(obj?.end);
            returnedItem = {
              cursorPos: obj?.start,
              prevNote: splicedNote,
            };
          }
        }
      });

      return returnedItem;
    };

    // Regex test for triple asterisk shortcut to
    // set double asterisk start and end and cursor middle
    const shortcutRegex = smaller900
      ? /(?<![c])[c]{3}(?![c]+)/gi
      : /(?<!\*)\*{3}(?!\*+)/gi;

    if (shortcutRegex.test(value)) {
      const replacement = value?.length === 3 ? "****" : "\n****";
      selectionRangeVal =
        value?.length === 3 ? target.selectionStart - 1 : target.selectionStart;
      value = target.value?.replace(shortcutRegex, replacement);
    }

    setClientForm((prev) => {
      const date = Date.now().toString();
      const type = event?.nativeEvent?.inputType;
      if (type === "insertLineBreak" || type === "deleteContentBackward") {
        const forwardBool = type === "insertLineBreak" ? true : false;
        const { cursorPos, prevNote } = breakOutOfAsterisk(
          prev?.clientNotes?.note,
          forwardBool
        );
        selectionRangeVal = cursorPos;

        return {
          ...prev,
          clientNotes: {
            note: prevNote || value,
            dateCreated: date,
            dateUpdated: "",
            updatedBy: "",
            createdBy: userName,
          },
        };
      }

      return {
        ...prev,
        clientNotes: {
          note: value,
          dateCreated: date,
          dateUpdated: "",
          updatedBy: "",
          createdBy: userName,
        },
      };
    });

    setSelectionRange(selectionRangeVal);

    return;
  };

  const addClientNumber = () => {
    setClientForm((prev) => {
      const numbersArr = prev?.phoneNumberIds;

      numbersArr.push({
        phoneNumber: "",
        numberType: "",
        title: "",
      });

      return {
        ...prev,
        phoneNumberIds: [...numbersArr],
      };
    });
  };

  const removeClientNumber = () => {
    setClientForm((prev) => {
      const numbersArr = [...prev?.phoneNumberIds];

      numbersArr.pop();

      return {
        ...prev,
        phoneNumberIds: [...numbersArr],
      };
    });
  };

  const handleKeapPaste = async () => {
    const date = Date.now().toString();
    const text = await pasteKeapNote(navigator?.clipboard, handleSnack);
    setClientForm((prev) => {
      const updatedText = `${prev?.clientNotes?.note?.trim()}\n${text}`;
      return {
        ...prev,
        clientNotes: {
          note: updatedText?.trim(),
          dateCreated: date,
          dateUpdated: "",
          updatedBy: "",
          createdBy: userName,
        },
      };
    });
  };

  const handlePasteAddress = async () => {
    const addressObj = await pasteKeapAddress(
      navigator?.clipboard,
      handleSnack
    );

    setClientForm((prev) => {
      return {
        ...prev,
        ...addressObj,
      };
    });
  };

  const handleStatus = (status) => {
    setClientForm((prev) => ({
      ...prev,
      clientStatus: status,
    }));
  };

  const clearErrors = () => {
    setErrorsArr([]);
  };

  const handleSaveClient = async () => {
    const valid = await createValidate.createClientIsValid();
    const id = passedPhnObj?._id || null;

    if (valid === true) {
      const { data } = await createNewClientGQL({
        variables: {
          ...newClientForm,
          existingPhnId: id,
        },
      });

      if (data?.createNewClient?._id) {
        // reset form on successful client save
        setClientForm(() => {
          return {
            ...clearForm,
            phoneNumberIds: [
              {
                phoneNumber: "",
                numberType: "",
                title: "",
              },
            ],
          };
        });

        if (id) {
          queryMessages(false);
          handleSnack(
            `${passedPhnObj?.phoneNumber} assigned to ${data?.createNewClient?.firstName} ${data?.createNewClient?.lastName}`,
            2500
          );
          setOpenDisplay(false);
          return;
        }

        navigate(
          `/bdr-office/clients/view-client/${data?.createNewClient?._id}`
        );
        return;
      }
    }

    setErrorsArr(valid);
    if (handleSnack) {
      handleSnack("Something went wrong. Please try again.", 2500);
    }
    if (changeSaving) {
      changeSaving(false, "");
    }
  };

  return dropdown ? (
    <div
      id="create-client-height-marker"
      className="create-client-container"
      ref={createClientDiv}
    >
      <button
        onClick={() => {
          if (heightTrigger) {
            heightTrigger();
          }

          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = createClientDiv.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;

              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              createClientDiv.current.scrollIntoView({ block: "start" });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);

          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Create Client</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="create-client-form">
          <h5>Client Status: {newClientForm?.clientStatus}</h5>
          <div className="create-client-status-box">
            <button
              className={
                newClientForm?.clientStatus === "Lead"
                  ? "create-client-btn success"
                  : "create-client-btn"
              }
              onClick={() => {
                handleStatus("Lead");
              }}
            >
              Lead
            </button>
            <button
              className={
                newClientForm?.clientStatus === "Client"
                  ? "create-client-btn success"
                  : "create-client-btn"
              }
              onClick={() => {
                handleStatus("Client");
              }}
            >
              Client
            </button>
            <button
              className={
                newClientForm?.clientStatus === "Other"
                  ? "create-client-btn success"
                  : "create-client-btn"
              }
              onClick={() => {
                handleStatus("Other");
              }}
            >
              Other
            </button>
          </div>
          <div className="create-client-errors-box">
            {errorsArr && (
              <ul>
                {errorsArr?.map((err, index) => {
                  return <li key={err + index}>{err}</li>;
                })}
              </ul>
            )}
          </div>
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
            type="text"
            label="First Name"
            name="firstName"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.firstName}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
            type="text"
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.lastName}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: "95%", my: 1 }}
            type="text"
            label="Spouse's Name"
            name="spouseName"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.spouseName}
            variant="standard"
          />
          <div className="create-client-add-phones-btn-box">
            <button className="create-client-btn" onClick={addClientNumber}>
              Add Phone Number
            </button>
            {newClientForm?.phoneNumberIds?.length > 1 && (
              <button
                className="create-client-btn"
                onClick={removeClientNumber}
              >
                Remove Number
              </button>
            )}
          </div>
          {newClientForm?.phoneNumberIds?.length > 0 &&
            newClientForm?.phoneNumberIds?.map((obj, index) => {
              return (
                <div
                  key={"phoneNumberCreationObj" + index}
                  className="create-client-phone-box"
                >
                  <CssTextFieldStandard
                    sx={{ width: "55%", my: 1 }}
                    type="tel"
                    inputProps={{ inputMode: "numeric" }}
                    label={`Phone Number ${index + 1}`}
                    name="phoneNumber"
                    onChange={(event) => {
                      handlePhoneNumChange(event, index);
                    }}
                    value={obj?.phoneNumber}
                    variant="standard"
                  />
                  <FormControl
                    style={{ alignSelf: "flex-end" }}
                    variant="standard"
                    sx={{ m: 1, minWidth: "20%" }}
                  >
                    <InputLabel
                      id="num-type-select"
                      sx={{
                        color: "#05F842",
                        "&.Mui-focused": { color: "#05F842" },
                      }}
                    >
                      Number Type
                    </InputLabel>
                    <Select
                      labelId="email-temp-select"
                      id="email-temp-select-input"
                      name="numberType"
                      value={obj?.numberType}
                      onChange={(event) => {
                        handlePhoneNumChange(event, index);
                      }}
                      disableUnderline={true}
                      sx={{ color: "#fff" }}
                      style={{ borderBottom: "1px solid #fff" }}
                    >
                      <MenuItem value={"Home"}>Home</MenuItem>
                      <MenuItem value={"Cell"}>Cell</MenuItem>
                      <MenuItem value={"Work"}>Work</MenuItem>
                      <MenuItem value={"Spouse"}>Spouse</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              );
            })}
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
            type="text"
            label="Primary Email"
            name="primaryEmail"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.primaryEmail}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
            type="text"
            label="Secondary Email (optional)"
            name="secondaryEmail"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.secondaryEmail}
            variant="standard"
          />
          <div className="create-client-paste-note-box">
            <button className="create-client-btn" onClick={handlePasteAddress}>
              Paste Address
            </button>
          </div>
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
            type="text"
            label="Street Address"
            name="streetAddress"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.streetAddress}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
            type="text"
            label="City"
            name="city"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.city}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
            type="text"
            label="State"
            name="state"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.state}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
            type="text"
            label="Zip Code"
            inputProps={{ maxLength: 10, inputMode: "numeric" }}
            name="zipCode"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.zipCode}
            variant="standard"
          />
          <CssTextFieldStandard
            sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
            type="text"
            label="Gate Code"
            name="gateCode"
            onChange={handleChange}
            onBlur={handleChange}
            value={newClientForm?.gateCode}
            variant="standard"
          />
          <div className="create-client-paste-note-box">
            <button className="create-client-btn" onClick={handleKeapPaste}>
              Paste Keap Note
            </button>
          </div>
          <MyFormHelperText
            errorText={
              "Note seems to contain a gate code. Please ensure gate code is entered in correct box and removed from note."
            }
            formError={/gate code|gate:/gi?.test(
              newClientForm?.clientNotes?.note
            )}
          />
          <CssTextFieldStandard
            inputRef={inputRef}
            sx={{ width: "95%", my: 1 }}
            type="text"
            label="Note: "
            name="note"
            onChange={handleNoteChange}
            value={newClientForm?.clientNotes?.note}
            multiline
            minRows={5}
          />
          <button
            className="create-client-btn"
            onClick={() => {
              clearErrors();
              if (changeSaving) {
                changeSaving(true, "Saving...");
              }
              handleSaveClient();
            }}
          >
            Save Client
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="text-msgs-numbers-box">
      <div className="create-quote-modal-close-container">
        <h5>Create Client</h5>
        <button
          onClick={(event) => {
            event.stopPropagation();
            setOpenDisplay(false);
          }}
          className="create-client-btn"
        >
          Cancel
        </button>
      </div>
      {/* <div className="cust-quote-page"> */}
      <div className="create-client-form">
        <h5>Client Status: {newClientForm?.clientStatus}</h5>
        <div className="create-client-status-box">
          <button
            className={
              newClientForm?.clientStatus === "Lead"
                ? "create-client-btn success"
                : "create-client-btn"
            }
            onClick={() => {
              handleStatus("Lead");
            }}
          >
            Lead
          </button>
          <button
            className={
              newClientForm?.clientStatus === "Client"
                ? "create-client-btn success"
                : "create-client-btn"
            }
            onClick={() => {
              handleStatus("Client");
            }}
          >
            Client
          </button>
          <button
            className={
              newClientForm?.clientStatus === "Other"
                ? "create-client-btn success"
                : "create-client-btn"
            }
            onClick={() => {
              handleStatus("Other");
            }}
          >
            Other
          </button>
        </div>
        <div className="create-client-errors-box">
          {errorsArr && (
            <ul>
              {errorsArr?.map((err, index) => {
                return <li key={err + index}>{err}</li>;
              })}
            </ul>
          )}
        </div>
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
          type="text"
          label="First Name"
          name="firstName"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.firstName}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
          type="text"
          label="Last Name"
          name="lastName"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.lastName}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: "95%", my: 1 }}
          type="text"
          label="Spouse's Name"
          name="spouseName"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.spouseName}
          variant="standard"
        />
        {newClientForm?.phoneNumberIds?.length > 0 &&
          newClientForm?.phoneNumberIds?.map((obj, index) => {
            return (
              <div
                key={"phoneNumberCreationObj" + index}
                className="create-client-phone-box"
              >
                <CssTextFieldStandard
                  sx={{ width: "55%", my: 1 }}
                  type="tel"
                  inputProps={{ maxLength: 12, inputMode: "numeric" }}
                  label={`Phone Number ${index + 1}`}
                  name="phoneNumber"
                  value={passedPhnObj?.phoneNumber}
                  variant="standard"
                />
                <FormControl
                  style={{ alignSelf: "flex-end" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: "20%" }}
                >
                  <InputLabel
                    id="num-type-select"
                    sx={{
                      color: "#05F842",
                      "&.Mui-focused": { color: "#05F842" },
                    }}
                  >
                    Number Type
                  </InputLabel>
                  <Select
                    labelId="email-temp-select"
                    id="email-temp-select-input"
                    name="numberType"
                    value={"Cell"}
                    disableUnderline={true}
                    sx={{ color: "#fff" }}
                    style={{ borderBottom: "1px solid #fff" }}
                  >
                    <MenuItem value={"Home"}>Home</MenuItem>
                    <MenuItem value={"Cell"}>Cell</MenuItem>
                    <MenuItem value={"Work"}>Work</MenuItem>
                    <MenuItem value={"Spouse"}>Spouse</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div>
            );
          })}
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
          type="text"
          label="Primary Email"
          name="primaryEmail"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.primaryEmail}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
          type="text"
          label="Secondary Email (optional)"
          name="secondaryEmail"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.secondaryEmail}
          variant="standard"
        />
        <div className="create-client-paste-note-box">
          <button className="create-client-btn" onClick={handlePasteAddress}>
            Paste Address
          </button>
        </div>
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
          type="text"
          label="Street Address"
          name="streetAddress"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.streetAddress}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "95%" : "45%", my: 1 }}
          type="text"
          label="City"
          name="city"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.city}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
          type="text"
          label="State"
          name="state"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.state}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
          type="text"
          label="Zip Code"
          inputProps={{ maxLength: 10, inputMode: "numeric" }}
          name="zipCode"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.zipCode}
          variant="standard"
        />
        <CssTextFieldStandard
          sx={{ width: smaller900 ? "45%" : "20%", m: 1 }}
          type="text"
          label="Gate Code"
          name="gateCode"
          onChange={handleChange}
          onBlur={handleChange}
          value={newClientForm?.gateCode}
          variant="standard"
        />
        <div className="create-client-paste-note-box">
          <button className="create-client-btn" onClick={handleKeapPaste}>
            Paste Keap Note
          </button>
        </div>
        <MyFormHelperText
          errorText={
            "Note seems to contain a gate code. Please ensure gate code is entered in correct box and removed from note."
          }
          formError={/gate code|gate:/gi?.test(
            newClientForm?.clientNotes?.note
          )}
        />
        <CssTextFieldStandard
          inputRef={inputRef}
          sx={{ width: "95%", my: 1 }}
          type="text"
          label="Note: "
          name="note"
          onChange={handleNoteChange}
          value={newClientForm?.clientNotes?.note}
          multiline
          minRows={5}
        />
        <button
          className="create-client-btn"
          onClick={() => {
            clearErrors();
            if (changeSaving) {
              changeSaving(true, "Saving...");
            }
            handleSaveClient();
          }}
        >
          Save Client
        </button>
      </div>
      {/* </div> */}
      {/* </div>
    </div> */}
    </div>
  );
};

export default CreateClient;
